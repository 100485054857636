export const AudioLoader = (props) => (
    // calling loader styling
    <div className="upper-calling-loader">
      <div>
      
      <p className="text-light">{props.text}<span class="loader-calling"></span></p>
      </div>
  
    </div>
  );
  