import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import useWebSocket from "react-use-websocket";
import {
  chatSocketUrl, doctorSocket,
} from "../../config/config";
import { openAiService } from "../../services/openai.services";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
const HistoryGenerate = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const doctor = searchParams.get("doctor");
  const appointmentsId = searchParams.get("id");
  const [promptQues, setPromptQues] = useState(localStorage.getItem("notes"));
  const [divClicked, setDivClicked] = useState("")
  const [showLoader, setShowLoader] = useState(false)
  const navigate = useNavigate();
  const chat_response_id = useRef();
  // let query = localStorage.getItem("notes") ? localStorage.getItem("notes") : "";
  const [id, setId] = useState();
  const [openaiId, setOpenaiId] = useState("");
  const [history, setHistory] = useState([]);
  const [editable, setEditable] = useState(true);
  const [chatTriggered, setChatTriggered] = useState(false)
  const [responseText, setResponseText] = useState("")
  const socket_url = doctorSocket + appointmentsId + '/' + doctor;
  console.log("userIduserId", "${chatSocketUrl}${userId}", socket_url)
  const [chatResponse,setChatResponse] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { sendMessage, lastMessage, readyState } = useWebSocket(`${socket_url}`);
  let i = 0;


  console.log("localStorage.getItem)", localStorage.getItem("notes"))

  useEffect(() => {
    i++;
    if (i == 1) {
      getHistory(appointmentsId)
      console.log("i fire once", 'i fire once');
      if (localStorage.getItem("notes")) {
        setShowLoader(true)
        let data = {
          prompt: localStorage.getItem("notes")
        };
        console.log("data sending------", data)
        handleSendMessage(JSON.stringify(data))
      }
    }

  }, [])

  useEffect(() => {
   setTimeout(() => {
    setSuccessMessage("")
   }, 5000); 
  }, [successMessage])

  const handleSendMessage = useCallback((data) => sendMessage(data), []);


  useEffect(() => {
    if (lastMessage !== null) {
      setShowLoader(false)
      console.log("lastmassage--", readyState, JSON.parse(lastMessage.data));

      let messageResponse = JSON.parse(lastMessage.data);
      if (!messageResponse.stream) {
        getHistory(appointmentsId)
        setChatTriggered(false)
        setPromptQues(messageResponse.prompt)
        localStorage.removeItem("notes");
      } else {
        setChatTriggered(true)
        setChatResponse(messageResponse.response)
        console.log("debug>>>>>>>>> message.response000", messageResponse.response)
        // let elm = document.getElementById("chat-response-id")
        // console.log("element-para", elm)
        // if (elm) {
        //   console.log("message.response000", messageResponse.response)
        //   // elm.append(messageResponse.message)
        //   elm.innerHTML = messageResponse.response
        // }
        document.getElementById("chat-response-id").focus();
        const textInputEl = chat_response_id.current;
        textInputEl.scrollTop = textInputEl.scrollHeight;  
      }
    }
  }, [lastMessage]);

  function getHistory(id, key=null) {
    openAiService.getNotesHistory(id)
      .then((res) => {
        setHistory(res.data?.data)
        if (res.data?.data.length < 1) {
          setPromptQues("")
          setChatResponse("")
          // let elm = document.getElementById("chat-response-id")
          // if (elm) {
          //   // elm.append(messageResponse.message)
          //   elm.innerHTML = ""
          // }

        }
        if (!localStorage.getItem("notes") && !key) {
          setData(res.data?.data[0])
          setDivClicked(res.data?.data[0].id)
        }

      })
      .catch((err) => console.log("err", err))
  }

  function deleteHistory(id) {
    openAiService.deleteNotesHistory(id)
      .then((res) => {
        getHistory(appointmentsId)
        if (history.length < 1) {
          setPromptQues("")
          setChatResponse("")
          // let elm = document.getElementById("chat-response-id")
          // if (elm) {
          //   // elm.append(messageResponse.message)
          //   elm.innerHTML = ""
          // }

        }
        console.log("deletede", res)
        Swal.fire("success", "History deleted successfully.", "success")
      })
      .catch((err) => {
        Swal.fire("error", "Something went wrong", "error")
      })
  }

  function setData(data) {
    console.log("datatata", data)
    setPromptQues(data.prompt)
    setChatResponse(data.response)
    // let elm = document.getElementById("chat-response-id")
    // console.log("element-para", elm)
    // if (elm) {
    //   elm.innerHTML = data.response
    // }
  }

  function updateResponse() {

    const response = document.getElementById("chat-response-id").value;
    var data = { response: response }
    openAiService.updateResponse(data, divClicked)
      .then((res) => {
        setEditable(true)
        getHistory(appointmentsId, "update")
        // setChatResponse(res.data?.response)
        // console.log("resres", res.data?.response)
      })
      .catch((err) => console.log("err", err))
    console.log("responseresponse", response);
  }

  function goBack(){
    navigate(-1)
  }

  function cancelRequest() {
    openAiService.getSingleNote(divClicked)
      .then((res) => {
        setPromptQues(res.data.prompt);
        setChatResponse(res.data.response)
        // document.getElementById("chat-response-id").value = res.data.response
        setEditable(true)
        console.log("responseses", res.data.response)
      })
      .catch((err) => console.log("errrr", err))
  }

  function copyTest(){
    var copyText = document.getElementById("chat-response-id");
    // copyText.select();
    // copyText.setSelectionRange(0, 99999); 
    if(navigator.clipboard.writeText(copyText.value)){
      setSuccessMessage('Copied');
      // Swal.fire("Success", "Note copied successfully.", "success")
    }else{
  }
  }

  

  return (
    <>
      <section className="generate-section pricing-area">

        <Container>
          <div class="back-btn-box"><a className="mt-0 mb-3" onClick={() => {
            goBack()
          }} > <i class="fa fa-angle-double-left me-2" aria-hidden="true"></i>Back </a></div>

          <Row>
            <Col lg={4}>
              <div style={{ cursor: "pointer" }} className={"generate-history-box position-relative left-gnerate"}>
                {history && history.length > 0 ?
                  history.map((res) => {
                    return (

                      <div className="inner-genrate-box">
                        <div onClick={() => {
                          setDivClicked(res.id)
                          setData(res)
                        }} className={divClicked == res.id ? "click-div active" : "click-div"}>
                        <h4 >{res.prompt}</h4>
                        <div className="right-generate-box">
                          <p>{moment(res.created_at).format("HH:mm | DD/MM/YYYY")}</p>
                         
                        </div>
                        </div>
                        <i class="fa fa-trash" onClick={() => {
                              Swal.fire({
                                title: "Warning",
                                text: "Are you sure you want to delete this history?",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, delete it!"
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  deleteHistory(res.id)
                                }
                              });

                            
                          }} aria-hidden="true"></i>
                      </div>

                    )
                  })

                  : <div className="no-data-box">
                     <img
                          src={require("../../assests/images/history-data.gif")}
                          alt="img"
                          className=""
                        />
                    <h5>No History Found</h5>
                   
                  </div>}
              </div>


            </Col>
            <Col lg={8}>
              <div className="generate-history-box">
                <Row>

                  <Col lg={12}>
                    <div className="transcription-box">
                    <div className="inner-summerized-area inner-summerized-area-main">
                      <h6>TRANSCRIPTION</h6>
                      </div>
                      {promptQues ? 
                      <div className="inner-transcription-box">
                        <p>{promptQues}</p>
                      </div>:
                        <div className="no-data-box inner-transcription-box">
                        <img
                             src={require("../../assests/images/history-data.gif")}
                             alt="img"
                             className=""
                           />
                       <h5>No Transcription Found</h5>
                      
                     </div>
                      }
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="transcription-box">
                      <div className="inner-summerized-area">
                      <h6>SUMMARIZED NOTES</h6>
                      {!chatTriggered && editable && promptQues &&
                        <div className="history-btn-area">
                          <Button onClick={() => {
                            document.getElementById("chat-response-id").focus()
                            setEditable(false)
                          }} title="Edit Note"><i class="fa fa-pencil" aria-hidden="true"></i></Button>
                          <Button className="paste-btn" onClick={() => {copyTest()}} title="Copy Note"><i class="fa fa-clone" aria-hidden="true"></i></Button>
                          <span style={{color:"red"}}>{successMessage}</span>
                        </div>
                      }
                      </div>

                      <div className="inner-transcription-box  text-area-box position-relative">
                          {showLoader && (
                        <div className="left-chat-box left-chat-typing-loader">
                          <div className="typing-loader-main-box">
                            <span>Notes Generating</span>
                          <div class="typing-loader"></div>
                          </div>
                        </div>
                      )}
                        
                        <textarea  id="chat-response-id" readOnly={editable?true:false}  value={chatResponse} ref={chat_response_id} className={editable ? "summerize-boxes-generate":""}
                         onChange={(e) => {
                          if(editable){
                            return false
                          }else{
                            setChatResponse(e.target.value)
                          }
                         }}
                        ></textarea>
                        {!promptQues && !chatResponse &&
                         <div className="no-data-box inner-transcription-box">
                        <img
                             src={require("../../assests/images/history-data.gif")}
                             alt="img"
                             className=""
                           />
                       <h5>No note Found</h5>
                      
                     </div> 
                     }
                        {/* <p id="chat-response-id"></p> */}
                      </div>

                     

                      {!editable && !chatTriggered &&
                        <div>
                          <Button title="" style={{ marginLeft: "10px" }} onClick={() => updateResponse()}>Save</Button>
                          <Button title="" style={{ marginLeft: "10px" }} onClick={() => cancelRequest()}>Cancel</Button>

                        </div>
                      }

                      {/* {!chatTriggered && promptQues &&
                        <div>
                         <Button onClick={() => {
                          document.getElementById("chat-response-id").focus()
                          setEditable(false)                      
                         }} >Edit</Button>
                         <Button style={{marginLeft:"10px"}} onClick={() => updateResponse()}>Save</Button>
                         </div>
                        } */}

                    </div>
                  </Col>

                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HistoryGenerate;