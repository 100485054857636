import { useEffect, useRef, useState, useContext } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  // MDBBreadcrumb,
  // MDBBreadcrumbItem,
  MDBProgress,
  MDBProgressBar,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
} from "mdb-react-ui-kit";
import { openAiService } from "../../services/openai.services";
import { context } from "../../GlobalContext/GlobalContext";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { Button, Col, Container, Modal, Row, Form, Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Loading } from "../common/loader";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import { allBloodGroup, baseUrl } from "../../config/config";
import { ShowHabit } from "../modal/member_habbit";
export default function ProfilePage() {
  const [show, setShow] = useState(false);
  const [habitModal, setHabitModal] = useState(false);
  const ct = require("countries-and-timezones");
  const timezone = ct.getTimezone(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const COUNTRY_CODE = timezone.countries[0];

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const extension = ["image/jpg", "image/png", "image/jpeg"];

  const [showAdd, setShowAdd] = useState(false);
  const [viewHabbit, setViewHabbit] = useState(false)

  const handleCloseAdd = () => setShowAdd(false);
  const handleShowAdd = () => setShowAdd(true);

  const showHabbitModal = () => setHabitModal(true);
  const hideHabbitModal = () => setHabitModal(false);

  const hideHabbit = () => setViewHabbit(false);
  const showHabbit = () => setViewHabbit(true)


  const [profileData, setprofileData] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const globalData = useContext(context);
  const globalDataRef = useRef();
  const [habitData, setHabitData] = useState([]);
  const [optionValue, setOptionValue] = useState();
  const [exercise, setExercise] = useState([]);
  const [diet, setDiet] = useState([]);
  const [alcohol, setAlcohol] = useState([]);
  const [caffine, setCaffine] = useState([]);
  const [smoke, setSmoke] = useState([]);
  // const [habitArray, setHabitArray] = useState([]);
  const [medical, setMedical] = useState("");
  const [smokeOpt, setSmokeOpt] = useState("");
  const [exerciseOpt, setExerciseOpt] = useState("");
  const [dietOpt, setDietOpt] = useState("");
  const [caffineOpt, setCaffineOpt] = useState("");
  const [alcoholOpt, setAlcoholOpt] = useState("");
  const [allergyData, setAllergyData] = useState("");
  const [isHabit, setIsHabit] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const [allergy, setAllergy] = useState(0);

  const [smokeVal, setSmokeVal] = useState("");
  const [exerciseVal, setExerciseVal] = useState("");
  const [dietVal, setDietVal] = useState("");
  const [caffineVal, setCaffineVal] = useState("");
  const [alcoholVal, setAlcoholVal] = useState("");
  const [isValidate, setIsValidate] = useState(true);
  const [isMemberValidate, setIsMemberValidate] = useState(true);
  const [weightErr, setWeightErr] = useState("");
  const [heightErr, setHeightErr] = useState("");
  const [delmedication, setDelMedication] = useState([]);
  const [familyMember, setFamilyMember] = useState([]);
  const [familyMedication, setFamilyMedication] = useState([])
  const [imageUrl, setImageUrl] = useState("");
  /*****************Member habit realated states****** */
  const [memberExercise, setMemberExercise] = useState([]);
  const [memberDiet, setMemberDiet] = useState([]);
  const [memberAlcohol, setMemberAlcohol] = useState([]);
  const [memberCaffine, setMemberCaffine] = useState([]);
  const [memberSmoke, setMemberSmoke] = useState([]);
  const [isMemberHabit, setIsMemberHabit] = useState(false);
  const [memberId, setMemberId] = useState("")

  const [memberAllergy, setMemberAllergy] = useState(0);

  const [memberSmokeVal, setMemberSmokeVal] = useState("");
  const [memberExerciseVal, setMemberExerciseVal] = useState("");
  const [memberDietVal, setMemberDietVal] = useState("");
  const [memberCaffineVal, setMemberCaffineVal] = useState("");
  const [memberAlcoholVal, setMemberAlcoholVal] = useState("");

  const [memberSmokeOpt, setMemberSmokeOpt] = useState("");
  const [memberExerciseOpt, setMemberExerciseOpt] = useState("");
  const [memberDietOpt, setMemberDietOpt] = useState("");
  const [memberCaffineOpt, setMemberCaffineOpt] = useState("");
  const [memberAlcoholOpt, setMemberAlcoholOpt] = useState("");
  const [memberAllergyData, setMemberAllergyData] = useState("");
  const [memberMedical, setMemberMedical] = useState("");
  const [memberHabitData, setMemberHabitData] = useState([])
  const [doberror, setDoberror] = useState("DOB field is required.");


  const [medication, setMedication] = useState([
    { name: "", dosage: "", unit: "MG", id: "" },
  ]);
  var validate = true
  var valid = true;
  globalDataRef.current = globalData;
  // const [habitModal, setHabitModal] = useState(false);
  const [memberInfo, setMemberInfo] = useState({
    id: "",
    image: "",
    member_name: "",
    relation: "",
    phone: "",
    dob: "",
    blood_group: "MG",
    weight: "",
    height: "",
    gender: "",
  })

  const [memberInfoErr, setMemberInfoErr] = useState({
    image: false,
    extension: false,
    member_name: false,
    relation: false,
    phone: false,
    dob: false,
    blood_group: false,
    weight: false,
    height: false,
    gender: false,
  })


  const [habitError, setHabitError] = useState({
    exercise: false,
    diet: false,
    alcohol: false,
    caffine: false,
    smoke: false,
    allergies: false,
    medicals: false,
  });

  const [memberHabitError, setMemberHabitError] = useState({
    exercise: false,
    diet: false,
    alcohol: false,
    caffine: false,
    smoke: false,
    allergies: false,
    medicals: false,
  })

  const renderTooltip_13 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View Habits
    </Tooltip>
  );
  const renderTooltip_14 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Add Habits
    </Tooltip>
  );
  const renderTooltip_15 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit Member Information
    </Tooltip>
  );


  const navigate = useNavigate();
  useEffect(() => {
    getHabits();
    getProfiles();
    getFamilyMembers();
  }, []);

  function setHabbitData(id) {
    getMemberHabbit(id);
    showHabbit()
  }

  // function phonoNoFormat(number){
  //   var result;
  //   if(typeof number == "string"){
  //     var cleaned = ('' + number.slice(3,number.length-1)).replace(/\D/g, '');
  //     var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  //     result = number.slice(0,2) + '(' + match[1] + ') ' + match[2] + '-' + match[3];
  //   }else{
  //     result = "";
  //   }
  //   alert(result);
  // }



  function capitalizeFLetter(string) {
    return (string[0].toUpperCase() +
      string.slice(1));

  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function getMemberHabbit(member_id) {
    setIsLoader(true)
    setMemberId(member_id)
    openAiService
      .getMembersHabits(member_id)
      .then((res) => {
        setIsLoader(false)
        console.log("member habbiyttts", res.data?.data?.habits)
        setMemberMedical(res.data?.data?.medical_history);
        //exercise data
        console.log(
          "exercise data",
          res.data?.data?.habits.filter((h) => h.id === 1)
        );
        setMemberExercise(res.data?.data?.habits.filter((h) => h.id === 1));
        //diet data
        console.log(
          "diet data",
          res.data?.data?.habits.filter((h) => h.id === 2)
        );
        setMemberDiet(res.data?.data?.habits.filter((h) => h.id === 2));
        //alcohol
        console.log(
          "alcohol",
          res.data?.data?.habits.filter((h) => h.name === "Alcohal Consumption")
        );
        setMemberAlcohol(res.data?.data?.habits.filter((h) => h.id === 3));
        //caffine
        setMemberCaffine(res.data?.data?.habits.filter((h) => h.id === 4));
        //smoke
        setMemberSmoke(res.data?.data?.habits.filter((h) => h.id === 5));

        var exerciseMemberFilter = res.data?.data?.habits
          .filter((e) => (e.id === 1))[0]
          ?.options.filter((o) => o.is_selected);
        var dietMemberFilter = res.data?.data?.habits
          .filter((e) => e.id === 2)[0]
          ?.options.filter((o) => o.is_selected);
        var alcoholMemberFilter = res.data?.data?.habits
          .filter((e) => e.id === 3)[0]
          ?.options.filter((o) => o.is_selected);
        var caffineMemberFilter = res.data?.data?.habits
          .filter((e) => e.id === 4)[0]
          ?.options.filter((o) => o.is_selected);
        var smokeMemberFilter = res.data?.data?.habits
          .filter((e) => e.id === 5)[0]
          ?.options.filter((o) => o.is_selected);

        console.log("exerciseMemberFilter", exerciseMemberFilter[0])

        setMemberExerciseOpt(exerciseMemberFilter[0] ? exerciseMemberFilter[0].id : "");
        setMemberDietOpt(dietMemberFilter[0] ? dietMemberFilter[0].id : "");
        setMemberAlcoholOpt(alcoholMemberFilter[0] ? alcoholMemberFilter[0].id : "");
        setMemberCaffineOpt(caffineMemberFilter[0] ? caffineMemberFilter[0].id : "");
        setMemberSmokeOpt(smokeMemberFilter[0] ? smokeMemberFilter[0].id : "");
        setMemberExerciseVal(exerciseMemberFilter[0] ? exerciseMemberFilter[0].option : "");
        setMemberDietVal(dietMemberFilter[0] ? dietMemberFilter[0].option : "");
        setMemberAlcoholVal(alcoholMemberFilter[0] ? alcoholMemberFilter[0].option : "");
        setMemberCaffineVal(caffineMemberFilter[0] ? caffineMemberFilter[0].option : "");
        setMemberSmokeVal(smokeMemberFilter[0] ? smokeMemberFilter[0].option : "");
        setMemberAllergyData(res.data?.data?.allergies);
        if (res.data?.data?.allergies) {
          setMemberAllergy(1);
        } else {
          setMemberAllergy(0);
        }

        /************Check habbits exits or not********** */
        if (
          exerciseMemberFilter ||
          dietMemberFilter ||
          alcoholMemberFilter ||
          caffineMemberFilter ||
          smokeMemberFilter ||
          res.data?.data?.medical_history ||
          res.data?.data?.allergies
        ) {
          setIsMemberHabit(true);
        } else {
          setIsMemberHabit(false);
        }

        setMemberHabitData(res.data?.data);

      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  function getFamilyMembers() {
    openAiService.getFamilyMembers()
      .then((result) => {
        setFamilyMember(result.data.data);


        // result.data?.data.map((res) => {
        //   getMemberHabbit(res.id)
        // })


        console.log("ressss", result.data.data)
      })
      .catch((err) => {
        console.log("errrr", err)
      })
  }

  function validateFields(exerciseOpt, dietOpt, smokeOpt, caffineOpt, alcoholOpt, medical, allergyData) {

    if (exerciseOpt === "") {
      validate = false
      setIsValidate(false)
      setHabitError((obj) => {
        return {
          ...obj,
          exercise: true,
        };
      });
    }
    if (dietOpt === "") {
      validate = false
      setIsValidate(false)
      setHabitError((obj) => {
        return {
          ...obj,
          diet: true,
        };
      });
    }
    if (smokeOpt === "") {
      validate = false
      setIsValidate(false)
      setHabitError((obj) => {
        return {
          ...obj,
          smoke: true,
        };
      });
    }
    if (caffineOpt === "") {
      validate = false
      setIsValidate(false)
      setHabitError((obj) => {
        return {
          ...obj,
          caffine: true,
        };
      });
    }
    if (alcoholOpt === "") {
      validate = false
      setIsValidate(false)
      setHabitError((obj) => {
        return {
          ...obj,
          alcohol: true,
        };
      });
    }

    // if(medical.trim() === ""){
    //   validate = false
    //   setIsValidate(false)
    //   setHabitError((obj) => {
    //     return {
    //       ...obj,
    //       medicals: true,
    //     };
    //   });
    // }

    if (allergy === 1 && allergyData.trim() === "") {
      validate = false
      setIsValidate(false)
      setHabitError((obj) => {
        return {
          ...obj,
          allergies: true,
        };
      });
    }

    return validate;

  }



  function getProfiles() {
    openAiService
      .getProfiles()
      .then((res) => {
        console.log(">>>>>>", res.data.data.height);
        setprofileData(res.data?.data);
        setDateOfBirth(res.data?.data?.dob);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          globalDataRef.current.setSignUpModal(true);
          navigate("/");
        }
      });
  }

  function onChangeMedicationName(index, value) {
    let tempList = [...medication];
    tempList[index].name = value;
    setMedication(tempList);
  }

  function onChangeMedicationDosage(index, value) {
    let tempList = [...medication];
    tempList[index].dosage = value;
    setMedication(tempList);
  }

  function onChangeMedicationUnit(index, value) {
    let tempList = [...medication];
    tempList[index].unit = value;
    setMedication(tempList);
  }

  function addMoreMedications() {
    let tempList = [...medication];
    tempList.push({ id: "", name: "", dosage: "", unit: "MG" });
    setMedication(tempList);
  }

  function DeleteAddMoreMedications(index, item) {
    let tempList = [...medication];

    tempList.splice(index, 1);
    setMedication(tempList);

    // console.log(item)
    // tempListDel.push(item.id)
    // (tempListDel)
    if (item.id != "") {
      let newArray = [...delmedication, item.id];
      console.log(newArray);
      setDelMedication(newArray);
    }
  }

  function isAnyKeyEmpty(objects) {
    var error;
    objects.some((obj) => {
      if (!obj.name || !obj.dosage) {
        error = false;
      } else {
        error = true
      }
    })
    return error;

  }

  function get_member_habit(id) {
    getMemberHabbit(id)
    showHabbitModal()
  }

  function handleAddMember() {
    var validation = true;
    const selectedDate = new Date(memberInfo.dob);
    const currentDate = new Date();
    if (!memberInfo.member_name || memberInfo.member_name == "") {
      validation = false;
      setMemberInfoErr((prev) => {
        return {
          ...prev, member_name: true
        }
      })
    } else {
      validation = true;
      setMemberInfoErr((prev) => {
        return {
          ...prev, member_name: false
        }
      })
    }

    if (!memberInfo.relation || memberInfo.relation.trim() === "") {
      validation = false;
      setMemberInfoErr((prev) => {
        return {
          ...prev, relation: true
        }
      })
    } else {
      validation = true;
      setMemberInfoErr((prev) => {
        return {
          ...prev, relation: false
        }
      })
    }

    // if (!memberInfo.phone || memberInfo.phone.trim() === "") {
    //   validation = false;
    //   setMemberInfoErr((prev) => {
    //     return {
    //       ...prev, phone: true
    //     }
    //   })
    // } else {
    //   validation = true;
    //   setMemberInfoErr((prev) => {
    //     return {
    //       ...prev, phone: false
    //     }
    //   })
    // }


    if (!memberInfo.dob || memberInfo.dob.trim() === "") {
      validation = false;
      setDoberror("Invalid date of birth");
      setMemberInfoErr((prev) => {
        return {
          ...prev, dob: true
        }
      })
    } else if (isNaN(selectedDate.getTime())) {
      validation = false;
      setDoberror("Invalid date of birth");
      setMemberInfoErr((prev) => {
        return {
          ...prev, dob: true
        }
      })
    } else if (selectedDate > currentDate) {
      validation = false;
      setDoberror("DOB can't be set as a future date.");
      setMemberInfoErr((prev) => {
        return {
          ...prev, dob: true
        }
      })
    } else {
      validation = true;
      setMemberInfoErr((prev) => {
        return {
          ...prev, dob: false
        }
      })
    }

    if (!memberInfo.weight || memberInfo.weight === "") {
      setWeightErr("Weight field is required.");
      validation = false;
      setMemberInfoErr((prev) => {
        return {
          ...prev, weight: true
        }
      })
    } else if (isNaN(memberInfo.weight)) {
      setWeightErr("Weight should be a number.");
      validation = false;
      setMemberInfoErr((prev) => {
        return {
          ...prev, weight: true
        }
      })
    } else {
      validation = true;
      setWeightErr("");
      setMemberInfoErr((prev) => {
        return {
          ...prev, weight: false
        }
      })
    }

    if (!memberInfo.height || memberInfo.height === "") {
      validation = false;
      setHeightErr("Height field is required.")
      setMemberInfoErr((prev) => {
        return {
          ...prev, height: true
        }
      })
    } else if (isNaN(memberInfo.height)) {
      validation = false;
      setHeightErr("Height should be a number.")
      setMemberInfoErr((prev) => {
        return {
          ...prev, height: true
        }
      })
    } else {
      validation = true;
      setHeightErr("")
      setMemberInfoErr((prev) => {
        return {
          ...prev, height: false
        }
      })
    }

    if (!memberInfo.blood_group || memberInfo.blood_group == "") {
      validation = false;
      setMemberInfoErr((prev) => {
        return {
          ...prev, blood_group: true
        }
      })
    } else {
      validation = true;
      setMemberInfoErr((prev) => {
        return {
          ...prev, blood_group: false
        }
      })
    }

    if (!memberInfo.gender || memberInfo.gender == "") {
      validation = false;
      setMemberInfoErr((prev) => {
        return {
          ...prev, gender: true
        }
      })
    } else {
      validation = true;
      setMemberInfoErr((prev) => {
        return {
          ...prev, gender: false
        }
      })
    }



    // if(memberInfo.image === "" && memberInfo.id == ""){
    //   validation =false;
    //   setMemberInfoErr((prev) => {
    //     return {
    //       ...prev,image:true
    //     }
    //    })   
    // }else if(memberInfo.image !== "" && !extension.includes(memberInfo.image.type)){
    //   validation =false;
    //   setMemberInfoErr((prev) => {
    //     return{...prev, extension:true}
    //   }) 
    // }else{
    //   validation =true;
    //    setMemberInfoErr((prev) => {
    //     return {
    //       ...prev,image:false
    //     }
    //    })
    // }

    if (validation) {
      if (memberInfo.member_name == "" || memberInfo.dob == "" || memberInfo.height == "" || memberInfo.weight == "" || memberInfo.gender == "" || memberInfo.blood_group == "") {
        Swal.fire("Error", "Please Fill the required fields", "error");
      } else {
        var formdata = new FormData();
        console.log("memberInfomemberInfo", memberInfo)
        if (memberInfo.image !== "") {
          formdata.append("image", memberInfo.image)
        }
        formdata.append("name", memberInfo.member_name)
        formdata.append("relation", memberInfo.relation)
        formdata.append("dob", memberInfo.dob)
        formdata.append("phone", memberInfo.phone)
        formdata.append("blood_group", memberInfo.blood_group)
        formdata.append("weight", memberInfo.weight)
        formdata.append("height", memberInfo.height)
        formdata.append("gender", memberInfo.gender)
        console.log("medicationmedication", isAnyKeyEmpty(medication))
        if (isAnyKeyEmpty(medication)) {
          formdata.append("medication", JSON.stringify(medication))
        } else {
          formdata.append("medication", JSON.stringify([]))
        }
        if (memberInfo.id !== "") {
          formdata.append("delmedication", JSON.stringify(delmedication))
        }
        //  
        if (memberInfo.id) {
          setIsLoader(true)
          openAiService.updateFamilyMember(memberInfo.id, formdata).then((res) => {
            console.log("add members", res.data)
            setIsLoader(false)
            setFamilyMember([])
            setImageUrl("")
            setMedication([{ name: "", dosage: "", unit: "MG", id: "" }])
            handleCloseAdd()
            getFamilyMembers()
            setDelMedication([])
            setMemberInfo({
              id: "",
              image: "",
              member_name: "",
              relation: "",
              phone: "",
              dob: "",
              blood_group: "MG",
              weight: "",
              height: "",
              gender: "",
            })
            Swal.fire("Success", "Information updated successfully", "success");
          }).catch((err) => {
            console.log("errrr--", err)
          })
        } else {
          setIsLoader(true)
          openAiService.addFamilyMember(formdata).then((res) => {
            console.log("add members", res.data)
            setIsLoader(false)
            setFamilyMember([])
            handleCloseAdd()
            setImageUrl("")
            setMedication([{ name: "", dosage: "", unit: "MG", id: "" }])
            setDelMedication([])
            getFamilyMembers()
            setMemberInfo({
              id: "",
              image: "",
              member_name: "",
              relation: "",
              phone: "",
              dob: "",
              blood_group: "MG",
              weight: "",
              height: "",
              gender: "male",
            })

            Swal.fire("Success", "Member added successfully", "success");

          }).catch((err) => {
            console.log("errrr--", err)
          })
        }

      }
      //  console.log("data sendingggg", formdata);
    }

  }

  const setdata = (value) => {
    setAllergyData(value);
    if (allergy == 1 && value.trim() === "") {
      setIsValidate(false)
      setHabitError((obj) => {
        return {
          ...obj,
          allergies: true,
        }
      })
    } else {
      setIsValidate(true)
      setHabitError((obj) => {
        return {
          ...obj,
          allergies: false,
        }
      })
    }
  };


  const setMemberData = (value) => {
    setMemberAllergyData(value);
    if (allergy == 1 && value.trim() === "") {
      setIsMemberValidate(false)
      setMemberHabitError((obj) => {
        return {
          ...obj,
          allergies: true,
        }
      })
    } else {
      setIsMemberValidate(true)
      setMemberHabitError((obj) => {
        return {
          ...obj,
          allergies: false,
        }
      })
    }
  };

  const addHabbit = () => {
    validateFields(exerciseOpt, dietOpt, smokeOpt, caffineOpt, alcoholOpt, medical, allergyData)

    if (validate) {
      setIsValidate(true)
      var habitArray = [exerciseOpt, dietOpt, smokeOpt, caffineOpt, alcoholOpt];
      var data = {
        habits: habitArray,
        medical_history: medical,
        allergies: allergyData
      };
      openAiService
        .addHabits(data)
        .then((res) => {
          // console.log("filtered data",res.data?.data?.habits)
          // setExerciseOpt()
          var exerciseFilter = res.data?.data?.habits
            .filter((e) => e.id === 1)[0]
            ?.options.filter((o) => o.is_selected);
          var dietFilter = res.data?.data?.habits
            .filter((e) => e.id === 2)[0]
            ?.options.filter((o) => o.is_selected);
          var alcoholFilter = res.data?.data?.habits
            .filter((e) => e.id === 3)[0]
            ?.options.filter((o) => o.is_selected);
          var caffineFilter = res.data?.data?.habits
            .filter((e) => e.id === 4)[0]
            ?.options.filter((o) => o.is_selected);
          var smokeFilter = res.data?.data?.habits
            .filter((e) => e.id === 5)[0]
            ?.options.filter((o) => o.is_selected);

          setExerciseOpt(exerciseFilter[0]?.id);
          setDietOpt(dietFilter[0]?.id);
          setAlcoholOpt(alcoholFilter[0]?.id);
          setCaffineOpt(caffineFilter[0]?.id);
          setSmokeOpt(smokeFilter[0]?.id);
          setExerciseVal(exerciseFilter[0]?.option);
          setDietVal(dietFilter[0]?.option);
          setAlcoholVal(alcoholFilter[0]?.option);
          setCaffineVal(caffineFilter[0]?.option);
          setSmokeVal(smokeFilter[0]?.option);
          setMedical(res.data?.data?.medical_history);
          setAllergyData(res.data?.data?.allergies);
          if (res.data?.data?.allergies) {
            setAllergy(1);
          } else {
            setAllergy(0);
          }

          if (
            exerciseFilter ||
            dietFilter ||
            alcoholFilter ||
            caffineFilter ||
            smokeFilter ||
            res.data?.data?.medical_history ||
            res.data?.data?.allergies
          ) {
            setIsHabit(true);
          } else {
            setIsHabit(false);
          }
          handleClose();
          Swal.fire("Success", "Information saved successfully.", "success");
        })
        .catch((err) => {
          console.log("err", err);
        })
    }
    console.log("array----", habitArray);
    console.log("textarea--", medical);
  };

  function validateMemberFields(exerciseOpt, dietOpt, smokeOpt, caffineOpt, alcoholOpt, memberAllergyData) {

    var have = true
    if (exerciseOpt === "") {
      have = false
      setIsMemberValidate(false)
      setMemberHabitError((obj) => {
        return {
          ...obj,
          exercise: true,
        };
      });
    }
    if (dietOpt === "") {
      have = false
      setIsMemberValidate(false)
      setMemberHabitError((obj) => {
        return {
          ...obj,
          diet: true,
        };
      });
    }
    if (smokeOpt === "") {
      have = false
      setIsMemberValidate(false)
      setMemberHabitError((obj) => {
        return {
          ...obj,
          smoke: true,
        };
      });
    }
    if (caffineOpt === "") {
      have = false
      setIsMemberValidate(false)
      setMemberHabitError((obj) => {
        return {
          ...obj,
          caffine: true,
        };
      });
    }
    if (alcoholOpt === "") {
      have = false
      setIsMemberValidate(false)
      setMemberHabitError((obj) => {
        return {
          ...obj,
          alcohol: true,
        };
      });
    }


    if (memberAllergy === 1 && !memberAllergyData) {
      have = false
      setIsMemberValidate(false)
      setMemberHabitError((obj) => {
        return {
          ...obj,
          allergies: true,
        };
      });
    }

    return have;
  }

  const addMemberHabbit = (memberId) => {
    var isValid = validateMemberFields(memberExerciseOpt, memberDietOpt, memberSmokeOpt, memberCaffineOpt, memberAlcoholOpt, memberAllergyData)

    if (isValid) {
      setIsMemberValidate(true)
      var habitArray = [memberExerciseOpt, memberDietOpt, memberSmokeOpt, memberCaffineOpt, memberAlcoholOpt];
      var data = {
        habits: habitArray
      };
      if (memberMedical) {
        data.medical_history = memberMedical
      }
      if (memberAllergyData) {
        data.allergies = memberAllergyData
      }
      openAiService
        .addMemberHabits(memberId, data)
        .then((res) => {
          getMemberHabbit(memberId);
          console.log("filtered data", res.data?.data?.habits)
          // setExerciseOpt()
          // var exerciseFilter = res.data?.data?.habits
          //   .filter((e) => e.id === 1)[0]
          //   ?.options.filter((o) => o.is_selected);
          // var dietFilter = res.data?.data?.habits
          //   .filter((e) => e.id === 2)[0]
          //   ?.options.filter((o) => o.is_selected);
          // var alcoholFilter = res.data?.data?.habits
          //   .filter((e) => e.id === 3)[0]
          //   ?.options.filter((o) => o.is_selected);
          // var caffineFilter = res.data?.data?.habits
          //   .filter((e) => e.id === 4)[0]
          //   ?.options.filter((o) => o.is_selected);
          // var smokeFilter = res.data?.data?.habits
          //   .filter((e) => e.id === 5)[0]
          //   ?.options.filter((o) => o.is_selected);

          // setMemberExerciseOpt(exerciseFilter[0].id);
          // setMemberDietOpt(dietFilter[0].id);
          // setMemberAlcoholOpt(alcoholFilter[0].id);
          // setMemberCaffineOpt(caffineFilter[0].id);
          // setMemberSmokeOpt(smokeFilter[0].id);
          // setMemberExerciseVal(exerciseFilter[0].option);
          // setMemberDietVal(dietFilter[0].option);
          // setMemberAlcoholVal(alcoholFilter[0].option);
          // setMemberCaffineVal(caffineFilter[0].option);
          // setMemberSmokeVal(smokeFilter[0].option);
          // setMemberMedical(res.data?.data?.medical_history);
          // setMemberAllergyData(res.data?.data?.allergies);
          // if (res.data?.data?.allergies) {
          //   setMemberAllergy(1);
          // } else {
          //   setMemberAllergy(0);
          // }

          // if (
          //   exerciseFilter ||
          //   dietFilter ||
          //   alcoholFilter ||
          //   caffineFilter ||
          //   smokeFilter ||
          //   res.data?.data?.medical_history ||
          //   res.data?.data?.allergies
          // ) {
          //   setIsHabit(true);
          // } else {
          //   setIsHabit(false);
          // }
          hideHabbitModal();
          Swal.fire("Success", "Information saved successfully.", "success");
        })
        .catch((err) => {
          console.log("err", err);
        })
    }
    console.log("array----", habitArray);
    console.log("textarea--", medical);

  }

  function setModalData(data) {
    console.log("datatttt", data)
    var imageUrl = data.image ? baseUrl + data.image : require("../../assests/images/family-img.png")
    setImageUrl(imageUrl);
    setMemberId(data.id)

    console.log("data.member_medication", data.member_medication)
    // document.getElementById("member_image").src = baseUrl+data.image

    setMemberInfo({
      id: data.id,
      image: memberInfo.image,
      member_name: data.name,
      relation: data.relation,
      phone: data.phone,
      dob: data.dob,
      blood_group: data.blood_group,
      weight: data.weight,
      height: data.height,
      gender: data.gender
    })
    handleShowAdd()

  }

  const setAllergyOpt = (value) => {
    if (value == 0) {
      setAllergyData(null);
      setIsValidate(true)
      setHabitError((obj) => {
        return {
          ...obj,
          allergies: false,
        }

      })
    }

    setAllergy(value);
  };

  const setMemberAllergyOpt = (value) => {
    if (value == 0) {
      setMemberAllergyData(null);
      setIsValidate(true)
      setMemberHabitError((obj) => {
        return {
          ...obj,
          allergies: false,
        }

      })
    }

    setMemberAllergy(value);
  }

  const getHabits = () => {
    setIsLoader(true)
    openAiService
      .getHabits()
      .then((res) => {
        setIsLoader(false)
        //exercise data
        console.log(
          "exercise data",
          res.data?.data?.habits.filter((h) => h.id === 1)
        );
        setExercise(res.data?.data?.habits.filter((h) => h.id === 1));
        //diet data
        console.log(
          "diet data",
          res.data?.data?.habits.filter((h) => h.id === 2)
        );
        setDiet(res.data?.data?.habits.filter((h) => h.id === 2));
        //alcohol
        console.log(
          "alcohol",
          res.data?.data?.habits.filter((h) => h.name === "Alcohal Consumption")
        );
        setAlcohol(res.data?.data?.habits.filter((h) => h.id === 3));
        //caffine
        setCaffine(res.data?.data?.habits.filter((h) => h.id === 4));
        //smoke
        setSmoke(res.data?.data?.habits.filter((h) => h.id === 5));

        var exerciseFilter = res.data?.data?.habits
          .filter((e) => e.id === 1)[0]
          ?.options.filter((o) => o.is_selected);
        var dietFilter = res.data?.data?.habits
          .filter((e) => e.id === 2)[0]
          ?.options.filter((o) => o.is_selected);
        var alcoholFilter = res.data?.data?.habits
          .filter((e) => e.id === 3)[0]
          ?.options.filter((o) => o.is_selected);
        var caffineFilter = res.data?.data?.habits
          .filter((e) => e.id === 4)[0]
          ?.options.filter((o) => o.is_selected);
        var smokeFilter = res.data?.data?.habits
          .filter((e) => e.id === 5)[0]
          ?.options.filter((o) => o.is_selected);

        setExerciseOpt(exerciseFilter[0].id);
        setDietOpt(dietFilter[0].id);
        setAlcoholOpt(alcoholFilter[0].id);
        setCaffineOpt(caffineFilter[0].id);
        setSmokeOpt(smokeFilter[0].id);
        setExerciseVal(exerciseFilter[0].option);
        setDietVal(dietFilter[0].option);
        setAlcoholVal(alcoholFilter[0].option);
        setCaffineVal(caffineFilter[0].option);
        setSmokeVal(smokeFilter[0].option);
        setMedical(res.data?.data?.medical_history);
        setAllergyData(res.data?.data?.allergies);
        if (res.data?.data?.allergies) {
          setAllergy(1);
        } else {
          setAllergy(0);
        }

        /************Check habbits exits or not********** */
        if (
          exerciseFilter ||
          dietFilter ||
          alcoholFilter ||
          caffineFilter ||
          smokeFilter ||
          res.data?.data?.medical_history ||
          res.data?.data?.allergies
        ) {
          setIsHabit(true);
        } else {
          setIsHabit(false);
        }

        setHabitData(res.data?.data);

      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const backToDashboard = () => {
    // var navigate = localStorage.getItem("navigate") ? 
    navigate(localStorage.getItem("navigate") ? localStorage.getItem("navigate") : -1);
  };

  const setMedicalData = (value) => {
    setMedical(value);
    // if(value.trim() == ""){
    //   setIsValidate(false)
    //   setHabitError((obj) => {
    //        return {
    //          ...obj,
    //          medicals: true,
    //        }
    //      })
    //    }else{
    //     setIsValidate(true)
    //      setHabitError((obj) => {
    //        return {
    //          ...obj,
    //          medicals: false,
    //        }
    //      })
    //    }
  };

  const setMemberMedicalData = (value) => {
    setMemberMedical(value);
    // if(value.trim() == ""){
    //   setIsValidate(false)
    //   setHabitError((obj) => {
    //        return {
    //          ...obj,
    //          medicals: true,
    //        }
    //      })
    //    }else{
    //     setIsValidate(true)
    //      setHabitError((obj) => {
    //        return {
    //          ...obj,
    //          medicals: false,
    //        }
    //      })
    //    }
  };



  // const handleClose = () => {
  //   setHabitModal(false);
  // }

  console.log("setHabitDatasetHabitData", habitData);
  console.log("e.target.value", exerciseOpt);
  console.log("allergy-----", allergy);

  function formatNumber(number) {
    if(number && !isNaN(number)){
    var code = number.slice(0, 2);
    var formatted = number.slice(2, number.length)
    formatted = formatted.slice(0, formatted.length / 2) + "-" + formatted.slice(formatted.length / 2, formatted.length)

    return "+" + code + "  " + formatted;
    }else{
      return "N/A"
    }
  }

  const calculateAge = (dateOfBirth) => {
    const birthDate = moment(dateOfBirth);
    const currentDate = moment();
    const diff = moment.duration(currentDate.diff(birthDate));
    const years = diff.years();
    const months = diff.months();
    const days = diff.days();

    var year = Math.floor(years + months / 12 + days / 365);
    // return { years, months, days };
    return year;
  };

  const age = calculateAge(dateOfBirth);

  const updateprofile = () => {
    window.location.href = "/create_profile";
  };
  return (
    <>
      <section
        style={{ backgroundColor: "#eee" }}
        className="pricing-area profiel-details-section"
      >

        <Container>
          <div class="back-btn-box">
            <a onClick={backToDashboard}>
              {" "}
              <i class="fa fa-angle-double-left me-2" aria-hidden="true"></i>
              Back{" "}
            </a>
          </div>
        </Container>
        <MDBContainer className="">
          {/* <div className="profile-content-area"><h2>My Profile</h2></div>  */}
          {/* <MDBRow>
          <MDBCol>
            <MDBBreadcrumb className="bg-light rounded-3 p-3 mb-4">
              <MDBBreadcrumbItem>
                <a href='#'>Home</a>
              </MDBBreadcrumbItem>
              <MDBBreadcrumbItem>
                <a href="#">User</a>
              </MDBBreadcrumbItem>
              <MDBBreadcrumbItem active>User Profile</MDBBreadcrumbItem>
            </MDBBreadcrumb>
          </MDBCol>
        </MDBRow> */}

          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-12">
              <div className=" profile-card">
                <div className="text-center">
                  {/* <MDBCardImage
                  src="../../assests/images/user-placeholder.png"
                  alt="avatar"
                  className="rounded-circle profile-img"
                  style={{ width: '150px' }}
                  fluid /> */}

                  <img
                    src={
                      profileData.profile_image
                        ? baseUrl+profileData.profile_image: profileData.picture ?profileData.picture
                        : require("../../assests/images/user-placeholder.png")
                    }
                    alt="img"
                    className="rounded-circle profile-img"
                    style={{ width: "150px" }}
                  />
                  <p className=" mb-1 mt-3">{profileData.name}</p>
                  <p className=" mb-2">
                    <i class="fa fa-envelope me-2" aria-hidden="true"></i>
                    {profileData.email}
                  </p>
                  <p className=" mb-4">
                    <i class="fa fa-phone me-2" aria-hidden="true"></i>
                    {profileData.phone ? profileData.phone : "N/A"}
                  </p>

                  <div className="d-flex justify-content-center mb-2">
                    <button
                      className="update-btn"
                      onClick={() => updateprofile()}
                    >
                      Update Profile
                    </button>
                    {/* <MDBBtn outline className="ms-1">Message</MDBBtn> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="profile-card-inner">
                <h5 className="user-text mb-3">
                  Profile Information{" "}
                  <img
                    className=""
                    src={require("../../assests/images/info.gif")}
                    alt="img"
                  />
                </h5>
                <div className="row">
                  <div className="col-sm-4 col-6">
                    {/* <MDBCardText>DOB</MDBCardText> */}
                    <div className="data-text">
                      <p>Age:</p>
                      {/* <MDBCardText className="text-muted">{profileData.dob}</MDBCardText> */}
                      <p className="text-muted">
                        <b>
                          {!isNaN(age) && age !== null && age !== ""
                            ? age + " " + "years"
                            : "N/A"}{" "}
                        </b>
                      </p>
                    </div>
                  </div>

                  <div className="col-sm-4 col-6">
                    <div className="data-text">
                      <p>Weight:</p>
                      <MDBCardText className="text-muted">
                        <b>
                          {profileData.weight !== null
                            ? `${profileData.weight} KG`
                            : "N/A"}{" "}
                        </b>
                      </MDBCardText>
                    </div>
                  </div>

                  <div className="col-sm-4 col-6">
                    <div className="data-text">
                      <MDBCardText>Height:</MDBCardText>
                      <MDBCardText className="text-muted">
                        <b>
                          {profileData?.height?.toString().includes(".")
                            ? profileData.height.toString().split(".")[0] +
                            " Feet " +
                            profileData.height.toString().split(".")[1] +
                            " Inches"
                            : profileData.height
                              ? profileData.height + " Feet"
                              : "N/A"}
                        </b>
                      </MDBCardText>
                    </div>
                  </div>

                  <div className="col-sm-4 mt-3 col-6">
                    <div className="data-text">
                      <MDBCardText>Blood Group:</MDBCardText>
                      <MDBCardText className="text-muted">
                        <b>
                          {profileData.blood_group
                            ? profileData.blood_group
                            : "N/A"}
                        </b>
                      </MDBCardText>
                    </div>
                  </div>

                  <div className="col-sm-4 mt-3 col-6">
                    <div className="data-text">
                      <MDBCardText>Gender:</MDBCardText>

                      <MDBCardText className="text-muted">
                        <b>{profileData.gender ? capitalizeFirstLetter(profileData.gender) : "N/A"}</b>
                      </MDBCardText>
                    </div>
                  </div>
                </div>
                {/* <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Mobile</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">(098) 765-4321</MDBCardText>
                  </MDBCol>
                </MDBRow> */}
                {/* <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Address</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">Bay Area, San Francisco, CA</MDBCardText>
                  </MDBCol>
                </MDBRow> */}

                {profileData.medication && profileData.medication.length > 0 ? (
                  <div className="upper-user-information">
                    <div className="row">
                      <h5 className="user-text">
                        Medication Information{" "}
                        <img
                          className=""
                          src={require("../../assests/images/info.gif")}
                          alt="img"
                        />
                      </h5>
                      <div className="col-sm-12">
                        <div className="mb-md-0 profile-card">
                          <div>
                            {profileData.medication &&
                              profileData.medication.length > 0 &&
                              profileData.medication.map((med, index) => {
                                return (
                                  <div className="mb-2">
                                    <MDBCardText><img
                                      className=""
                                      src={require("../../assests/images/check-main.png")}
                                      alt="img"
                                    /> {med.name}:  <b>
                                    {med.dosage} {med.unit}
                                  </b></MDBCardText>
                                    {/* <MDBCardText className="text-muted">
                                     
                                    </MDBCardText> */}
                                  </div>
                                );
                              })}
                            {profileData.medication &&
                              profileData.medication.length == 0 ? (
                              <div>
                                <div className="col-sm-9">No data found</div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="profile-card-inner mt-3">
                {isLoader && <Loading />}
                <h5 className="user-text mb-3">
                  Healthy & Unhealthy Habits{" "}
                  <img
                    className=""
                    src={require("../../assests/images/habit.gif")}
                    alt="img"
                  />
                </h5>
                <div className="heathy-box">
                  {isHabit ? (
                    <>
                      <div>
                        <h6 className="d-flex">
                          <img
                            className=""
                            src={require("../../assests/images/check-main.png")}
                            alt="img"
                          />{" "}
                          <p>Exercise: <span>{exerciseVal}</span></p>
                        </h6>
                        <h6 className="d-flex">
                          <img
                            className=""
                            src={require("../../assests/images/check-main.png")}
                            alt="img"
                          />
                          <p>Diet Followed: <span>{dietVal}</span></p>
                        </h6>
                        <h6 className="d-flex">
                          <img
                            className=""
                            src={require("../../assests/images/check-main.png")}
                            alt="img"
                          />
                          <p>Alcohol consumption: <span>{alcoholVal}</span></p>
                        </h6>
                        <h6 className="d-flex">
                          <img
                            className=""
                            src={require("../../assests/images/check-main.png")}
                            alt="img"
                          />
                          <p> Caffeine Consumption: <span>{caffineVal}</span></p>
                        </h6>
                        <h6 className="d-flex">
                          <img
                            className=""
                            src={require("../../assests/images/check-main.png")}
                            alt="img"
                          />
                          <p> Smoking: <span>{smokeVal}</span></p>
                        </h6>
                        <h6 className="d-flex">
                          <img
                            className=""
                            src={require("../../assests/images/check-main.png")}
                            alt="img"
                          />
                          <p>Medical History: <span>{medical ? medical : "N/A"}</span></p>
                        </h6>
                        <h6 className="d-flex">
                          <img
                            className=""
                            src={require("../../assests/images/check-main.png")}
                            alt="img"
                          />
                          <p>Allegry: <span>{allergyData ? allergyData : "No"}</span></p>
                        </h6>

                        <a href="#" className="add-btn" onClick={handleShow}>
                          Edit Habits{" "}
                          <i class="fa fa-edit" aria-hidden="true"></i>
                        </a>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-center">
                        <img
                          className=""
                          src={require("../../assests/images/diet.svg").default}
                          alt="img"
                        />
                        <h5>No Healthy Habits Added</h5>
                        <a href="#" className="add-btn" onClick={handleShow}>
                          Add Habits{" "}
                          <i
                            class="fa fa-plus-circle ms-1"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </>
                  )}
                </div>
              </div>

            </div>
            <div className="col-lg-5 col-md-12">
              <div className="profile-card-inner">
                <div className="famil-box-info">
                  <h5 className="user-text mb-3">
                    Family Information{" "}
                    <img
                      className=""
                      src={require("../../assests/images/info.gif")}
                      alt="img"
                    />
                  </h5>
                  <a className="blue-text" onClick={() => {
                    setMemberId("")
                    handleShowAdd()
                  }}>+ Add Members</a>
                </div>
                <div className="family-box-body">

                  <Accordion defaultActiveKey="">
                    {familyMember.length > 0 ?
                      familyMember.map((res, index) => {
                        return (
                          <Accordion.Item eventKey={index}>
                            <Accordion.Header>
                              <div className="header-family">
                                <img
                                  className=""
                                  src={res.image ? baseUrl + res.image : require("../../assests/images/family-img.png")}
                                  alt="img"
                                />
                                <div className="family-content">
                                  <h5>{res.name}</h5>
                                  <p>{res.relation}  || {res.dob} </p>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="body-family">
                                <Row>
                                  <Col xs={6} md={6}>
                                    <div className="data-text">
                                      <p>Contact No:</p>
                                      <p className="text-muted"><b>{formatNumber(res.phone)}</b></p>
                                    </div>
                                  </Col>
                                  <Col xs={6} md={6}>
                                    <div className="data-text">
                                      <p>Blood Group:</p>
                                      <p className="text-muted"><b>{res.blood_group}</b></p>
                                    </div>
                                  </Col>
                                  <Col xs={6} md={6} className="mt-2">
                                    <div className="data-text">
                                      <p>Gender:</p>
                                      <p className="text-muted"><b>{capitalizeFLetter(res.gender)}</b></p>
                                    </div>
                                  </Col>
                                  <Col xs={6} md={6} className="mt-2">
                                    <div className="data-text">
                                      <p>Height:</p>
                                      <p className="text-muted"><b>{res.height + " " + "ft"}</b></p>
                                    </div>
                                  </Col>
                                  <Col xs={6} md={6} className="mt-2">
                                    <div className="data-text">
                                      <p>Weight:</p>
                                      <p className="text-muted"><b>{res.weight} kg</b></p>
                                    </div>
                                  </Col>
                                  <Col xs={6} md={6} className="mt-2">
                                    <div className="family-action">
                                      <div className="data-text">
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={renderTooltip_13}
                                        >
                                          <Link href="">
                                            <img
                                              className=""
                                              onClick={() => { setHabbitData(res.id) }}
                                              src={require("../../assests/images/view-btn.svg").default}
                                              alt="img"
                                            />
                                          </Link>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={renderTooltip_14}
                                        >
                                          <Link href="" className="ms-2">
                                            <img
                                              className=""
                                              onClick={() => {
                                                get_member_habit(res.id)
                                              }}
                                              src={require("../../assests/images/add-btn.svg").default}
                                              alt="img"
                                            />
                                          </Link>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={renderTooltip_15}
                                        >
                                          <Link href="" className="ms-2">
                                            <img
                                              className=""
                                              onClick={() => {
                                                setMedication(res.member_medication.length > 0 ? res.member_medication : medication)
                                                setModalData(res)
                                              }}
                                              src={require("../../assests/images/edit-btn.svg").default}
                                              alt="img"
                                            />
                                          </Link>
                                        </OverlayTrigger>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={12}>
                                    {res.member_medication && res.member_medication.length > 0 ?
                                      <div className="upper-user-information">
                                        <div className="row">
                                          <h5 className="">
                                            Medication Information{" "}
                                          </h5>
                                          <div className="col-sm-12">
                                            <div className="mb-md-0 profile-card">
                                              <div>
                                                {res.member_medication.length > 0 ?
                                                  res.member_medication.map((data) => {
                                                    return (<div className="mb-2">
                                                      <MDBCardText><img
                                                        className=""
                                                        src={require("../../assests/images/check-main.png")}
                                                        alt="img"
                                                      /> {data.name}: <b>
                                                      {data.dosage} {data.unit}
                                                    </b></MDBCardText>
                                                      {/* <MDBCardText className="text-muted">
                                                        <b>
                                                          {data.dosage} {data.unit}
                                                        </b>
                                                      </MDBCardText> */}
                                                    </div>)
                                                  })

                                                  : "No Medication Added"}

                                                <div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div> : ""}
                                  </Col>
                                </Row>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>

                        )

                      })

                      : <div className="no-data-found-family">
                        <img
                          className=""
                          src={require("../../assests/images/family-data.gif")}
                          alt="img"
                        />
                        <p>No Family Information</p>
                      </div>}
                  </Accordion>
                  {/* <ShowHabit show={habitModal} close={hideHabbitModal} />   */}
                </div>

              </div>
            </div>
            <div className="col-lg-4 col-md-12">
            </div>
          </div>

        </MDBContainer>
      </section>



      <Modal show={show} onHide={handleClose} centered >
        <div className="">

          <Modal.Header className="heathy-header" closeButton>
            <Modal.Title>Healthy & Unhealthy Habits</Modal.Title>
          </Modal.Header>
          <Modal.Body className="health-outer-box">
            <div className="health-boxes">
              {exercise ? (
                <div className="inner-health">
                  <h6>{exercise[0]?.name} *</h6>
                  <div className="row">
                    {habitError.exercise ? <p style={{ color: "red" }}>*Field is Required</p> : ""}
                    {exercise[0]?.options.map((res, i) => {
                      return (
                        <>
                          { }
                          <aside className="col-md-6">
                            <div class="form-check">
                              <input
                                type="radio"
                                class="form-check-input"
                                id={"ex_" + i}
                                name="ex_radio"
                                value={res.id}
                                onChange={(e) => {
                                  setExerciseOpt(e.target.value)
                                  setIsValidate(true)
                                  setHabitError((obj) => {
                                    return {
                                      ...obj,
                                      exercise: false,
                                    }
                                  })

                                }}
                                // console.log("res.id",res.id)
                                // console.log("e.target.value",e.target.value)


                                checked={exerciseOpt == res.id ? true : ""}
                              />
                              {res.option}
                              <label
                                class="form-check-label"
                                for="radio1"
                              ></label>
                            </div>
                          </aside>
                        </>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}

              {diet ? (
                <div className="inner-health">
                  <h6>{diet[0]?.name} *</h6>
                  <div className="row">
                    {habitError.diet ? <p style={{ color: "red" }}>*Field is Required</p> : ""}
                    {diet[0]?.options.map((res, i) => {
                      return (
                        <>
                          { }
                          <aside className="col-md-6">
                            <div class="form-check">
                              <input
                                type="radio"
                                class="form-check-input"
                                id={"diet_" + i}
                                name="diet_radio"
                                value={res.id}
                                // checked={dietOpt === res.id}
                                onChange={
                                  (e) =>
                                  // console.log("res.id",res.id)
                                  {
                                    setDietOpt(e.target.value)
                                    setIsValidate(true)
                                    setHabitError((obj) => {
                                      return {
                                        ...obj,
                                        diet: false,
                                      }
                                    })
                                  }
                                }
                                checked={dietOpt == res.id ? true : ""}
                              />
                              {res.option}
                              <label
                                class="form-check-label"
                                for="radio1"
                              ></label>

                            </div>
                          </aside>
                        </>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}

              {alcohol ? (
                <div className="inner-health">
                  <h6>{alcohol[0]?.name} *</h6>
                  <div className="row">
                    {habitError.alcohol ? <p style={{ color: "red" }}>*Field is Required</p> : ""}
                    {alcohol[0]?.options.map((res, i) => {
                      return (
                        <>
                          { }
                          <aside className="col-md-6">
                            <div class="form-check">
                              <input
                                type="radio"
                                class="form-check-input"
                                id={"alcohol_" + i}
                                name="alcohol_radio"
                                value={res.id}
                                // checked={alcoholOpt === res.id}
                                onChange={
                                  (e) => {
                                    setAlcoholOpt(e.target.value)
                                    setIsValidate(true)
                                    setHabitError((obj) => {
                                      return {
                                        ...obj,
                                        alcohol: false,
                                      }
                                    })

                                    // if(alcoholOpt === ""){
                                    //   setIsValidate(false)
                                    //   setHabitError((obj) => {
                                    //        return {
                                    //          ...obj,
                                    //          alcohol: true,
                                    //        }
                                    //      })
                                    //    }else{
                                    //     setIsValidate(true)
                                    //      setHabitError((obj) => {
                                    //        return {
                                    //          ...obj,
                                    //          alcohol: false,
                                    //        }
                                    //      })
                                    //    }
                                  }
                                  // setHabitArray([...habitArray, e.target.value])
                                }
                                checked={alcoholOpt == res.id ? true : ""}
                              />
                              {res.option}
                              <label
                                class="form-check-label"
                                for="radio1"
                              ></label>

                            </div>
                          </aside>
                        </>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}

              {caffine ? (
                <div className="inner-health">
                  <h6>{caffine[0]?.name} *</h6>
                  <div className="row">
                    {habitError.caffine ? <p style={{ color: "red" }}>*Field is Required</p> : ""}
                    {caffine[0]?.options.map((res, i) => {
                      return (
                        <>
                          { }
                          <aside className="col-md-6">
                            <div class="form-check">
                              <input
                                type="radio"
                                class="form-check-input"
                                id={"caffine_" + i}
                                name="caffine_radio"
                                value={res.id}
                                // checked={caffineOpt === res.id}
                                onChange={
                                  (e) => {
                                    setCaffineOpt(e.target.value)
                                    setIsValidate(true)
                                    setHabitError((obj) => {
                                      return {
                                        ...obj,
                                        caffine: false,
                                      }
                                    })
                                    // if(caffineOpt === ""){
                                    //   setIsValidate(false)
                                    //   setHabitError((obj) => {
                                    //        return {
                                    //          ...obj,
                                    //          caffine: true,
                                    //        }
                                    //      })
                                    //    }else{
                                    //     setIsValidate(true)
                                    //      setHabitError((obj) => {
                                    //        return {
                                    //          ...obj,
                                    //          caffine: false,
                                    //        }
                                    //      })
                                    //    }
                                  }
                                  // setHabitArray([...habitArray, e.target.value])
                                }
                                checked={caffineOpt == res.id ? true : ""}
                              />
                              {res.option}
                              <label
                                class="form-check-label"
                                for="radio1"
                              ></label>

                            </div>
                          </aside>
                        </>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}

              {smoke ? (
                <div className="inner-health">
                  <h6>{smoke[0]?.name} *</h6>
                  <div className="row">
                    {habitError.smoke ? <p style={{ color: "red" }}>*Field is Required</p> : ""}
                    {smoke[0]?.options.map((res, i) => {
                      return (
                        <>
                          { }
                          <aside className="col-md-6">
                            <div class="form-check">
                              <input
                                type="radio"
                                class="form-check-input"
                                id={"smoke_" + i}
                                name="smoke_radio"
                                value={res.id}
                                // checked={smokeOpt === res.id}
                                // onChange={(e) =>
                                //   setHabitArray([...habitArray.push(e.target.value)])
                                // }
                                onChange={
                                  (e) => {
                                    setIsValidate(false)
                                    setSmokeOpt(e.target.value)
                                    setIsValidate(true)
                                    setHabitError((obj) => {
                                      return {
                                        ...obj,
                                        smoke: false,
                                      }
                                    })
                                    // if(smokeOpt === ""){

                                    //   setHabitError((obj) => {
                                    //        return {
                                    //          ...obj,
                                    //          smoke: true,
                                    //        }
                                    //      })
                                    //    }else{
                                    //     setIsValidate(true)
                                    //      setHabitError((obj) => {
                                    //        return {
                                    //          ...obj,
                                    //          smoke: false,
                                    //        }
                                    //      })
                                    //    }
                                  }
                                  // setHabitArray([...habitArray, e.target.value])
                                }
                                checked={smokeOpt == res.id ? true : ""}
                              />
                              {res.option}
                              <label
                                class="form-check-label"
                                for="radio1"
                              ></label>

                            </div>
                          </aside>
                        </>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="inner-health">
                <h6>Include additional comments about Medical History:</h6>
                <textarea
                  className="form-control"
                  onChange={(e) => {
                    setMedicalData((e.target.value).trim())
                  }}
                >
                  {medical}
                </textarea>
                {/* {habitError.medicals ? <p style={{color:"red"}}>*Field is Required</p> : ""} */}
              </div>
              <div className="inner-health">
                <h6>
                  Are there any known allergies? If so, please specify below:
                </h6>
                <div className="row">
                  <aside className="col-md-6">
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="allergy1"
                        name="allergyradio"
                        value={1}
                        onChange={(e) => {
                          setAllergyOpt(1);
                        }}
                        checked={allergy == 1 ? true : ""}
                      />
                      Yes
                      <label class="form-check-label" for="radio1"></label>
                    </div>
                  </aside>
                  <aside className="col-md-6">
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="allergy2"
                        name="allergyradio"
                        value={0}
                        onChange={(e) => {
                          setAllergyOpt(0);
                        }}
                        checked={allergy == 0 ? true : ""}
                      />
                      No
                      <label class="form-check-label" for="radio1"></label>
                      {habitError.allergies ? <p style={{ color: "red" }}>*Field is Required</p> : ""}
                    </div>
                  </aside>
                </div>
                {allergy == 1 ? (
                  <textarea
                    onChange={(e) => {
                      setdata(e.target.value);
                    }}
                    className="form-control"
                  >
                    {allergyData}
                  </textarea>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* </div> */}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              disabled={isValidate ? false : true}
              onClick={() => {
                addHabbit();
              }}
              className="try-ti-buton"
            >
              Save
            </Button>
          </Modal.Footer>
        </div>
      </Modal>


      {isLoader && <Loading />}


      <Modal show={showAdd} onHide={() => {
        setImageUrl("")
        setMedication([{ name: "", dosage: "", unit: "MG", id: "" }])
        setMemberInfo({
          image: "",
          member_name: "",
          relation: "",
          phone: "",
          dob: "",
          blood_group: "MG",
          weight: "",
          height: "",
          gender: "",
        })
        setMemberInfoErr({
          image: false,
          extension: false,
          member_name: false,
          relation: false,
          phone: false,
          dob: false,
          blood_group: false,
          weight: false,
          height: false,
          gender: false,
        })
        handleCloseAdd()
      }} centered className="family-box-section">
        <Modal.Header closeButton>
          <Modal.Title>Family Members</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="family-form">

            <Row>
              <Col md={4}>
                <div className="upload-family-img">
                  <img
                    className={imageUrl ? "family-img" : ""}
                    id="member_image"
                    src={imageUrl !== "" ? imageUrl : require("../../assests/images/family-img.png")}
                    alt="img"
                  />
                  <Form.Control id="member_profile" type="file" accept="image/*" onChange={(e) => {
                    console.log("e.target.files[0].type", e.target.files[0])
                    setMemberInfo((prev) => {
                      return {
                        ...prev, image: e.target.files[0]
                      }
                    });
                    if (e.target.files[0] === "") {
                      document.getElementById("member_profile").value = null;
                      setMemberInfoErr((prev) => {
                        return { ...prev, image: true }
                      })
                    } else if (!extension.includes(e.target.files[0].type)) {
                      document.getElementById("member_profile").value = null;
                      setMemberInfo((prev) => {
                        return {
                          ...prev, image: ""
                        }
                      });
                      setMemberInfoErr((prev) => {
                        return { ...prev, extension: true }
                      })
                    } else {
                      setImageUrl(URL.createObjectURL(e.target.files[0]))
                      // document.getElementById("member_image").src = URL.createObjectURL(e.target.files[0])
                      setMemberInfoErr((prev) => {
                        return { ...prev, extension: false }
                      })
                    }
                  }} placeholder="Enter Member Name" />
                  <p>Upload Picture here</p>
                  {memberInfoErr.image ? <span className="error-class">Field is Required.</span> : memberInfoErr.extension ? <span className="error-class">Select valid Extension.</span> : ""}

                </div>
              </Col>
              <Col md={8}>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Member Name *</Form.Label>
                      <Form.Control maxLength={30} value={memberInfo.member_name} type="text" onChange={(e) => {
                        setMemberInfo((prev) => {
                          return {
                            ...prev, member_name: e.target.value
                          }
                        })
                        if (e.target.value.trim() === "") {

                          setMemberInfoErr((prev) => {
                            return {
                              ...prev, member_name: true
                            }
                          })
                        } else {
                          setMemberInfoErr((prev) => {
                            return {
                              ...prev, member_name: false
                            }
                          })
                        }
                      }} placeholder="Enter Member Name" />
                      {memberInfoErr.member_name &&
                        <span className="error-class">Member Name field is required.</span>}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Relationship *</Form.Label>
                      <Form.Control type="text" maxLength={30} value={memberInfo.relation} onChange={(e) => {
                        setMemberInfo((prev) => {
                          return {
                            ...prev, relation: e.target.value
                          }
                        })
                        if (e.target.value === "" || e.target.value.trim() === "") {
                          setMemberInfoErr((prev) => {
                            return {
                              ...prev, relation: true
                            }
                          })
                        } else {
                          setMemberInfoErr((prev) => {
                            return {
                              ...prev, relation: false
                            }
                          })
                        }
                      }} placeholder="Enter Relationship" />
                      {memberInfoErr.relation && <span className="error-class">Relationship field is required.</span>}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Contact Number</Form.Label>
                      <PhoneInput
                        country={
                          COUNTRY_CODE
                            ? COUNTRY_CODE.toLocaleLowerCase()
                            : "us"
                        }
                        placeholder="Enter phone number"
                        value={memberInfo.phone}
                        onChange={(e) => {
                          setMemberInfo((prevObj) => {
                            return {
                              ...prevObj,
                              phone: e,
                            };
                          });
                          // if (e.trim() === "") {
                          //   setMemberInfoErr((prevObj) => {
                          //     return {
                          //       ...prevObj,
                          //       phone: true,
                          //     };
                          //   });
                          // } else {
                          //   setMemberInfoErr((prevObj) => {
                          //     return {
                          //       ...prevObj,
                          //       phone: false,
                          //     };
                          //   });
                          // }
                        }}
                      />
                      {/* {memberInfoErr.phone && <span className="error-class">Contact Number field is required.</span>} */}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3 date-icon" controlId="exampleForm.ControlInput1">
                      <Form.Label>DOB *</Form.Label>
                      <Form.Control value={memberInfo.dob} max={moment(new Date).format("YYYY-MM-DD")} type="date" onChange={(e) => {
                        setMemberInfo((prev) => {
                          return {
                            ...prev, dob: e.target.value
                          }
                        })
                       if(new Date(e.target.value) > new Date()){
                        setMemberInfo((prev) => {
                          return {
                            ...prev, dob: ""
                          }
                        }) 
                       } 
                      }} placeholder="mm/dd/yyy" />
                      <i class="fa fa-calendar" aria-hidden="true"></i>
                      {memberInfoErr.dob && <span className="error-class">{doberror}</span>}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Blood Group *</Form.Label>
                      <Form.Select
                        aria-label=".form-select-lg example"
                        value={memberInfo.blood_group}
                        onChange={(e) => {
                          setMemberInfo((prev) => {
                            return {
                              ...prev, blood_group: e.target.value
                            }
                          })
                        }}
                      >
                        <option>Select Blood Group</option>
                        {allBloodGroup.map((groupBlood, index) => {
                          // {console.log(groupBlood)}
                          return (
                            <option value={groupBlood}>{groupBlood}</option>
                          );
                        })}
                      </Form.Select>
                      {memberInfoErr.blood_group && <span className="error-class">Blood Group field is required.</span>}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Weight(kg) *</Form.Label>
                      <Form.Control type="text" value={memberInfo.weight} onChange={(e) => {
                        setMemberInfo((prev) => {
                          return {
                            ...prev, weight: e.target.value
                          }
                        })
                        if (e.target.value.trim == "") {
                          setWeightErr("Weight field is required.")
                          setMemberInfoErr((prev) => {
                            return {
                              ...prev, weight: true
                            }
                          })
                        } else if (e.target.value < 1) {
                          setMemberInfo((prev) => {
                            return {
                              ...prev, weight: ""
                            }
                          })
                        } else {
                          setWeightErr("")
                          setMemberInfoErr((prev) => {
                            return {
                              ...prev, weight: false
                            }
                          })
                        }
                      }} placeholder="Enter Weight(kg)" />
                      {memberInfoErr.weight && <span className="error-class">{weightErr}</span>}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Height(ft) *</Form.Label>
                      <Form.Control type="text" value={memberInfo.height} onChange={(e) => {
                        console.log("e.target.value", e.target.value)
                        setMemberInfo((prev) => {
                          return {
                            ...prev, height: e.target.value
                          }
                        })
                        if (e.target.value.trim == "") {
                          setHeightErr("Height field is required.")
                          setMemberInfoErr((prev) => {
                            return {
                              ...prev, height: true
                            }
                          })
                        } else if (e.target.value < 1) {
                          setMemberInfo((prev) => {
                            return {
                              ...prev, height: ""
                            }
                          })
                        } else {
                          setHeightErr("")
                          setMemberInfoErr((prev) => {
                            return {
                              ...prev, height: false
                            }
                          })
                        }
                      }} placeholder="Enter Height(ft)" />
                      {memberInfoErr.height && <span className="error-class">{heightErr}</span>}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Gender *</Form.Label>
                      <Form.Select
                        aria-label=".form-select-lg example"
                        value={memberInfo.gender}
                        onChange={(e) => {
                          setMemberInfo((prev) => {
                            return {
                              ...prev, gender: e.target.value
                            }
                          })
                        }}
                      >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </Form.Select>
                      {memberInfoErr.gender && <span className="error-class">Gender field is required.</span>}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <div className="profile-medication-area">
                  <h6>Medication</h6>
                  {medication &&
                    medication.map((item, index) => {
                      let dosage = parseInt(medication[index]?.dosage);
                      let unit = parseInt(medication[index]?.unit);
                      //  let dosage = parseInt(med[0])
                      //    console.log(med)
                      return (
                        <>
                          <Form className="medication-form">
                            <Row>
                              <Col md={6}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Name"
                                    onChange={(e) =>
                                      onChangeMedicationName(
                                        index,
                                        e.target.value
                                      )
                                    }
                                    value={medication[index]?.name}
                                    onKeyDown={(e) => {
                                      if (
                                        !e.target.value &&
                                        e.code === "Space"
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                              {/* {console.log("medication.......", medication)} */}
                              <Col md={6}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Dosage</Form.Label>
                                  <div className="dosage-area">
                                    <Form.Control
                                      type="number"
                                      placeholder="Enter Dosage"
                                      onWheel={() =>
                                        document.activeElement.blur()
                                      }
                                      value={dosage}
                                      onChange={(e) =>
                                        onChangeMedicationDosage(
                                          index,
                                          e.target.value
                                        )
                                      }
                                      onKeyDown={(e) => {
                                        if (
                                          !e.target.value &&
                                          e.code === "Space"
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />

                                    <Form.Select
                                      aria-label=".form-select-lg example"
                                      style={{ width: "20%" }}
                                      onChange={(e) =>
                                        onChangeMedicationUnit(
                                          index,
                                          e.target.value
                                        )
                                      }
                                      value={
                                        medication[index]?.unit || unit
                                      }
                                    >
                                      <option value="MG">MG</option>
                                      <option value="ML">ML</option>
                                    </Form.Select>
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>

                            {index == medication?.length - 1 &&
                              medication?.length < 5 && (
                                <p
                                  style={{ cursor: "pointer" }}
                                  onClick={() => addMoreMedications()}
                                >
                                  + Add More
                                </p>
                              )}
                            {medication?.length > 1 ? (
                              <a
                                href="javascript:;"
                                className="delete-text"
                                onClick={() =>
                                  DeleteAddMoreMedications(index, item)
                                }
                              >
                                <i
                                  class="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            ) : null}
                          </Form>
                        </>
                      );
                    })}


                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAddMember}>
            {memberId ? "Update" : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={habitModal} onHide={hideHabbitModal} centered >
        <div className="">

          <Modal.Header className="heathy-header" closeButton>
            <Modal.Title>Member Healthy & Unhealthy Habits</Modal.Title>
          </Modal.Header>
          <Modal.Body className="health-outer-box">
            <div className="health-boxes">
              {memberExercise ? (
                <div className="inner-health">
                  <h6>{memberExercise[0]?.name} *</h6>
                  <div className="row">
                    {memberHabitError.exercise ? <p style={{ color: "red" }}>*Field is Required</p> : ""}
                    {memberExercise[0]?.options.map((res, i) => {
                      return (
                        <>
                          { }
                          <aside className="col-md-6">
                            <div class="form-check">
                              <input
                                type="radio"
                                class="form-check-input"
                                id={"ex_" + i}
                                name="ex_radio"
                                value={res.id}
                                onChange={(e) => {
                                  setMemberExerciseOpt(e.target.value)
                                  setIsMemberValidate(true)
                                  setMemberHabitError((obj) => {
                                    return {
                                      ...obj,
                                      exercise: false,
                                    }
                                  })

                                }}


                                checked={memberExerciseOpt == res.id ? true : ""}
                              />
                              {res.option}
                              <label
                                class="form-check-label"
                                for="radio1"
                              ></label>
                            </div>
                          </aside>
                        </>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}

              {memberDiet ? (
                <div className="inner-health">
                  <h6>{memberDiet[0]?.name} *</h6>
                  <div className="row">
                    {memberHabitError.diet ? <p style={{ color: "red" }}>*Field is Required</p> : ""}
                    {memberDiet[0]?.options.map((res, i) => {
                      return (
                        <>
                          { }
                          <aside className="col-md-6">
                            <div class="form-check">
                              <input
                                type="radio"
                                class="form-check-input"
                                id={"diet_" + i}
                                name="diet_radio"
                                value={res.id}
                                // checked={dietOpt === res.id}
                                onChange={
                                  (e) =>
                                  // console.log("res.id",res.id)
                                  {
                                    setMemberDietOpt(e.target.value)
                                    setIsMemberValidate(true)
                                    setMemberHabitError((obj) => {
                                      return {
                                        ...obj,
                                        diet: false,
                                      }
                                    })
                                  }
                                }
                                checked={memberDietOpt == res.id ? true : ""}
                              />
                              {res.option}
                              <label
                                class="form-check-label"
                                for="radio1"
                              ></label>

                            </div>
                          </aside>
                        </>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}

              {memberAlcohol ? (
                <div className="inner-health">
                  <h6>{alcohol[0]?.name} *</h6>
                  <div className="row">
                    {memberHabitError.alcohol ? <p style={{ color: "red" }}>*Field is Required</p> : ""}
                    {memberAlcohol[0]?.options.map((res, i) => {
                      return (
                        <>
                          { }
                          <aside className="col-md-6">
                            <div class="form-check">
                              <input
                                type="radio"
                                class="form-check-input"
                                id={"alcohol_" + i}
                                name="alcohol_radio"
                                value={res.id}
                                // checked={alcoholOpt === res.id}
                                onChange={
                                  (e) => {
                                    setMemberAlcoholOpt(e.target.value)
                                    setIsMemberValidate(true)
                                    setMemberHabitError((obj) => {
                                      return {
                                        ...obj,
                                        alcohol: false,
                                      }
                                    })

                                  }
                                }
                                checked={memberAlcoholOpt == res.id ? true : ""}
                              />
                              {res.option}
                              <label
                                class="form-check-label"
                                for="radio1"
                              ></label>

                            </div>
                          </aside>
                        </>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}

              {memberCaffine ? (
                <div className="inner-health">
                  <h6>{caffine[0]?.name} *</h6>
                  <div className="row">
                    {memberHabitError.caffine ? <p style={{ color: "red" }}>*Field is Required</p> : ""}
                    {memberCaffine[0]?.options.map((res, i) => {
                      return (
                        <>
                          { }
                          <aside className="col-md-6">
                            <div class="form-check">
                              <input
                                type="radio"
                                class="form-check-input"
                                id={"caffine_" + i}
                                name="caffine_radio"
                                value={res.id}
                                // checked={caffineOpt === res.id}
                                onChange={
                                  (e) => {
                                    setMemberCaffineOpt(e.target.value)
                                    setIsMemberValidate(true)
                                    setMemberHabitError((obj) => {
                                      return {
                                        ...obj,
                                        caffine: false,
                                      }
                                    })

                                  }
                                }
                                checked={memberCaffineOpt == res.id ? true : ""}
                              />
                              {res.option}
                              <label
                                class="form-check-label"
                                for="radio1"
                              ></label>

                            </div>
                          </aside>
                        </>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}

              {memberSmoke ? (
                <div className="inner-health">
                  <h6>{smoke[0]?.name} *</h6>
                  <div className="row">
                    {memberHabitError.smoke ? <p style={{ color: "red" }}>*Field is Required</p> : ""}
                    {memberSmoke[0]?.options.map((res, i) => {
                      return (
                        <>
                          { }
                          <aside className="col-md-6">
                            <div class="form-check">
                              <input
                                type="radio"
                                class="form-check-input"
                                id={"smoke_" + i}
                                name="smoke_radio"
                                value={res.id}
                                // checked={smokeOpt === res.id}
                                // onChange={(e) =>
                                //   setHabitArray([...habitArray.push(e.target.value)])
                                // }
                                onChange={
                                  (e) => {
                                    setIsMemberValidate(false)
                                    setMemberSmokeOpt(e.target.value)
                                    setIsMemberValidate(true)
                                    setMemberHabitError((obj) => {
                                      return {
                                        ...obj,
                                        smoke: false,
                                      }
                                    })

                                  }
                                }
                                checked={memberSmokeOpt == res.id ? true : ""}
                              />
                              {res.option}
                              <label
                                class="form-check-label"
                                for="radio1"
                              ></label>

                            </div>
                          </aside>
                        </>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="inner-health">
                <h6>Include additional comments about Medical History:</h6>
                <textarea
                  className="form-control"
                  onChange={(e) => {
                    setMemberMedicalData((e.target.value).trim())
                  }}
                >
                  {memberMedical}
                </textarea>
                {/* {habitError.medicals ? <p style={{color:"red"}}>*Field is Required</p> : ""} */}
              </div>
              <div className="inner-health">
                <h6>
                  Are there any known allergies? If so, please specify below:
                </h6>
                <div className="row">
                  <aside className="col-md-6">
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="allergy1"
                        name="allergyradio"
                        value={1}
                        onChange={(e) => {
                          setMemberAllergyOpt(1);
                        }}
                        checked={memberAllergy == 1 ? true : ""}
                      />
                      Yes
                      <label class="form-check-label" for="radio1"></label>
                    </div>
                  </aside>
                  <aside className="col-md-6">
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="allergy2"
                        name="allergyradio"
                        value={0}
                        onChange={(e) => {
                          setMemberAllergyOpt(0);
                        }}
                        checked={memberAllergy == 0 ? true : ""}
                      />
                      No
                      <label class="form-check-label" for="radio1"></label>
                      {memberHabitError.allergies ? <p style={{ color: "red" }}>*Field is Required</p> : ""}
                    </div>
                  </aside>
                </div>
                {memberAllergy == 1 ? (
                  <textarea
                    onChange={(e) => {
                      setMemberData(e.target.value);
                    }}
                    className="form-control"
                  >
                    {memberAllergyData}
                  </textarea>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* </div> */}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              disabled={isMemberValidate ? false : true}
              onClick={() => {
                addMemberHabbit(memberId);
              }}
              className="try-ti-buton"
            >Submit</Button>
          </Modal.Footer>
        </div>
      </Modal>


      {/* Modal for family member habits view */}

      <Modal
        className="success-box outer-habit-modal"
        backdrop="static"
        show={viewHabbit}
        onHide={hideHabbit}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="profile-card-inner outer-profile-main mt-3">
            {isLoader && <Loading />}
            <h5 className="user-text mb-3">
              Healthy & Unhealthy Habits{" "}
              <img
                className=""
                src={require("../../assests/images/habit.gif")}
                alt="img"
              />
            </h5>
            <div className="heathy-box">
              {isMemberHabit ? (
                <>
                  <div>
                    <h6 className="d-flex">
                      <img
                        className=""
                        src={require("../../assests/images/check-main.png")}
                        alt="img"
                      />{" "}
                      <p>Exercise: <span>{memberExerciseVal ? memberExerciseVal : "N/A"}</span></p>
                    </h6>
                    <h6 className="d-flex">
                      <img
                        className=""
                        src={require("../../assests/images/check-main.png")}
                        alt="img"
                      />
                      <p>Diet Followed: <span>{memberDietVal ? memberDietVal : "N/A"}</span></p>
                    </h6>
                    <h6 className="d-flex">
                      <img
                        className=""
                        src={require("../../assests/images/check-main.png")}
                        alt="img"
                      />
                      <p>Alcohol consumption: <span>{memberAlcoholVal ? memberAlcoholVal : "N/A"}</span></p>
                    </h6>
                    <h6 className="d-flex">
                      <img
                        className=""
                        src={require("../../assests/images/check-main.png")}
                        alt="img"
                      />
                      <p> Caffeine Consumption: <span>{memberCaffineVal ? memberCaffineVal : "N/A"}</span></p>
                    </h6>
                    <h6 className="d-flex">
                      <img
                        className=""
                        src={require("../../assests/images/check-main.png")}
                        alt="img"
                      />
                      <p> Smoking: <span>{memberSmokeVal ? memberSmokeVal : "N/A"}</span></p>
                    </h6>
                    <h6 className="d-flex">
                      <img
                        className=""
                        src={require("../../assests/images/check-main.png")}
                        alt="img"
                      />
                      <p>Medical History: <span>{memberMedical ? memberMedical : "N/A"}</span></p>
                    </h6>
                    <h6 className="d-flex">
                      <img
                        className=""
                        src={require("../../assests/images/check-main.png")}
                        alt="img"
                      />
                      <p>Allegry: <span>{memberAllergyData ? memberAllergyData : "No"}</span></p>
                    </h6>
                  </div>
                </>
              ) : <div className="text-center">
                <img
                  className=""
                  src={require("../../assests/images/diet.svg").default}
                  alt="img"
                />
                <h5>No Healthy Habits Added</h5></div>}
            </div>
          </div>
        </Modal.Body>
      </Modal>


    </>
  );
}
