import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { context } from "../../GlobalContext/GlobalContext";

const Footer = () => {
  const navigate = useNavigate();
  const globalData = useContext(context);
  const userType = localStorage.getItem('userType');
  const user = localStorage.getItem('user');
  const location = useLocation();


  function showModal() {
    // var url = window.location.href;
    // if(url.includes('login')){
    //   navigate("/login")
    //   // window.location.href = '/login';
    // }else{
    //   navigate("/")
    // }
    globalData.setShowContactUsModal(true)

  }
  function makeActiveTab() {
    let ele = document.getElementsByClassName("header-top-right active");
    if (ele[0]) {
      ele[0].classList.remove("active")
    }
    globalData.setActiveLink("");
  }

  function scrollTop(){
    document.body.scrollTop = 0; 
    document.documentElement.scrollTop = 0;
  }
  return <>
    <footer>
      <div className="upper-professional-section">
        <Container>
          <Row>
            <Col sm={6} md={4} lg={4}>
              <div className="professional-tools">
                <img
                  className="logo"
                  src={require("../../assests/images/logo22.png")}
                  alt="img"
                />
                <p className="mt-3">Meet our AI Health Assistant—a game-changer in healthcare workflows. Streamlining admin tasks, from e-prescriptions to report management. Elevate healthcare with our advanced AI technology.</p>
              </div>
            </Col>
            {userType !== 'doctor' &&
              <Col sm={6} md={4} lg={3}>
                <div className={(userType == 'doctor') ? "professional-tools opacit-0-3" :"professional-tools"}>
                  <h5>For Patient:</h5>
                  <ul>
                    {/*  || location.pathname == '/login' */}
                    {!user &&
                    <li className={""}>
                      <button onClick={(userType == 'doctor') ? () => {return false}: () => {
                        globalData.setActiveTab('advisor')
                        if (localStorage.getItem("user")) {
                          if (location.pathname !== "/247-virtual-assistant") {
                            scrollTop()
                            navigate("/247-virtual-assistant")
                          }
                        } else {
                          localStorage.setItem('redirectUrl',`/247-virtual-assistant`)
                          globalData.setTriggerLogin(true)
                        }
                      }}><i class="fa fa-arrow-circle-o-right me-2" aria-hidden="true"></i>
                       Patient Login</button>
                    </li>
            }
                    {/* location.pathname == '/login'?"opacit-0-3" : */}
                    <li className={""}>
                      <button onClick={(userType == 'doctor') ? () => {return false}:() => {
                       globalData.setActiveTab('report')
                        if (localStorage.getItem("user")) {
                          if (location.pathname !== "/clinical-insights") {
                            scrollTop()
                            navigate("/clinical-insights")
                          }
                        } else {
                          localStorage.setItem('redirectUrl',`/clinical-insights`)
                          globalData.setTriggerLogin(true)
                        }
                      }} ><i class="fa fa-arrow-circle-o-right me-2" aria-hidden="true"></i>Clinical Insight</button>
                    </li>
                    {/* location.pathname == '/login'?"opacit-0-3" : */}
                    <li className={""}>
                      <button onClick={(userType == 'doctor' ) ? () => {return false}: () => {
                        globalData.setActiveTab('advisor')
                        if (localStorage.getItem("user")) {
                          if (location.pathname !== "/247-virtual-assistant") {
                            scrollTop()
                            navigate("/247-virtual-assistant")
                          }
                        } else {
                          localStorage.setItem('redirectUrl',`/247-virtual-assistant`)
                          globalData.setTriggerLogin(true)
                        }
                      }} ><i class="fa fa-arrow-circle-o-right me-2" aria-hidden="true"></i>24/7 Virtual Assistant</button>
                    </li>
                  </ul>
                </div>
              </Col>
            }
            {/* {userType !== 'doctor' && !user && */}
            {!user && userType !== 'doctor' && !window.location.href.includes('login') && 
              
              <Col sm={6} md={4} lg={3}>
              <div className={(user || userType == 'doctor' || window.location.href.includes('login')) ?" professional-tools opacit-0-3": "professional-tools"}>
                <h5>For Professionals:</h5>
                <ul>
                  <li>
                    <button className={(user || userType == 'doctor' || window.location.href.includes('login')) ?"opacit-0-3" : ""}  onClick={(user || userType == 'doctor' || window.location.href.includes('login')) ? () => {return false}: () => { navigate('/login') }} ><i class="fa fa-arrow-circle-o-right me-2" aria-hidden="true"></i>Staff Login</button>
                  </li>
                </ul>
              </div>
            </Col>
            
            }
              
            {/* } */}

            <Col sm={6} md={4} lg={2}>
              <div className="privacy-box professional-tools">
                <h5>Links:</h5>
                {userType !== "doctor" ?
                  <span style={{ 'cursor': 'pointer' }} onClick={() => {
                    showModal()
                  }} className="privacy-content"><i class="fa fa-arrow-circle-o-right me-2" aria-hidden="true"></i>Contact Us</span> : ""}

                <span style={{ 'cursor': 'pointer' }} onClick={() => {
                  makeActiveTab()
                  navigate("/privacy")
                }} className="privacy-content"><i class="fa fa-arrow-circle-o-right me-2" aria-hidden="true"></i>Privacy Policy</span>

                <span style={{ 'cursor': 'pointer' }} onClick={() => {
                  makeActiveTab()
                  navigate("/terms")
                }} className="privacy-content"><i class="fa fa-arrow-circle-o-right me-2" aria-hidden="true"></i>Term & Conditions</span>
                <a style={{ 'cursor': 'pointer','color':'#070606' }} rel="noreferrer" target="_blank" href="https://blogs.aihealthassistant.pro" className="privacy-content"><i class="fa fa-arrow-circle-o-right me-2" aria-hidden="true"></i>Blogs</a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <div className="partician-footer">
          <Row>
            {/* <Col md={4}>
            <div className="social-handle">
              <a href="">
                <img src={require("../../assests/images/insta.svg").default} alt="img" />
              </a>
              <a href="">
                <img src={require("../../assests/images/fac.svg").default} alt="img" />
              </a>
              <a href="">
                <img src={require("../../assests/images/twi.svg").default} alt="img" />
              </a>
            </div>
          </Col> */}

            <Col md={6} lg={12}>
              <p className="attorney-copyright text-center">{`AI Health Assistant (c) ${new Date().getFullYear()}`}</p>
            </Col>
          </Row>
        </div>
      </Container>
    </footer>
  </>;
};

export default Footer;
