import { Col, Form, Row } from "react-bootstrap";
import { Loading } from "../common/loader";
import { useEffect, useState } from "react";
import { openAiService } from "../../services/openai.services";
import { Line } from "react-chartjs-2";
import { GraphLoader } from "../common/graphLoader";
import {
  Chart,
  registerables,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useNavigate } from "react-router-dom";
Chart.register(
  ...registerables,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

export const HomeData = () => {
  const todayDate = new Date();
  const userType = localStorage.getItem("userType");
  const user = localStorage.getItem("user");
  const navigate = useNavigate();

  useEffect(() => {
    if (userType !== "doctor" && user == null) {
      navigate("/login");
    } else if (
      (userType !== "doctor" && user !== null) ||
      (userType === "doctor" && user !== null)
    ) {
      navigate("/247-virtual-assistant");
    }
  }, []);

  const graphType = ["Month", "Year", "Annual"];
  const monthData = [
    { value: 1, name: "January" },
    { value: 2, name: "February" },
    { value: 3, name: "March" },
    { value: 4, name: "April" },
    { value: 5, name: "May" },
    { value: 6, name: "June" },
    { value: 7, name: "July" },
    { value: 8, name: "August" },
    { value: 9, name: "September" },
    { value: 10, name: "October" },
    { value: 11, name: "November" },
    { value: 12, name: "December" },
  ];
  const [isLoader, setIsLoader] = useState(false);
  const [cardData, setCardData] = useState([
    { name: "Total Appointments", image: require("../../assests/images/total-appointment.svg").default, count: 0 },
    { name: "Upcoming Appointments", image: require("../../assests/images/upcoming-appointment.svg").default, count: 0 },
    { name: "Completed Appointments", image: require("../../assests/images/complete-appointment.svg").default, count: 0 },
    { name: "Non-Attended Appointments", image: require("../../assests/images/non-appointment.svg").default, count: 0 },
  ]);
  const [yearData, setYearData] = useState([]);
  const [selectedType, setSelectedType] = useState("month");
  const [selectedMonth, setSelectedMonth] = useState(todayDate.getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(todayDate.getFullYear());
  const [graphData, setGraphData] = useState([]);

  const LineOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: function (value) {
            return `APPOINTMENT COUNT: ${value.formattedValue}`;
          },
        },
      },
    },
  };

  const LineData = {
    labels: graphData.map((d) => d.value),
    datasets: [
      {
        label: "AI HEALTH ASSISTANT APPOINTMENT",
        data: graphData.map((d) => d.count),
        backgroundColor: "#7269EF",
        borderColor: "#736aed78",
      },
    ],
  };

  useEffect(() => {
    getGraphData();
  }, [selectedType, selectedMonth, selectedYear]);

  useEffect(() => {
    changeYearData();
    getDashCount();
  }, []);

  const changeYearData = () => {
    var temp = [];
    for (let i = 2022; i <= (todayDate.getFullYear() + 1); i++) {
      temp.push(i);
    }
    setYearData(temp);
  };

  const getDashCount = () => {
    setIsLoader(true);
    openAiService
      .dashboardCount()
      .then((res) => {
        setIsLoader(false);
        setCardData([
          { name: "Total Appointments", image: require("../../assests/images/total-appointment.svg").default, count: res.data.total },
          { name: "Upcoming Appointments", image: require("../../assests/images/upcoming-appointment.svg").default, count: res.data.pending },
          { name: "Completed Appointments", image: require("../../assests/images/complete-appointment.svg").default, count: res.data.completed },
          { name: "Non-Attended Appointments", image: require("../../assests/images/non-appointment.svg").default, count: res.data.cancelled },
        ]);
      })
      .catch((err) => {
        console.log("Error: ", err);
        setIsLoader(false);
      });
  };

  const getGraphData = () => {
    setIsLoader(true);
    openAiService
      .graphDataApi(selectedType, selectedMonth, selectedYear)
      .then((res) => {
        console.log("res.adata",res.data)
        setGraphData(res.data);
        setIsLoader(false);
      })
      .catch((err) => {
        console.log("Error: ", err);
        setIsLoader(false);
      });
  };

  return (
    <>
    <div className="appointment-boxes report-insight-area">
        <div className="container">
          <div className="appointment-area">
            <h2>Dashboard</h2>

            
          </div>
        </div>
      </div>
    <section className="pricing-area under-appointment width-wise-dashboard inner-dashboard-section">
      <Row className="justify-content-center">
        
        <Col md={12}>
          <div className="profile-content-area">
            <Row className="mt-4">
              {cardData.map((d, i) => (
                <Col  sm={6} md={6} lg={3}  key={i}>
                  <div className="card-shadow">
                    <img
                      src={d.image}
                      className="img-responsive"
                      alt="img"
                    />
                    <div className="card-content">
                      <p>{d.name}</p>
                      <h2 className="text-orange f-700">{d.count}</h2>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            <Row className="mt-3">
              <Col md={8} lg={6}  className="position-relative">
              {isLoader && <GraphLoader />}
                <div className="card-shadows"> 
                <h6 className="f-700 mb-3">Appointment Chart</h6>
                  <div className="card-contents inner-card-content-main w-100 align-items-cente justify-content-between">
                   
                    <Form.Select
                      className="time-period d-inline mx-2"
                      aria-label="Default select example"
                      value={selectedType}
                      onChange={(e) => setSelectedType(e.target.value)}
                    >
                      {graphType.map((d, i) => (
                        <option key={i} value={d.toLowerCase()}>
                          {d}
                        </option>
                      ))}
                    </Form.Select>
                    {selectedType === "month" && (
                      <Form.Select
                        className="time-period d-inline mx-2"
                        aria-label="Default select example"
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(e.target.value)}
                      >
                        {monthData.map((d, i) => (
                          <option key={i} value={d.value}>
                            {d.name}
                          </option>
                        ))}
                      </Form.Select>
                    )}
                    {selectedType !== "annual" && (
                      <Form.Select
                        className="time-period d-inline mx-2"
                        aria-label="Default select example"
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                      >
                        {yearData.map((d, i) => (
                          <option key={i}  value={d}>
                            {d}
                          </option>
                        ))}
                      </Form.Select>
                    )}
                  </div>
                  <Line options={LineOptions} data={LineData} />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </section>
    </>
  );
};
