import React, { useEffect, useState } from "react";
import { Col, Container, Dropdown, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { openAiService } from "../../services/openai.services";
import { Loading } from "../common/loader";
import { useNavigate } from "react-router-dom";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css";
const localizer = momentLocalizer(moment) // or globalizeLocalizer

function Appointments() {
    const [appointments, setAppointments] = useState([]);
    const [isLoader, setIsLoader] = useState(true)

    var add_minutes =  function (dt, minutes) {
        return new Date(dt.getTime() + minutes*60000);
    }
    const formats = {
        eventTimeRangeFormat: () => { 
          return "";
        },
      };
    

    useEffect(() => {
        openAiService
            .getAllUpcompingAppointments()
            .then((result) => {
                
                console.log("result>>>",result?.data);
                let allAppointments = result?.data?.data;
                let allAppointmentsIlterations = []
                for (let i = 0; i < allAppointments.length; i++) {
                    let tmpObj = {};
                    tmpObj.id = i;
                    tmpObj.start = new Date(allAppointments[i].appointment_time);
                    tmpObj.title = allAppointments[i].name;
                    tmpObj.label = allAppointments[i].name;
                    tmpObj.description = allAppointments[i].name;
                    tmpObj.end = add_minutes(tmpObj.start, result?.data?.doctor_slot );
                    allAppointmentsIlterations.push(tmpObj);
                }
                setAppointments(allAppointmentsIlterations)
                setIsLoader(false)
            })
            .catch((err) => {
                setIsLoader(false)
                console.log(err);
            }).finally(() => {
                setIsLoader(false)
            })
    }, [])

    return (
        <>
            <section className="availability-box">
                {isLoader && <Loading />}
                <Container>
                    <br></br>
                    <div style={{ height: 700 }}>
                    <Calendar
                        localizer={localizer}
                        events={appointments}
                        startAccessor="start"
                        endAccessor="end"
                        formats={formats}
                    />
                    </div>
                </Container>
            </section>
        </>
    )
}
export default Appointments;