import { useState } from "react"
import Swal from "sweetalert2";
import { openAiService } from "../../services/openai.services";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { allBloodGroup, baseUrl } from "../../config/config";
import moment from "moment";
import { Loading } from "../common/loader";

export const AddMember = (props) => {
    const [doberror, setDoberror] = useState("DOB field is required.");
    const [weightErr, setWeightErr] = useState("");
    const [heightErr, setHeightErr] = useState("");
    const [delmedication, setDelMedication] = useState([]);
    const [imageUrl, setImageUrl] = useState("");
    const [isLoader,setIsLoader] = useState(false);
    const extension = ["image/jpg", "image/png", "image/jpeg"];
    

    const ct = require("countries-and-timezones");
   const timezone = ct.getTimezone(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const COUNTRY_CODE = timezone.countries[0];
    const [medication, setMedication] = useState([
        { name: "", dosage: "", unit: "MG", id: "" },
      ]);
    const [memberInfo, setMemberInfo] = useState({
        id: "",
        image: "",
        member_name: "",
        relation: "",
        phone: "",
        dob: "",
        blood_group: "MG",
        weight: "",
        height: "",
        gender: "",
      })
    
      const [memberInfoErr, setMemberInfoErr] = useState({
        image: false,
        extension: false,
        member_name: false,
        relation: false,
        phone: false,
        dob: false,
        blood_group: false,
        weight: false,
        height: false,
        gender: false,
      })

      function isAnyKeyEmpty(objects) {
        var error;
        objects.some((obj) => {
          if (!obj.name || !obj.dosage) {
            error = false;
          } else {
            error = true
          }
        })
        return error;
    
      }

      function addMoreMedications() {
        let tempList = [...medication];
        tempList.push({ id: "", name: "", dosage: "", unit: "MG" });
        setMedication(tempList);
      }

      function onChangeMedicationName(index, value) {
        let tempList = [...medication];
        tempList[index].name = value;
        setMedication(tempList);
      }

      function onChangeMedicationDosage(index, value) {
        let tempList = [...medication];
        tempList[index].dosage = value;
        setMedication(tempList);
      }
    
      function onChangeMedicationUnit(index, value) {
        let tempList = [...medication];
        tempList[index].unit = value;
        setMedication(tempList);
      }
    
      function DeleteAddMoreMedications(index, item) {
        let tempList = [...medication];
    
        tempList.splice(index, 1);
        setMedication(tempList);
    
        // console.log(item)
        // tempListDel.push(item.id)
        // (tempListDel)
        if (item.id != "") {
          let newArray = [...delmedication, item.id];
          console.log(newArray);
          setDelMedication(newArray);
        }
      }


      function getFamilyMembers() {
        openAiService.getFamilyMembers()
          .then((result) => {
            props.change(result.data.data);
    
    
            // result.data?.data.map((res) => {
            //   getMemberHabbit(res.id)
            // })
    
    
            console.log("ressss", result.data.data)
          })
          .catch((err) => {
            console.log("errrr", err)
          })
      }



      function handleAddMember() {
        var validation = true;
        const selectedDate = new Date(memberInfo.dob);
        const currentDate = new Date();
    
        if (!memberInfo.member_name || memberInfo.member_name == "") {
          validation = false;
          setMemberInfoErr((prev) => {
            return {
              ...prev, member_name: true
            }
          })
        } else {
          validation = true;
          setMemberInfoErr((prev) => {
            return {
              ...prev, member_name: false
            }
          })
        }
    
        if (!memberInfo.relation || memberInfo.relation.trim() === "") {
          validation = false;
          setMemberInfoErr((prev) => {
            return {
              ...prev, relation: true
            }
          })
        } else {
          validation = true;
          setMemberInfoErr((prev) => {
            return {
              ...prev, relation: false
            }
          })
        }
    
        // if (!memberInfo.phone || memberInfo.phone.trim() === "") {
        //   validation = false;
        //   setMemberInfoErr((prev) => {
        //     return {
        //       ...prev, phone: true
        //     }
        //   })
        // } else {
        //   validation = true;
        //   setMemberInfoErr((prev) => {
        //     return {
        //       ...prev, phone: false
        //     }
        //   })
        // }
    
    
        if (!memberInfo.dob || memberInfo.dob.trim() === "") {
          validation = false;
          setDoberror("Invalid date of birth");
          setMemberInfoErr((prev) => {
            return {
              ...prev, dob: true
            }
          })
        } else if (isNaN(selectedDate.getTime())) {
          validation = false;
          setDoberror("Invalid date of birth");
          setMemberInfoErr((prev) => {
            return {
              ...prev, dob: true
            }
          })
        } else if (selectedDate > currentDate) {
          validation = false;
          setDoberror("DOB can't be set as a future date.");
          setMemberInfoErr((prev) => {
            return {
              ...prev, dob: true
            }
          })
        } else {
          validation = true;
          setMemberInfoErr((prev) => {
            return {
              ...prev, dob: false
            }
          })
        }
    
        if (!memberInfo.weight || memberInfo.weight === "") {
          setWeightErr("Weight field is required.");
          validation = false;
          setMemberInfoErr((prev) => {
            return {
              ...prev, weight: true
            }
          })
        } else if (isNaN(memberInfo.weight)) {
          setWeightErr("Weight should be a number.");
          validation = false;
          setMemberInfoErr((prev) => {
            return {
              ...prev, weight: true
            }
          })
        } else {
          validation = true;
          setWeightErr("");
          setMemberInfoErr((prev) => {
            return {
              ...prev, weight: false
            }
          })
        }
    
        if (!memberInfo.height || memberInfo.height === "") {
          validation = false;
          setHeightErr("Height field is required.")
          setMemberInfoErr((prev) => {
            return {
              ...prev, height: true
            }
          })
        } else if (isNaN(memberInfo.height)) {
          validation = false;
          setHeightErr("Height should be a number.")
          setMemberInfoErr((prev) => {
            return {
              ...prev, height: true
            }
          })
        } else {
          validation = true;
          setHeightErr("")
          setMemberInfoErr((prev) => {
            return {
              ...prev, height: false
            }
          })
        }
    
        if (!memberInfo.blood_group || memberInfo.blood_group == "") {
          validation = false;
          setMemberInfoErr((prev) => {
            return {
              ...prev, blood_group: true
            }
          })
        } else {
          validation = true;
          setMemberInfoErr((prev) => {
            return {
              ...prev, blood_group: false
            }
          })
        }
    
        if (!memberInfo.gender || memberInfo.gender == "") {
          validation = false;
          setMemberInfoErr((prev) => {
            return {
              ...prev, gender: true
            }
          })
        } else {
          validation = true;
          setMemberInfoErr((prev) => {
            return {
              ...prev, gender: false
            }
          })
        }

        if (validation) {
          if (memberInfo.member_name == "" || memberInfo.dob == "" || memberInfo.height == "" || memberInfo.weight == "" || memberInfo.gender == "" || memberInfo.blood_group == "") {
            Swal.fire("Error", "Please Fill the reaquired fields", "error");
          } else {
            var formdata = new FormData();
            console.log("memberInfomemberInfo", memberInfo)
            if (memberInfo.image !== "") {
              formdata.append("image", memberInfo.image)
            }
            formdata.append("name", memberInfo.member_name)
            formdata.append("relation", memberInfo.relation)
            formdata.append("dob", memberInfo.dob)
            formdata.append("phone", memberInfo.phone)
            formdata.append("blood_group", memberInfo.blood_group)
            formdata.append("weight", memberInfo.weight)
            formdata.append("height", memberInfo.height)
            formdata.append("gender", memberInfo.gender)
            console.log("medicationmedication", isAnyKeyEmpty(medication))
            if (isAnyKeyEmpty(medication)) {
              formdata.append("medication", JSON.stringify(medication))
            } else {
              formdata.append("medication", JSON.stringify([]))
            }
            //  
              setIsLoader(true)
              openAiService.addFamilyMember(formdata).then((res) => {
                console.log("add members", res.data)
                setIsLoader(false)
                props.close()
                props.selectMember(res.data?.data)
                setImageUrl("")
                setMedication([{ name: "", dosage: "", unit: "MG", id: "" }])
                setDelMedication([])
                getFamilyMembers()
                setMemberInfo({
                  id: "",
                  image: "",
                  member_name: "",
                  relation: "",
                  phone: "",
                  dob: "",
                  blood_group: "MG",
                  weight: "",
                  height: "",
                  gender: "male",
                })
    
                Swal.fire("Success", "Member added successfully", "success");
    
              }).catch((err) => {
                console.log("errrr--", err)
              })
            
    
          }
          //  console.log("data sendingggg", formdata);
        }
    
      }
      
      


  return (
    
    <>
     {isLoader && <Loading />}
    <Modal show={props.showModal} onHide={() => {
        setImageUrl("")
        setMedication([{ name: "", dosage: "", unit: "MG", id: "" }])
        setMemberInfo({
          image: "",
          member_name: "",
          relation: "",
          phone: "",
          dob: "",
          blood_group: "MG",
          weight: "",
          height: "",
          gender: "",
        })
        setMemberInfoErr({
          image: false,
          extension: false,
          member_name: false,
          relation: false,
          phone: false,
          dob: false,
          blood_group: false,
          weight: false,
          height: false,
          gender: false,
        })
        props.close()
        // handleCloseAdd()
      }} centered className="family-box-section">
        <Modal.Header closeButton>
          <Modal.Title>Family Members</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="family-form">

            <Row>
              <Col md={4}>
                <div className="upload-family-img">
                  <img
                    className={imageUrl ? "family-img" : ""}
                    id="member_image"
                    src={imageUrl !== "" ? imageUrl : require("../../assests/images/family-img.png")}
                    alt="img"
                  />
                  <Form.Control id="member_profile" type="file" accept="image/*" onChange={(e) => {
                    console.log("e.target.files[0].type", e.target.files[0])
                    setMemberInfo((prev) => {
                      return {
                        ...prev, image: e.target.files[0]
                      }
                    });
                    if (e.target.files[0] === "") {
                      document.getElementById("member_profile").value = null;
                      setMemberInfoErr((prev) => {
                        return { ...prev, image: true }
                      })
                    } else if (!extension.includes(e.target.files[0].type)) {
                      document.getElementById("member_profile").value = null;
                      setMemberInfo((prev) => {
                        return {
                          ...prev, image: ""
                        }
                      });
                      setMemberInfoErr((prev) => {
                        return { ...prev, extension: true }
                      })
                    } else {
                      setImageUrl(URL.createObjectURL(e.target.files[0]))
                      // document.getElementById("member_image").src = URL.createObjectURL(e.target.files[0])
                      setMemberInfoErr((prev) => {
                        return { ...prev, extension: false }
                      })
                    }
                  }} placeholder="Enter Member Name" />
                  <p>Upload Picture here</p>
                  {memberInfoErr.image ? <span className="error-class">Field is Required.</span> : memberInfoErr.extension ? <span className="error-class">Select valid Extension.</span> : ""}

                </div>
              </Col>
              <Col md={8}>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Member Name *</Form.Label>
                      <Form.Control maxLength={30} value={memberInfo.member_name} type="text" onChange={(e) => {
                        setMemberInfo((prev) => {
                          return {
                            ...prev, member_name: e.target.value
                          }
                        })
                        if (e.target.value.trim() === "") {

                          setMemberInfoErr((prev) => {
                            return {
                              ...prev, member_name: true
                            }
                          })
                        } else {
                          setMemberInfoErr((prev) => {
                            return {
                              ...prev, member_name: false
                            }
                          })
                        }
                      }} placeholder="Enter Member Name" />
                      {memberInfoErr.member_name &&
                        <span className="error-class">Member Name field is required.</span>}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Relationship *</Form.Label>
                      <Form.Control type="text" maxLength={30} value={memberInfo.relation} onChange={(e) => {
                        setMemberInfo((prev) => {
                          return {
                            ...prev, relation: e.target.value
                          }
                        })
                        if (e.target.value === "" || e.target.value.trim() === "") {
                          setMemberInfoErr((prev) => {
                            return {
                              ...prev, relation: true
                            }
                          })
                        } else {
                          setMemberInfoErr((prev) => {
                            return {
                              ...prev, relation: false
                            }
                          })
                        }
                      }} placeholder="Enter Relationship" />
                      {memberInfoErr.relation && <span className="error-class">Relationship field is required.</span>}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Contact Number</Form.Label>
                      <PhoneInput
                        country={
                          COUNTRY_CODE
                            ? COUNTRY_CODE.toLocaleLowerCase()
                            : "us"
                        }
                        placeholder="Enter phone number"
                        value={memberInfo.phone}
                        onChange={(e) => {
                          setMemberInfo((prevObj) => {
                            return {
                              ...prevObj,
                              phone: e,
                            };
                          });
                          // if (e.trim() === "") {
                          //   setMemberInfoErr((prevObj) => {
                          //     return {
                          //       ...prevObj,
                          //       phone: true,
                          //     };
                          //   });
                          // } else {
                          //   setMemberInfoErr((prevObj) => {
                          //     return {
                          //       ...prevObj,
                          //       phone: false,
                          //     };
                          //   });
                          // }
                        }}
                      />
                      {/* {memberInfoErr.phone && <span className="error-class">Contact Number field is required.</span>} */}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3 date-icon" controlId="exampleForm.ControlInput1">
                      <Form.Label>DOB *</Form.Label>
                      <Form.Control value={memberInfo.dob} max={moment(new Date).format("YYYY-MM-DD")} type="date" onChange={(e) => {
                        setMemberInfo((prev) => {
                          return {
                            ...prev, dob: e.target.value
                          }
                        })
                      }} placeholder="mm/dd/yyy" />
                      <i class="fa fa-calendar" aria-hidden="true"></i>
                      {memberInfoErr.dob && <span className="error-class">{doberror}</span>}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Blood Group *</Form.Label>
                      <Form.Select
                        aria-label=".form-select-lg example"
                        value={memberInfo.blood_group}
                        onChange={(e) => {
                          setMemberInfo((prev) => {
                            return {
                              ...prev, blood_group: e.target.value
                            }
                          })
                        }}
                      >
                        <option>Select Blood Group</option>
                        {allBloodGroup.map((groupBlood, index) => {
                          // {console.log(groupBlood)}
                          return (
                            <option value={groupBlood}>{groupBlood}</option>
                          );
                        })}
                      </Form.Select>
                      {memberInfoErr.blood_group && <span className="error-class">Blood Group field is required.</span>}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Weight(kg) *</Form.Label>
                      <Form.Control type="number" value={memberInfo.weight} onChange={(e) => {
                        setMemberInfo((prev) => {
                          return {
                            ...prev, weight: e.target.value
                          }
                        })
                        if (e.target.value.trim == "") {
                          setWeightErr("Weight field is required.")
                          setMemberInfoErr((prev) => {
                            return {
                              ...prev, weight: true
                            }
                          })
                        } else if (e.target.value < 1) {
                          setMemberInfo((prev) => {
                            return {
                              ...prev, weight: ""
                            }
                          })
                        } else {
                          setWeightErr("")
                          setMemberInfoErr((prev) => {
                            return {
                              ...prev, weight: false
                            }
                          })
                        }
                      }} placeholder="Enter Weight(kg)" />
                      {memberInfoErr.weight && <span className="error-class">{weightErr}</span>}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Height(ft) *</Form.Label>
                      <Form.Control type="number" value={memberInfo.height} onChange={(e) => {
                        setMemberInfo((prev) => {
                          return {
                            ...prev, height: e.target.value
                          }
                        })
                        if (e.target.value.trim == "") {
                          setHeightErr("Height field is required.")
                          setMemberInfoErr((prev) => {
                            return {
                              ...prev, height: true
                            }
                          })
                        } else if (e.target.value < 1) {
                          setMemberInfo((prev) => {
                            return {
                              ...prev, height: ""
                            }
                          })
                        } else {
                          setHeightErr("")
                          setMemberInfoErr((prev) => {
                            return {
                              ...prev, height: false
                            }
                          })
                        }
                      }} placeholder="Enter Height(ft)" />
                      {memberInfoErr.height && <span className="error-class">{heightErr}</span>}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Gender *</Form.Label>
                      <Form.Select
                        aria-label=".form-select-lg example"
                        value={memberInfo.gender}
                        onChange={(e) => {
                          setMemberInfo((prev) => {
                            return {
                              ...prev, gender: e.target.value
                            }
                          })
                        }}
                      >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </Form.Select>
                      {memberInfoErr.gender && <span className="error-class">Gender field is required.</span>}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <div className="profile-medication-area">
                  <h6>Medication</h6>
                  {medication &&
                    medication.map((item, index) => {
                      let dosage = parseInt(medication[index]?.dosage);
                      let unit = parseInt(medication[index]?.unit);
                      //  let dosage = parseInt(med[0])
                      //    console.log(med)
                      return (
                        <>
                          <Form className="medication-form">
                            <Row>
                              <Col md={6}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Name"
                                    onChange={(e) =>
                                      onChangeMedicationName(
                                        index,
                                        e.target.value
                                      )
                                    }
                                    value={medication[index]?.name}
                                    onKeyDown={(e) => {
                                      if (
                                        !e.target.value &&
                                        e.code === "Space"
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                              
                              <Col md={6}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Dosage</Form.Label>
                                  <div className="dosage-area">
                                    <Form.Control
                                      type="number"
                                      placeholder="Enter Dosage"
                                      onWheel={() =>
                                        document.activeElement.blur()
                                      }
                                      value={dosage}
                                      onChange={(e) =>
                                        onChangeMedicationDosage(
                                          index,
                                          e.target.value
                                        )
                                      }
                                      onKeyDown={(e) => {
                                        if (
                                          !e.target.value &&
                                          e.code === "Space"
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />

                                    <Form.Select
                                      aria-label=".form-select-lg example"
                                      style={{ width: "20%" }}
                                      onChange={(e) =>
                                        onChangeMedicationUnit(
                                          index,
                                          e.target.value
                                        )
                                      }
                                      value={
                                        medication[index]?.unit || unit
                                      }
                                    >
                                      <option value="MG">MG</option>
                                      <option value="ML">ML</option>
                                    </Form.Select>
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>

                            {index == medication?.length - 1 &&
                              medication?.length < 5 && (
                                <p
                                  style={{ cursor: "pointer" }}
                                  onClick={() => addMoreMedications()}
                                >
                                  + Add More
                                </p>
                              )}
                            {medication?.length > 1 ? (
                              <a
                                href="javascript:;"
                                className="delete-text"
                                onClick={() =>
                                  DeleteAddMoreMedications(index, item)
                                }
                              >
                                <i
                                  class="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            ) : null}
                          </Form>
                        </>
                      );
                    })}


                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAddMember}>
            {"Submit"}
          </Button>
        </Modal.Footer>
      </Modal>
      </>

  )    



}
