import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Privacy from "../pages/Privacy";
import CommonLayout from "./CommonLayout";
import Terms from "../pages/Terms";
import Chat from "../pages/Chat";
import DocumentChat from "../pages/DocumentChat";
import Home from "../pages/Home";
import Pricing from "../pages/Pricing";
import CardForm from "../pages/SavedCard";
import CardDetail from "../pages/CardDetail";
// import Speech from "../pages/Speech";
import CancellationPolicy from "../pages/CancellationPolicy";
import ErrorPage from "../pages/ErrorPage";
import MyProfile from "../pages/MyProfile";
import ProfilePage from "../pages/ProfilePage";
import Login from "../Login";
import Dashboard from "../pages/dashboard";
import UserProfilePage from "../pages/userProfile";
import Appointment from "../pages/myAppointment";
import UserDetails from "../pages/UserDetails";
import Setting from "../pages/Setting";
import DoctorAvailability from "../pages/DoctorAvailability";
import UserMedicationPage from "../pages/UserMedicationPage";
import Cancer from "../pages/cancerAdvisory";
import Appointments from "../pages/Appointments";
import HistoryGenerate from "../pages/HistoryGenerate";
import { HomeData } from "../pages/HomeData";
import { ReportInsight } from "../pages/reportInsight";
import AlaCare from "../pages/AlaCare";
import UpdatedHome from "../pages/UpdatedHome";

const
  Routing = () => (
    <Router>
      <Routes>
        <Route path="/" element={<CommonLayout>{<Home />}</CommonLayout>}></Route>
        <Route
          path="/pricing"
          element={<CommonLayout>{<Pricing />}</CommonLayout>}
        ></Route>
        <Route
          path="/specialist/:type"
          element={<CommonLayout>{<Cancer />}</CommonLayout>}
        ></Route>
        <Route
          path="/247-virtual-assistant"
          element={<CommonLayout>{<Chat />}</CommonLayout>}
        ></Route>
        <Route
          path="/clinical-insights"
          element={<CommonLayout>{<DocumentChat />}</CommonLayout>}
        ></Route>
        <Route
          path="/privacy"
          element={<CommonLayout>{<Privacy />}</CommonLayout>}
        ></Route>
        <Route
          path="/terms"
          element={<CommonLayout>{<Terms />}</CommonLayout>}
        ></Route>
        <Route
          path="/cancellation-policy"
          element={<CommonLayout>{<CancellationPolicy />}</CommonLayout>}
        ></Route>
        <Route
          path="/home"
          element={<CommonLayout>{<Home />}</CommonLayout>}
        ></Route>
        <Route
          path="/savedcard"
          element={<CommonLayout>{<CardForm />}</CommonLayout>}
        ></Route>
        <Route
          path="/card_details"
          element={<CommonLayout>{<CardDetail />}</CommonLayout>}
        ></Route>
        <Route
          path="/create_profile"
          element={<CommonLayout>{<MyProfile />}</CommonLayout>}>
        </Route>
        <Route
          path="/my_profile"
          element={<CommonLayout>{<ProfilePage />}</CommonLayout>}>
        </Route>
        <Route
          path="/my_appointment"
          element={<CommonLayout>{<Appointment />}</CommonLayout>}>
        </Route>
        <Route path="/login"
          element={<CommonLayout>{<Login />}</CommonLayout>}
        ></Route>
        <Route path="/my-appointment"
          element={<CommonLayout>{<Dashboard />}</CommonLayout>}
        ></Route>
        <Route path="/dashboard"
          element={<CommonLayout>{<HomeData />}</CommonLayout>}
        ></Route>
        <Route
          path="/userProfile"
          element={<CommonLayout>{<UserProfilePage />}</CommonLayout>}
        ></Route>

        <Route
          path="/user-medication"
          element={<CommonLayout>{<UserMedicationPage />}</CommonLayout>}
        ></Route>

        <Route path="/userdetails"
          element={<CommonLayout>{<UserDetails />}</CommonLayout>}
        ></Route>

        <Route path="/setting"
          element={<CommonLayout>{<Setting />}</CommonLayout>}
        ></Route>
        <Route path="/availability"
          element={<CommonLayout>{<DoctorAvailability />}</CommonLayout>}
        ></Route>

        <Route path="/appointments"
          element={<CommonLayout>{<Appointments />}</CommonLayout>}
        ></Route>

        <Route path="/history-generate"
          element={<CommonLayout>{<HistoryGenerate />}</CommonLayout>}
        ></Route>
        <Route path="/report-insight"
          element={<CommonLayout>{<ReportInsight />}</CommonLayout>}
        ></Route>
        <Route path="/ala-care"
          element={<CommonLayout>{<AlaCare />}</CommonLayout>}
        ></Route>
        <Route path="/updated-home"
          element={<CommonLayout>{<UpdatedHome />}</CommonLayout>}
        ></Route>

        <Route
          exact="true" path="/*"
          element={<ErrorPage />}
        ></Route>
      </Routes>
    </Router>
  );

export default Routing;
