import { Col, Row, Container } from "react-bootstrap";

const AlaCare = () => {

  return (
    <>
      <div className="pricing-area">
        {/* ala care top section start */}
        <section className="huge-benefits-section ">
          <Container>
            <Row className="align-items-center">
              <Col md={6}>
                <div className="huge-benefits-content">
                  <h1>Ayushmam Bharat Digital Mission(ABDM)</h1>
                  <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Adipisci pariatur exercitationem nam suscipit consectetur ea, non dicta quos fugiat quo sequi quisquam laborum nulla quis earum repellat hic deserunt voluptatibus!</p>
                  <button type="button" class="try-ti-buton get-in-touch animated-btn mt-3 btn btn-primary"><span class="pulse1"></span>Book a Call</button>
                </div>
              </Col>
              <Col md={6}>
                <div className="huge-benefits-images">
                <img
                    src={require("../../assests/images/bharat-digital.svg").default}
                    alt="img"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* ala care top section ends */}

        {/* samll spend section start */}
        <section className="small-spend-section">
          <Container>
            <Row className="align-items-center">
              <Col md={4}>
                <div className="small-speed-content">
                  <h2>Small Spend</h2>
                  <h3 className="small-headings">Huge benefis</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel suscipit, ex sequi commodi consectetur officia praesentium incidunt dolore ratione quo, sed totam facilis. Reprehenderit a, incidunt sint assumenda accusamus blanditiis.</p>
                </div>
              </Col>
              <Col md={8}>
                <div className="small-speed-images">
                  <img
                    src={require("../../assests/images/medicine.svg").default}
                    alt="img"
                  />
                  <img
                    src={require("../../assests/images/lab-test.svg").default}
                    alt="img"
                  />
                  <img
                    src={require("../../assests/images/doctor.svg").default}
                    alt="img"
                  />
                  <img
                    src={require("../../assests/images/more.svg").default}
                    alt="img"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* samll spend section start */}

        {/* expert section start */}
        <section className="expert-section">
          <Container>
            <Row className="justify-content-center">
              <Col md={3}>
                <div className="expert-box text-center">
                  <img
                    src={require("../../assests/images/doctor-icon-1.png")}
                    alt="img"
                  />
                  <h3><b>5000+</b></h3>
                  <p><b>Expert Doctors</b></p>
                </div>
              </Col>
              <Col md={3}>
                <div className="expert-box text-center">
                  <img
                    src={require("../../assests/images/doctor-icon-2.png")}
                    alt="img"
                  />
                  <h3><b>4.5 L+</b></h3>
                  <p><b>Branded Medicine</b></p>
                </div>
              </Col>
              <Col md={3}>
                <div className="expert-box text-center">
                  <img
                    src={require("../../assests/images/doctor-icon-3.png")}
                    alt="img"
                  />
                  <h3><b>7500+</b></h3>
                  <p><b>Prescriptions</b></p>
                </div>
              </Col>
              {/* <Col md={3}>
                <div className="expert-box text-center">
                  <img
                    src={require("../../assests/images/doctor-icon-5.png")}
                    alt="img"
                  />
                  <h3><b>300K+</b></h3>
                  <p><b>ABHA ID</b></p>
                </div>
              </Col> */}
            </Row>
          </Container>
        </section>
        {/* expert section ends */}

        {/* Create account start */}
        <section className="create-section">
          <Container>
            <Row>
              <Col md={12}>
                <div className="create-box text-center">
                  <h3 className="mb-3">Create Ayushman Bharat Health
                    Account - ABHA Health ID Card</h3>
                  <p>ABHA (Health ID) card is a 14 digit unique number with which
                    you can link all your health records digitally. Create your
                    ABHA Health ID card using an Aadhar number in less than 30
                    seconds.</p>
                </div>
                <div className="create-box-banner mt-5">
                <img
                    src={require("../../assests/images/bharat-digital-banner.png")}
                    alt="img"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Create account end */}
      </div>
    </>
  );
};

export default AlaCare;
