import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import ReactPlayer from "react-player";
export const DateTimeFormatter = (props) => {
    if (props.date) {
        return <>{moment(props.date).format("DD, MMMM YYYY | HH:mm")}</>;
    }
};


export const DateFormatter = (props) => {
    if (props.date) {
        return <>{moment(props.date).format("Do MMM YYYY")}</>;
    }
};



export const AssetRender = ({ fileUrl, fileType, thumbnail, isFile }) => {
    const timestamp = Date.now();
    const [videoMediaUrl, setVideoMediaUrl] = useState(``);
    
    useEffect(() => {
      if (isFile && (fileType === "image")) {
        setVideoMediaUrl(URL.createObjectURL(fileUrl))
      } else {
        setVideoMediaUrl(fileUrl);
      }
  
    }, [fileUrl]);
  
   
  
   
  
    return (
      <>
        {fileType === "video" ? (
            <ReactPlayer
              controls={false}
              playing={true}
              muted={true}
              playsinline={true}
              width="100%"
              height="100%"
              loop
              className='react-player'
              url={[
                { src: `${videoMediaUrl}#t=0.1&n=1&d=19680000&v=${timestamp}`, type: 'video/webm' },
                { src: `${videoMediaUrl}#t=0.1&n=1&d=19680000&v=${timestamp}`, type: 'video/ogg' },
                { src: `${videoMediaUrl}#t=0.1&n=1&d=19680000&v=${timestamp}`, type: 'video/mp4' }
              ]}
              config={{
                file: {
                  attributes: {
                    poster: thumbnail ? thumbnail + "#t=0.1" : "",
                    preload:"metadata",
                    controlsList: "nodownload",
                    id: `video${videoMediaUrl.split("/").pop()}`
                  },
                }
              }}
           
            />
          
        ) : fileType === "image" ? (
          <img
            id={`video${videoMediaUrl.split("/").pop()}`}
            src={videoMediaUrl}
            alt="Asset" />
        ) : (
  
          <iframe
            className="embed-responsive-item"
            src={fileUrl.includes("watch?v=") ? `${fileUrl.replace("watch?v=", "embed/")}?enablejsapi=1` : fileUrl}
            frameBorder="0"
            width="100%"
            height="100%"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={true}
          ></iframe>
  
        )}
       
      </>
    );
  };
