import { Button, Col, Container, Row, Form, Modal } from "react-bootstrap";
import { useState, useContext, useEffect } from "react";
import { MDBContainer, MDBCol, MDBRow, MDBBtn, MDBIcon, MDBInput, MDBCheckbox } from 'mdb-react-ui-kit';

import { openAiService } from "../services/openai.services";
import { context } from "../GlobalContext/GlobalContext";

import Swal from "sweetalert2";
import SuccessPopup from "./modal/SuccessPopup"
import Errorpopup from "./modal/Errorpopup"
import { useNavigate } from "react-router-dom";
import { Loading } from "./common/loader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReCAPTCHA from "react-google-recaptcha";
import {
  DEFAULT_PLAN_ID,
  EMAILREGEX,
  Project_Title,
} from "../config/config";
const Login = () => {
  const globalData = useContext(context);
  const [isLoader, setIsLoader] = useState(false)
  const [show, setShow] = useState(false);
  const [message1, setmessage1] = useState("")
  const [message2, setmessage2] = useState("")
  const [showErr, setShowErr] = useState(false);
  const [errmessage1, seterrmessage1] = useState("Error")
  const [errmessage2, seterrmessage2] = useState("")
  const [redirectlink, setredirectlink] = useState("/")
  const [resend_btn, setresendbt] = useState(false)
  const user = localStorage.getItem('user');
  const userType = localStorage.getItem('userType');
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState('password');
  
  const [contactUsFields, setContactUsFields] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
  });
  const [isCaptchaValue, setIsCaptchaValue] = useState();
  const [contactUsFieldsErrors, setContactUsFieldsErrors] = useState({
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    message: false,
  });
  const ct = require("countries-and-timezones");

const timezone = ct.getTimezone(
  Intl.DateTimeFormat().resolvedOptions().timeZone
);
const COUNTRY_CODE = timezone.countries[0];

  function onChange(value) {
    // isCaptchaValue.current = value;
    setIsCaptchaValue(value);
    console.log("Captcha value:", value);
  }

  useEffect(() => {
    if (user != null) {
      navigate("/247-virtual-assistant")
    } else if (token !== null && userType === 'doctor') {
      navigate("/dashboard")
    }
  }, [])

  const handleClose = (link) => {
    window.location.href = link

    setShow(false);
  }
  const handleCloseErr = () => {



    setShowErr(false);
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit()
    }
  };
  const handleShowErr = () => setShowErr(true);
  const handleShow = () => setShow(true);

  // console.log("======globalData====",globalData)

  const [loginFields, setloginFields] = useState({
    email: "",
    password: "",

  });
  const [loginFieldsErrs, setloginFieldsErrs] = useState({
    email: false,
    emailErr: "",
    password: false,
    passwordErr: "",

  });

  const showPassWord = () => {
    passwordType === 'password' ? setPasswordType('text') : setPasswordType('password')
    // setPasswordType('text');
  }


  const handleSubmit = () => {

    // e.preventDefault();
    let formValidated = openAiService.loginValidate(setloginFieldsErrs, loginFields)
    if (formValidated) {
      setIsLoader(true)
      let data = {
        email: loginFields.email,
        password: loginFields.password,

      };
      console.log("====login", data)
      openAiService.login(data).then((res) => {
        console.log("====token", res.data.data)
        localStorage.setItem("token", res.data.data.token)
        // localStorage.setItem("user",res.data.data)
        localStorage.setItem("userType", "doctor")

        window.location.href = "/dashboard"

      })
        .catch((err) => {

          // handleShowErr()
          Swal.fire("Error", "Email or Password is incorrect.", "error")
          setIsLoader(false)
          //   Swal.fire("Error", "Something went wrong.", "error");
          console.log("contact us api error", err.response);
        });
    }
  };

  const handleContactUsSubmit = (e) => {
    e.preventDefault();
    let formValidated = true;
    if (contactUsFields.firstName.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          firstName: true,
        };
      });
    }
    if (contactUsFields.lastName.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          lastName: true,
        };
      });
    }
    if (contactUsFields.phone.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          phone: true,
        };
      });
    } else if (contactUsFields.phone.trim().length < 10) {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          phone: true,
        };
      });
    }
    if (contactUsFields.email.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          email: true,
        };
      });
    } else if (!EMAILREGEX.test(contactUsFields.email)) {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          email: true,
        };
      });
    }
    if (contactUsFields.message.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          message: true,
        };
      });
    }
    if (formValidated) {
      let data = {
        first_name: contactUsFields.firstName,
        last_name: contactUsFields.lastName,
        email: contactUsFields.email,
        message: contactUsFields.message,
        phone: "+" + contactUsFields.phone,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      openAiService
        .contactUs(data)
        .then((res) => {
          console.log("contact us api response", res);
          Swal.fire(
            "Success",
            "Thank you for submitting your request. We are reviewing it and will respond soon!",
            "success"
          ).then(() => {
            globalData.setShowContactUsModal(false);
            setContactUsFields({
              firstName: "",
              lastName: "",
              email: "",
              message: "",
              phone: "",
            });
          });
        })
        .catch((err) => {
          Swal.fire("Error", "Something went wrong.", "error");
          console.log("contact us api error", err);
        });
    }
  };
  return (
    <>
      <section className="pricing-area login-area-box">

        <Container>
          <MDBContainer fluid className="p-3 my-5 h-custom">
            <MDBRow className="align-items-center">

              <MDBCol col='10' md='6'>
                <img src={require("../assests/images//marketing-software-feature-2.png")} class="img-fluid login-img" alt="Sample image" />
              </MDBCol>

              <MDBCol col='4' md='6'>

                <div className="d-flex flex-row align-items-center justify-content-center">

                  <h2 className="mb-0 me-3 login-heading">Sign in </h2>
                </div>
                <div className="login-box ">
                  <div className="position-relative">
                    <label>Enter Email</label><br />
                    <input wrapperClass='mb-4' label='Email address' id='formControlLg' type='email' size="lg" placeholder="Enter Email"
                      value={loginFields.email}
                      maxLength={100}
                      onChange={(e) => {
                        setloginFields((prevObj) => {
                          return {
                            ...prevObj,
                            email: e.target.value,
                          };
                        });
                      }}
                      onKeyDown={handleKeyDown} />
                    <p className="errors" style={{color:"red"}}>{loginFieldsErrs.emailErr}</p>
                    {isLoader && < Loading />}
                  </div>
                  <div className="position-relative">
                    <label className="mt-3">Enter Password</label><br />
                    <input wrapperClass='mb-4' label='Password' id='formControlLg' type={passwordType} size="lg"
                      placeholder="Enter Password"
                      value={loginFields.password}
                      maxLength={50}
                      onChange={(e) => {
                        setloginFields((prevObj) => {
                          return {
                            ...prevObj,
                            password: e.target.value,
                          };
                        });
                      }}
                      onKeyDown={handleKeyDown} />
                    {passwordType === 'text' ? <i class="fa fa-eye-slash password" onClick={showPassWord} aria-hidden="true"></i> : <i class="fa fa-eye password" onClick={showPassWord} aria-hidden="true"></i>}




                    <p className="errors" style={{color:"red"}}>{loginFieldsErrs.passwordErr}</p>
                  </div>


                  {/* <div className="d-flex justify-content-between my-4">
                    <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Remember me' className="d-flex " />
                    <a href="!#">Forgot password?</a>
                  </div> */}

                  <div className='text-center text-md-start mt-4 pt-2'>
                    <button className="mb-0 px-5 update-btn" size='lg' onKeyDown={handleKeyDown} onClick={handleSubmit}>Login</button>
                    {/* <p className="small fw-bold mt-2 pt-1 mb-2">Don't have an account? <a href="#!" className="">Register</a></p> */}
                  </div>
                </div>

              </MDBCol>

            </MDBRow>


            <SuccessPopup show={show} handleClose={handleClose} message1={message1} message2={message2} redirectlink={redirectlink} />
            <Errorpopup showErr={showErr} handleCloseErr={handleCloseErr} errmessage1={errmessage1} errmessage2={errmessage2} resend_btn={resend_btn} />

          </MDBContainer>
        </Container></section>

        <Modal
        className="modal-area-box"
        show={globalData.showContactUsModal}
        onHide={() => {
          globalData.setShowContactUsModal(false);
          setContactUsFields({
            firstName: "",
            lastName: "",
            email: "",
            message: "",
            phone: "",
          });
          setContactUsFieldsErrors({
            firstName: false,
            lastName: false,
            email: false,
            message: false,
            phone: false,
          });
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <div className="right-chat-boxes">
            <div className="left-chat-box">
              <div className="chat-history-header"></div>
              <div className="right-chat-box-dropdown">
                <div>
                  <Form onSubmit={handleContactUsSubmit}>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        style={{ minWidth: "100%" }}
                        multiple={false}
                        className={
                          contactUsFieldsErrors.firstName ? "border-red" : ""
                        }
                        placeholder="Enter First Name"
                        value={contactUsFields.firstName}
                        maxLength={30}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              firstName: e.target.value,
                            };
                          });
                          if (e.target.value.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                firstName: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                firstName: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        style={{ minWidth: "100%" }}
                        multiple={false}
                        placeholder="Enter Last Name"
                        className={
                          contactUsFieldsErrors.lastName ? "border-red" : ""
                        }
                        value={contactUsFields.lastName}
                        maxLength={30}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              lastName: e.target.value,
                            };
                          });
                          if (e.target.value.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                lastName: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                lastName: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>Phone Number</Form.Label>
                      <PhoneInput
                        country={
                          COUNTRY_CODE ? COUNTRY_CODE.toLocaleLowerCase() : "us"
                        }
                        className={
                          contactUsFieldsErrors.phone
                            ? "border-red phone-input"
                            : "phone-input"
                        }
                        placeholder="Enter phone number"
                        value={contactUsFields.phone}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              phone: e,
                            };
                          });
                          if (e.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                phone: true,
                              };
                            });
                          } else if (e.trim().length < 10) {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                phone: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                phone: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        style={{ minWidth: "100%" }}
                        multiple={false}
                        className={
                          contactUsFieldsErrors.email ? "border-red" : ""
                        }
                        placeholder="Enter Email"
                        value={contactUsFields.email}
                        maxLength={100}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              email: e.target.value,
                            };
                          });
                          if (e.target.value.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                email: true,
                              };
                            });
                          } else if (!EMAILREGEX.test(e.target.value)) {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                email: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                email: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        rows={3}
                        style={{ minWidth: "100%" }}
                        multiple={false}
                        placeholder="Enter Message"
                        className={
                          contactUsFieldsErrors.message ? "border-red" : ""
                        }
                        value={contactUsFields.message}
                        maxLength={1000}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              message: e.target.value,
                            };
                          });
                          if (e.target.value.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                message: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                message: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      controlId="formFile"
                      className="mb-2 captcha-size"
                    >
                      <ReCAPTCHA
                        sitekey="6LeKLXAoAAAAAH20G0gfS6AaDgK6zhPjbcNBDeZW"
                        onChange={onChange}
                      />
                    </Form.Group>
                    <br />
                    <button
                      className="try-ti-buton"
                      disabled={isCaptchaValue ? false : true}
                      type="submit"
                    >
                      Submit
                    </button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* <Modal className="success-box" show={showErr} onHide={handleCloseErr} centered>
                <Modal.Header>
                    <Modal.Title>
                      
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Error</h5>
                    <p>bbnbnbb</p>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" onClick={handleClose}>
                        Resend Link
                    </Button>
                </Modal.Footer>
            </Modal> */}
    </>
  );
};
export default Login;