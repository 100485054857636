export const openAiApiUrl = "https://api.openai.com/v1/chat/completions"
export const openAiImageVariationUrl = "https://api.openai.com/v1/images/variations"

export const OPENAI_SECRET_API_KEY = "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"

export const OPENAI_ORGANIZATION_ID = "org-EKNzI3W6Lfpd0T7MOpXjFmU2"

export const GOOGLE_CLIENT_ID = "279502010852-620btdovam7pftovu7bi1ol91jrcbihv.apps.googleusercontent.com"

export const STRIPE_KEY = "sk_test_51NQqzFSA6chiZa9Ye2bvirms4kj765EbNvBhaLpX8kfhByuTbxj3IgY0WSjBGrrkE08TUXBDqzUkYNEcFL0gSOhv00Q2SfPeHs"

export const STRIPE_Publish_KEY = "pk_test_51NQqzFSA6chiZa9Y9vEelnF4TAalki7gKGokCXUWNOJd8pB5ZvGg7HE44dooj3l2H30UQxKUGu4k4El9V5UmW11U00N8y50ZEs" // staging
// export const APP_URL = "aiattorney.biz"   // live
export const APP_URL = "https://aihealthassistant.pro/" // staging
export const CONTACT_US_EMAIL = "info@aihealthassistant.pro"

export const DEFAULT_PLAN_ID = "price_1NQr1jSA6chiZa9YyoEC8UES"
export const PREMIUM_PLAN_ID = "price_1NQr6TSA6chiZa9YbVSSZYB6"
export const stripe_prod_id = "prod_ODHaV4vDEhCTry"
export const Project_Title = "AI Health Assistant"
// export const socketUrl = "wss://aimedhealth-api.netsetdigital.com/ws/doctor/appointment"
// export const socketUrl = "wss://api.aihealthassistant.pro/ws/doctor/appointment"
// export const socketUrl = "wss://api.aihealthassistant.pro/ws/doctor/appointment"
export const chatSocketUrl = "wss://api.aihealthassistant.pro/ws/chat/"
export const doctorSocket = "wss://api.aihealthassistant.pro/ws/appoint/"
// export const chatSocketUrl = "wss://staging-api.aihealthassistant.pro/ws/chat/"
// export const doctorSocket = "wss://staging-api.aihealthassistant.pro/ws/appoint/"

// export const chatSocketUrl = `wss://aimedhealth-api-staging.netsetdigital.com/ws/chat/`
// export const socketUrl = "wss:///aimedhealth-api-staging.netsetdigital.com/ws/doctor/appointment"
// 
// export const socketUrl = "wss://staging-api.aihealthassistant.pro/ws/doctor/appointment"
export const socketUrl = "wss://api.aihealthassistant.pro/ws/doctor/appointment"
// export const baseUrl = "http://192.168.2.210:8000"  // local
// export const baseUrl = "https://2034-223-178-209-15.ngrok-free.app"
// expshort const baseUrl = "https://5bc6-112-196-97-226.ngrok.io"
        //    export const baseUrl = "https://aimedhealth-api.netsetdigital.com"
        //    export const baseUrl = "https://aimedhealth-api-staging.netsetdigital.com"
        //    export const baseUrl = "https://api.aihealthassistant.pro"
        //    export const baseUrl = "https://api.aihealthassistant.pro"
        //    export const baseUrl = "https://api.aihealthassitant.com"
        // export const baseUrl = "http://192.168.1.24:4000"
        // export const socketUrl = "wss://api.aihealthassistant.pro/ws/doctor/appointment"
export const baseUrl = "https://api.aihealthassistant.pro"
// export const baseUrl = "https://staging-api.aihealthassistant.pro"
  
export const sampleQuestions =  [
    [require("../assests/images/11.png") , "What are the symptoms of the common cold?"],
    [require("../assests/images/22.png") , "What are the signs and symptoms of a heart attack?"],
    [require("../assests/images/33.png") , "What are the early signs of diabetes?"],
    [require("../assests/images/44.png") , "How can I manage stress and anxiety?"],
    [require("../assests/images/55.png") , "What is a healthy diet and what foods should I include?"],

    // [require("../assests/images/11.png") , "What are the symptoms of the common cold?"],
    // ["🫁", "What are the signs and symptoms of a heart attack?"],
    // ["❤️‍🩹🩺", "What are the early signs of diabetes?"],
    // ["🫨", "How can I manage stress and anxiety?"],
    // ["🥗", "What is a healthy diet and what foods should I include?"],
]

export const cancerQuestions =  {
    Neurology: [[require("../assests/images/11.png") , "Can you provide information about common symptoms of [Neurological Condition]?"],
    [require("../assests/images/22.png") , "What are the treatment options available for [Neurological Condition]?"],
    [require("../assests/images/33.png") , "How can I manage chronic migraines effectively?"],
    [require("../assests/images/44.png") , "Tell me about the early signs of Parkinson's disease."],
    [require("../assests/images/55.png") , "What lifestyle changes can help improve cognitive health?"],
    [require("../assests/images/55.png") , "Explain the differences between a stroke and a transient ischemic attack (TIA)."]],

    Cardiology: [[require("../assests/images/11.png") , "What are the risk factors for heart disease and how can I reduce them?"],
    [require("../assests/images/22.png") , "Explain the symptoms of a heart attack and when to seek immediate medical help."],
    [require("../assests/images/33.png") , "What is the role of cholesterol in heart health, and how can I maintain healthy levels?"],
    [require("../assests/images/44.png") , "Can you provide tips for managing hypertension and preventing complications?"],
    [require("../assests/images/55.png") , "Tell me about the benefits of regular exercise for cardiovascular health."],
    [require("../assests/images/55.png") , "How can I differentiate between angina and a heartburn episode?"]],

    Gynecology: [[require("../assests/images/11.png") , "Explain the different types of contraception options available for women."],
    [require("../assests/images/22.png") , "What are the common symptoms of PCOS and how is it diagnosed?"],
    [require("../assests/images/33.png") , "Provide tips for managing menstrual pain and discomfort."],
    [require("../assests/images/44.png") , "Tell me about the importance of regular pap smears and cervical cancer prevention."],
    [require("../assests/images/55.png") , "What are the key aspects of prenatal care during pregnancy?"],
    [require("../assests/images/55.png") , "Explain the potential causes and treatments for abnormal uterine bleeding."]
],

    Diabetes: [[require("../assests/images/11.png") , "What are the key factors in managing type 2 diabetes?"],
    [require("../assests/images/22.png") , "How does insulin work, and when is it necessary for diabetes management?"],
    [require("../assests/images/33.png") , "Provide tips for monitoring blood glucose levels effectively."],
    [require("../assests/images/44.png") , "Can you explain the importance of a balanced diet for people with diabetes?"],
    [require("../assests/images/55.png") , "Tell me about the potential complications of uncontrolled diabetes and how to prevent them."],
    [require("../assests/images/55.png") , "What are some common myths about diabetes that need to be debunked?"]],

    Pediatrics: [[require("../assests/images/11.png") , "What are the essential vaccinations recommended for infants and young children?"],
    [require("../assests/images/22.png") , "Explain the common symptoms of childhood allergies and how to manage them."],
    [require("../assests/images/33.png") , "How can I ensure my child's healthy growth and development?"],
    [require("../assests/images/44.png") , "Provide tips for dealing with common childhood illnesses like cold and flu."],
    [require("../assests/images/55.png") , "Tell me about the signs of developmental delays in children and when to seek professional evaluation."],
    [require("../assests/images/55.png") , "What are some strategies to encourage healthy eating habits in kids?"]],

    Oncology: [[require("../assests/images/11.png") , "Can you provide information about common symptoms of Oncology?"],
    [require("../assests/images/22.png") , "What are the treatment options available for Oncology and stages of Oncology?"],
    [require("../assests/images/33.png") , "Tell me about the early signs and risk factors associated with Oncology."],
    [require("../assests/images/44.png") , "What lifestyle changes can help support my recovery and overall well-being during cancer treatment?"],
    [require("../assests/images/55.png") , "Explain the differences between chemotherapy, radiation therapy, and immunotherapy as treatment options for cancer."]]

}




export const prompts =  [

[ "Neurology?",[
    "Can you provide information about common symptoms of [Neurological Condition]?",
    "What are the treatment options available for [Neurological Condition]?",
    "How can I manage chronic migraines effectively?",
    "Tell me about the early signs of Parkinson's disease.",
    "What lifestyle changes can help improve cognitive health?",
    "Explain the differences between a stroke and a transient ischemic attack (TIA)."

   ]
],
[ " Cardiology?",[
    "What are the risk factors for heart disease and how can I reduce them?",
    "Explain the symptoms of a heart attack and when to seek immediate medical help.",
    "What is the role of cholesterol in heart health, and how can I maintain healthy levels?",
    "Can you provide tips for managing hypertension and preventing complications?",
    "Tell me about the benefits of regular exercise for cardiovascular health.",
    "How can I differentiate between angina and a heartburn episode?"
   ]
],
[ "Gynecology?",[
    "Explain the different types of contraception options available for women.",
    "What are the common symptoms of PCOS and how is it diagnosed?",
    "Provide tips for managing menstrual pain and discomfort.",
    "Tell me about the importance of regular pap smears and cervical cancer prevention.",
    "What are the key aspects of prenatal care during pregnancy?",
    "Explain the potential causes and treatments for abnormal uterine bleeding."
]
],

[ "Diabetes?",[
   
    "What are the key factors in managing type 2 diabetes?",
    "How does insulin work, and when is it necessary for diabetes management?",
    "Provide tips for monitoring blood glucose levels effectively.",
    "Can you explain the importance of a balanced diet for people with diabetes?",
    "Tell me about the potential complications of uncontrolled diabetes and how to prevent them.",
    "What are some common myths about diabetes that need to be debunked?"



]
],

[ "Pediatrics:",[
   
    
    "What are the essential vaccinations recommended for infants and young children?",
    "Explain the common symptoms of childhood allergies and how to manage them.",
    "How can I ensure my child's healthy growth and development?",
    "Provide tips for dealing with common childhood illnesses like cold and flu.",
    "Tell me about the signs of developmental delays in children and when to seek professional evaluation.",
    "What are some strategies to encourage healthy eating habits in kids?"

]
], 
]
// make sure that question is at index 1 in each array
 
export const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const allBloodGroup = ["A+","A-","B+","B-","AB+","AB-","O+","O-"]
export const allDEPARTMENT = ["Neurology","Cardiology","Gynecology","Pediatrics","Oncology","Orthopedics","Others"]

// export const stagingpalID= "prod_O9p22A5oocuqre"
// export const livePlanID ="prod_Nu4Ic73wsNzWOm"
