import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { APP_URL, CONTACT_US_EMAIL } from "../../config/config";

const Privacy = () => {
  useEffect(()=>{
    window.scrollTo(0, 0)
  }, [])
  const appUrl = APP_URL
  const contactUsEmail = CONTACT_US_EMAIL

  return (
    <>
      <section className="privacy-area">
        <Container fluid>
          <Row>
            <Col md={3} className="p-0">
            </Col>
            <Col lg={6}>
                <div className="privacy-content">
                    <h2>Privacy Policy</h2>
                    <p>
                    This Privacy Policy ("Policy") explains how <strong className="d-inline">{appUrl}</strong> ("we", "us", or "our") collects, uses, and discloses information about visitors to our website. By using our website, you consent to the terms of this Policy.
                    </p>
                    <p className="mt-3">
                    <strong>Information We Collect : </strong>
                    When you visit our website, we collect certain information about you and your device. This includes:<br/>
                      <ul className="privacy-list">
                        <li>
                        Your IP address, browser type, and operating system
                        </li>
                        <li>
                        Information about your visit to our website, such as pages viewed, links clicked, and the time and date of your visit
                        </li>
                        <li>
                        Information you provide to us, such as your name and email address, when you sign up for our newsletter or contact us through our support email address <strong className="d-inline">({contactUsEmail})</strong>
                        </li>
                      </ul>
                    </p>
                    <p>
                    <strong>Use of Information : </strong>
                    We use the information we collect to:<br/>
                    <ul className="privacy-list">
                      <li>
                      Operate and improve our website
                      </li>
                      <li>
                      Communicate with you about our products and services
                      </li>
                      <li>
                      Respond to your inquiries and requests
                      </li>
                      <li>
                      Comply with healthcare processes
                      </li>
                    </ul>
                    </p>
                    <p>
                    <strong>Sharing of Information : </strong>
                    We may share information with:<br/>
                    <ul className="privacy-list">
                      <li>
                      Our service providers, who perform services on our behalf
                      </li>
                      <li>
                      Law enforcement and government officials, as required by law
                      </li>
                      <li>
                      Other parties in connection with a merger, acquisition, or sale of our business
                      </li>
                      We do not sell or rent your personal information to third parties.<br/>
                    </ul>
                    </p>
                    <p>
                    <strong>Security : </strong>
                    We take reasonable measures to protect the information we collect from loss, misuse, and unauthorized access, disclosure, alteration, and destruction.
                    </p>
                    <p>
                    <strong>Children's Privacy :</strong>
                    Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13.
                    </p>
                    <p>
                    <strong>Updates to this Policy :</strong>
                    We may update this Policy from time to time. We encourage you to review this Policy periodically for any changes.
                    </p>
                    <p>
                    <strong>Contact Us :</strong>
                    If you have any questions or concerns about this Policy, please contact us at <strong className="d-inline">{contactUsEmail}</strong>.<br/>
                    <strong>Effective Date :</strong>
                    This Privacy Policy is effective as of 15th May 2023.
                    </p>
                    {/* <p>
                    Contact Us:
                    If you have any questions or concerns about our privacy policy, please contact us at [insert contact information here].
                    </p> */}
                </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Privacy;
