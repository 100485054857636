import React, { useEffect } from "react";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import { useRef, useState, useContext } from "react";
import { ReactMic } from 'react-mic';
import {
  Container,
  Form,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
} from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { openAiService } from "../../services/openai.services";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Moment from "react-moment";
import { Loading } from "../common/loader";
import Swal from "sweetalert2";
import { baseUrl } from "../../config/config";
import parse from "html-react-parser";
import moment from "moment";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { EmailShareButton } from "react-share";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ShowPdf } from "../modal/showPdf";
import { DateFormatter, DateTimeFormatter } from "../common/common";
import { AudioLoader } from "../common/AudioLoader";

const UserDetails = () => {
  const input_focused = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const user_id = searchParams.get("userId");
  const appointmentsId = searchParams.get("id");
  const fromTab = searchParams.get("tabType");
  const fromPage = searchParams.get("fromPage");
  const type = searchParams.get("type");
  const fromDate = searchParams.get("date") != null && searchParams.get("date") != 'null' ? new Date(searchParams.get("date")) : "";
  const [userDetails, setUserDetails] = useState([]);
  const [diagnose, setDiagnosis] = useState([]);
  const [activeDiagnose, setActiveDiagnose] = useState({});
  const [notes, setNotes] = useState([]);
  const [tests, setTests] = useState([]);
  const { transcript, resetTranscript } = useSpeechRecognition();
  const navigate = useNavigate();
  const reportArr = [];
  const [showModal, setShowModal] = useState(false);
  const [noteError, setNoteError] = useState(false);
  const [notesValue, setNotesValue] = useState("");
  const [reportCount, setReportCount] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const userType = localStorage.getItem("userType");
  const user = localStorage.getItem("user");
  const [noteType, setNoteType] = useState("note");
  const [reportData, setReportData] = useState("");
  const [view, setView] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState();
  const [habbits, setHabbits] = useState();
  const [availableSlots, setAvailableSlots] = useState([]);
  const [slot, setSlot] = useState("");
  const [valid, setValid] = useState(false);
  const [pdfContent,setPdfContent] = useState("");
  // const [datePickerOpen,setDatePickerOpen] = useState(false)
  const [nextAppointment, setNextAppointment] = useState(new Date());
  const [enableDays, setEnableDays] = useState([]);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [show_record, setShow_record] = useState(false);
  const [audioModel, setAudioModel] = useState(false);
  const [isRecording,setIsRecording] = useState(false);
  const [recordedContent,setRecordedContent] = useState(false);
  const [audioUrl,setAudioUrl] = useState()
  const [isPaused,setIsPaused] = useState(false)
  const [audioLoader,setAudioLoader] = useState(false)
  const [loaderText, setLoaderText] = useState('');

  const handleClose_record = () => {
    stopHandle()
    handleReset()
    setShow_record(false)};
  const handleShow_record = () => setShow_record(true);

  const showAudioModel = () => {
    setAudioUrl()
    setAudioModel(true)};

  const showContent = () => setRecordedContent(true);

  const hideContent = () => setRecordedContent(false);

  const hideAudioModel = () => {
    console.log("audioUrlaudioUrl",audioUrl)
    setAudioModel(false);
    
  }

  // const dateArr = [];
  var allDays = [
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ];
  const [selectedTests, setSelectedTests] = useState([]);
  const [pdfShow, setPdfShow] = useState(false);
  const [selectedHistoryItem, setSelectedHistoryItem] = useState({});
  const [shareEmailsShow, setShareEmailsShow] = useState(false);
  const [shareEmails, setShareEmails] = useState([]);
  const [selectedItemTemp, setSelectedItemTemp] = useState([]);
  const [onAddValue, setOnAddValue] = useState("");
  const [emailError, setEmailError] = useState("");
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const [isListening, setIsListening] = useState(false);
  const [textModal, setTextModal] = useState(false)
  const [queryString, setQueryString] = useState("")
  const [doctorId,setDoctorId] = useState("")
  const [notesHistory, setNotesHistory] = useState([])
  const [noteContent,setNoteContent] = useState("");

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setSelectedTests((prevSelectedTests) => {
      // Toggle the checkbox value in the selectedTests array
      if (prevSelectedTests.includes(value)) {
        return prevSelectedTests.filter((test) => test !== value);
      } else {
        return [...prevSelectedTests, value];
      }
    });
  };
  
  useEffect(() => {
    setQueryString(transcript);
    if(isListening){
      document.getElementById("input_focused").focus();
      const textInputEl = input_focused.current;
      textInputEl.scrollTop = textInputEl.scrollHeight;
    }   
     
  }, [transcript]);

  useEffect(() => {
    getUserHistory(appointmentsId);
    getNotesHistory(appointmentsId)
    getAvailableDays();
    if (userType !== "doctor" && user == null) {
      navigate("/login");
    } else if (
      (userType !== "doctor" && user !== null) ||
      (userType === "doctor" && user !== null)
    ) {
      navigate("/247-virtual-assistant");
    }
  }, []);

  useEffect(() => {
    getAvailableSlots(moment(new Date(nextAppointment)).format("YYYY-MM-DD"));
  }, [nextAppointment]);


  // useEffect(() => {
  //    if(isListening){
  //     const interval = setInterval(() => {
  //       SpeechRecognition.startListening({
  //         continuous: true,
  //       });
  //       }, 20000)
  //       return () => clearInterval(interval) 
  //    }
  // }, [isListening])

  // const openDatePicker = () => {
  //   setDatePickerOpen(true);
  // }


  /***********************Function for speech to text***************/
  


  const handleListing = () => {
    console.log("handleListing")
    setIsListening(true);
    // microphoneRef.current.classList.add("listening");
    SpeechRecognition.startListening({
      continuous: true,
    });

    
  };
  const stopHandle = () => {
    console.log("stopHandle")
    setIsListening(false);
    SpeechRecognition.stopListening();
    // setQueryString(document.getElementById("input_focused").value);
  };

  const handleReset = () => {
    setQueryString("");
    resetTranscript();
  };

  function genrateResponse(){
    var value =  document.getElementById("input_focused").value;
    if(value == "" || value == null || value == undefined){
       Swal.fire("error", "Please record some words.")
    }else{
      localStorage.setItem("notes", value);
      navigate({
        pathname: "/history-generate",
        search: createSearchParams({
          id: appointmentsId,
          doctor:doctorId
        }).toString(),
      });
    }  
  }

  function getNotesHistory(id){
    openAiService.getNotesHistory(id)
    .then((res) => {
      setNotesHistory(res.data?.data)
    })
    .catch((err) => {
      console.log("errr", err)
    })
  }


  /*******************End speech to text functions*****************/

  const ShareButton = ({ url, subject, body }) => {
    console.log("url---", url, "subject____", subject, "body___----", body);
    return (
      <EmailShareButton url={url} subject={subject} body={body} separator=" - ">
        <button>Share via Email</button>
      </EmailShareButton>
    );
  };

  const isDayEnabled = (date) => {
    console.log("enable days ", enableDays);
    return enableDays.some((enabledDay) => enabledDay === date.getDay());
  };

  function getAvailableDays() {
    const dayArr = [];
    openAiService
      .getAvailableDays()
      .then((result) => {
        console.log("availbale dayass", result.data.data);
        result.data.data
          .filter((d) => d.enabled)
          .map((res) => {
            console.log("available slots", res.day);

            dayArr.push(
              allDays.indexOf(res.day) !== -1 ? allDays.indexOf(res.day) : ""
            );
            setEnableDays(dayArr);
            // if(!enableDays.includes(res.day)){
            //   enableDays.push(res.day)
            // }
          });
      })
      .catch((err) => console.log("error--", err));
  }

  const getUserHistory = (appointmentsId) => {
    setIsLoader(true);
    openAiService
      .getAppointmentId(appointmentsId)
      .then((result) => {
        setUserDetails(result.data?.member ? result.data?.member : result.data?.user);
        console.log("resssrrsrsrt", result.data)
        setDiagnosis(result.data?.diagnose);
        setDoctorId(result.data?.doctor?.id)
        setAppointmentDetails(result.data);
        setActiveDiagnose(result?.data?.diagnose[0]);
        let report = result?.data.diagnose;
        if (report.filter((t) => t.report_content).length > 0) {
          var filtered_data = report.filter((t) => t.report_content);
          setActiveDiagnose(filtered_data[0]);
        }
        const resultArray = result.data?.habits.map((habit) => {
          // Find the selected option in the options array
          const selectedOption = habit.options.find(
            (option) => option.is_selected
          );

          // Create a new object with "name" and "value" properties
          return {
            name: habit.name,
            value: selectedOption ? selectedOption.option : null,
          };
        });
        setHabbits(resultArray);
        console.log("resultArray", resultArray);
        setNotes(result.data?.notes.filter((n) => n.note_type === "note"));

        setTests(
          result.data?.notes.filter((n) => n.note_type === "recommended")
        );
        console.log("notesrsstestss", tests);
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          Swal.fire({
            title: "Error",
            text: " Your session has expired, Please login again.",
            type: "error",
          }).then(() => {
            navigate("/login");
          });
        }
      });
  };

  function removeSecondFromString(time) {
    var modify = time.split(":");
    return modify[0] + ":" + modify[1];
  }

  function getAvailableSlots(date) {
    console.log("dateeeeeee", date);
    openAiService
      .getAvailableSlots(date)
      .then((result) => {
        // console.log("getAvailableSlots------", new Date(date + " " + result.data.data[1].time))
        var output = result?.data?.data?.filter(
          (s) => new Date(date + " " + s.time)?.getTime() > new Date().getTime()
        );
        console.log("output------>>>>>>>>>>>", output);
        setAvailableSlots(output);
      })
      .catch((err) => console.log(err));
  }

  const onData = (recordedBlob) => {
    console.log("recordedBlobrecordedBlob", recordedBlob)
    if (recordedBlob?.blobURL) {
      fetch(recordedBlob?.blobURL)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], 'audio.wav', { type: 'audio/wav' });
          console.log("audio file", file)
          setAudioUrl(file);
        })
        .catch((error) => {
          console.error('Error converting Blob URL to file:', error);
        });
    }
  };


  function getContentFromAudio(audioUrl,type){
    if(audioUrl){
        setAudioLoader(true) 
      // setIsLoader(true) 
      console.log("audioUrl", audioUrl)
      var formdata = new FormData();
       formdata.append("audio_file", audioUrl)
       openAiService.getContentFromAudio(formdata)
       .then((res) => {
        setAudioLoader(false)
        setNoteContent(res.data.text)
          console.log("resss", res.data.text)
          if(type == "view"){
            showContent()
          }else{
           localStorage.setItem("notes", res.data.text);
           navigate({
            pathname: "/history-generate",
            search: createSearchParams({
              id: appointmentsId,
              doctor:doctorId
            }).toString(),
          });              
          }
       }).catch((err) => {
        setAudioUrl()
        setAudioLoader(false)
          Swal.fire("Error", "Recording file not found. Please attempt to record again.", "error");
       })
    }else{
      Swal.fire("Error", "Please Provide a Audio file.", "error")
    }
  } 


  const startRecording = () => {
    setIsRecording(true)
  }
 
  const stopRecording = () => {
    setIsRecording(false)
  }

  const handleClose = () => {
    setShowModal(false);
    setView(false);
    setShowAppointmentModal(false);
    setTextModal(false);
    setChecked(false);
    setValid(false)
    setNextAppointment(new Date())
    setSlot("")
  };
  const saveTests = () => {
    let html = "";
    for (let i = 0; i < selectedTests.length; i++) {
      html += selectedTests[i] + "\n";
    }
    let data = {
      note: html,
      note_type: "recommended",
    };

    if (type === "someone") {
      data.appointed_user = user_id;
    } else {
      data.user = user_id;
    }
    saveNotes(data);
    console.log("======checkboxes===", html);
  };

  function saveNotes(data) {
    openAiService
      .uploadNotes(data)
      .then((result) => {
        console.log("create notes", result?.data?.data);
        console.log("dsfsdfds", notes);

        let array = [...notes];
        let newArr = [...tests];
        if (result?.data?.data.note_type === "recommended") {
          newArr.unshift(result?.data?.data);
        } else {
          array.unshift(result?.data?.data);
        }

        console.log("arra y", array);
        console.log("newArrr", newArr);

        setNotes(array);
        setTests(newArr);
        setNotesValue("");
        setShowModal(false);
        setView(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getCheckedCheckboxes() {
    const checkboxes = document.querySelectorAll(
      'input[type="checkbox"]:checked'
    ); // Select all checkboxes
    const checkedCheckboxes = []; // Initialize an array to store checked checkboxes

    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        // Check if the checkbox is checked
        checkedCheckboxes.push(checkbox.value); // Add the value to the array
      }
    });

    return checkedCheckboxes; // Return the array of checked checkboxes
  }
  const showReportModal = (data) => {
    console.log("report data json======", JSON.parse(data));
    let mydata = JSON.parse(data);
    setReportData(mydata.RecommendedTests);
    setView(true);
  };

  const scheduleRevisit = () => {
    if (slot === "") {
      Swal.fire("error", "Please select a slot", "error");
    } else {
      var date = moment(
        new Date(moment(nextAppointment).format("YYYY-MM-DD") + " " + slot)
      ).format("YYYY-MM-DDTHH:mm");
      var data = {
        id: appointmentsId,
        appointment_time: date,
      };
      openAiService
        .revisitAppointment(data)
        .then((result) => {
          console.log("response----+++", result);
          setChecked(false)
          Swal.fire("Success", "Your appointment has been successfully scheduled.", "success");
          setShowAppointmentModal(false)
          setNextAppointment(new Date())
          setSlot("")
        })
        .catch((err) => {
          console.log("err----", err);
        });
    }
  };

  const getReportName = (name) => {
    var filename;
    if (name) {
      var file = name.split('/');
      filename = file[1]
    } else {
      filename = null
    }
    return filename;

  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let formValidate = true;

    if (notesValue.trim() === "") {
      formValidate = false;
      setNoteError(true);
    }

    if (formValidate) {
      let data = {
        note: notesValue,
        note_type: noteType,
      };

      if (type === "someone") {
        data.appointed_user = user_id;
      } else {
        data.user = user_id;
      }

      saveNotes(data);
    }
  };

  const handleDateChange = (date) => {
    console.log("formated dates", moment(date).format("YYYY-MM-DD"));
    getAvailableSlots(moment(date).format("YYYY-MM-DD"));
    setNextAppointment(date);
    setSlot("")
    setValid(false)
    // set
  };

  function getAge(dob) {
    dob = new Date(dob)
    var month_diff = new Date().getFullYear() - dob.getFullYear();
    var age; 
    if(Math.abs(month_diff) == 1){
        age = "1 year"
    }else{
        age = Math.abs(month_diff) + " years"
    }

    if(Math.abs(month_diff) <= 0){
          month_diff = new Date().getMonth() - dob.getMonth();
          if(Math.abs(month_diff) > 1){
            age = month_diff + " months"
          }else{
              age = "1 month";
          }  
        }
    //  setAge(age);
    return age
  }

  const backToDashboard = () => {
    navigate({
      pathname: "/my-appointment",
      search: createSearchParams({
        activeTab: fromTab,
        page: fromPage,
        date: fromDate
      }).toString()
    });
    // navigate("/dashboard");
  };

  const changeStates = (res) => {
    setActiveDiagnose(res);
  };
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Download
    </Tooltip>
  );
  const renderTooltip_second = (props) => (
    <Tooltip id="button-tooltip_second" {...props}>
      View Prescription
    </Tooltip>
  );
  const renderTooltip_third = (props) => (
    <Tooltip id="button-tooltip_second" {...props}>
      Share
    </Tooltip>
  );
  const renderTooltip_view = (props) => (
    <Tooltip id="button-tooltip_second" {...props}>
      View History
    </Tooltip>
  );
  const renderTooltip_Record= (props) => (
    <Tooltip id="button-tooltip_second" {...props}>
      Record
    </Tooltip>
  );
 


  const showHideModal = (e) => {
    if (e.target.checked) {
      setShowAppointmentModal(true);
      setChecked(true);
    } else {
      setShowAppointmentModal(false);
      setChecked(false);
    }
  };

  const onSelectItem = (item) => {
    setSelectedItemTemp(
      (prevSelectedDays) => {
        const isSelected = prevSelectedDays.includes(item);
        if (isSelected) {
          return prevSelectedDays.filter((selectedDay) => selectedDay !== item);
        } else {
          return [...prevSelectedDays, item];
        }
      },
      () => {
        console.log(item); // Log the current state to check
      }
    );
  };

  const handleKeyPres1 = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!EMAILREGEX.test(onAddValue)) {
        setEmailError("Please enter valid email");
      } else {
        setEmailError("");
        onAddRecommendTest();
      }
    }
  };

  const pdfModal = (data) =>{
    setPdfContent(data);
    setShowPdfModal(true)

  }

  const onAddRecommendTest = () => {
    if (!onAddValue) {
      return;
    }
    setShareEmails((prevItem) => {
      return [...prevItem, onAddValue];
    });
    setSelectedItemTemp((prevSelectedDays) => {
      return [...prevSelectedDays, onAddValue];
    });
    setOnAddValue("");
  };

  const onShareWithEmail = () => {

    if (selectedItemTemp.length === 0) {
      //  set
      Swal.fire("error", "Please choose an email.", "error")
    } else {

      let data = {
        emails: selectedItemTemp,
        id: selectedHistoryItem.id,
        appointment_id: selectedHistoryItem.appointment.id,
      };
      setIsLoader(true);
      openAiService
        .shareOnEmail(data)
        .then((result) => {
          setIsLoader(false);
          Swal.fire(
            "Success",
            "The prescription has been shared on the mentioned emails.",
            "success"
          ).then(() => {
            setShareEmailsShow(false);
            setSelectedItemTemp([]);
            setShareEmails([]);
          });
        })
        .catch((error) => {
          setIsLoader(false);
          if (error?.response?.status === 401) {
            localStorage.clear();
            Swal.fire({
              title: "Error",
              text: " Your session has expired, Please login again.",
              type: "error",
            }).then(() => {
              navigate("/login");
            });
          }
        });
    }
  };

  return (
    <>
      <section className="user-details-section">
        <Container>
          <div style={{ cursor: "pointer" }} class="back-btn-box">
            <a onClick={backToDashboard}>
              {" "}
              <i class="fa fa-angle-double-left me-2" aria-hidden="true"></i>
              Back To List{" "}
            </a>
          </div>
          <div className="row">
            <aside className="col-lg-3 col-md-4 col-sm-12">
              <div className="user-details-box mb-3">
                <div className="user-inner-box profile-main">
                  {isLoader && <Loading />}
                  {audioLoader && <AudioLoader text={loaderText} />}
                  <img
                    src={
                      type === "me"
                        ? userDetails?.profile_image ? baseUrl + userDetails?.profile_image :userDetails?.picture
                        : userDetails.image ? baseUrl + userDetails.image : require("../../assests/images/user-placeholder.png")
                    }
                    alt=""
                    class="img-fluid"
                    referrerpolicy="no-referrer"
                  />
                  <div className="details-user">
                    <p className="mb-1">
                      <b>{userDetails?.name}</b>,{" "}
                      <b>
                        {userDetails?.dob ? getAge(userDetails.dob) : "N/A"}
                      </b>
                    </p>

                    <p className="mb-1">
                      <img
                        className="me-2 "
                        src={
                          require("../../assests/images/blood-group.svg")
                            .default
                        }
                        alt=""
                      />{" "}
                      <b>
                        {userDetails?.blood_group
                          ? userDetails?.blood_group
                          : "A+"}
                      </b>
                      <img
                        className="ms-2 "
                        src={require("../../assests/images/gender.svg").default}
                        alt=""
                      />{" "}
                      <b>
                        {userDetails?.gender?.charAt(0).toUpperCase() +
                          userDetails?.gender?.slice(1)}
                      </b>
                    </p>
                  </div>
                </div>
              </div>

              <div className="profile-card-inner doctor-user">
                <h5 className="user-text mb-3">
                  Healthy & Unhealthy Habits
                  <img
                    className=""
                    src={require("../../assests/images/habit.gif")}
                    alt="img"
                  />
                </h5>
                <div className="heathy-box">
                  <div>
                    {habbits?.map((item) => {
                      return (
                        <h6 className="d-flex">
                          <img
                            className=""
                            src={require("../../assests/images/check-main.png")}
                            alt="img"
                          />
                          <p>
                            {item.name === "Exercise"
                              ? "Exercise"
                              : item.name === "Eating following a diet"
                                ? "Diet Followed"
                                : item.name === "Do you smoke?"
                                  ? "Smoking"
                                  : item.name}
                            : <span>{item?.value ? item?.value : "N/A"}</span>
                          </p>
                        </h6>
                      );
                    })}

                    <h6 className="d-flex">
                      <img
                        className=""
                        src={require("../../assests/images/check-main.png")}
                        alt="img"
                      />
                      <p>
                        {" "}
                        Medical History:{" "}
                        <span>{userDetails?.medical_history ? userDetails?.medical_history : "N/A"}</span>
                      </p>
                    </h6>
                    <h6 className="d-flex">
                      <img
                        className=""
                        src={require("../../assests/images/check-main.png")}
                        alt="img"
                      />
                      <p>
                        {" "}
                        Allegry:{" "}
                        <span className="white_space">
                          {userDetails?.allergies
                            ? userDetails?.allergies
                            : "No"}
                        </span>
                      </p>
                    </h6>
                  </div>
                </div>
              </div>
            </aside>

            <aside className="col-lg-9 col-md-8 col-sm-12">
              <div className="problem-box mb-3">
                <Row>
                  <Col lg={10} md={8}>
                    <div className="problem-left-box">
                      {/* <h5 className="purple-txt">Problem:</h5> */}
                      <p className="white_space">
                        {appointmentDetails?.reason}
                      </p>
                    </div>
                  </Col>
                  <Col lg={2} md={4} className="recorded-upper-box">
                    {fromTab != "past" && (
                      <a
                        onClick={() => {
                          navigate({
                            pathname: "/user-medication",
                            search: createSearchParams({
                              id: appointmentsId,
                              type: type
                            }).toString(),
                          });
                        }}
                        className="add-btn"
                        style={{ cursor: "pointer" }}
                      >
                        e-Prescription{" "}
                        <img
                          className="ms-1"
                          src={
                            require("../../assests/images/add-main.svg").default
                          }
                          alt=""
                        />
                      </a>
                    )}
                    <div className="recorde-outer">
                    {navigator.userAgent.includes("Chrome") && !window.navigator?.brave?.isBrave ?  
                    <div>
                     <OverlayTrigger
                     placement="top"
                     delay={{ show: 250, hide: 400 }}
                     overlay={renderTooltip_Record}
                   >
                    <a className="record-menu desktop-menu-recorded" onClick={handleShow_record}>
                      <img
                        src={require("../../assests/images/record-upper.svg").default}
                        alt="img"
                        className=""
                      />
                    </a>
                    </OverlayTrigger>
                    <a className="record-menu mobile-menu-recorded" onClick={handleShow_record}>
                      <img
                        src={require("../../assests/images/record-upper.svg").default}
                        alt="img"
                        className=""
                      />Record Audio
                    </a>
                    </div>
                     :<div>
                     <OverlayTrigger
                     placement="top"
                     delay={{ show: 250, hide: 400 }}
                     overlay={renderTooltip_Record}
                   >
                    <a className="record-menu desktop-menu-recorded" onClick={showAudioModel}>
                      <img
                        src={require("../../assests/images/record-upper.svg").default}
                        alt="img"
                        className=""
                      />
                    </a>
                    </OverlayTrigger>
                    <a className="record-menu mobile-menu-recorded" onClick={showAudioModel}>
                      <img
                        src={require("../../assests/images/record-upper.svg").default}
                        alt="img"
                        className=""
                      />Record Audio
                    </a>
                    </div>
                    } 
         
                    {/* 
                    <Button onClick={showAudioModel}>Preview</Button> */}

                    {notesHistory && notesHistory.length > 0 ?
                    <div>
                       <OverlayTrigger
                       placement="top"
                       delay={{ show: 250, hide: 400 }}
                       overlay={renderTooltip_view}
                     >
                  <a className="view-menu desktop-menu-recorded" onClick={() => {
                    navigate({
                      pathname: "/history-generate",
                      search: createSearchParams({
                        id: appointmentsId,
                        doctor:doctorId
                      }).toString(),
                    });
                  }}>
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  </a>            
                  
                  </OverlayTrigger>
                  <a className="view-menu mobile-menu-recorded" onClick={() => {
                    navigate({
                      pathname: "/history-generate",
                      search: createSearchParams({
                        id: appointmentsId,
                        doctor:doctorId
                      }).toString(),
                    });
                  }}>
                    <i class="fa fa-eye" aria-hidden="true"></i>
                    View History
                  </a>  
                   </div>
                  : ""
                    
                  }
                     </div>

                  </Col>
                </Row>

                {/* {navigator.userAgent.includes("Chrome") && !window.navigator?.brave?.isBrave ?
                          <div className="microphone-section-area">
                            {!isListening && (
                              <div
                                className={
                                    isListening
                                    ? "disableBtn microphone-icon-container submit"
                                    : "microphone-icon-container submit"
                                }
                                // ref={microphoneRef}
                                style={{ cursor: "pointer" }}
                                // disabled ={}
                                onClick={
                                    isListening
                                    ? () => {
                                      return false;
                                    }
                                    : () => {
                                      setTextModal(true);
                                      handleListing();
                                      document
                                        .getElementById("input_focused")
                                        .focus();
                                    }
                                }
                              >
                                <i
                                  class="fa fa-microphone microphone-icon"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            )}
                            {isListening && (
                              <div
                                className="microphone-icon-container submit"
                                onClick={stopHandle}
                                style={{ cursor: "pointer" }}
                              >
                                <i class="fa fa-stop-circle" aria-hidden="true"></i>
                              </div>
                            )}
                            <div className="microphone-status">
                              {isListening ? (
                                <i class="fa-light fa-waveform-lines"></i>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          : ""} */}

              </div>
              {/* {diagnose?.filter((f) => f.report_content).length > 0 &&  */}

              <div className="user-under-box">
                <div>
                  <div className="reports-box">
                    {diagnose
                      .filter((d) => d.report_content)
                      .map((res, i) => (
                        
                        <div
                          role="button"
                          id={`report_${i}`}
                          className={`report_${i} report-box ${activeDiagnose ? (activeDiagnose.id === res?.id ? "active" : "") : ""
                            // activeDiagnose?.id === res?.id ? "active" : ""
                            }`}
                          key={i}
                          onClick={() => setActiveDiagnose(res)}
                        >
                          <h4 title={getReportName(res?.report_name) != null ? getReportName(res?.report_name) : "Report"}>
                            {getReportName(res?.report_name) != null ? getReportName(res?.report_name).substr(0, 6) : "Report"}

                            {/* {res?.appointment &&
                              res?.appointment?.appointment_report[0] != null ?
                              <ShowPdf open={showPdfModal}
                                onClose={() => setShowPdfModal(false)}
                                data={baseUrl +
                                  (res?.appointment?.appointment_report[0] &&
                                    res.appointment?.appointment_report[0]?.report_file
                                    ? res.appointment?.appointment_report[0]?.report_file
                                    : res.appointment?.appointment_report[0]?.user_report
                                      ? res.appointment?.appointment_report[0]?.user_report.file
                                      : "")}
                                showShare={() => setShareEmailsShow(true)}
                              />
                              : ""} */}

                            <span className="downloaded-icon" >
                              {res?.appointment &&
                                res?.appointment?.appointment_report[0] != null ? (
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip}
                                >
                                  <a
                                    onClick={() => {
                                      pdfModal(baseUrl + (res?.appointment?.appointment_report[0] &&
                                        res.appointment?.appointment_report[0]?.report_file
                                        ? res.appointment?.appointment_report[0]?.report_file
                                        : res.appointment?.appointment_report[0]?.user_report
                                          ? res.appointment?.appointment_report[0]?.user_report.file
                                          : ""))
                                       }}
                                  // href={
                                  //   baseUrl +
                                  //   (res?.appointment?.appointment_report[0] &&
                                  //   res.appointment?.appointment_report[0]?.report_file
                                  //     ? res.appointment?.appointment_report[0]?.report_file
                                  //     : res.appointment?.appointment_report[0]?.user_report
                                  //     ? res.appointment?.appointment_report[0]?.user_report.file
                                  //     : "")
                                  // }
                                  // target="_blank"
                                  // rel="noreferrer"
                                  >
                                    <img
                                      src={
                                        require("../../assests/images/download.svg")
                                          .default
                                      }
                                      alt=""
                                    />{" "}
                                  </a>
                                </OverlayTrigger>
                              ) : (
                                ""
                              )}

                              {/* {res.recommended_test ? <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip_second}
                                ><img

                                    onClick={() => { showReportModal(res.recommended_test) }}
                                    src={
                                      require("../../assests/images/pdf.svg")
                                        .default
                                    }
                                    alt=""
                                  />
                                </OverlayTrigger> : ""} */}
                            </span>
                          </h4>
                          {/* <p className="mt-2">
                              <img
                                className="me-2 "
                                src={
                                  require("../../assests/images/date.svg")
                                    .default
                                }
                                alt=""
                              />
                              <p>
                                <Moment format="DD MMM YYYY ">
                                  {res.created_at}
                                </Moment>
                              </p>
                            </p> */}
                        </div>
                      ))}
                  </div>
                  <div className="row">
                    <aside className="col-lg-6 col-md-12">
                      <h5 className="">Key Points</h5>
                      <div className="key-point-box">
                        <div className="key-point-box-inner">
                          {/* <p className="mb-2" style={{}}> */}{" "}
                          {/* <img
                              className="me-2 "
                              src={
                                require("../../assests/images/corner.svg").default
                              }
                              alt=""
                            /> */}
                          {activeDiagnose?.report_content ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: activeDiagnose?.report_content,
                              }}
                            />
                          ) : (
                            <div className="no-data-found">
                              <img
                                src={require("../../assests/images/no-data-main.gif")}
                                alt=""
                              />
                              <h4 className="mt-2">No data found</h4>
                            </div>
                          )}
                        </div>
                        {/* </p> */}
                        {/* {diagnose.filter((t) => t.report_content).map((result) => 
                  <p className="mb-2"> <img className="me-2 "  src={require("../../assests/images/corner.svg").default} alt="" />{result.report_content}</p>
                  )} */}
                      </div>
                    </aside>
                    <aside className="col-lg-6 col-md-12">
                      <h5 className="">Patient Clinical Situation</h5>
                      <div className="key-point-box">
                        <div className="key-point-box-inner">
                          <p className="mb-2">
                            {" "}
                            {/* <img
                              className="me-2 "
                              src={
                                require("../../assests/images/corner.svg").default
                              }
                              alt=""
                            /> */}
                            {activeDiagnose?.report_content ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: activeDiagnose?.diagnosis_data,
                                }}
                              />
                            ) : (
                              <div className="no-data-found">
                                <img
                                  src={require("../../assests/images/no-data-main.gif")}
                                  alt=""
                                />
                                <h4 className="mt-2">No data found</h4>
                              </div>
                            )}
                          </p>
                        </div>
                      </div>
                    </aside>
                    <aside className="col-md-12">
                      <h5 className="my-2">History</h5>
                      <div className="history-height">
                        {appointmentDetails?.prescription.length !== 0 ? (
                          <>
                            {appointmentDetails?.prescription.map((item) => {
                              return (
                                <div className="key-point-box history-box ">
                                  <div className="date-box d-flex justify-content-between mb-3">
                                    <p>
                                      <DateTimeFormatter date={item?.created_at} />
                                      {/* {moment(item?.created_at).format(
                                        "DD-MM-YYYY HH:MM"
                                      )} */}
                                    </p>
                                    <div className="button-section">
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip_second}
                                      >
                                        <a
                                          onClick={() => {
                                            setPdfShow(true);
                                            console.log("item.id", item.id, "item.appointment.id", item.appointment.id)
                                            setSelectedHistoryItem(item);
                                          }}
                                          className="add-btn"
                                        >
                                          <i
                                            class="fa fa-file-text-o"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip_third}
                                      >
                                        <a
                                          onClick={() => {
                                            setSelectedHistoryItem(item);
                                            setShareEmailsShow(true);
                                          }}
                                          className="add-btn share-b"
                                        >
                                          <i
                                            class="fa fa-share-alt"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                      </OverlayTrigger>
                                      {/* onClick={() => window.open(`${baseUrl + item?.prescription_report}`)} */}
                                    </div>
                                  </div>
                                  <p className="mb-2 white_space">
                                    {item?.appointment?.reason}
                                  </p>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div className="no-history-box">
                            <img
                              src={require("../../assests/images/history.gif")}
                              alt=""
                            />
                            <h5>No history found!</h5>
                          </div>
                        )}
                      </div>
                    </aside>
                    {/* {fromTab == "past" && (
                      <aside className="col-md-12 main-check-box">
                        <div key={`default-checkbox`} className="mb-3">
                        <Form.Group className="d-flex mt-2 align-items-center">
                          <b>
                          <Form.Check
                            type={"checkbox"}
                            value={checked}
                            style={{cursor:"pointer"}}
                            onChange={(e) => {
                              showHideModal(e);
                            }}
                            id={`default-checkbox`}
                            checked={checked}
                            label={"Next Visit Required?"}
                          />
                          </b>
                           </Form.Group>
                        </div>
                      </aside>
                    )} */}
                  </div>
                </div>

                {/* {diagnose && diagnose.length > 0 ?
                  diagnose.map((result) => {
                    const data = result.diagnosis_data;
                    if(result.report_content != null){
                      reportArr.push(result.report_content)
                    }
                    return <div style={{whiteSpace:"pre-line"}} dangerouslySetInnerHTML={{__html: data}}></div>
                  }) : <div className="appointment-content-area-image">
                    <img className="no-data" src={require("../../assests/images/no_data.gif")} alt="" />
                    <p>No Diagnoses Found</p>
                  </div>
                } */}
              </div>

              {/* <div className="user-under-box">
                <div className="row">
                  <aside className="col-md-6">

                    <div className="d-flex align-items-center justify-content-between mb-2">
                     
                      <h5 className="">Recommend Test</h5>
                      <a href="#" className="add-text" onClick={() => {
                        setShowModal(true);
                        setNoteType("recommended");
                      }}>Add</a>
                    </div>

                   
                    <div className="key-point-box">
                     

                      {tests.length > 0 ? (<p
                        className="mb-2"
                        style={{}}
                      >
                        {" "}

                        {
                          tests.map((res) => {
                            return (<div className="test-recomended">
                              <p className="white_space">
                                {" "}
                                <img
                                  className="me-2 "
                                  src={
                                    require("../../assests/images/corner.svg")
                                      .default
                                  }
                                  alt=""
                                />
                               
                                {res.note}{" "}
                              </p>
                              <span>
                                <Moment format="DD MMM YYYY ">
                                  {res.created_at}
                                </Moment>
                              </span>
                            </div>)

                          })
                        }
                      </p>) : (
                        <div className="appointment-content-area-image">
                          <img
                            className="no-data"
                            src={require("../../assests/images/no_data.gif")}
                            alt=""
                          />
                          <p>No Data Found</p>
                        </div>
                      )}

                    </div>
                  </aside>
                  <aside className="col-lg-6 col-md-12">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <h5 className="">Notes</h5>
                      <a href="#" className="add-text" onClick={() => {
                        setShowModal(true);
                        setNoteType("note");
                      }}>Add</a>
                    </div>
                    <div className="key-point-box notes-box">
                      {notes.length > 0 ? (
                        notes.map((res) => {
                          return (
                            <div className="test-recomended">
                              <p className="">
                                {" "}
                                <img
                                  className="me-2 "
                                  src={
                                    require("../../assests/images/corner.svg")
                                      .default
                                  }
                                  alt=""
                                />
                                {res.note}{" "}
                              </p>
                              <span>
                                <Moment format="DD MMM YYYY ">
                                  {res.created_at}
                                </Moment>
                              </span>
                            </div>
                          );
                        })
                      ) : (
                        <div className="appointment-content-area-image">
                          <img
                            className="no-data"
                            src={require("../../assests/images/no_data.gif")}
                            alt=""
                          />
                          <p>No Data Found</p>
                        </div>
                      )}
                     
                    </div>
                  </aside>
                </div>

              </div> */}
            </aside>
            {/* <aside className="col-lg-3 col-md-6 col-sm-6">
              <div className="user-under-box">
                <h5><b>Notes:</b></h5>
                {notes && notes.length > 0 ?
                  notes.map((result) => {
                    return <p className="mt-2"><b>{result.note}</b></p>
                  }) : <div className="appointment-content-area-image">
                    <img className="no-data" src={require("../../assests/images/no_data.gif")} alt="" />
                    <p>No Notes Found</p>
                  </div>
                }
              </div>
            </aside> */}
            {/* <aside className="col-lg-3 col-md-6 col-sm-6">
              <div className="user-under-box">
                <h5><b>Report:</b></h5>
                {reportArr && reportArr.length > 0 ?
                  reportArr.map((result) => {
                    return <div style={{whiteSpace:"pre-line"}} dangerouslySetInnerHTML={{__html: result}}></div>
                  }) : <div className="appointment-content-area-image">
                    <img className="no-data" src={require("../../assests/images/no_data.gif")} alt="" />
                    <p>No Notes Found</p>
                  </div>
                }
              </div>
            </aside> */}
          </div>
        </Container>
      </section>

      <Modal show={showAppointmentModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Slot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="key-point-box history-box required-box p-0">
            <div className="date-box">
              <div className="">
                <Row>
                  <Col md={12}>
                    <div className="position-relative date-inner">
                      <DatePicker
                        selected={nextAppointment}
                        onChange={handleDateChange}
                        filterDate={isDayEnabled}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        minDate={new Date()}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                      <i class="fa fa-calendar" aria-hidden="true"></i>
                    </div>
                    <div className="tags-box-main">
                      {availableSlots && availableSlots.length > 0
                        ? availableSlots.map((res) => {
                          return res.available ? (
                            <span
                              style={{ cursor: "pointer" }}
                              className={
                                slot === res.time ? "tags active" : "tags"
                              }
                              onClick={(e) => {
                                setSlot(res.time);
                                setValid(true);

                              }}
                            >
                              <i class="fa fa-clock-o" aria-hidden="true"></i>
                              {removeSecondFromString(res.time)}
                            </span>
                          ) : (
                            <span
                              style={{ cursor: "pointer" }}
                              className={"tag-disables tags"}
                            >
                              <i class="fa fa-clock-o" aria-hidden="true"></i>
                              {removeSecondFromString(res.time)}
                            </span>
                          );
                        })
                        : ""}
                    </div>
                  </Col>
                  {/* <Col md={3}>
                    <a
                      onClick={() => {
                        scheduleRevisit();
                      }}
                      className="btn btn-primary"
                    >
                      Next Appointment
                    </a>
                  </Col> */}
                </Row>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {valid ? <Button variant="primary" onClick={() => { scheduleRevisit() }}>
            Book Appointment
          </Button> : <Button variant="" className={valid ? "" : "btn-disabled"}>
            Book Appointment
          </Button>}


        </Modal.Footer>
      </Modal>

      <Modal
        className="success-box pdf-view-modal"
        backdrop="static"
        show={pdfShow}
        onHide={() => setPdfShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pdf-view-inner">
            <a
              onClick={() => setShareEmailsShow(true)}
              className="add-btn share-b"
            >
              <i class="fa fa-share-alt" aria-hidden="true"></i>
            </a>
            <iframe
              src={baseUrl + "/" + selectedHistoryItem?.prescription_report}

            ></iframe>
            {console.log(
              "-----",
              baseUrl + "/" + selectedHistoryItem?.prescription_report
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={shareEmailsShow}
        onHide={() => setShareEmailsShow(false)}
        centered
      >
        <Modal.Header closeButton className="p-0 pb-3">
          <Modal.Title>Share Prescription</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="recommend-box-upper">
            <div className="recommend-box">
              {shareEmails?.map((item) => {
                return (
                  <span
                    className={`${selectedItemTemp?.includes(item) ? "active" : ""
                      } `}
                    onClick={() => onSelectItem(item)}
                  >
                    {item}
                  </span>
                );
              })}
            </div>
            <div className="input-box">
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Label>Add Email</Form.Label>
                  </div>
                  <Form.Control
                    onKeyPress={handleKeyPres1}
                    onChange={(e) => setOnAddValue(e.target.value.trimStart())}
                    value={onAddValue}
                    type="email"
                    placeholder="Press the 'Enter' key to add the email"
                    className="mt-2"
                  />
                  <p className="errors" style={{ color: "red" }}>
                    {emailError}
                  </p>
                </Form.Group>
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={selectedItemTemp.length == 0} variant="primary" onClick={() => onShareWithEmail()}>
            Share
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="add-notes"
        show={showModal}
        onHide={() => {
          setNotesValue("");
          handleClose();
        }}
        centered
      >
        <Modal.Header closeButton className="p-0">
          <Modal.Title>
            {noteType === "note" ? "Add Notes" : "Recommend Test"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 mt-3">
          <Form onSubmit={handleFormSubmit}>
            {/* <label>
              <b>{noteType === "note" ? "Add Notes" : "Recommend Test"}</b>
            </label> */}
            <Form.Control
              type="text"
              as="textarea"
              maxLength={500}
              rows={3}
              style={{ minWidth: "100%" }}
              multiple={false}
              placeholder="Enter note here..."
              value={notesValue}
              onChange={(e) => {
                console.log("notes value", e.target.value);
                setNotesValue(e.target.value);
                if (e.target.value === "") {
                  setNoteError(true);
                } else {
                  setNoteError(false);
                }
              }}
            />
            <p className="errors" style={{ color: "red" }}>
              {noteError && `* This field is required.`}
            </p>
            <br></br>
            <button
              className="try-ti-buton text-light"
              disabled={noteError ? true : false}
              type="submit"
            >
              Submit
            </button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={view} onHide={handleClose} centered>
        <Modal.Header className="p-0" closeButton>
          <Modal.Title>Recommended Test(s)</Modal.Title>
        </Modal.Header>
        <Modal.Body className="recomended-test-box">
          <ul className="recomended-box">
            {reportData &&
              reportData.map((recommendation, index) => (
                <li key={index}>
                  <label className="form-check">
                    <input
                      type="checkbox"
                      className="checkbox-item form-check-input"
                      value={recommendation}
                      checked={selectedTests.includes(recommendation)}
                      onChange={handleCheckboxChange}
                    />
                    {" " + recommendation}
                  </label>
                </li>
              ))}
          </ul>

          {/* {  parse(reportData)} */}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose} className="try-ti-buton close-btn">
            Close
          </Button> */}
          <Button
            variant="primary"
            onClick={saveTests}
            className="try-ti-buton"
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show_record} backdrop="static" onHide={handleClose_record} centered>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="recorded-content">
      
            <img
              className={isListening ? "record-icon rec": "record-icon"}
              src={require("../../assests/images/audio-icon.svg").default}
              alt="img"
              onClick={() => {
                if(isListening){
                  return false
                }else{
                  handleListing()
                  document.getElementById("input_focused").focus();
                }
                }}
            />
            {
             !isListening && !queryString &&
            <p>Tap the record icon to start recording audio.</p>
            }
            {
             !isListening && queryString &&
            <p> Tap the record icon to resume recording audio.</p>
            }
            <textarea  disabled={!isListening}  ref={input_focused}  className={isListening || queryString?"recorded-animation" :"recorded-animation record-animation-hide"} id="input_focused" value={queryString}>
              {/* add animation code from backend side */}

            </textarea>
            <div className="record-footer">
             {isListening && 
               
            <img
              className="pause-icon"
              src={require("../../assests/images/pause-icon.svg").default}
              alt="img"
              title="Pause"
              onClick={stopHandle}
            />
             }
             {queryString  && 
             
             <img
              className="pause-icon"
              src={require("../../assests/images/reset.svg").default}
              alt="img"
              title="Reset"
              onClick={handleReset}
            />
             }

               {!isListening && queryString &&
                   <img
                    onClick={() => {
                      genrateResponse()
                    }}
                    className="pause-icon"
                    src={require("../../assests/images/finalize.svg").default}
                    alt="img"
                    title="Finalize"
                  />
              }
            </div>
            
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
      

      <Modal className="firefox-browser-modal" backdrop="static" show={audioModel} onHide={() =>{
         setAudioUrl()
         stopRecording()
         hideAudioModel()
      }
        } centered>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
        <div className="recorded-content">
        <img className={isRecording ? "record-icon rec" : "record-icon"} style={{cursor:audioUrl? "default":"pointer"}} src={require("../../assests/images/audio-icon.svg").default}
           onClick={() => {
             if(audioUrl){
                return false
             }else{
              startRecording()
             }
            }} 
           alt="" />
            {audioUrl &&  
           <p className="mt-3"> Tap the view icon to access the recorded audio</p>
            }
           {!isRecording && !audioUrl &&  
           <p className="mt-3"> Tap the record icon to start recording audio.</p>
            }


          <ReactMic
          record={isRecording}
          className={isRecording ? "sound-wave" :"sound-wave sound-wave-line-hide"}
          onStop={(e)=> {
            onData(e)
          }}
          onData={(e)=> {
            onData(e)
          }}
          strokeColor={"#000000"}
          backgroundColor="#fff" />
        
    <div className="record-footer">
      {isRecording && 
         <div className="inner-pause-icon">
          <img
              className="pause-icon"
              src={require("../../assests/images/pause-icon.svg").default}
              alt="img"
              title="Stop"
              onClick={stopRecording}
            />
            {/* <img
              className="pause-icon"
              src={require("../../assests/images/stop-outer-icon.svg").default}
              alt="img"
              onClick={() => {
                if(isRecording){
                   setIsPaused(true)
                }else{
                  setIsPaused(false)
                }
              }}
              title="Resume"
            />  */}
            </div>

             }

             {audioUrl &&   
             <div className="outer-pause-box">
             <img
              className="pause-icon"
              src={require("../../assests/images/reset.svg").default}
              alt="img"
              title="Reset"
              onClick={() => {
                setAudioUrl()
              }}
            />
             <img
                   onClick={() => {
                    setLoaderText('Converting the audio into text...')
                    getContentFromAudio(audioUrl, "view")   
                 }}
                    className="pause-icon"
                    src={require("../../assests/images/view-icon-fire.svg").default}
                    alt="img"
                    title="View Recording"
                  />  

             <img
                    onClick={() => {
                      setLoaderText('Summarizing the finalization of your audio...');
                      getContentFromAudio(audioUrl, "final")  
                    }}
                    className="pause-icon"
                    src={require("../../assests/images/finalize.svg").default}
                    alt="img"
                    title="Finalize"
                  />                             
       </div>           

         }
            </div>  
      </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>



      <Modal
        className="success-box pdf-view-modal"
        backdrop="static"
        show={showPdfModal}
        onHide={() => {setShowPdfModal(false)}}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pdf-view-inner">
          {/* <a
              onClick={props.showShare}
              className="add-btn share-b"
            >
              <i class="fa fa-share-alt" aria-hidden="true"></i>
            </a> */}
            <iframe
              title="pdf"
              src={pdfContent}
              frameborder="0"
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>



      <Modal show={recordedContent} onHide={hideContent} centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <div className="generate-modal-text">
          <div className="inner-generate-modal-text">
        <img
              className="pause-icon"
              src={require("../../assests/images/generated-text.gif")}
              alt="img"
            />
            <h5>Preview Recorded Audio</h5>
            </div>
         <p>{noteContent}</p>

      </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default UserDetails;
