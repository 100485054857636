import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import React, { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { Button, Col, Container, Dropdown, Form, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { context } from "../../GlobalContext/GlobalContext";
import axios from "axios";
import { setItem } from "../utils/localStorage";
import { openAiService } from "../../services/openai.services";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";
import { DEFAULT_PLAN_ID, baseUrl } from '../../config/config';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReCAPTCHA from "react-google-recaptcha";
import { Loading } from "../common/loader";
import {
  EMAILREGEX,
  Project_Title,
} from "../../config/config";

const ct = require("countries-and-timezones");

const timezone = ct.getTimezone(
  Intl.DateTimeFormat().resolvedOptions().timeZone
);
const COUNTRY_CODE = timezone.countries[0];

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const globalData = useContext(context);
  const [name, setName] = useState("");
  const [user, setUser] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [isUserSigningUp, setIsUserSigningUp] = useState(false);
  const [termsCheck, setTermsCheck] = useState(false);
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [googleUserData, setGoogleUserData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [isDoctor, setIsDoctor] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [profileImage,setProfileImage] = useState("")
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let i= 0;
  // const [activeTab, setActiveTab] = useState(window.location.href.includes('247-virtual-assistant') ? "advisor" : "");
  const [errors, setErrors] = useState({
    termsError: false,
    privacyError: false,
  });
  const pricingSectionRef = useRef();
  const [contactUsFields, setContactUsFields] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
  });
  const [isCaptchaValue, setIsCaptchaValue] = useState();
  const [contactUsFieldsErrors, setContactUsFieldsErrors] = useState({
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    message: false,
  });
  const tAndC = <span style={{ 'fontSize': '14px' }}>Accept Terms and Conditions <a target="_blank" href="/terms">Click here for T&Cs.</a></span>
  const privacyStatement = <span style={{ 'fontSize': '14px' }}>Review and accept the Privacy Policy <a target="_blank" href="/privacy">Click here for the Privacy Policy.</a></span>
  useEffect(() => {
    if (globalData.triggerLogin) {
      login()
      globalData.setTriggerLogin(false)
    }
  }, [globalData.triggerLogin])
  useEffect(() => {
    if (localStorage.getItem("token")) {
    } else if (location.pathname === "/" || location.pathname === "/login" || location.pathname === "/dashboard" || location.pathname.includes("/pricing") || location.pathname.includes("/terms") || location.pathname.includes("/privacy")) { } else {
      navigate("/");
    }
  }, [location.pathname]);
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
    onNonOAuthError: (error) => {
      if (error.type === "popup_closed") {
        setIsUserSigningUp(false)
      }
    }
  });

  useEffect(() => {
    if (localStorage.getItem('userType') != null) {
      setIsDoctor(true);
    } else {
      setIsDoctor(false);
    }


    const handleVisibilityChange = () => {
      if (localStorage.getItem('userType') != null) {
        setIsDoctor(true);
      } else {
        setIsDoctor(false);
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  useEffect(() => {
    if (user) {
      getGoogleUser(user)    
    }
  }, [user]);

  useEffect(() => {
    
   if(localStorage.getItem('user') && !localStorage.getItem('userType')){
     if(i == 0){
      getProfiles()
     }
     i++;
   }
  }, [i])

  

   function navigateTo(type){
    // console.log(window.location.pathname)
    setShow(false)
    globalData.setCancerClicked(true);
    if(!localStorage.getItem('user')){
      globalData.setTriggerLogin(true)
      localStorage.setItem('redirectUrl',`/specialist/${type}`)
    }else{
      navigate(`/specialist/${type}`)
    }
    
 
    
   }



  // for fetching user details for login/signup
  function getGoogleUser(user) {
    axios.get(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
        Accept: "application/json",
      },
    }).then((res) => {
      setNewUser(true);
      console.log("i am in the getGoogleUser function")
      console.log('registers------', res);
      localStorage.setItem("navigate", "/247-virtual-assistant")
      if (res.data.id) {
        let data = {
          email: res.data.email,
          name: res.data.given_name + " " + res.data.family_name,
          socialid: res.data.id,
          picture: res.data.picture || "",
        };
        setGoogleUserData(data)
        if (isUserSigningUp) {
          data.isTermsConditionsChecked = termsCheck ? 1 : 0
          data.isPrivacyPolicyChecked = privacyCheck ? 1 : 0
        }
        setShowLoader(true);
        registerUserApi(data);
      }
    }).catch((err) => console.log(err));
  }
  function registerUserApi(data) {
    openAiService.registerUser(data).then((res) => {
      console.log(">>>>>>>>>>>>>>>>>>", res.data)
      setNewUser(true);
      if (res.data?.token) {
        setItem("user", res.data);
        localStorage.setItem("token", res.data.token)
        setItem("token", res.data.token);
        // setName(res?.data?.name)
        globalData.setToken(res.data.token);
        globalData.setName(res?.data?.name);

        getSubscription(res.data.subscription_plan, res?.data?.name)
      }
      setShowLoader(false);
      globalData.setSignUpModal(false);
    }).catch((err) => {
      globalData.setSignUpModal(false);
      // if user tries to login and has not accepted terms and conditions then show modal to accept terms and conditions
      if (!err?.response?.data?.agreed) {
        setErrors({})
        globalData.setTermsAndPrivacyModal(true)
      } else {
        Swal.fire("Error", err.response?.data?.error || "Something went wrong.", "error")
      }
      setShowLoader(false);
    });

  }
  function getSubscription(subId, name) {
    console.log(">>>>>>>>>>>token>>>>>>>", localStorage.getItem("token"))
    // if user has no plan then there will be no subId
    if (subId) {
      openAiService.getSubscription(subId).then(res => {
        // console.log("getsubscription api res", res)
        if (res.data?.plan.active) {
          if (res.data?.plan.id === DEFAULT_PLAN_ID) { } else {
            globalData.setCurrentPlan(res.data.plan)
            localStorage.setItem("plan", JSON.stringify(res.data.plan))
          }
        }
        setShowLoader(false);
        showLoginSuccess(name)
      }).catch(err => {
        console.log("get subscription id err", err)
        setShowLoader(false);
        window.location.href = "/247-virtual-assistant";
      })
    } else {
      showLoginSuccess(name)
    }
  }
  const showLoginSuccess = (name) => {
    Swal.fire("Success", "Login successful", "success").then(() => {

      if (name == "" || name == null) {
        window.location.href = '/my_profile'
        // navigate('/create_profile')
        globalData.setTermsAndPrivacyModal(false)
      } else if (newUser) {
        window.location.href = '/my_profile'
      } else {
        if (globalData.loginVia === "PRICING") {
          globalData.setScrollToPricing(true)
          globalData.setLoginVia("");
          localStorage.setItem("scPrice", true)
          window.location.href = "/"
        } else if (globalData.loginVia === "OTHER_FEATURES") {
          globalData.setShowPopupModal(true)
          globalData.setLoginVia("")
          localStorage.setItem("shPop", true)
          window.location.href = "/247-virtual-assistant"
        } else if(localStorage.getItem('redirectUrl')){
          window.location.href = localStorage.getItem('redirectUrl');
        } else{
          window.location.href = "/247-virtual-assistant";
        }
      }

    })
  }


  function onChange(value) {
    // isCaptchaValue.current = value;
    setIsCaptchaValue(value);
    console.log("Captcha value:", value);
  }

  const handleContactUsSubmit = (e) => {
    e.preventDefault();
    let formValidated = true;
    if (contactUsFields.firstName.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          firstName: true,
        };
      });
    }
    if (contactUsFields.lastName.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          lastName: true,
        };
      });
    }
    if (contactUsFields.phone.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          phone: true,
        };
      });
    } else if (contactUsFields.phone.trim().length < 10) {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          phone: true,
        };
      });
    }
    if (contactUsFields.email.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          email: true,
        };
      });
    } else if (!EMAILREGEX.test(contactUsFields.email)) {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          email: true,
        };
      });
    }
    if (contactUsFields.message.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          message: true,
        };
      });
    }
    if (formValidated) {
      let data = {
        first_name: contactUsFields.firstName,
        last_name: contactUsFields.lastName,
        email: contactUsFields.email,
        message: contactUsFields.message,
        phone: "+" + contactUsFields.phone,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      openAiService
        .contactUs(data)
        .then((res) => {
          console.log("contact us api response", res);
          Swal.fire(
            "Success",
            "Thank you for submitting your request. We are reviewing it and will respond soon!",
            "success"
          ).then(() => {
            globalData.setShowContactUsModal(false);
            setContactUsFields({
              firstName: "",
              lastName: "",
              email: "",
              message: "",
              phone: "",
            });
          });
        })
        .catch((err) => {
          Swal.fire("Error", "Something went wrong.", "error");
          console.log("contact us api error", err);
        });
    }
  };

  function getProfiles() {
    openAiService
      .getProfiles()
      .then((res) => {
        setProfileImage(res.data.data.profile_image)
        console.log(">>>>>>", res.data.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  }

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleItemClick = () => {
    setDropdownOpen(false);
  };

  const handleSignUpSubmit = () => {
    let formValidated = true
    if (!termsCheck) {
      formValidated = false
      setErrors(prevObj => {
        return ({
          ...prevObj,
          termsError: true
        })
      })
    }
    if (!privacyCheck) {
      formValidated = false
      setErrors(prevObj => {
        return ({
          ...prevObj,
          privacyError: true
        })
      })
    }

    if (formValidated) {
      setIsUserSigningUp(true)
      login()
    }
  }
  const logOut = () => {
    localStorage.clear();
    globalData.setActiveTab("")
    googleLogout();
    navigate("/");
  };
  const logOutDoc = () => {
    localStorage.clear();
    // googleLogout();
    navigate("/login");
  };
  return (
    <>

      {showLoader && <Loading />}
      <section className="header">
        <Container>
          <div className="main-chat-header">
            <div
              className="header-left"
              style={{ cursor: "pointer" }}
              onClick={() => {
                globalData.setActiveTab("")
                if (localStorage.getItem('userType') === 'doctor') {
                  // window.location.reload()
                  navigate('/dashboard')
                } else {
                  if (window.location.href.includes('login')) {
                    navigate('/login')
                  } else {
                    navigate("/")
                  }

                }
              }}
            >
              <img
                className="logo"
                src={require("../../assests/images/logo22.png")}

                alt="img"
              />
            </div>
            <div className={isDoctor ? "header-right header-right-doctor" : "header-right"}>
              <div className="activate-pro-faq">
                {/* {localStorage.getItem("user") && */}
                <>

                  {/* add cancer advisory link */}

                  {window.location.href.includes('login') || (localStorage.getItem("token") && isDoctor) ?
                    ""
                    :
                    <div id="cancer-prompt" className={globalData.activeTab === 'cancer' ? "header-top-right active" : "header-top-right"}>
                      <p style={{ 'cursor': 'pointer' }} disabled={disabled} onClick={() => {
                        globalData.setActiveTab('cancer')
                        
                        if (localStorage.getItem("user")) {
                          setShow(true);
                        } else if (localStorage.getItem("token")) {
                          setDisabled(true);
                          // alert('doctor')
                        } else {
                          setShow(true);
                          // globalData.setTriggerLogin(true)
                        }
                      }} >
                       Specialist
                      </p>
                    </div>
                  }


                  {window.location.href.includes('login') || (localStorage.getItem("token") && isDoctor) ?
                    ""
                    : <div className={globalData.activeTab === 'advisor' ? "header-top-right active" : "header-top-right"}>
                      <p style={{ 'cursor': 'pointer' }}
                        onClick={() => {
                          globalData.setActiveTab('advisor')
                          if (localStorage.getItem("user")) {
                            if (location.pathname !== "/247-virtual-assistant") {

                              navigate("/247-virtual-assistant")
                            }
                          } else if (localStorage.getItem("token")) {
                            setDisabled(true);
                            //  alert('doctor')
                          } else {
                            localStorage.setItem('redirectUrl',`/247-virtual-assistant`)
                            globalData.setTriggerLogin(true)
                          }
                        }}>
                        24/7 Virtual Assistant
                      </p>
                    </div>}

                  {window.location.href.includes('login') || (localStorage.getItem("token") && isDoctor) ?
                    ""
                    :
                    <div className={globalData.activeTab === 'report' ? "header-top-right active" : "header-top-right"}>
                      <p style={{ 'cursor': 'pointer' }} disabled={disabled} onClick={() => {
                        globalData.setActiveTab('report')
                        if (localStorage.getItem("user")) {
                          if (location.pathname !== "/clinical-insights") {
                            navigate("/clinical-insights")
                          }
                        } else if (localStorage.getItem("token")) {
                          setDisabled(true);
                          // alert('doctor')
                        } else {
                          localStorage.setItem('redirectUrl',`/clinical-insights`)
                          globalData.setTriggerLogin(true)
                        }
                      }}>
                        Clinical Insights
                      </p>
                    </div>
                  }




                </>
                {/* } */}
                {/* <div className="header-top-right">
                  <p style={{'cursor': 'pointer'}} onClick={() => {
                      // scroll to how it works video
                      // navigate("/")
                    }}>
                    How it works
                  </p>
                </div> */}
                {/* <div className="header-top-right">
                  <p style={{'cursor': 'pointer'}} onClick={() => {
                      globalData.setScrollToPricing(true)
                      navigate("/", {state:{setScrollToPrice: true}})
                    }}>
                    Pricing
                  </p>
                </div> */}
              </div>
              {localStorage.getItem("user") ? (
                <div className="email-dropdown">
                  <div className="email-top-area">
                    {localStorage.getItem("user") ? (
                      <>
                        <h6>
                          {JSON.parse(localStorage.getItem("user")).name}
                        </h6>
                        <p>{JSON.parse(localStorage.getItem("user")).email}</p>
                      </>
                    ) : null}
                  </div>
                  <div className="dropdown-top">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {console.log("localStorage.getItem().picture----------", localStorage.getItem("user"))}
                        <img src={localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).profile_image ? baseUrl+JSON.parse(localStorage.getItem("user")).profile_image :profileImage ? baseUrl+profileImage :localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).picture  ? JSON.parse(localStorage.getItem("user")).picture : require("../../assests/images/user-placeholder.png")} alt="img" className="rounded-circle profile-img"
                          style={{ width: '150px' }} referrerPolicy="no-referrer" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {/* <Dropdown.Item
                          onClick={() => {
                            profileImage ? baseUrl+profileImage :
                            if(location.pathname !== "/legal-advisory"){
                              navigate("/legal-advisory");
                            }
                          }}
                           <a >
                            <span className="sidebar-icon">
                                <img
                                    className=""
                                    src={require("../../assests/images/shop.svg").default}
                                    alt="img"
                                />
                            </span>
                            Dashboard</a>
                        >
                          Chat
                        </Dropdown.Item> */}
                        <Dropdown.Item href="/my_profile"><i class="fa fa-user" aria-hidden="true"></i>
                          <span>My Profile</span></Dropdown.Item>
                        <Dropdown.Item href="/my_appointment"><i class="fa fa-calendar-check-o" aria-hidden="true"></i>
                          <span>My Appointment</span></Dropdown.Item>
                        <Dropdown.Item onClick={logOut}><i class="fa fa-sign-out" aria-hidden="true"></i>
                          <span>Logout</span></Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              ) : (
                <>
                  {(localStorage.getItem("token") && isDoctor)
                    ?

                    <div className="dropdown-top menu-profile logout-btn">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <img
                            className="logo"
                            src={require("../../assests/images/user-placeholder.png")}
                            alt="img"
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {/* <Dropdown.Item
                          onClick={() => {
                            if(location.pathname !== "/legal-advisory"){
                              navigate("/legal-advisory");
                            }
                          }}
                        >
                          Chat
                        </Dropdown.Item> */}
                          {/* <Dropdown.Item href="/setting">Settings</Dropdown.Item> */}
                          <Dropdown.Item onClick={() => {navigate("/dashboard")
                          globalData.setActiveLink('dashboard')}
                        }><i class="fa fa-dashboard" aria-hidden="true"></i>
                            Dashboard</Dropdown.Item>
                          <Dropdown.Item onClick={() => navigate("/availability")}><i class="fa fa-calendar-check-o" aria-hidden="true"></i>
                            Manage Availability</Dropdown.Item>
                          <Dropdown.Item onClick={logOutDoc}><i class="fa fa-sign-out" aria-hidden="true"></i>
                            Logout</Dropdown.Item>

                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    : (window.location.href.includes('login')) ? "" : <div className="header-top-left">
                      <p onClick={() => {
                        login()
                      }}>
                        <img
                          className="google-logo-style"
                          src={require("../../assests/images/google-logo.png")}
                          alt="google"
                        ></img>
                        Login with google
                      </p>
                    </div>}
                </>
              )}
            </div>
            {localStorage.getItem("token") && !isDoctor ?
              <div className="dropdown-top mobile-tab">
                <Dropdown show={isDropdownOpen} onSelect={handleItemClick}>
                  <Dropdown.Toggle onClick={handleDropdownToggle} variant="success" id="dropdown-basic">
                    ☰
                  </Dropdown.Toggle>
                  <Dropdown.Menu>

                    <div className="header-right">
                      <div className="activate-pro-faq">
                        {/* {localStorage.getItem("user") && */}
                        <>

                          {window.location.href.includes('login') || (localStorage.getItem("token") && isDoctor) ?
                            ""
                            :
                            <div  id="cancer-prompt" className={globalData.activeTab === 'cancer' ? "header-top-right active" : "header-top-right"}>
                            <p style={{ 'cursor': 'pointer' }} disabled={disabled} onClick={() => {
                              globalData.setActiveTab('cancer')
                               handleItemClick()
                              
                              if (localStorage.getItem("user")) {
                                setShow(true);
                              } else if (localStorage.getItem("token")) {
                                setDisabled(true);
                                // alert('doctor')
                              } else {
                                setShow(true);
                                // globalData.setTriggerLogin(true)
                              }
                            }} >
                            Specialist
                            </p>
                          </div>
                          }

                          {window.location.href.includes('login') || (localStorage.getItem("token") && isDoctor) ?
                            ""
                            : <div className={globalData.activeTab === 'advisor' ? "header-top-right active" : "header-top-right"}>
                              <p style={{ 'cursor': 'pointer' }}
                                onClick={() => {
                                  globalData.setActiveTab('advisor')
                                  handleItemClick()
                                  if (localStorage.getItem("user")) {
                                    if (location.pathname !== "/247-virtual-assistant") {

                                      navigate("/247-virtual-assistant")
                                    }
                                  } else if (localStorage.getItem("token")) {
                                    setDisabled(true);
                                    //  alert('doctor')
                                  } else {
                                    localStorage.setItem('redirectUrl',`/247-virtual-assistant`)
                                    globalData.setTriggerLogin(true)
                                  }
                                }}>
                                24/7 Virtual Assistant
                              </p>
                            </div>}

                          {window.location.href.includes('login') || (localStorage.getItem("token") && isDoctor) ?
                            ""
                            :
                            <div className={globalData.activeTab === 'report' ? "header-top-right active" : "header-top-right"}>
                              <p style={{ 'cursor': 'pointer' }} disabled={disabled} onClick={() => {
                                globalData.setActiveTab('report')
                                handleItemClick()
                                if (localStorage.getItem("user")) {
                                  if (location.pathname !== "/rclinical-insights") {
                                    navigate("/clinical-insights")
                                  }
                                } else if (localStorage.getItem("token")) {
                                  setDisabled(true);
                                  // alert('doctor')
                                } else {
                                  localStorage.setItem('redirectUrl',`/clinical-insights`)
                                  globalData.setTriggerLogin(true)
                                }
                              }}>
                                Clinical Insights
                              </p>
                            </div>
                          }


                        </>
                        {/* } */}
                        {/* <div className="header-top-right">
                  <p style={{'cursor': 'pointer'}} onClick={() => {
                      // scroll to how it works video
                      // navigate("/")
                    }}>
                    How it works
                  </p>
                </div> */}
                        {/* <div className="header-top-right">
                  <p style={{'cursor': 'pointer'}} onClick={() => {
                      globalData.setScrollToPricing(true)
                      navigate("/", {state:{setScrollToPrice: true}})
                    }}>
                    Pricing
                  </p>
                </div> */}
                      </div>
                      {localStorage.getItem("user") ? (
                        <div className="email-dropdown mobiel-inner-style">
                          <div className="d-flex align-items-center">
                            <div className="dropdown-top inner-mobile-tab">

                              <Dropdown>
                                <Dropdown.Toggle variant="unset" id="dropdown-basic">
                                  {console.log("localStorage.getItem().picture----------", localStorage.getItem("user"))}
                                  <img src={localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).profile_image ? baseUrl+JSON.parse(localStorage.getItem("user")).profile_image :profileImage ? baseUrl+profileImage :localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).picture  ? JSON.parse(localStorage.getItem("user")).picture : require("../../assests/images/user-placeholder.png")} alt="img" className="rounded-circle profile-img"
                          style={{ width: '150px' }} referrerPolicy="no-referrer" />
                                </Dropdown.Toggle>
                                {/* <Dropdown.Menu>
<Dropdown.Item href="my_profile"></Dropdown.Item>
<Dropdown.Item href="my_appointment"><i class="fa fa-calendar" aria-hidden="true"></i>
<span>My Appointments</span></Dropdown.Item>
<Dropdown.Item onClick={logOut}><i class="fa fa-sign-out" aria-hidden="true"></i>
<span>Logout</span></Dropdown.Item>
</Dropdown.Menu> */}
                              </Dropdown>


                            </div>
                            <div className="email-top-area">
                              {localStorage.getItem("user") ? (
                                <>
                                  <h6>
                                    {JSON.parse(localStorage.getItem("user")).name}
                                  </h6>
                                  <p>{JSON.parse(localStorage.getItem("user")).email}</p>
                                </>
                              ) : null}
                            </div>

                          </div>
                          <div  className="inner-content-main">
                            <a href="my_profile">
                              <i class="fa fa-user" aria-hidden="true"></i>
                              <span>My Profile</span>
                            </a>
                            <a href="my_appointment">
                              <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
                              <span>My Appointments</span>
                            </a>
                            <a onClick={logOut}>
                              <i class="fa fa-sign-out" aria-hidden="true"></i>
                              <span>Logout</span>
                            </a>
                          </div>
                        </div>
                      ) : ("")}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              :
              window.location.href.includes('login') || (localStorage.getItem("token") && isDoctor) ?
                "" :
                <div className="dropdown-top mobile-tab">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      ☰
                    </Dropdown.Toggle>
                    <Dropdown.Menu>

                      <div className="header-right">
                        <div className="activate-pro-faq">
                          {/* {localStorage.getItem("user") && */}
                          <>
                            {window.location.href.includes('login') || (localStorage.getItem("token") && isDoctor) ?
                              ""
                              :
                              <div id="cancer-prompt" className={globalData.activeTab === 'cancer' ? "header-top-right active" : "header-top-right"}>
                              <p style={{ 'cursor': 'pointer' }} disabled={disabled} onClick={() => {
                                globalData.setActiveTab('cancer')
                                
                                if (localStorage.getItem("user")) {
                                  setShow(true);
                                } else if (localStorage.getItem("token")) {
                                  setDisabled(true);
                                  // alert('doctor')
                                } else {
                                  setShow(true);
                                  // globalData.setTriggerLogin(true)
                                }
                              }} >
                             Specialist
                              </p>
                            </div>
                            }

                            {window.location.href.includes('login') || (localStorage.getItem("token") && isDoctor) ?
                              ""
                              : <div className={globalData.activeTab === 'advisor' ? "header-top-right active" : "header-top-right"}>
                                <p style={{ 'cursor': 'pointer' }}
                                  onClick={() => {
                                    globalData.setActiveTab('advisor')
                                    if (localStorage.getItem("user")) {
                                      if (location.pathname !== "/247-virtual-assistant") {

                                        navigate("/247-virtual-assistant")
                                      }
                                    } else if (localStorage.getItem("token")) {
                                      setDisabled(true);
                                      //  alert('doctor')
                                    } else {
                                      localStorage.setItem('redirectUrl',`/247-virtual-assistant`)
                                      globalData.setTriggerLogin(true)
                                    }
                                  }}>
                                  24/7 Virtual Assistant
                                </p>
                              </div>}

                            {window.location.href.includes('login') || (localStorage.getItem("token") && isDoctor) ?
                              ""
                              :
                              <div className={globalData.activeTab === 'report' ? "header-top-right active" : "header-top-right"}>
                                <p style={{ 'cursor': 'pointer' }} disabled={disabled} onClick={() => {
                                  globalData.setActiveTab('report')
                                  if (localStorage.getItem("user")) {
                                    if (location.pathname !== "/rclinical-insights") {
                                      navigate("/clinical-insights")
                                    }
                                  } else if (localStorage.getItem("token")) {
                                    setDisabled(true);
                                    // alert('doctor')
                                  } else {
                                    localStorage.setItem('redirectUrl',`/clinical-insights`)
                                    globalData.setTriggerLogin(true)
                                  }
                                }}>
                                  Clinical Insights
                                </p>
                                <p className="google-signp" onClick={() => {
                                  login()
                                }}>
                                  <img
                                    className="google-logo-style"
                                    src={require("../../assests/images/google-logo.png")}
                                    alt="google"
                                  ></img>
                                  Login with google
                                </p>
                              </div>

                            }
                          </>
                        </div>

                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>}
          </div>
        </Container>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="prompt-box-modal"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal title</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="promt-box">
            <Row>
              <Col md={12}>
                <h2>Prompt Library</h2>
                <p>Choose the prompt that best fits your preference. Upon
                  clicking the question, it will be promptly transmitted to the AI Health Assistant.</p>
              </Col>
              <Col md={12}>
                <div className="promt-section-iiner">
                  <Row>
                  <Col xs={6} md={6} lg={4}>
                      <div className="promt-main-box"
                        onClick={ () => navigateTo("oncology")}
                      >
                        <img
                          src={require("../../assests/images/cancer-1.png")}
                          alt="img"
                          className=""
                        />
                        <h4>Oncology</h4>
                      </div>
                    </Col>
                    <Col xs={6}  md={6} lg={4}>
                      <div className="promt-main-box"
                      onClick={() => navigateTo("neurology")}
                      >
                        <img
                          src={require("../../assests/images/cancer-2.png")}
                          alt="img"
                          className=""
                        />
                        <h4>Neurology</h4>
                      </div>
                    </Col>

                    <Col xs={6} md={6} lg={4}>
                      <div className="promt-main-box"
                       onClick={() => navigateTo("cardiology")}
                      >
                        <img
                          src={require("../../assests/images/cardiology.png")}
                          alt="img"
                          className=""
                        />
                        <h4>Cardiology</h4>
                      </div>
                    </Col>
                    
                    <Col xs={6} md={6} lg={4}>
                      <div className="promt-main-box last-box"
                      onClick={() => navigateTo("gynecology")}
                      >
                        <img
                          src={require("../../assests/images/cancer-4.png")}
                          alt="img"
                          className=""
                        />
                        <h4>Gynecology</h4>
                      </div>
                    </Col>
                    <Col xs={6} md={6} lg={4}>
                      <div className="promt-main-box last-box"
                        onClick={() => navigateTo("diabetes")}
                      >
                        <img
                          src={require("../../assests/images/cancer-5.png")}
                          alt="img"
                          className=""
                        />
                        <h4>Diabetes</h4>
                      </div>
                    </Col>
                    <Col xs={6} md={6} lg={4}>
                      <div className="promt-main-box last-box"
                       onClick={() => navigateTo("pediatrics")}
                      >
                        <img
                          src={require("../../assests/images/cancer-6.png")}
                          alt="img"
                          className=""
                        />
                        <h4>Pediatrics</h4>
                      </div>
                    </Col>
                  
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>

      <Modal
        className="terms-condition-modal-area-area"
        show={globalData.termsAndPrivacyModal}
        // backdrop="static"
        onHide={() => {
          globalData.setTermsAndPrivacyModal(false);
          setTermsCheck(false)
          setPrivacyCheck(false)
          setErrors({})
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Sign Up</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="google-login-btn">
            <Form>
              <div className="row">
                <div className="login-form-box terms-condition-box-area">
                  <h5 className="mb-4">To utilize AIHealth Assistant, you are required to agree to the following terms:</h5>
                  <Form.Check className={errors.termsError ? "mb-3 signup-error terms-label" : "mb-3 terms-label"} type="checkbox" disabled={isUserSigningUp} checked={termsCheck}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setErrors(obj => {
                          return ({
                            ...obj,
                            termsError: false
                          })
                        })
                      }
                      setTermsCheck(e.target.checked)
                    }}
                    label={tAndC} />
                  <Form.Check className={errors.privacyError ? "mb-3 signup-error terms-label" : "mb-3 terms-label"} type="checkbox" disabled={isUserSigningUp} checked={privacyCheck}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setErrors(obj => {
                          return ({
                            ...obj,
                            privacyError: false
                          })
                        })
                      }
                      setPrivacyCheck(e.target.checked)
                    }}
                    label={privacyStatement} />
                  <Button
                    type="button"
                    variant="unset"
                    className="w-75"
                    disabled={!termsCheck || !privacyCheck}
                    onClick={() => {
                      let data = googleUserData
                      data.isTermsConditionsChecked = termsCheck ? 1 : 0
                      data.isPrivacyPolicyChecked = privacyCheck ? 1 : 0
                      setGoogleUserData(data)
                      setNewUser(true)
                      registerUserApi(data)

                    }}
                    style={{ margin: "12px 12px" }}
                  >
                    <img
                      className="google-logo-style"
                      src={require("../../assests/images/google-logo.png")}
                      alt="google"
                    ></img>
                    Continue
                    {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
                  </Button></div>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="modal-area-box"
        show={globalData.showContactUsModal}
        onHide={() => {
          globalData.setShowContactUsModal(false);
          setIsCaptchaValue(false)
          setContactUsFields({
            firstName: "",
            lastName: "",
            email: "",
            message: "",
            phone: "",
          });
          setContactUsFieldsErrors({
            firstName: false,
            lastName: false,
            email: false,
            message: false,
            phone: false,
          });
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <div className="right-chat-boxes">
            <div className="left-chat-box">
              <div className="chat-history-header"></div>
              <div className="right-chat-box-dropdown">
                <div>
                  <Form onSubmit={handleContactUsSubmit}>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        style={{ minWidth: "100%" }}
                        multiple={false}
                        className={
                          contactUsFieldsErrors.firstName ? "border-red" : ""
                        }
                        placeholder="Enter First Name"
                        value={contactUsFields.firstName}
                        maxLength={30}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              firstName: e.target.value,
                            };
                          });
                          if (e.target.value.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                firstName: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                firstName: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        style={{ minWidth: "100%" }}
                        multiple={false}
                        placeholder="Enter Last Name"
                        className={
                          contactUsFieldsErrors.lastName ? "border-red" : ""
                        }
                        value={contactUsFields.lastName}
                        maxLength={30}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              lastName: e.target.value,
                            };
                          });
                          if (e.target.value.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                lastName: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                lastName: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>Phone Number</Form.Label>
                      <PhoneInput
                        country={
                          COUNTRY_CODE ? COUNTRY_CODE.toLocaleLowerCase() : "us"
                        }
                        className={
                          contactUsFieldsErrors.phone
                            ? "border-red phone-input"
                            : "phone-input"
                        }
                        placeholder="Enter phone number"
                        value={contactUsFields.phone}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              phone: e,
                            };
                          });
                          if (e.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                phone: true,
                              };
                            });
                          } else if (e.trim().length < 10) {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                phone: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                phone: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        style={{ minWidth: "100%" }}
                        multiple={false}
                        className={
                          contactUsFieldsErrors.email ? "border-red" : ""
                        }
                        placeholder="Enter Email"
                        value={contactUsFields.email}
                        maxLength={100}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              email: e.target.value,
                            };
                          });
                          if (e.target.value.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                email: true,
                              };
                            });
                          } else if (!EMAILREGEX.test(e.target.value)) {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                email: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                email: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        rows={3}
                        style={{ minWidth: "100%" }}
                        multiple={false}
                        placeholder="Enter Message"
                        className={
                          contactUsFieldsErrors.message ? "border-red" : ""
                        }
                        value={contactUsFields.message}
                        maxLength={1000}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              message: e.target.value,
                            };
                          });
                          if (e.target.value.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                message: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                message: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      controlId="formFile"
                      className="mb-2 captcha-size"
                    >
                      <ReCAPTCHA
                        sitekey="6LeKLXAoAAAAAH20G0gfS6AaDgK6zhPjbcNBDeZW"
                        onChange={onChange}
                      />
                    </Form.Group>
                    <br />
                    <button
                      className="try-ti-buton"
                      disabled={isCaptchaValue ? false : true}
                      type="submit"
                    >
                      Submit
                    </button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Header;
