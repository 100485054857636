import React, { useState } from "react";
import Stripe from "stripe";
import axios from "axios";

const stripe = new Stripe("your_api_key_here");

const CardForm = () => {
  const [cardDetails, setCardDetails] = useState({
    number: "",
    expMonth: "",
    expYear: "",
    cvc: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCardDetails({ ...cardDetails, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { token } = await stripe.createToken({
      card: {
        number: cardDetails.number,
        exp_month: cardDetails.expMonth,
        exp_year: cardDetails.expYear,
        cvc: cardDetails.cvc,
      },
    });

    // try {
    //     const response = await axios.post(`https://api.aiattorney.biz/save-stripe-info/`,{
    //     payment_method_id: token.id,
    //   });

    //   console.log(response.data);
    // } catch (error) {
    //   console.error(error);
    // }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="number"
        placeholder="Card Number"
        value={cardDetails.number}
        onChange={handleInputChange}
      />
      <input
        type="text"
        name="expMonth"
        placeholder="MM"
        value={cardDetails.expMonth}
        onChange={handleInputChange}
      />
      <input
        type="text"
        name="expYear"
        placeholder="YYYY"
        value={cardDetails.expYear}
        onChange={handleInputChange}
      />
      <input
        type="text"
        name="cvc"
        placeholder="CVC"
        value={cardDetails.cvc}
        onChange={handleInputChange}
      />
      <button type="submit">Save Card</button>
    </form>
  );
};

export default CardForm;
