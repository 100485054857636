import { useEffect, useState } from "react"
import { openAiService } from "../../services/openai.services";
import { Form, Button, Col, Container, Modal, Row } from "react-bootstrap";
import { AddReport } from "../modal/AddReport";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { EMAILREGEX, baseUrl } from "../../config/config";
import { Loading } from "../common/loader";
import { DateFormatter, DateTimeFormatter } from "../common/common";
import { pagination } from "../partials/pagination"; 


export const ReportInsight = () => {

  const [reports, setReport] = useState([]);
  const [showReportModal,setShowReportModal] = useState(false);
  const [shareEmailsShow,setShareEmailsShow] = useState(false);
  const [selectedItemTemp, setSelectedItemTemp] = useState([]);
  const [onAddValue, setOnAddValue] = useState("");
  const [emailError, setEmailError] = useState("");
  const [shareEmails, setShareEmails] = useState([]);
  const [pdfShow, setPdfShow] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [addReportData, setAddReportData] = useState();
  const navigate = useNavigate();
  const [reportId, setReportId] = useState();
  const [reportNameErr, setReportNameErr] = useState("");
  const [reportName, setReportName] = useState("");
  const [total,setTotal] = useState(0)

  const handleAddReportClose = () => setShowReportModal(false);
  const handleAddReportShow = () => setShowReportModal(true);
  const [showNameModal,setShowNameModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [pageNo,setPageNo] = useState(1);
  const [activePage, setActivePage] = useState(1);


  useEffect(() => {
    getReport(pageNo)
  }, [])

  useEffect(() => {
    if(addReportData && addReportData.response){
      setShowNameModal(true); 
    } 
  }, [addReportData])


  const handleKeyPres1 = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!EMAILREGEX.test(onAddValue)) {
        setEmailError("Please enter valid email");
      } else {
        setEmailError("");
        onAddRecommendTest();
      }
    }
  };

  const onAddRecommendTest = () => {
    if (!onAddValue) {
      return;
    }
    setShareEmails((prevItem) => {
      return [...prevItem, onAddValue];
    });
    setSelectedItemTemp((prevSelectedDays) => {
      return [...prevSelectedDays, onAddValue];
    });
    setOnAddValue("");
  };


  const onSelectItem = (item) => {
    setSelectedItemTemp(
      (prevSelectedDays) => {
        const isSelected = prevSelectedDays.includes(item);
        if (isSelected) {
          return prevSelectedDays.filter((selectedDay) => selectedDay !== item);
        } else {
          return [...prevSelectedDays, item];
        }
      },
      () => {
        console.log(item); // Log the current state to check
      }
    );
  };



  /**************Get Report List *************/
  const getReport = (pageNo) => {
  setIsLoader(true);
    openAiService.getAllReports(pageNo)
      .then((res) => {
        setReport(res.data?.data)
        setIsLoader(false);
        setTotal(res.data?.totalCount);
        console.log("report data", res.data)
      })
      .catch((err) => {
        console.log('err----', err)
      })
  }
  /**************Get Report List END *************/

  /**************Delete Report*************/

  const deleteReport = (id) => {
    Swal.fire({
      icon:"warning",
      title: "Are you sure you want to delete this report?",
      showCancelButton: true,
  }).then((result) => {
      if (result.isConfirmed) {
        openAiService.deleteReport(id)
        .then((res) => {
          console.log("report data", res.data?.data)
          getReport(pageNo)
          Swal.fire("Success", "Report deleted successfully.", "success");
        })
        .catch((err) => {
          Swal.fire("Error", "Something went wrong.", "error");
        })
      } 
    })
   
  }

  /**************Delete Report Ends*************/



  /**************Share Report*************/
  const shareReport = (id) => {


    if (selectedItemTemp.length === 0) {
      Swal.fire("error", "Please choose an email.", "error")
    } else {
      setIsLoader(true);
      let data = {
        emails: selectedItemTemp, handleAddReportShow
      };
      openAiService
        .shareReportInsight(id, data)
        .then((result) => {
          setIsLoader(false);
          Swal.fire(
            "Success",
            "The report has been shared on the mentioned emails.",
            "success"
          ).then(() => {
            setShareEmailsShow(false);
            setSelectedItemTemp([]);
            setShareEmails([]);
          });
        })
        .catch((error) => {
          setIsLoader(false);
          if (error?.response?.status === 401) {
            localStorage.clear();
            Swal.fire({
              title: "Error",
              text: " Your session has expired, Please login again.",
              type: "error",
            }).then(() => {
              navigate("/login");
            });
          }
        });
    }

  }


  /**************Share Report End*************/

  const toggleButton = (id) => {
    console.log(document.getElementById('toggle_' + id))
    var element = document.getElementById('toggle_' + id);
    if (element.classList.contains('open')) {
      element.classList.remove('open');
    } else {
      element.classList.add('open');
    }

  }


  const reportDataFn = (res) => {
    console.log("setAddReportData", res)
    console.log("setAddReportDataType", typeof res)
    setReportId(res.id)

    setReportName(res.file ? res.file.split('/')[2]:"");
    setAddReportData(res)
  }


  const handleAddReportName = (id) => {
    if (reportName.trim() == '' || reportName.trim() == null || reportName.trim() == undefined) {
      setReportNameErr("Report name field is required.")
    } else {
      setIsLoader(true)
      var data = { 'name': reportName };
      openAiService.saveReportName(id, data)
        .then((res) => {
          console.log('resss', res.data)
          getReport(pageNo)
          setIsLoader(false)
          setReportName("");
          setAddReportData();
          setShowNameModal(false);
          Swal.fire("Success", "Information added successfully.", "success")

        })
        .catch((err) => {
          console.log("errrr", err)
        })
    }

  }

  const showPdfModal = (data) => {
    setPdfUrl(baseUrl + '/' + data.generated_report);
    setReportId(data.id)
    setPdfShow(true)

  }

  return (
    <>
      {isLoader && <Loading />}
      <div className="appointment-boxes report-insight-area">
        <div className="container">
          <div className="appointment-area">
            <h2>Report Insight</h2>

            <div className="add-report-top-btn">
              <Button variant="unset" onClick={() => { setShowReportModal(true) }} >+ Add Report</Button>
            </div>

          </div>
        </div>
      </div>

      <div className=" pricing-area report-insight-section width-wise-dashboard">
      <div className="overflow-report-area">
        <Container>
          {reports && reports.length > 0 ?
            reports.map((res, index) => {
              return (<div class="appointments_box request_appointments_box doctor-side toggle_past width-wise-dashboard-left" id={'toggle_' + res.id}>

                <div className="report-insight-left-area appointment_innerbox">
                  <div className="pdf-left-area appointment-content-image">
                    <img src={require("../../../src/assests/images/pdf-icon.png")} />
                  </div>
                  <div className="pdf-content-right-area appointment-content-text">
                    <h3>{res.name}</h3>
                    <p className="short_info"><div
                     dangerouslySetInnerHTML={{
                       __html: res?.response,
                         }}
                            /></p>
                    <div className="report-right-area appointment_rightarea">
                      <p><DateTimeFormatter date={res.created_at} /></p>
                      <span class="appointment_togglebtn my-2" onClick={() => {
                        toggleButton(res.id)
                      }}><i class="fa fa-chevron-down" aria-hidden="true" ></i></span>
                      <div className="report-area-bootom-btn icons-left">
                        
                        
                        <Button variant="unset" title="View Pdf" type="button" onClick={() => { showPdfModal(res) }} className="view-btn"><i className="fa fa-eye"></i></Button>
                        <Button variant="unset" title="Delete Report" type="button" onClick={() => { deleteReport(res.id) }} className="delete-btn"><i className="fa fa-trash-o"></i></Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pdf-content-right-area bottom-pdf-content appointment_fullcontent">
                  
                  <span>
                  <div
                     dangerouslySetInnerHTML={{
                       __html: res?.response,
                         }}
                            /></span>
                </div>


              </div>)
            })
            : <div className="appointment-content-area-image">
              <div className="outer-no-data">
                <img className="no-data" src={require("../../assests/images/no_data.gif")} alt="" />
                <p>No Data Found</p>
              </div>
            </div>}



        </Container>

       { pagination.setReportPagination(
              Math.ceil(total / 5),
              total,
              setReport,
              activePage,
              setActivePage,
              setIsLoader,
              setPageNo
            )
       }

          </div>
          
          {/* {addReportData && addReportData.response ?
          <div className="container">
            <Row>
              <Col md={6}>
                <div className="generated-report-area inner-generate-box">
                  <h6>Generated Response</h6>
                  <div className="">
                    <div className="d-flex flex-direction-main">
                    <label>Name:</label>
                    <input className="form-control w-100 mt-2" type="text" placeholder="Enter name..." maxLength={50} id="report_name" value={reportName} onChange={(e) => {
                      if (e.target.value.trim() == '' || e.target.value.trim() == null || e.target.value.trim() == undefined) {
                        setReportNameErr('Please fill a name for the report.');
                      } else {
                        setReportName(e.target.value);
                      }
                    }} />
                    </div>
                    {reportNameErr &&
                      <span className="file_error">{reportNameErr}</span>
                    }
                  </div>
                  <div className="d-flex flex-direction-main mt-2">
                  <label>Reason:</label>
                    <p className="text-report">{addReportData.response}</p>
                  </div>
                  <Button type="button" disabled={reportName?false:true} variant="unset" onClick={() => handleAddReportName(reportId)}>Save</Button>
                </div>
              </Col>
            </Row>
            </div> : ""
          } */}
        <AddReport show={showReportModal}
          handleClose={() => handleAddReportClose()}
          refreshData={(data) => {
            reportDataFn(data)
          }} />
      </div>


      <Modal show={showNameModal} onHide={()=> {setShowNameModal(false)
      setReportName("");
      setAddReportData();
      }} centered>
        <Modal.Header closeButton>
          <Modal.Title>Report Generated</Modal.Title>
        </Modal.Header>
        <Modal.Body>
       
                    <div className="d-flex flex-direction-main">
                    <label>Enter Report Name* :</label>
                    <input className="form-control w-100 mt-2" type="text" placeholder="Enter Report Name" maxLength={50} id="report_name" value={reportName} onChange={(e) => {
                      // if (e.target.value.trim() == '' || e.target.value.trim() == null || e.target.value.trim() == undefined) {
                      //   setReportNameErr('Report name field is required.');
                      // } else {
                        setReportNameErr('');
                        setReportName(e.target.value);
                      // }
                    }} />
                    </div>
                    {reportNameErr &&
                      <span className="file_error error">{reportNameErr}</span>
                    }
                
                  <div className="report-div d-flex flex-direction-main mt-3">
                  <label>Reason:</label>
                    <p className="text-report">{addReportData?<div
                     dangerouslySetInnerHTML={{
                       __html: addReportData.response,
                         }}
                            />  : ""}</p>
                  </div>
            </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={reportName?false:true} style={{cursor: reportName ? "pointer":"not-allowed" }} onClick={() => handleAddReportName(reportId)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal
        className="success-box pdf-view-modal"
        backdrop="static"
        show={pdfShow}
        onHide={() => setPdfShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pdf-view-inner">
            <a
              onClick={() => setShareEmailsShow(true)}
              className="add-btn share-b"
            >
              <i class="fa fa-share-alt" aria-hidden="true"></i>
            </a>
            {/* {console.log("selectedHistoryItem---",selectedHistoryItem)} */}
            <iframe
              src={pdfUrl}
              frameborder="0"
            ></iframe>

          </div>
        </Modal.Body>
      </Modal>


      <Modal
        show={shareEmailsShow}
        onHide={() => setShareEmailsShow(false)}
        centered
      >
        <Modal.Header closeButton className="p-0 pb-3">
          <Modal.Title>Share Report</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="recommend-box-upper">
            <div className="recommend-box">
              {shareEmails?.map((item) => {
                return (
                  <span
                    className={`${selectedItemTemp?.includes(item) ? "active" : ""
                      } `}
                    onClick={() => onSelectItem(item)}
                  >
                    {item}
                  </span>
                );
              })}
            </div>
            <div className="input-box">
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Label>Add Email</Form.Label>
                  </div>
                  <Form.Control
                    onKeyPress={handleKeyPres1}
                    onChange={(e) => setOnAddValue(e.target.value.trimStart())}
                    value={onAddValue}
                    type="email"
                    placeholder="Press the 'Enter' key to add the email"
                    className="mt-2"
                  />
                  <p className="errors" style={{ color: "red" }}>
                    {emailError}
                  </p>
                </Form.Group>
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={selectedItemTemp.length == 0} variant="primary" onClick={() => shareReport(reportId)}>
            Share
          </Button>
        </Modal.Footer>
      </Modal>


         


</>    
         

  )

}