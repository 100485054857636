import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { openAiService } from "../../services/openai.services";
// import { Loading } from "../common/loader";
import { AudioLoader } from "../common/AudioLoader";

export const AddReport = (props) => {

  const extensions = ['application/pdf']
  const [fileError, setFileError] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [showNameModal, setShowNameModal] = useState(false);
  const [reportNameErr, setReportNameErr] = useState("");
  const [reportName, setReportName] = useState("");

  const handleAddReport = () => {
    var formdata = new FormData();
    if (!selectedFile) {
      setFileError('*Please select a file.');
    } else if (!extensions.includes(selectedFile.type)) {
      setFileError('*Please select a valid extension.');
      document.getElementById('report_file').value = "";
    } else {
      setIsLoader(true)
      formdata.append('file', selectedFile);

      openAiService.addReportInsight(formdata)
        .then((res) => {
          props.refreshData(res.data?.data)
          setIsLoader(false)
          setSelectedFile("")
          document.getElementById('report_file').value = "";
          props.handleClose()

          console.log("resulrt", res)
        })
        .catch((err) => {
          console.log("resulrt", err)
        })
    }



  }

 
  return (
    <>
    {isLoader && <AudioLoader text={"Report Uploading"} /> }
      
      <Modal show={props.show} onHide={() => {
        props.handleClose()
        setSelectedFile("")
        document.getElementById('report_file').value = "";
      }} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form encType="multipart/form-data">
              <div className="report-upload-file">
                <img className={selectedFile ?"file-uploaded" :""} src={selectedFile ?require("../../../src/assests/images/pdf-icon.svg").default :require("../../../src/assests/images/upload-report.svg").default} />
                <input type="file" id="report_file" accept="application/pdf" onChange={(e) => {
                  if (!e.target.files[0]) {
                    setFileError('*Please select a file.');

                  } else if (!extensions.includes(e.target.files[0].type)) {
                    setFileError('*Please select a valid extension.');
                    document.getElementById('report_file').value = "";
                  } else {
                    setSelectedFile(e.target.files[0]);
                    setFileError("")
                  }
                  console.log("file selected", e.target.files)
                }} />
                {fileError &&
                  <p className="file_error red-text mt-1">{fileError}</p>
                }
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button title="Upload Report" disabled = {selectedFile ? false:true}  variant="primary" onClick={() => { handleAddReport() }}>
          Upload Report
          </Button>

        </Modal.Footer>
      </Modal>

    </>
  );


}