import { useEffect, useRef, useState,useContext } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from 'mdb-react-ui-kit';
import { openAiService } from "../../services/openai.services";
import { context } from "../../GlobalContext/GlobalContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from 'moment';
import { Loading } from "../common/loader";
 function UserProfilePage() {
  const [profileData, setprofileData] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState('');
  const globalData = useContext(context);
  const [isLoader, setIsLoader] = useState(false);
  const globalDataRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  
  globalDataRef.current = globalData;
  const navigate = useNavigate();
  
  useEffect(()=>{
    getUserProfile(searchParams.get('userId'))
  }, [])
  function getUserProfile(user_id){
     setIsLoader(true)
    openAiService.getProfileById(user_id).then(res=>{
      setIsLoader(false)
      setprofileData(res.data?.data)
      setDateOfBirth(res.data?.data?.dob)
    }).catch(error=>{
      if(error?.response?.status === 401){
        setIsLoader(false)
        localStorage.clear()
        globalDataRef.current.setSignUpModal(true)
        navigate("/")
      }
    })

  }

  const calculateAge = (dateOfBirth) => {
    const birthDate = moment(dateOfBirth);
    const currentDate = moment();
    const diff = moment.duration(currentDate.diff(birthDate));
    const years = diff.years();
    return  years ;
  };

  const age=calculateAge(dateOfBirth);

  return (
    <section style={{ backgroundColor: '#eee' }} className="pricing-area">
      {isLoader && < Loading />}
      <MDBContainer className="py-5">
      <div>
      <a href="/dashboard" className="update-btn mb-2">Back</a>
    </div>
        <MDBRow>
          <MDBCol lg="4">
            <MDBCard className="mb-4">
              <MDBCardBody className="text-center">
                <MDBCardImage
                  src={profileData.picture !== null ? profileData.picture :"https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"}
                  alt="avatar"
                  className="rounded-circle"
                  style={{ width: '150px' }}
                  fluid />
                <p className="text-muted mb-1">{profileData.name}</p>
                <p className="text-muted mb-4">{profileData.email}</p>
              </MDBCardBody>
            </MDBCard>  
          </MDBCol>
          <MDBCol lg="8">
            <MDBCard className="mb-4">
              <MDBCardBody>
               
             
             
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Age</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{age} years</MDBCardText>
 
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Weight</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{profileData.weight} KG</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Height</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{profileData?.height?.toString().includes(".")?
                    profileData.height.toString().split(".")[0]+" Feet "+ profileData.height.toString().split(".")[1]+" Inches":profileData.height+" Feet"}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Blood Group</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{profileData.blood_group}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
              </MDBCardBody>
            </MDBCard>

            <MDBRow>
              <MDBCol md="12">
                <MDBCard className="mb-4 mb-md-0">
                  <MDBCardBody>
                    
                    <MDBCardText className="mb-4"><span className="text-primary font-italic me-1"></span> Medication</MDBCardText>
                   {profileData.medication && profileData.medication.length>0 && profileData.medication.map((med,index)=>{
                        return(<MDBRow>
                        <MDBCol sm="3">
                          <MDBCardText>{med.name}</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="9">
                          <MDBCardText className="text-muted">{med.dosage} {med.unit}</MDBCardText>
                        </MDBCol>
                        </MDBRow>)

                   })}
                    {profileData.medication && profileData.medication.length==0?
                    <MDBRow>
                       <MDBCol sm="6">
                        No data found
                       </MDBCol>
                      </MDBRow>:null}
                  
            </MDBCardBody>
                </MDBCard>
              </MDBCol>

            
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}

export default UserProfilePage;