import { useState, useEffect } from "react";
import { openAiService } from "../../services/openai.services";
import { pagination } from "../partials/pagination";
import Moment from "react-moment";
import { Row, Col, Modal, Button, Toast, Badge, OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Loading } from "../common/loader";
import Swal from "sweetalert2";
import { baseUrl } from "../../config/config";
import { DateTimeFormatter } from "../common/common";

function Appointment() {
  const [activeUpcomingPage, setActiveUpcomingPage] = useState(1);
  const [activePastPage, setActivePastPage] = useState(1);
  const [isLoader, setIsLoader] = useState(false);
  const [pageno, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const [addActive, setAddActive] = useState("upcoming");
  const navigate = useNavigate();
  const [isUpComingClicked, setIsUpcomingClicked] = useState(true);
  const [isPastClicked, setIsPastClicked] = useState(false);
  const [past, setPast] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [totalPastRecords, setTotalPastRecords] = useState(0);
  const [totalUpcomingRecords, setTotalUpcomingRecords] = useState(0);
  const userType = localStorage.getItem("userType");
  const [deviceType, setDeviceType] = useState("");
  const [show, setShow] = useState(false);
  const [text, setText] = useState("");
  const [copied, setCopied] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [url,setUrl] = useState("");

  const [pdfShow, setPdfShow] = useState(false);
  const [selectedHistoryItem, setSelectedHistoryItem] = useState({prescription:[]});
  const [shareEmailsShow, setShareEmailsShow] = useState(false);
  const [shareEmails, setShareEmails] = useState([]);
  const [selectedItemTemp, setSelectedItemTemp] = useState([]);
  const [onAddValue, setOnAddValue] = useState("");
  const [emailError, setEmailError] = useState("");
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;


  useEffect(() => {
    const platform = navigator.platform;
    // alert(platform)
    setDeviceType(
      /iphone|ipad|ipod|android|webos|blackberry|windows phone/.test(platform)
        ? "mobile"
        : "desktop"
    );
    getMyAppointments(pageno, status);
    if (userType === "doctor") {
      navigate("/dashboard");
    }
  }, []);

  // function getMyAppointments(pageno, status) {
  //   setIsLoader(true)
  //   openAiService.getMyAppointments(pageno, status).then(res => {
  //     console.log('result-------', res.data?.data);
  //     setAppointment(res.data?.data)
  //     setTotalRecords(res.data.totalCount);
  //     setIsLoader(false)
  //   }).catch(error => {
  //     if (error?.response?.status === 401) {
  //       localStorage.clear();
  //       navigate('/');
  //     }
  //     console.log('userdetails error------', error)
  //   })
  // }

  function getMyAppointments(pageno, status) {
    setIsLoader(true);
    openAiService
      .getAppointments(pageno, status)
      .then((res) => {
        const pastData = res.data?.past.data;
        console.log("res.data?.past.data", res.data?.past.data)
        const upcomingData = res.data?.upcoming?.data;
        setPast(pastData);
        setUpcoming(upcomingData);
        setTotalPastRecords(res.data?.past?.totalCount);
        setTotalUpcomingRecords(res.data?.upcoming?.totalCount);
        setIsLoader(false);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          Swal.fire({
            title: "Error",
            text: " Your session has expired, Please login again.",
            type: "error",
          }).then(() => {
            navigate("/login");
          });
        }
        console.log("userdetails error------", error);
      });
  }

  const toggleButton = (id) => {
    console.log(document.getElementById("toggle_" + id));
    var element = document.getElementById("toggle_" + id);
    if (element.classList.contains("open")) {
      element.classList.remove("open");
    } else {
      element.classList.add("open");
    }
  };

  const removeToggle = () => {
    if (addActive === "upcoming") {
      let ele = document.getElementsByClassName('toggle_up');
      for (let i = 0; i < ele.length; i++) {
        ele[i].classList.remove('open');
      }
    } else {
      let ele = document.getElementsByClassName('toggle_past');
      for (let i = 0; i < ele.length; i++) {
        ele[i].classList.remove('open');
      }
    }
  }

  const handleClose = () => setShow(false);

  function copyText() {
    var copyText = document.getElementById("copy-phone");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    setCopied(true);
    setShow(false);
  }

  const openModal = (url) => {
    setShowPdf(true);
    setUrl(url);
  }

  const closeModal = () => {
    setShowPdf(false);
  };





  const toastUpcomingPhoneShowHide = (appointment_list, index, showHide) => {
    appointment_list.show_call_btn = showHide;
    const upcomingItems = [...upcoming];
    upcomingItems[index] = appointment_list;
    setUpcoming(upcomingItems);
    setCopied(false)
  }

  // function formatPhoneNumber(phoneNumberString) {
  //   console.log("priginal", phoneNumberString);
  //   if(phoneNumberString.slice(0,2)=='91'){
  //     mobile = mobile.substring(3)
  //  }
  //   var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  //   console.log("first format", cleaned);
  //   var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  //   console.log("second match", match);
  //   if (match) {
  //     return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  //   }
  //   return null;
  // }

  const toastPastPhoneShowHide = (appointment_list, index, showHide) => {
    appointment_list.show_call_btn = showHide;
    const pastItems = [...past];
    pastItems[index] = appointment_list;
    setPast(pastItems);
    setCopied(false)
  }

  function getAge(dob) {
    dob = new Date(dob)
    var month_diff = new Date().getFullYear() - dob.getFullYear();
    var age; 
    if(Math.abs(month_diff) == 1){
        age = "1 year"
    }else{
        age = Math.abs(month_diff) + " years"
    }

    if(Math.abs(month_diff) <= 0){
          month_diff = new Date().getMonth() - dob.getMonth();
          if(Math.abs(month_diff) > 1){
            age = month_diff + " months"
          }else{
              age = "1 month";
          }  
        }
    //  setAge(age);
    return age
  }

  // const fetchDataAddClass = (page, status, tab) => {
  //   setAddActive(tab);
  //   setStatus(status);
  //   setPage(page);
  //   setActivePage(page)
  //   getMyAppointments(page, status);
  // }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View Prescription
    </Tooltip>
  );

  const renderTooltip_12 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Call
    </Tooltip>
  );

  const onShareWithEmail = () => {
    if (selectedItemTemp.length === 0) {
      Swal.fire("error", "Please choose an email.", "error")
    } else {
      setIsLoader(true);
      let data = {
        emails: selectedItemTemp,
        id: selectedHistoryItem.prescription[0].id,
        appointment_id: selectedHistoryItem.id,
      };
      openAiService
        .shareOnEmail(data)
        .then((result) => {
          setIsLoader(false);
          Swal.fire(
            "Success",
            "The prescription has been shared on the mentioned emails.",
            "success"
          ).then(() => {
            setShareEmailsShow(false);
            setSelectedItemTemp([]);
            setShareEmails([]);
          });
        })
        .catch((error) => {
          setIsLoader(false);
          if (error?.response?.status === 401) {
            localStorage.clear();
            Swal.fire({
              title: "Error",
              text: " Your session has expired, Please login again.",
              type: "error",
            }).then(() => {
              navigate("/login");
            });
          }
        });
    }
  };

  const handleKeyPres1 = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!EMAILREGEX.test(onAddValue)) {
        setEmailError("Please enter valid email");
      } else {
        setEmailError("");
        onAddRecommendTest();
      }
    }
  };

  const onAddRecommendTest = () => {
    if (!onAddValue) {
      return;
    }
    setShareEmails((prevItem) => {
      return [...prevItem, onAddValue];
    });
    setSelectedItemTemp((prevSelectedDays) => {
      return [...prevSelectedDays, onAddValue];
    });
    setOnAddValue("");
  };


  const onSelectItem = (item) => {
    setSelectedItemTemp(
      (prevSelectedDays) => {
        const isSelected = prevSelectedDays.includes(item);
        if (isSelected) {
          return prevSelectedDays.filter((selectedDay) => selectedDay !== item);
        } else {
          return [...prevSelectedDays, item];
        }
      },
      () => {
        console.log(item); // Log the current state to check
      }
    );
  };



  console.log("pastData******-------", past);
  console.log("upcomingData******-------", upcoming);

  return (
    <section className="pricing-area under-appointment">
      <Row className="justify-content-center">
        {isLoader && <Loading />}
        <Col md={12} className="p-0">
          <div className="profile-content-area">
            <div className="appointment-boxes">
              <div className="container">
                <div className="appointment-area">
                  <h2>My Appointments</h2>
                  <ul>
                    <li
                      style={{ cursor: "pointer" }}
                      className={addActive === "upcoming" ? "active" : ""}
                      onClick={(e) => {
                        setIsUpcomingClicked(true);
                        setIsPastClicked(false);
                        setAddActive("upcoming");
                        setCopied(false)
                        removeToggle();

                        // if (isUpComingClicked) {
                        //   return false;
                        // } else {
                        //   setIsUpcomingClicked(true);
                        //   setIsPastClicked(false)
                        //   fetchDataAddClass(1, 'pending', 'upcoming')
                        // }
                      }}
                    >
                      Upcoming
                    </li>
                    <li
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setIsUpcomingClicked(false);
                        setIsPastClicked(true);
                        setAddActive("past");
                        setCopied(false)
                        removeToggle();
                      }}
                      className={addActive === "past" ? "active" : ""}
                    >
                      Past
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {isUpComingClicked && upcoming.length > 0 ? (
              upcoming.map((result, index) => {
                return (
                  <div className="container">
                    <div
                      class="appointments_box request_appointments_box user-side toggle_up"
                      id={"toggle_" + result.id}
                    >
                      <div class="appointment_innerbox">
                        <div className="appointment-content-image">
                          <img
                            src={require("../../assests/images/user-placeholder.png")}
                            alt=""
                          />
                        </div>
                        <div className="appointment-content-text">
                          <h3 class="category_name mb-2">
                            <b>
                              {result.doctor?.first_name +
                                " " +
                                result.doctor?.last_name}
                            </b>
                          </h3>
                          <p>
                            {/* Request For:{" "}
                            <span>{result.doctor_type?.name}</span>{" "}
                            <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span> */}
                             Insurance Provider: {" "}
                            <span>
                              {result.insurance_type === "provider"
                                ? result.insurance_provider
                                : result.insurance_type
                                  .charAt(0)
                                  .toUpperCase() +
                                result.insurance_type.slice(1)}
                            </span>
                          </p>
                          <p class="short_info purple-txt">
                            Reason For Visit <br />
                            <span>{result.reason}</span>
                          </p>

                          <div class="appointment_rightarea text-right">
                          <p>
                          <DateTimeFormatter date={result.appointment_time} />
                          </p>
                            {/* <h3 class="process_text waiting">Waiting</h3> */}
                            <span
                              class="appointment_togglebtn my-2"
                              onClick={() => {
                                toggleButton(result.id);
                              }}
                            >
                              <i
                                class="fa fa-chevron-down"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <div className="icons-left">
                            <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip_12}
                                >
                              <a class="cc-calto-action-ripple " onClick={() => toastUpcomingPhoneShowHide(result, index, true)}  >
                                <i class="fa fa-phone"></i> </a>
                                </OverlayTrigger>
                              {/* {result?.appointment_report[0] && 
                                <a className="download_box" href={baseUrl + result?.appointment_report[0]?.report_file} target="_blank" rel="noreferrer">
                            <i class="fa fa-eye"></i>
                               </a>
                               } */}

                              <Toast show={result.show_call_btn == true} onClose={() => toastUpcomingPhoneShowHide(result, index, false)}>
                                <Toast.Header>
                                  <strong className="me-auto">Call</strong>

                                </Toast.Header>
                                {copied ? <p><img
                                  src={require("../../assests/images/check-main.png")}
                                  alt="img"
                                />Copied</p> : ""}
                                <Toast.Body>

                                  <input
                                    type="text"
                                    id="copy-phone"
                                    value={result?.doctor?.phone}
                                    readOnly
                                  ></input>

                                  <i class="fa fa-clone" onClick={() => {
                                    copyText
                                      ()
                                  }} aria-hidden="true"></i>
                                </Toast.Body>
                              </Toast>
                              {/* {deviceType == "mobile" ? (
                                <a
                                  class="cc-calto-action-ripple "
                                  href={"tel:" + result?.doctor?.phone}
                                >
                                  <i class="fa fa-phone"></i>
                                </a>
                              ) : (
                                <a
                                  class="cc-calto-action-ripple "
                                  onClick={() => {
                                    setShow(true);
                                    setText(result?.doctor?.phone);
                                  }}
                                >
                                  <i class="fa fa-phone"></i>
                                </a>
                              )} */}
                              {/* <Badge bg="success" className="completed-text">Completed</Badge> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="appointment_fullcontent">
                        <p className="purple-txt">
                          Reason For Visit:{" "}
                        </p>
                        <span>{result.reason}</span>
                        <p className="purple-txt mt-2">
                          Patient:{" "}
                        </p>
                        <span>
                          {result.member
                            ? result.member.name
                            : result.user.name}
                          ,{" "}
                          {result.member
                            ? getAge(result.member.dob)
                            : getAge(result.user.dob)}
                          ,{"  "}
                          {result.member
                            ? result.member.gender
                              .charAt(0)
                              .toUpperCase() +
                            result.member.gender.slice(1)
                            : result.user.gender.charAt(0).toUpperCase() +
                            result.user.gender.slice(1)}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : isPastClicked && past.length > 0 ? (
              past.map((result, index) => {
                return (
                  <div className="container">
                    <div
                      class="appointments_box request_appointments_box user-side toggle_past"
                      id={"toggle_" + result.id}
                    >
                      <div class="appointment_innerbox">
                        <div className="appointment-content-image">
                          <img
                            src={require("../../assests/images/user-placeholder.png")}
                            alt=""
                          />
                        </div>
                        <div className="appointment-content-text">
                          <h3 class="category_name mb-2">
                            <b>
                              {result.doctor?.first_name +
                                " " +
                                result.doctor?.last_name}
                            </b>
                          </h3>
                          <p>
                            {/* Request For:{" "}
                            <span>{result.doctor_type?.name}</span>{" "}
                            <span>|</span>  */}
                            Insurance Provider: {" "}
                            <span>
                              {result.insurance_type === "provider"
                                ? result.insurance_provider
                                : result.insurance_type
                                  .charAt(0)
                                  .toUpperCase() +
                                result.insurance_type.slice(1)}
                            </span>
                          </p>
                          <p class="short_info purple-txt">
                            Reason For Visit: <br />
                            <span>{result.reason}</span>
                          </p>

                          <div class="appointment_rightarea text-right">
                            <p>
                            <DateTimeFormatter date={result.appointment_time} />
                            </p>
                            {/* <h3 class="process_text waiting">Waiting</h3> */}
                            <span
                              class="appointment_togglebtn my-2"
                              onClick={() => {
                                toggleButton(result.id);
                              }}
                            >
                              <i
                                class="fa fa-chevron-down"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <div className="icons-left">

                            <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip_12}
                                >
                              <a class="cc-calto-action-ripple " onClick={() => toastPastPhoneShowHide(result, index, true)}  >
                                <i class="fa fa-phone"></i> </a>
                                </OverlayTrigger>
                              {result?.prescription[index] &&
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip}
                                >
                                  <a className="download_box " onClick={() => {setPdfShow(true);setSelectedHistoryItem(result); }} target="_blank" rel="noreferrer">
                                  <i class="fa fa-file-text-o" aria-hidden="true"></i>
                                  </a>
                                  {/* <a className="download_box set_margin" onClick={() => {
                                    openModal(baseUrl + "/" + result?.prescription[index]?.prescription_report)
                                  }}>
                                    <i class="fa fa-eye"></i>
                                  </a> */}
                                  
                                </OverlayTrigger>
                              }
                              <Toast show={result.show_call_btn == true} onClose={() => toastPastPhoneShowHide(result, index, false)}>
                                <Toast.Header>
                                  <strong className="me-auto">Call</strong>

                                </Toast.Header>
                                {copied ? <p><img
                                  src={require("../../assests/images/check-main.png")}
                                  alt="img"
                                />Copied</p> : ""}
                                <Toast.Body>

                                  <input
                                    type="text"
                                    id="copy-phone"
                                    value={result?.doctor?.phone}
                                    readOnly
                                  ></input>

                                  <i class="fa fa-clone" onClick={() => {
                                    copyText
                                      ()
                                  }} aria-hidden="true"></i>
                                </Toast.Body>
                              </Toast>
                              <Badge bg="success" className="completed-text">Completed</Badge>
                              {/* {deviceType == "mobile" ? (
                                <a
                                  class="cc-calto-action-ripple "
                                  href={"tel:" + result?.doctor?.phone}
                                >
                                  <i class="fa fa-phone"></i>
                                </a>
                              ) : (
                                <a
                                  class="cc-calto-action-ripple "
                                  onClick={() => {
                                    setShow(true);
                                    setText(result?.doctor?.phone);
                                  }}
                                >
                                  <i class="fa fa-phone"></i>
                                </a>
                              )} */}
                              {/* <Badge bg="success" className="completed-text">Completed</Badge> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="appointment_fullcontent">
                        <p className="purple-txt">
                          Reason For Visit:{" "}
                        </p>
                        <span>{result.reason}</span>
                        <p className="purple-txt mt-2">
                          Patient:{" "}
                        </p>
                        <span>
                          {result.member
                            ? result.member.name
                            : result.user.name}
                          ,{" "}
                          {result.member
                            ? getAge(result.member.dob)
                            : getAge(result.user.dob)}
                          ,{"  "}
                          {result.member
                            ? result.member.gender
                              .charAt(0)
                              .toUpperCase() +
                            result.member.gender.slice(1)
                            : result.user.gender.charAt(0).toUpperCase() +
                            result.user.gender.slice(1)}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="appointment-content-area-image">
                <div className="outer-no-data">
                  <img
                    className="no-data"
                    src={require("../../assests/images/no_data.gif")}
                    alt=""
                  />
                  <p>No Data Found</p>
                </div>
              </div>
            )}

            {isPastClicked &&
              pagination.setPastPagination(
                "my_appointment",
                activePastPage,
                Math.ceil(totalPastRecords / 10),
                status,
                setStatus,
                setPast,
                setActivePastPage,
                setIsLoader,
                totalPastRecords
              )}

            {isUpComingClicked &&
              pagination.setUpcomingPagination(
                "my_appointment",
                activeUpcomingPage,
                Math.ceil(totalUpcomingRecords / 10),
                status,
                setStatus,
                setUpcoming,
                setActiveUpcomingPage,
                setIsLoader,
                totalUpcomingRecords
              )}
          </div>
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Copy Text</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="text" value={text} readOnly id="copy_text"></input>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            title={"click to copy text"}
            onClick={copyText}
          >
            Copy
          </Button>
        </Modal.Footer>
      </Modal>
     


      <Modal show={showPdf} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Copy Text</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {/* <div className="modal">
          <div className="modalContent"> */}
          <p><iframe style={{display:"inline-block", height: "2622px"}} frameborder="0" scrolling="no" src={url} width="100%"></iframe></p>
              {/* <iframe src={url} style={{width:"600px", height:"500px"}} frameborder="0"></iframe> */}
          {/* </div>
      </div> */}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            variant="primary"
            title={"click to copy text"}
            onClick={copyText}
          >
            Copy
          </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal
        className="success-box pdf-view-modal"
        backdrop="static"
        show={pdfShow}
        onHide={() => setPdfShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pdf-view-inner">
            {/* <a
              onClick={() => setShareEmailsShow(true)}
              className="add-btn share-b"
            >
              <i class="fa fa-share-alt" aria-hidden="true"></i>
            </a> */}
            {/* {console.log("selectedHistoryItem---",selectedHistoryItem)} */}
            <iframe
              src={baseUrl + "/" + selectedHistoryItem?.prescription[0]?.prescription_report}
              frameborder="0"
            ></iframe>

          </div>
        </Modal.Body>
      </Modal>


      <Modal
        show={shareEmailsShow}
        onHide={() => setShareEmailsShow(false)}
        centered
      >
        <Modal.Header closeButton className="p-0 pb-3">
          <Modal.Title>Share Prescription</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="recommend-box-upper">
            <div className="recommend-box">
              {shareEmails?.map((item) => {
                return (
                  <span
                    className={`${
                      selectedItemTemp?.includes(item) ? "active" : ""
                      } `}
                    onClick={() => onSelectItem(item)}
                  >
                    {item}
                  </span>
                );
              })}
            </div>
            <div className="input-box">
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Label>Add Email</Form.Label>
                  </div>
                  <Form.Control
                    onKeyPress={handleKeyPres1}
                    onChange={(e) => setOnAddValue(e.target.value.trimStart())}
                    value={onAddValue}
                    type="email"
                    placeholder="Press the 'Enter' key to add the email"
                    className="mt-2"
                  />
                  <p className="errors" style={{ color: "red" }}>
                    {emailError}
                  </p>
                </Form.Group>
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={selectedItemTemp.length==0} variant="primary" onClick={() => onShareWithEmail()}>
            Share
          </Button>
        </Modal.Footer>
      </Modal>

    </section>
  );
}

export default Appointment;
