import { useRef, useState, useContext, useEffect } from "react";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import { openAiService } from "../../services/openai.services";
import { pagination } from "../partials/pagination";
import Swal from "sweetalert2";
import Moment from "react-moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import useWebSocket from "react-use-websocket";
import { CalenderView } from "../common/calenderView";
import { upperCaseFirst } from "upper-case-first";
import {
  Accordion,
  Badge,
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  Nav,
  OverlayTrigger,
  Row,
  Tab,
  Tooltip,
} from "react-bootstrap";
import AppointmentTabs from "../partials/tabs";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Loading } from "../common/loader";
import { baseUrl, socketUrl } from "../../config/config";
import Toast from "react-bootstrap/Toast";
import { DateTimeFormatter } from "../common/common";
import Sidebar from "../common/Sidebar";
import moment from "moment";
import { CallLoader } from "../common/CallLoader";

function Dashboard() {
  const [showA, setShowA] = useState(false);
  const [showVideoChatHistory, setShowVideoChatHistory] = useState(false);
  const handleCloseshowVideoChatHistory = () => setShowVideoChatHistory(false);
  // const toggleShowA = (list) => setShowA(!showA);
  const [callLogs, setCallLogs] = useState([]);
  const [activeUpcomingPage, setActiveUpcomingPage] = useState(1);
  const [activePastPage, setActivePastPage] = useState(1);
  const [isLoader, setIsLoader] = useState(false);
  const [callLoader, setCallLoader] = useState(false);
  const [pageno, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const [addActive, setAddActive] = useState("upcoming");
  const navigate = useNavigate();
  const [isUpComingClicked, setIsUpcomingClicked] = useState(true);
  const [isPastClicked, setIsPastClicked] = useState(false);
  const [past, setPast] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [totalPastRecords, setTotalPastRecords] = useState(0);
  const [totalUpcomingRecords, setTotalUpcomingRecords] = useState(0);

  const [appointment, setAppointment] = useState([]);
  const userType = localStorage.getItem("userType");
  const user = localStorage.getItem("user");
  const [searchParams, setSearchParams] = useSearchParams();

  var fromTab = searchParams.get("activeTab");
  var fromPage = searchParams.get("page");
  var dateFrom = searchParams.get("date");
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [age, setAge] = useState(0);
  const [noData, setNoData] = useState(false);
  const no_of_pages = Math.ceil(totalRecords / 10);
  var location = useLocation();
  const [deviceType, setDeviceType] = useState("");
  const [show, setShow] = useState(false);
  const [text, setText] = useState("");
  const [copied, setCopied] = useState(false);
  const [pdfShow, setPdfShow] = useState(false);
  const [selectedHistoryItem, setSelectedHistoryItem] = useState({
    prescription: [],
  });
  const [shareEmailsShow, setShareEmailsShow] = useState(false);
  const [shareEmails, setShareEmails] = useState([]);
  const [selectedItemTemp, setSelectedItemTemp] = useState([]);
  const [onAddValue, setOnAddValue] = useState("");
  const [emailError, setEmailError] = useState("");
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const [activeView, setActiveView] = useState("listView");
  const [dateToShow, setDateToShow] = useState([]);
  const [dataAccordingDate, setDataAccordingDate] = useState([]);
  const [calenderValue, setCalenderValue] = useState(new Date());
  const [calenderViewData, setCalenderViewData] = useState([]);
  const [activeBlock, setActiveBlock] = useState();
  const activeBlockRef = useRef();
  const [viewClicked, setViewClicked] = useState('list');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [calenderDate, setCalenderDate] = useState('');

  const currentDate = new Date();
  var currentMonth;
  var currentYear;
  var cur_date;

  
  if(dateFrom != null && dateFrom != 'null' && dateFrom != undefined && dateFrom != ''){
   cur_date = new Date(dateFrom);
   currentMonth =  cur_date.getMonth() + 1;
   currentYear = cur_date.getFullYear();
   console.log("currentMonth",currentMonth, "currentYear", currentYear)
  }else if(month && year){
      cur_date = new Date(calenderDate);
  }else{
    cur_date = new Date();
    currentMonth = currentDate.getMonth() + 1;
    currentYear = currentDate.getFullYear();
  }


   
  const [loaderText, setLoaderText] = useState("Connecting");

  useEffect(() => {
    if (fromTab && fromPage && fromPage != "null") {
      getAppointments(fromPage, status, true, null);

      if (fromTab === "upcoming") {
        setActiveUpcomingPage(fromPage);
        var tab = document.getElementById("upcomeId");
        tab.click();
        setIsPastClicked(false);
        setIsUpcomingClicked(true);
      } else {
        var past_tab = document.getElementById("pastId");
        past_tab.click();
        setActivePastPage(fromPage);
        setIsPastClicked(true);
        setIsUpcomingClicked(false);
      }
      const myList = document.querySelector(".pagination");
      if (myList) {
        const specificLi = myList.querySelector(`li:nth-child(${fromPage})`);
        if (specificLi) {
          specificLi.classList.add("active");
        }
      }
    } else if (fromTab === "calenderView") {
      setActiveView("calenderView");
      setViewClicked('calender');
      getUpcomingEvents(currentMonth,currentYear,true)
    } else {
      getAppointments(pageno, status, true, null);
    }
  }, []);

  // alert(socketUrl);
  // console.log(localStorage.getItem('token'));
  useEffect(() => {
    if (userType !== "doctor" && user == null) {
      navigate("/login");
    } else if (
      (userType !== "doctor" && user !== null) ||
      (userType === "doctor" && user !== null)
    ) {
      navigate("/247-virtual-assistant");
    }
  }, []);

  // useEffect(() => {
  //   const platform = navigator.platform;

  //   // alert(platform)
  //   setDeviceType(
  //     /iphone|ipad|ipod|android|webos|blackberry|windows phone/.test(
  //       platform
  //     )
  //       ? "mobile"
  //       : "desktop"
  //   );
  // }, []);

  const getUpcomingEvents = (month, year, showLoad) => {
    if(showLoad){
      setIsLoader(true);
    }else{
      setIsLoader(false); 
    }
    var date = cur_date;
    setDataAccordingDate([]);
    openAiService
      .getCalenderEvents(month, year)
      .then((result) => {
        console.log("result?.data?.data", result?.data?.data)
        setCalenderViewData(result?.data?.data);
        if (date.getFullYear() == year && date.getMonth() + 1 == month) {
          var data = result?.data?.data.filter(
            (res) =>
              moment(res.appointment_time).format("YYYY-MM-DD") ==
              moment(date).format("YYYY-MM-DD")
          );

          setDataAccordingDate(data);
          setCalenderValue(date);
        }
        setIsLoader(false);
      })
      .catch((err) => {
        setIsLoader(false);
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const getAppointments = (page, status, loaderr, type) => {
    if (!loaderr) {
      setIsLoader(false);
    } else {
      setIsLoader(true);
    }
     console.log("type---", type)
    if (type) {
      console.log(type)
      setCallLoader(true);
    }
    openAiService
      .getAppointments(page, status,null)
      .then((res) => {
        console.log("appoint time------", res.data);
        const pastData = res.data?.past.data;
        const upcomingData = res.data?.upcoming?.data;
        console.log("upcomingData---------", res?.data);
        setPast(pastData);
        setUpcoming(upcomingData);
        setTotalPastRecords(res.data?.past?.totalCount);
        setTotalUpcomingRecords(res.data?.upcoming?.totalCount);
        setIsLoader(false);
        setCallLoader(false);
        
      })
      .catch((err) => {
        setIsLoader(false);
        if (err?.response?.status === 401) {
          localStorage.clear();
          Swal.fire({
            title: "Error",
            text: " Your session has expired, Please login again.",
            type: "error",
          }).then(() => {
            navigate("/login");
          });
        }
        // Swal.fire("Error", "Something went wrong.", "error");
        console.log("contact us api error", err);
      });
  };

  useWebSocket(socketUrl, {
    onOpen: () => {
      console.log("WebSocket connection established.");
    },
    onMessage: (event) => {
      // upcoming.unshift(JSON.parse(event.data).appointment)
      console.log("njnknk", JSON.parse(event.data));
      if (JSON.parse(event.data).appoint_type == "call_change") {
        var type = JSON.parse(event.data)?.call_data?.status;
        setLoaderText(type);
      
        getAppointments(pageno, status, false, type);
         
        getUpcomingEvents(month,year, false);
        
      } else {
        getAppointments(pageno, status, false, null);
      }

      // console.log('njnknk', JSON.parse(event.data))
    },
    onError: (error) => {
      console.log("error--", error);
    },
    onClose: () => {
      console.log("WebSocket connection disconntected.");
    },
  });

  // const markAsComplete = (id, status) => {
  //   // setIsLoader(true);
  //   const params = { status: status };
  //   openAiService
  //     .markAsComplete(id, params)
  //     .then((res) => {
  //       document
  //         .getElementById("toggle_" + id)
  //         .classList.add("accordion_animate");
  //       var newData = upcoming.filter((items) => items.id !== id);
  //       setUpcoming(newData);
  //       setTotalUpcomingRecords(totalUpcomingRecords - 1);
  //       past.unshift(res.data);
  //       setTotalPastRecords(totalPastRecords + 1);

  //       // setAnimateClass('appointment-content-area accordion_animate')
  //       // setTimeout(() => {
  //       //   document.getElementById(id).remove();
  //       // }, 1000);

  //       // if(appointment.length <= 1){
  //       //   setNoData(true);
  //       // }
  //     })
  //     .catch((error) => {
  //       if (error?.response?.status === 401) {
  //         localStorage.clear();
  //         Swal.fire({
  //           title: "Error",
  //           text: " Your session has expired, Please login again.",
  //           type: "error",
  //         }).then(() => {
  //           navigate("/login");
  //         });
  //       }
  //       console.log("errrrrrr", error);
  //     });
  // };

  const toastUpcomingPhoneShowHide = (appointment_list, index, showHide) => {
    appointment_list.show_call_btn = showHide;
    const upcomingItems = [...upcoming];
    upcomingItems[index] = appointment_list;
    setUpcoming(upcomingItems);
    setCopied(false);
  };

  const toastPastPhoneShowHide = (appointment_list, index, showHide) => {
    appointment_list.show_call_btn = showHide;
    const pastItems = [...past];
    pastItems[index] = appointment_list;
    setPast(pastItems);
    setCopied(false);
  };

  const toastPhoneShowHide = (list, index, type) => {
    list.show_call_btn = type;
    const collection = [...dataAccordingDate];
    collection[index] = list;
    setPast(collection);
    setCopied(false);
  };

  const toggleButton = (id) => {
    console.log(document.getElementById("toggle_" + id));
    var element = document.getElementById("toggle_" + id);
    if (element.classList.contains("open")) {
      element.classList.remove("open");
    } else {
      element.classList.add("open");
    }
  };

  const removeToggle = () => {
    if (addActive === "upcoming") {
      let ele = document.getElementsByClassName("toggle_up");
      for (let i = 0; i < ele.length; i++) {
        ele[i].classList.remove("open");
      }
    } else {
      let ele = document.getElementsByClassName("toggle_past");
      for (let i = 0; i < ele.length; i++) {
        ele[i].classList.remove("open");
      }
    }
  };

  const onShareWithEmail = () => {
    if (selectedItemTemp.length === 0) {
      Swal.fire("error", "Please choose an email.", "error");
    } else {
      setIsLoader(true);
      let data = {
        emails: selectedItemTemp,
        id: selectedHistoryItem?.prescription[0]?.id,
        appointment_id: selectedHistoryItem.id,
      };
      openAiService
        .shareOnEmail(data)
        .then((result) => {
          setIsLoader(false);
          Swal.fire(
            "Success",
            "The prescription has been shared on the mentioned emails.",
            "success"
          ).then(() => {
            setShareEmailsShow(false);
            setSelectedItemTemp([]);
            setShareEmails([]);
          });
        })
        .catch((error) => {
          setIsLoader(false);
          if (error?.response?.status === 401) {
            localStorage.clear();
            Swal.fire({
              title: "Error",
              text: " Your session has expired, Please login again.",
              type: "error",
            }).then(() => {
              navigate("/login");
            });
          }
        });
    }
  };

  const handleKeyPres1 = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!EMAILREGEX.test(onAddValue)) {
        setEmailError("Please enter valid email");
      } else {
        setEmailError("");
        onAddRecommendTest();
      }
    }
  };

  const onAddRecommendTest = () => {
    if (!onAddValue) {
      return;
    }
    setShareEmails((prevItem) => {
      return [...prevItem, onAddValue];
    });
    setSelectedItemTemp((prevSelectedDays) => {
      return [...prevSelectedDays, onAddValue];
    });
    setOnAddValue("");
  };

  const onSelectItem = (item) => {
    setSelectedItemTemp(
      (prevSelectedDays) => {
        const isSelected = prevSelectedDays.includes(item);
        if (isSelected) {
          return prevSelectedDays.filter((selectedDay) => selectedDay !== item);
        } else {
          return [...prevSelectedDays, item];
        }
      },
      () => {
        console.log(item); // Log the current state to check
      }
    );
  };

  const goToUserProfile = (id, type, view, date = null) => {
    if (view == "list") {
      navigate({
        pathname: "/userdetails",
        state: { location },
        search: createSearchParams({
          id: id,
          type: type,
          tabType: addActive,
          fromPage:
            addActive === "upcoming" ? activeUpcomingPage : activePastPage,
        }).toString(),
      });
    } else {
      navigate({
        pathname: "/userdetails",
        state: { location },
        search: createSearchParams({
          id: id,
          type: type,
          tabType: activeView,
          date: date,
        }).toString(),
      });
    }
  };

  const handleClose = () => setShow(false);

  function copyText() {
    var copyText = document.getElementById("copy-phone");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    setCopied(true);
    setShow(false);
  }

  function getDataAccordingToDate(date) {
    
    setCalenderValue(new Date(date));
    const data = calenderViewData.filter(
      (res) =>
        moment(res.appointment_time).format("YYYY-MM-DD") ==
        moment(date).format("YYYY-MM-DD")
    );
    setDataAccordingDate(data);
    if(window.innerWidth < 980){
      window.scrollTo(0, 550);
    }
  }

  function getAge(dob) {
    dob = new Date(dob);
    var month_diff = new Date().getFullYear() - dob.getFullYear();
    var age;
    if (Math.abs(month_diff) == 1) {
      age = "1 year";
    } else {
      age = Math.abs(month_diff) + " years";
    }

    if (Math.abs(month_diff) <= 0) {
      month_diff = new Date().getMonth() - dob.getMonth();
      if (Math.abs(month_diff) > 1) {
        age = month_diff + " months";
      } else {
        age = "1 month";
      }
    }
    //  setAge(age);
    return age;
  }
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View Detail
    </Tooltip>
  );
  const renderTooltip_past = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View Prescription
    </Tooltip>
  );

  const callToAssistant = (id) => {
    openAiService
      .callToAssistant(id)
      .then((res) => {
        console.log("result------", res);
      })
      .catch((err) => {
        Swal.fire("error", "Call not connected due to low balance, please recharge your account.", "error")
        console.log("err-------", err);
      });
  };

  const getDuration = (duration) => {
    var time = parseInt(duration);
    var result = 0 + " Second";
    if (time >= 60 && time < 3600) {
      result =
        parseInt(time / 60) > 1
          ? parseInt(time / 60) + " Minutes"
          : parseInt(time / 60) + " Minute";
    } else if (time >= 3600) {
      result =
        parseInt(time / 3600) > 1
          ? parseInt(time / 3600) + " Hours"
          : parseInt(time / 3600) + " Hour";
    } else if (time > 0 && time < 60) {
      result = time > 1 ? time + " Seconds" : time + " Second";
    }

    return result;
  };

  const renderTooltip_13 = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        Call
      </Tooltip>
    );
  };
  const renderTooltip_calendner = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Calendar View
    </Tooltip>
  );
  const renderTooltip_list = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      List View
    </Tooltip>
  );

  const renderTooltip_14 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Appointment
    </Tooltip>
  );
  const renderTooltip_16 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Call History
    </Tooltip>
  );

  const viewChange = (type) => {
    if (type == "calenderView") {
      if(viewClicked !== 'calender'){
        getUpcomingEvents(currentMonth, currentYear,true);
        getDataAccordingToDate(new Date());
      }
      setViewClicked('calender');
    }else{
      if(viewClicked !== 'list'){
        getAppointments(pageno, status, true, null);
      } 
      setViewClicked('list');
    }
    setActiveView(type);
  };

  const getCallLogs = (id) => {
    setIsLoader(true)
    openAiService
      .getCallLogs(id)
      .then((res) => {
        setIsLoader(false)  
        setCallLogs(res.data?.data);
        activeBlockRef.current = res.data?.data[0]?.call_id;
        // setActiveBlock(res.data?.data[0]?.call_id)
        if (res.data?.data.length > 0) {
          setShowVideoChatHistory(true);
        }
        console.log("calllogas", res.data);
      })
      .catch((err) => {
        console.log("calllogas eerr", err);
      });
  };

  return (
    <section className="pricing-area under-appointment dashboard-appointment-section">
      <Row className="justify-content-center">
        {isLoader && <Loading />}
        {callLoader && <CallLoader text={loaderText} />}
        <Col md={12} className="p-0">
          <div className="profile-content-area">
            <div className="appointment-boxes">
              <div className="container">
                <div className="appointment-area">
                  <h2>My Appointments</h2>
                  <div className="list-view-box">
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip_list}
                    >
                      <a
                        onClick={() => {
                          viewChange("listView")
                      }}
                        className={
                          activeView == "listView"
                            ? "active list-inner"
                            : "list-inner"
                        }
                      >
                        <img
                          src={
                            require("../../assests/images/list-view.svg")
                              .default
                          }
                          alt="img"
                          className="dark-view-no-active"
                        />
                        <img
                          src={
                            require("../../assests/images/list-view-dark.svg")
                              .default
                          }
                          alt="img"
                          className="dark-view"
                        />
                      </a>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip_calendner}
                    >
                      <a
                        onClick={() => {
                          viewChange("calenderView")}}
                        className={
                          activeView == "calenderView"
                            ? "active list-inner"
                            : "list-inner"
                        }
                      >
                        <img
                          src={
                            require("../../assests/images/calender-view.svg")
                              .default
                          }
                          alt="img"
                          className="dark-view-no-active"
                        />
                        <img
                          src={
                            require("../../assests/images/calender-view-dark.svg")
                              .default
                          }
                          alt="img"
                          className="dark-view"
                        />
                      </a>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>
            {activeView == "listView" && (
              <div className="outer-dashboard-section">
                <div className="container">
                  <div className="appointment-area">
                    <ul>
                      <li
                        id="upcomeId"
                        className={addActive === "upcoming" ? "active" : ""}
                        onClick={(e) => {
                          setIsUpcomingClicked(true);
                          setIsPastClicked(false);
                          setAddActive("upcoming");
                          setCopied(false);
                          removeToggle();
                        }}
                      >
                        Upcoming
                      </li>
                      <li
                        id="pastId"
                        onClick={(e) => {
                          setIsUpcomingClicked(false);
                          setIsPastClicked(true);
                          setAddActive("past");
                          setCopied(false);
                          removeToggle();
                        }}
                        className={addActive === "past" ? "active" : ""}
                      >
                        Past
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}

            {
              activeView == "listView" ? (
                isUpComingClicked && upcoming && upcoming.length > 0 ? (
                  upcoming.map((result, index) => {
                    console.log("dasda", result);
                    return (
                      <div className="outer-width-main">
                        <div className="">
                          <div className="width-wise-dashboard">
                            <div
                              class="appointments_box request_appointments_box doctor-side toggle_up "
                              id={"toggle_" + result.id}
                            >
                              <div class="appointment_innerbox">
                                <div className="appointment-content-image">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      goToUserProfile(
                                        result.id,
                                        result.member != null ? "someone" : "me"
                                      );
                                    }}
                                  >
                                    <img
                                      src={
                                        result?.user?.profile_image
                                          ? baseUrl +
                                            result?.user?.profile_image
                                          : result?.user?.picture
                                      }
                                      referrerpolicy="no-referrer"
                                      alt=""
                                    />
                                  </a>
                                </div>
                                <div className="appointment-content-text">
                                  <h3
                                    class="category_name"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      goToUserProfile(
                                        result.id,
                                        result.member != null ? "someone" : "me"
                                      );
                                    }}
                                  >
                                    <b>{result.user.name}</b>
                                  </h3>
                                  <p>
                                    Insurance Provider:{" "}
                                    <span>
                                      {result.insurance_type === "provider"
                                        ? result?.insurance_provider
                                        : result?.insurance_type
                                            ?.charAt(0)
                                            .toUpperCase() +
                                          result?.insurance_type.slice(1)}
                                    </span>
                                  </p>
                                  <p class="short_info purple-txt">
                                    Reason For Visit: <br />
                                    <span>{result.reason}</span>
                                  </p>

                                  <div class="appointment_rightarea text-right">
                                    <p>
                                      <DateTimeFormatter
                                        date={result.appointment_time}
                                      />
                                    </p>
                                    {/* <p><Moment format="DD MMM YYYY hh:mm ">
                            {result.appointment_time}</Moment></p> */}
                                    {/* <h3 class="process_text waiting">Waiting</h3> */}
                                    <span
                                      class="appointment_togglebtn my-2"
                                      onClick={() => {
                                        toggleButton(result.id);
                                      }}
                                    >
                                      <i
                                        class="fa fa-chevron-down"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <div className="icons-left ">
                                      <div className="d-flex">
                                      
                                        {result?.call?.can_call &&
                                          result?.call?.have_log && (
                                            <OverlayTrigger
                                              placement="top"
                                              delay={{ show: 250, hide: 400 }}
                                              overlay={renderTooltip_16}
                                            >
                                              <a
                                                class="call-history-box"
                                                onClick={(e) => {
                                                  getCallLogs(result.id);
                                                }}
                                              >
                                                <img
                                                  src={
                                                    require("../../assests/images/call-history.svg")
                                                      .default
                                                  }
                                                  alt="img"
                                                  className="call-history-icon"
                                                />
                                              </a>
                                            </OverlayTrigger>
                                          )}

                                          <a
                                            class="cc-calto-action-ripple "
                                            title={
                                              !result?.call?.can_call
                                                ? "On Call"
                                                : "Call"
                                            }
                                            onClick={
                                              !result?.call?.can_call
                                                ? () => {
                                                    return false;
                                                  }
                                                : () => {
                                                    callToAssistant(result.id);
                                                  }
                                            }
                                          >
                                            <i
                                              class={`fa fa-phone ${
                                                !result?.call?.can_call
                                                  ? "disable-call"
                                                  : ""
                                              }`}
                                            ></i>
                                          </a>
                                        {/* </OverlayTrigger> */}
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={renderTooltip}
                                        >
                                          <a
                                            className="download_box"
                                            onClick={() => {
                                              goToUserProfile(
                                                result.id,
                                                result.member != null
                                                  ? "someone"
                                                  : "me"
                                              );
                                            }}
                                          >
                                            <i class="fa fa-eye"></i>
                                          </a>
                                        </OverlayTrigger>
                                        
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="appointment_fullcontent">
                                <p className="purple-txt">Reason For Visit: </p>
                                <span>{result.reason}</span>
                                <p className="purple-txt mb-0 mt-2">
                                  Patient:{" "}
                                </p>
                                <span>
                                  {result.member
                                    ? result.member.name
                                    : result.user.name}
                                  ,{" "}
                                  {result.member
                                    ? getAge(result.member.dob)
                                    : getAge(result.user.dob)}
                                  ,{" "}
                                  {result.member
                                    ? result.member.gender
                                    : result.user.gender}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : isPastClicked && past && past?.length > 0 ? (
                  past.map((result, index) => {
                    console.log("result past", result);
                    return (
                      <div className="outer-width-main">
                        <div className="width-wise-dashboard">
                          <div
                            class="appointments_box request_appointments_box doctor-side toggle_past width-wise-dashboard-left"
                            id={"toggle_" + result.id}
                          >
                            <div class="appointment_innerbox">
                              <div className="appointment-content-image">
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    goToUserProfile(
                                      result.id,
                                      result.member != null ? "someone" : "me",
                                      "list"
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      result?.user?.profile_image
                                        ? baseUrl + result?.user?.profile_image
                                        : result?.user?.picture
                                    }
                                    referrerpolicy="no-referrer"
                                    alt=""
                                  />
                                </a>
                              </div>
                              <div className="appointment-content-text">
                                <h3
                                  class="category_name"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    goToUserProfile(
                                      result.id,
                                      result.member != null ? "someone" : "me",
                                      "list"
                                    );
                                  }}
                                >
                                  <b>{result.user.name}</b>
                                </h3>
                                <p>
                                  Insurance Provider:{" "}
                                  <span>
                                    {result.insurance_type === "provider"
                                      ? result?.insurance_provider
                                      : result?.insurance_type
                                          ?.charAt(0)
                                          .toUpperCase() +
                                        result?.insurance_type.slice(1)}
                                  </span>
                                </p>
                                <p class="short_info purple-txt">
                                  Reason For Visit: <br />
                                  <span>{result.reason}</span>
                                </p>

                                <div class="appointment_rightarea text-right">
                                  <p>
                                    <DateTimeFormatter
                                      date={result.appointment_time}
                                    />
                                  </p>
                                  <span
                                    class="appointment_togglebtn my-2"
                                    onClick={() => {
                                      toggleButton(result.id);
                                    }}
                                  >
                                    <i
                                      class="fa fa-chevron-down"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <div className="icons-left">
                                    <div className="d-flex">
                                  
                                      {result?.call?.can_call &&
                                        result?.call?.have_log && (
                                          <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip_16}
                                          >
                                            <a
                                              class="call-history-box"
                                              onClick={(e) => {
                                                getCallLogs(result.id);
                                              }}
                                            >
                                              <img
                                                src={
                                                  require("../../assests/images/call-history.svg")
                                                    .default
                                                }
                                                alt="img"
                                                className="call-history-icon"
                                              />
                                            </a>
                                          </OverlayTrigger>
                                        )}
                                      {/* <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip_13}
                                      > */}
                                        <a
                                          class="cc-calto-action-ripple "
                                          title={
                                            !result?.call?.can_call
                                              ? "On Call"
                                              : "Call"
                                          }
                                          onClick={
                                            result?.call?.can_call
                                              ? (e) => {
                                                  callToAssistant(result.id);
                                                }
                                              : (e) => {
                                                  return false;
                                                }
                                          }
                                        >
                                          <i
                                            class={`fa fa-phone ${
                                              !result?.call?.can_call
                                                ? "disable-call"
                                                : ""
                                            }`}
                                          ></i>
                                        </a>
                                      {/* </OverlayTrigger> */}

                                      {result?.prescription[0]
                                        ?.prescription_report && (
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 250, hide: 400 }}
                                          title={"View Prescription"}
                                          overlay={renderTooltip_past}
                                        >
                                          <a
                                            className="download_box "
                                            onClick={() => {
                                              setPdfShow(true);
                                              setSelectedHistoryItem(result);
                                            }}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <i
                                              class="fa fa-file-text-o"
                                              aria-hidden="true"
                                            ></i>
                                          </a>
                                        </OverlayTrigger>
                                      )}
                                    </div>
                                     {result.status == 'not-attended' ?
                                       <Badge
                                       bg="warning"
                                       className="completed-text"
                                     >
                                     Not-Attended
                                     </Badge>

                                     : <Badge
                                     bg="success"
                                     className="completed-text"
                                   >
                                    Completed
                                   </Badge>}
                                      
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="appointment_fullcontent">
                              <p className="purple-txt">Reason For Visit: </p>
                              <span>{result.reason}</span>
                              <p className="purple-txt mb-0 mt-2">Patient: </p>
                              <span>
                                {result.member
                                  ? result.member.name
                                  : result.user.name}
                                ,{" "}
                                {result.member
                                  ? getAge(result.member.dob)
                                  : getAge(result.user.dob)}
                                ,{" "}
                                {result.appointed_user
                                  ? result?.member?.gender
                                      ?.charAt(0)
                                      ?.toUpperCase() +
                                    result?.member?.gender.slice(1)
                                  : result?.user?.gender
                                      ?.charAt(0)
                                      ?.toUpperCase() +
                                    result?.user?.gender.slice(1)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="appointment-content-area-image">
                    <div className="outer-no-data">
                      <img
                        className="no-data"
                        src={require("../../assests/images/no_data.gif")}
                        alt=""
                      />
                      <p>No Data Found</p>
                    </div>
                  </div>
                )
              ) : (
                <CalenderView
                  calenderData={(e) => {
                    setCalenderDate(e);
                    setMonth(new Date(e).getMonth() + 1);
                    setYear(new Date(e).getFullYear());
                    getDataAccordingToDate(e)}}
                  value={calenderValue}
                  upcomingEvent={(month, year) =>
                    { 
                      
                      getUpcomingEvents(month, year,true)}
                  }
                  result={calenderViewData}
                  data={dataAccordingDate}
                  profile={(id, type, view, date) =>
                    goToUserProfile(id, type, view, date)
                  }
                  toggle={(id) => toggleButton(id)}
                  tool_1={() => renderTooltip_13()}
                  tool_2={() => renderTooltip()}
                  showHide={(data, i, type) =>
                    toastPhoneShowHide(data, i, type)
                  }
                  copy={() => copyText()}
                  duplicate={copied}
                  age={(dob) => getAge(dob)}
                  callLogs={(id) => {getCallLogs(id)}}
                  callTo={(id) => {callToAssistant(id)}}
                />
              )

              // <div className="calender-view-section">
              //   {/* <div className="outer-dashboard-section">
              //     <div className="container">
              //       <div className="appointment-area">
              //         <ul>
              //           <li id="upcomeId" className={addActive === 'upcoming' ? 'active' : ''} onClick={(e) => {
              //             setIsUpcomingClicked(true);
              //             setIsPastClicked(false);
              //             setAddActive("upcoming");
              //             setCopied(false)
              //             removeToggle();
              //           }}>
              //             Upcoming
              //           </li>
              //           <li id="pastId" onClick={(e) => {
              //             setIsUpcomingClicked(false);
              //             setIsPastClicked(true);
              //             setAddActive("past");
              //             setCopied(false)
              //             removeToggle();
              //           }} className={addActive === 'past' ? 'active' : ''}
              //           >
              //             Past
              //           </li>
              //         </ul>
              //       </div>
              //     </div>
              //   </div> */}
              //   <div className="container">
              //     <div className="row">
              //       <aside className="col-lg-5 col-md-12">
              //         <div className="calender-boxes">
              //           <Calendar onChange={(e) => {
              //              getDataAccordingToDate(e);
              //           }}
              //           value={calenderValue}
              //           onActiveStartDateChange={({ action, activeStartDate, value, view }) =>
              //              {let month = (new Date(activeStartDate).getMonth() + 1);
              //               let year = (new Date(activeStartDate).getFullYear());
              //                getUpcomingEvents(month, year);
              //             }
              //         }
              //           tileContent={(e) => {show_title(e.date)}}
              //            />
              //         </div>
              //       </aside>
              //       <aside className="col-lg-7 col-md-12">
              //         <div className="appointment-list-view">
              //           <h4 className="mb-4">Appointment Schedule <span className="purple-txt">({dataAccordingDate.length})</span></h4>
              //            {dataAccordingDate.length > 0 ?
              //             dataAccordingDate.map((res,index) => {
              //               return (<div class="appointments_box request_appointments_box doctor-side toggle_up" id={'toggle_'+res.id}>
              //               <div class="appointment_innerbox">
              //                 <div className="appointment-content-image">
              //                   <a style={{ cursor: "pointer" }} onClick={() => { goToUserProfile(res.id, res.member != null ? "someone" : "me",'calender', res.appointment_time) }}>
              //                     <img src={res?.user?.profile_image ? baseUrl + res?.user?.profile_image : res?.user?.picture} referrerpolicy="no-referrer" alt="" />
              //                   </a>
              //                 </div>
              //                 <div className="appointment-content-text">
              //                   <h3 class="category_name" style={{ cursor: "pointer" }} onClick={() => { goToUserProfile(res.id, res.member != null ? "someone" : "me", 'calender',res.appointment_time) }}><b>{res.name}</b></h3>
              //                   <p class="short_info purple-txt" >Reason For Visit: <br /><span>{res.reason}</span></p>

              //                   <div class="appointment_rightarea text-right">
              //                     <p>
              //                       <DateTimeFormatter date={res.appointment_time} />
              //                     </p>
              //                     <span class="appointment_togglebtn my-2" onClick={() => {
              //                       toggleButton(res.id)
              //                     }} ><i class="fa fa-chevron-down" aria-hidden="true" ></i></span>
              //                     <div className="icons-left">

              //                       <OverlayTrigger
              //                         placement="top"
              //                         delay={{ show: 250, hide: 400 }}
              //                         overlay={renderTooltip_13}
              //                       >
              //                         <a class="cc-calto-action-ripple" onClick={() => toastPhoneShowHide(res, index, true)}  ><i class="fa fa-phone"></i></a>
              //                       </OverlayTrigger>
              //                       <OverlayTrigger
              //                         placement="top"
              //                         delay={{ show: 250, hide: 400 }}
              //                         overlay={renderTooltip}
              //                       >
              //                         <a className="download_box" onClick={() => { goToUserProfile(res.id, res.member != null ? "someone" : "me", 'calender', res.appointment_time) }}>
              //                           <i class="fa fa-eye"></i>
              //                         </a>
              //                       </OverlayTrigger>
              //                       <Toast show={res.show_call_btn == true} onClose={() => toastPhoneShowHide(res, index, false)}>
              //                         <Toast.Header>
              //                           <strong className="me-auto">Call</strong>

              //                         </Toast.Header>
              //                         {copied ? <p> <img
              //                           src={require("../../assests/images/check-main.png")}
              //                           alt="img"
              //                         />Copied</p> : ""}
              //                         <Toast.Body>

              //                           <input
              //                             type="text"
              //                             id="copy-phone"
              //                             value={res.phone}
              //                             readOnly
              //                           ></input>

              //                           <i class="fa fa-clone" onClick={() => {
              //                             copyText
              //                               ()
              //                           }} aria-hidden="true"></i>
              //                         </Toast.Body>
              //                       </Toast>

              //                     </div>
              //                   </div>
              //                 </div>
              //               </div>
              //               <div class="appointment_fullcontent">
              //                 <p className="purple-txt">Reason For Visit: </p>
              //                 <span>{res.reason}</span>
              //                 <p className="purple-txt mb-0 mt-2">Patient: </p>
              //                 <span>{res.member ? res.member.name : res.user.name}, {res.member ? getAge(res.member.dob) : getAge(res.user.dob)},{" "}{res.member ? res.member.gender : res.user.gender}</span>
              //               </div>
              //             </div>)
              //             }) : "No Data Found"
              //            }
              //         </div>
              //       </aside>
              //     </div>
              //   </div>
              // </div>
            }

            {isPastClicked &&
              pagination.setPastPagination(
                "my_appointment",
                activePastPage,
                Math.ceil(totalPastRecords / 10),
                status,
                setStatus,
                setPast,
                setActivePastPage,
                setIsLoader,
                totalPastRecords
              )}

            {isUpComingClicked &&
              activeView == "listView" &&
              pagination.setUpcomingPagination(
                "my_appointment",
                activeUpcomingPage,
                Math.ceil(totalUpcomingRecords / 10),
                status,
                setStatus,
                setUpcoming,
                setActiveUpcomingPage,
                setIsLoader,
                totalUpcomingRecords
              )}
          </div>
        </Col>
      </Row>

      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Copy Text</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="text" value={text} readOnly id="copy_text"></input>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" title={"click to copy text"} onClick={copyText}>
            Copy
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal
        className="success-box pdf-view-modal"
        backdrop="static"
        show={pdfShow}
        onHide={() => setPdfShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pdf-view-inner">
            <a
              onClick={() => setShareEmailsShow(true)}
              className="add-btn share-b"
            >
              <i class="fa fa-share-alt" aria-hidden="true"></i>
            </a>
            {/* {console.log("selectedHistoryItem---",selectedHistoryItem)} */}
            <iframe
              src={
                baseUrl +
                "/" +
                selectedHistoryItem?.prescription[0]?.prescription_report
              }
              frameborder="0"
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={shareEmailsShow}
        onHide={() => setShareEmailsShow(false)}
        centered
      >
        <Modal.Header closeButton className="p-0 pb-3">
          <Modal.Title>Share Prescription</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="recommend-box-upper">
            <div className="recommend-box">
              {shareEmails?.map((item) => {
                return (
                  <span
                    className={`${
                      selectedItemTemp?.includes(item) ? "active" : ""
                    } `}
                    onClick={() => onSelectItem(item)}
                  >
                    {item}
                  </span>
                );
              })}
            </div>
            <div className="input-box">
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Label>Add Email</Form.Label>
                  </div>
                  <Form.Control
                    onKeyPress={handleKeyPres1}
                    onChange={(e) => setOnAddValue(e.target.value.trimStart())}
                    value={onAddValue}
                    type="email"
                    placeholder="Press the 'Enter' key to add the email"
                    className="mt-2"
                  />
                  <p className="errors" style={{ color: "red" }}>
                    {emailError}
                  </p>
                </Form.Group>
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={selectedItemTemp.length == 0}
            variant="primary"
            onClick={() => onShareWithEmail()}
          >
            Share
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showVideoChatHistory}
        onHide={handleCloseshowVideoChatHistory}
        size="lg"
        className="video-history-popup-box"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>View Call History</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={callLogs && callLogs[0]?.call_id}
          >
            <Row>
              <Col md={4} lg={3}>
                <div className="call-history-tab-left">
                  <Nav
                    variant="pills"
                    className="flex-column"
                    defaultActiveKey={callLogs && callLogs[0]?.call_id}
                    activeKey={callLogs && callLogs[0]?.call_id}
                  >
                    {callLogs?.length > 0 &&
                      callLogs.map((item, index) => {
                        return (
                          <>
                            <div
                              className={
                                item.call_id == activeBlockRef.current
                                  ? "call-history active"
                                  : "call-history"
                              }
                            >
                              <Nav.Item>
                                <Nav.Link
                                  onClick={() => {
                                    setActiveBlock(item.call_id);
                                    activeBlockRef.current = item.call_id;
                                    console.log(
                                      "item.call_id",
                                      activeBlock,
                                      activeBlockRef.current
                                    );
                                  }}
                                  eventKey={item.call_id}
                                >
                                  <div className="call-link">
                                    Call {index + 1}
                                    <span>
                                      <DateTimeFormatter
                                        date={item.created_at}
                                      />
                                    </span>
                                  </div>
                                  <p>{upperCaseFirst(item?.status)}</p>
                                  <p className="duration">
                                    <span>Duration:</span>
                                    {getDuration(item.duration)}
                                  </p>
                                </Nav.Link>
                              </Nav.Item>
                            </div>
                          </>
                        );
                      })}
                  </Nav>
                </div>
              </Col>
              <Col md={8} lg={9}>
                <Tab.Content className="content-tab-bottom">
                  {callLogs?.length > 0 &&
                    callLogs.map((item, index) => {
                      return (
                        <>
                          <Tab.Pane eventKey={item.call_id}>
                            <div id={item.call_id} class="chatbox-body p-0">
                              {item.call_messages.map(
                                (innerItem, innerIndex) => {
                                  if (item.call_messages?.length == 1) {
                                    return (
                                      <div className="no-call-histroy">
                                        <img
                                          src={require("../../assests/images/no-call-history.svg").default}
                                          alt="img"
                                        />
                                        <p>No response received from the user</p>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <>
                                        <div
                                          class={
                                            innerItem.role == "assistant"
                                              ? "left-chat-box"
                                              : "right-chat-box"
                                          }
                                        >
                                          <p
                                            class={
                                              innerItem.role == "assistant"
                                                ? "prompt-heading"
                                                : ""
                                            }
                                          >
                                            {innerItem.content}
                                          </p>
                                        </div>
                                      </>
                                    );
                                  }
                                }
                              )}
                            </div>
                          </Tab.Pane>
                        </>
                      );
                    })}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default Dashboard;
