import { useState } from "react";
import {
  Button, Col, Container, Modal, Row
} from "react-bootstrap";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

const Setting = () => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [value, onChange] = useState('10:00');

  const [availability, setAvailability] = useState(true);
  const days = ['Monday','Tuesday', 'Wednesday', 'Thrusday', 'Friday','Saturday','Sunday'];
  // const [mondayAvailability, setMondayAvailability] = useState(true);

   const changeStatus = (i,status) => {
     var element = document.getElementById('check_'+i);
     console.log(element.checked);   
   }

  return (
    <>
      <section className="setting-area">
        <Container>
          <div className="setting-box">
            <Row>
              <Col md={5}>
                <div className="setting-card">
                  <img className="no-data" src={require("../../assests/images/doctor.png")} alt="" />
                  <h4><b>Adam Smith</b></h4>
                  <p><b>Eamil:</b> adamsmith@yopmail.com</p>
                  <p><b>Working Hours:</b> 24 hr</p>
                  <p><b>Phone:</b>+91 454 455 5668</p>
                  <p><b>Address: </b> A1, 1, Udyog Sadan, Midc, Andheri (west) Mumbai</p>
                </div>
              </Col>
              <Col md={7}>
                <div className="setting-card">
                  <img className="no-data" src={require("../../assests/images/book.svg").default} alt="" />
                  <buton btn btn-primary className="btn-grad p-2 mt-3"
                 onClick={handleShow} style={{ cursor: "pointer" }}>
            Add notes</buton>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <Modal show={show} onHide={handleClose} className="appointment-box">
        <Modal.Header closeButton>
          <Modal.Title>Opening Hours</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="appointent-body">
           
           {days.map((result,index) => {
             return (<div className="appointment-boxes-inner">
             <Row className="align-items-center">
               <Col md={2}>
                 <p>{result}</p>
               </Col>
               <Col md={9}>
                 <div className="d-flex">
                 <div className="position-relative">
                     <input type="text" className="form-control" placeholder="Start Date" />
                     <i class="fa fa-clock-o" aria-hidden="true"></i>
                   </div>
                   <div className="position-relative">
                     <input type="text" className="form-control" placeholder="End Date" />
                     <i class="fa fa-clock-o" aria-hidden="true"></i>
                   </div>
                   <div className="position-relative">
                     <input type="text" className="form-control" placeholder="Start Date" />
                     <i class="fa fa-clock-o" aria-hidden="true"></i>
                   </div>
                   <div className="position-relative">
                     <input type="text" className="form-control" placeholder="End Date" />
                     <i class="fa fa-clock-o" aria-hidden="true"></i>
                   </div>
                 </div>
               </Col>
               <Col md={1}>
                 <div class="form-check form-switch">
                   <input class="form-check-input" id={'check_'+index} onChange={() => {
                     changeStatus(index,availability);
                   }}  type="checkbox" role="switch"  />
                 </div>
               </Col>
             </Row>
           </div>)
           })}
            
           
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      </section>
    </>
  );
};
export default Setting;
