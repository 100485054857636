const { Modal } = require("react-bootstrap");

 export const ShowPdf = (props) => {

    return (
        <Modal
        className="success-box pdf-view-modal"
        backdrop="static"
        show={props.open}
        onHide={props.onClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pdf-view-inner">
          {/* <a
              onClick={props.showShare}
              className="add-btn share-b"
            >
              <i class="fa fa-share-alt" aria-hidden="true"></i>
            </a> */}
            <iframe
              title="pdf"
              src={props.data}
              frameborder="0"
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>
    )
}