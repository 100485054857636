import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const AppointmentTabs = () => {
  return (
    <Tabs
      defaultActiveKey="upcoming"
      transition={false}
      id="noanim-tab-example"
      className="mb-3"
    >
      <Tab eventKey="upcoming" title="Upcoming">
        Upcoming fgfdgfd
      </Tab>
      <Tab eventKey="past" title="Past">
        Past
      </Tab>
    </Tabs>
  );
}

export default AppointmentTabs;