import Pagination from 'react-bootstrap/Pagination';
import { openAiService } from "../../services/openai.services";

export const pagination = {
  setUpcomingPagination,
  setPastPagination,
  setReportPagination
}
const setPastStates = (type,number,status,setStatus,setPast,setActivePage,setIsLoader) =>{
  // setPage(number);
  setStatus('completed');
  setActivePage(number);
    setIsLoader(true)
    openAiService.getAppointments(number,'completed','not-attended').then(res=>{
      console.log('result-------', res.data?.data);
      setPast(res.data?.data)
      setIsLoader(false)
      // setTotalRecords(res.data.totalCount);
    }).catch((error) =>{
      if(error?.response?.status === 401){
        localStorage.clear()
        window.location.href = '/login';
        }
       console.log('errrrrrr', error)
     }) 

}


const setUpcomingStates = (type,number,status,setStatus,setUpcoming,setActivePage,setIsLoader) =>{
  setStatus('pending');
    setActivePage(number);
    setIsLoader(true)
    openAiService.getAppointments(number,'pending',null).then(res=>{
      console.log('result-------', res.data?.data);
      setUpcoming(res.data?.data)
      setIsLoader(false)
      // setTotalRecords(res.data.totalCount)
    }).catch((error) =>{
      if(error?.response?.status === 401){
        localStorage.clear()
        window.location.href = '/login';
        }
       console.log('errrrrrr', error)
     }) 

}

function setPastPagination(type,activeTab,count,status,setStatus,setPast,setActivePage,setIsLoader,total){
  console.log(total);
  
  let items = [];
  if(total > 10){
  for (let number = 1; number <= count; number++) {
    
      let active = activeTab;
      items.push(
        <Pagination.Item key={number} active={number === active} onClick={(e)=>{
          setPastStates(type,number,status,setStatus,setPast,setActivePage,setIsLoader)
        }}>
          {number}
        </Pagination.Item>,
      );
    
    
  }
}

  return (
    <div>
      <Pagination>{items}</Pagination>
    </div>
  );
}


function setUpcomingPagination(type,activeTab,count,status,setStatus,setAppointment,setActivePage,setIsLoader,total){
  
  let items = [];
  if(total > 10){
  for (let number = 1; number <= count; number++) {
    
      let active = activeTab;
      items.push(
        <Pagination.Item key={number} active={number === active} onClick={(e)=>{
          setUpcomingStates(type,number,status,setStatus,setAppointment,setActivePage,setIsLoader)
        }}>
          {number}
        </Pagination.Item>,
      );
    
  }
  }

  return (
    <div>
      <Pagination>{items}</Pagination>
    </div>
  );
}


function getReports(number,setReport,setActivePage,setIsLoader,setPageNo){
  setActivePage(number)
  setPageNo(number)
  setIsLoader(true);
  openAiService.getAllReports(number)
  .then((res) => {
    setReport(res.data?.data)
    console.log("report data", res.data?.data)
    setIsLoader(false);
  })
  .catch((err) => {
    console.log('err----', err)
  })
}


function setReportPagination(count,total,setReport,activePage, setActivePage, setIsLoader, setPageNo){
  console.log("setReportPagination", total)
  let items = [];
  if(total > 5){
  for (let number = 1; number <= count; number++) {
    
      let active = activePage;
      items.push(
        <Pagination.Item key={number} active={number === active} onClick={() => {getReports(number,setReport,setActivePage,setIsLoader,setPageNo)}} >
          {number}
        </Pagination.Item>,
      );
    
  }
  }

  return (
    <div>
      <Pagination>{items}</Pagination>
    </div>
  );
}

function setCalenderPagination(count,total,setReport,activePage, setActivePage, setIsLoader){
  console.log("setReportPagination", total)
  let items = [];
  if(total > 5){
  for (let number = 1; number <= count; number++) {
    
      let active = activePage;
      items.push(
        <Pagination.Item key={number} active={number === active} onClick={() => {getReports(number,setReport,setActivePage,setIsLoader)}} >
          {number}
        </Pagination.Item>,
      );
    
  }
  }

  return (
    <div>
      <Pagination>{items}</Pagination>
    </div>
  );
}
