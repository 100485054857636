import React, { useEffect, useState } from "react";
import moment from 'moment-timezone';
import Swal from "sweetalert2";
import { Col, Container, Dropdown, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { openAiService } from "../../services/openai.services";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import Checkbox from '@mui/material/Checkbox';
import { Loading } from "../common/loader";
import { useNavigate } from "react-router-dom";

function DoctorAvailability() {
    const slotIntervals = [
        { label: "15 minutes", value: 15 },
        { label: "30 minutes", value: 30 },
        { label: "45 minutes", value: 45 },
        { label: "1 hour", value: 60 },
    ];
    const userType = localStorage.getItem('userType');
    const user = localStorage.getItem('user');
    const navigate = useNavigate();

    useEffect(() => {
        if (userType !== 'doctor' && user == null) {
            navigate("/login")
        } else if ((userType !== 'doctor' && user !== null) || (userType === 'doctor' && user !== null)) {
            navigate("/247-virtual-assistant")
        }
    }, [])
    const initialSlots = [
        {
            day: "MONDAY",
            enabled: true,
            time_slot: [
                {
                    start_time: "00:00",
                    end_time: "00:45"
                }
            ]
        },
        {
            day: "TUESDAY",
            enabled: true,
            time_slot: [
                {
                    start_time: "00:00",
                    end_time: "00:45"
                }
            ]
        },
        {
            day: "WEDNESDAY",
            enabled: true,
            time_slot: [
                {
                    start_time: "00:00",
                    end_time: "00:45"
                }
            ]
        },
        {
            day: "THURSDAY",
            enabled: true,
            time_slot: [
                {
                    start_time: "00:00",
                    end_time: "00:45"
                }
            ]
        },
        {
            day: "FRIDAY",
            enabled: true,
            time_slot: [
                {
                    start_time: "00:00",
                    end_time: "00:45"
                }
            ]
        },
        {
            day: "SATURDAY",
            enabled: true,
            time_slot: [
                {
                    start_time: "00:00",
                    end_time: "00:45"
                }
            ]
        },
        {
            day: "SUNDAY",
            enabled: true,
            time_slot: [
                {
                    start_time: "00:00",
                    end_time: "00:45"
                }
            ]
        },
    ];
    const DaysOfWeek = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

    function formatTime(time) {
        return time.slice(0, 5); // Extract the first 5 characters
    }
    useEffect(() => {
        openAiService
            .getSchedule()
            .then((result) => {
                setIsLoader(false)
                const formattedSlots = result?.data?.data?.day_schedule.map(slot => {
                    const formattedTimeSlot = slot.time_slot.map(timeSlot => ({
                        start_time: formatTime(timeSlot.start_time),
                        end_time: formatTime(timeSlot.end_time)
                    }));
                    return {
                        ...slot,
                        time_slot: formattedTimeSlot
                    };
                });
                setDoctorSlot(formattedSlots)
                setIntervals(result?.data?.data?.slot_interval)
            })
            .catch((err) => {
                setIsLoader(false)
                console.log(err);
            }).finally(() => {
                setIsLoader(false)
            })
    }, [])

    const [selectedDays, setSelectedDays] = useState([]);
    const [dropdownOpenIndex, setDropdownOpenIndex] = useState();
    const [doctorSlot, setDoctorSlot] = useState(initialSlots)
    const [intervals, setIntervals] = useState(45)
    const [isLoader, setIsLoader] = useState(true)

    // enable day
    const onCheckboxSelected = (index) => {
        let array = [...doctorSlot]
        array[index].enabled = !array[index].enabled
        setDoctorSlot(array)
    }

    function calculateTimeDifferenceInDay(startTime, endTime) {
        const [startHour, startMinute] = startTime.split(':').map(Number);
        const [endHour, endMinute] = endTime.split(':').map(Number);
        const startMinutes = startHour * 60 + startMinute;
        const endMinutes = endHour * 60 + endMinute;
        let timeDifference = endMinutes - startMinutes;
        if (timeDifference < 0) {
            console.error("End time should be later than start time within the same day.");
            return null;
        }
        const hoursDifference = Math.floor(timeDifference / 60);
        const minutesDifference = timeDifference % 60;

        return { hours: hoursDifference, minutes: minutesDifference };
    }

    // start time slot
    const handleStartTimeChange = (value, indexSlot, index) => {
        let array = [...doctorSlot];
        let start_Time = value
        let end_Time = moment(value, 'HH:mm').add(intervals, 'minutes').format('HH:mm');
        let currentStartTime = array[index].time_slot[indexSlot].start_time
        let currentEndTime = array[index].time_slot[indexSlot].end_time
        console.log("currentStartTime", currentStartTime, currentEndTime,)
        const timeDifference = calculateTimeDifferenceInDay(start_Time, end_Time);

        if (!timeDifference) {
            array[index].time_slot[indexSlot].start_time = moment(currentStartTime, 'HH:mm').add(1, 'minutes').format('HH:mm');
            array[index].time_slot[indexSlot].end_time = moment(currentEndTime, 'HH:mm').add(1, 'minutes').format('HH:mm');
            setDoctorSlot(array);
            Swal.fire({
                icon: 'error',
                title: 'Invalid Time Range',
                text: `Time slots must not exceed 24 hours.`,
            })
            return;
        }
        if (indexSlot > 0) {
            const previousEndTime = array[index].time_slot[indexSlot - 1].end_time;
            if (moment(value, 'HH:mm').isBefore(moment(previousEndTime, 'HH:mm'))) {
                array[index].time_slot[indexSlot].start_time = moment(currentStartTime, 'HH:mm').add(1, 'minutes').format('HH:mm');
                array[index].time_slot[indexSlot].end_time = moment(currentEndTime, 'HH:mm').add(1, 'minutes').format('HH:mm');
                setDoctorSlot(array);
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid Time Range',
                    text: `Start time must be after the end time of the previous slot`,
                });
                return; // Exit the function to prevent further updates
            } else {
                const newEndTime = moment(value, 'HH:mm').add(intervals, 'minutes').format('HH:mm');
                array[index].time_slot[indexSlot].end_time = newEndTime;
                array[index].time_slot[indexSlot].start_time = value;
                setDoctorSlot(array);
            }
        } else {
            const newEndTime = moment(value, 'HH:mm').add(intervals, 'minutes').format('HH:mm');
            array[index].time_slot[indexSlot].end_time = newEndTime;
            array[index].time_slot[indexSlot].start_time = value;
            setDoctorSlot(array);
        }

    };

    function isStartTimeAfterEndTime(startTime, endTime) {
        const [startHour, startMinute] = startTime.split(':').map(Number);
        const [endHour, endMinute] = endTime.split(':').map(Number);

        const startMinutes = startHour * 60 + startMinute;
        const endMinutes = endHour * 60 + endMinute;

        return startMinutes <= endMinutes;
    }
    // end time slot
    const handleEndTimeChange = (value, indexSlot, index) => {
        let array = [...doctorSlot];
        const startMoment = moment(array[index].time_slot[indexSlot].start_time, 'HH:mm');
        const endMoment = moment(value, 'HH:mm');

        if (array[index]?.time_slot[indexSlot + 1]?.start_time) {
            if (isStartTimeAfterEndTime(array[index].time_slot[indexSlot + 1].start_time, value)) {
                array[index].time_slot[indexSlot].end_time = moment(array[index].time_slot[indexSlot].end_time, 'HH:mm').add(1, 'minutes').format('HH:mm')
                setDoctorSlot(array);
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid Time Range',
                    text: `Please choose another time since the current time has already been selected.`,
                });
                return
            }
        }
        if (endMoment.isSameOrAfter(startMoment.add(intervals, 'minutes'))) {
            array[index].time_slot[indexSlot].end_time = value;
            setDoctorSlot(array);
        } else {
            array[index].time_slot[indexSlot].end_time = moment(array[index].time_slot[indexSlot].end_time, 'HH:mm').add(1, 'minutes').format('HH:mm')
            setDoctorSlot(array);
            Swal.fire({
                icon: 'error',
                title: 'Invalid Time Range',
                text: `The time span between the start & end times should align with the selected duration, and the end time must be later than the start time.`,
            });
        }

    };

    // add more slot
    const onAddSlot = (index) => {
        let array = [...doctorSlot];
        const hasNullTime = array[index].time_slot?.some(slot => !slot?.start_time || !slot?.end_time);
        if (hasNullTime) {
            Swal.fire({
                title: 'Error!',
                text: 'Please fill in both start and end times before adding a new slot.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        } else {

            let start_Time = array[index].time_slot[array[index].time_slot?.length - 1].end_time
            let end_Time = moment(array[index].time_slot[array[index].time_slot?.length - 1].end_time, 'HH:mm').add(intervals, 'minutes').format('HH:mm');
            const timeDifference = calculateTimeDifferenceInDay(start_Time, end_Time);
            if (!timeDifference) {
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid Time Range',
                    text: `Time slots must not exceed 24 hours.`,
                })
                return;
            }

            let previousEndTime = array[index].time_slot[array[index].time_slot?.length - 1].end_time
            array[index].time_slot?.push({
                start_time: moment(previousEndTime, 'HH:mm').add(5, 'minutes').format('HH:mm'),
                end_time: moment(previousEndTime, 'HH:mm').add(intervals + 5, 'minutes').format('HH:mm')
            });
            setDoctorSlot(array);
        }
    };

    // remove slot
    const onRemoveSlot = (indexSlot, index) => {
        let array = [...doctorSlot];
        // if (indexSlot > 0) {
        //     const previousSlot = array[index].time_slot[indexSlot - 1];
        //     const removedSlot = array[index].time_slot[indexSlot];
        //     previousSlot.end_time = removedSlot.end_time;
        // }
        array[index].time_slot.splice(indexSlot, 1);
        setDoctorSlot(array);
    };

    // select day for copy slot
    const handleDaySelection = (day, event) => {
        setSelectedDays((prevSelectedDays) => {
            const isSelected = prevSelectedDays.includes(day);
            if (isSelected) {
                return prevSelectedDays.filter((selectedDay) => selectedDay !== day);
            } else {
                return [...prevSelectedDays, day];
            }
        }, () => {
            console.log(selectedDays); // Log the current state to check
        });
    };



    // copy slot
    const onCopySlot = (index) => {
        let array = [...doctorSlot];
        let selectedSlot = array[index].time_slot;

        selectedDays.forEach((day) => {
            const dayIndex = DaysOfWeek.indexOf(day);
            if (dayIndex !== -1) {
                array[dayIndex].time_slot = selectedSlot.map((slot) => ({ ...slot }));
                array[dayIndex].enabled = true;
            }
        });
        setDoctorSlot(array);
        setDropdownOpenIndex("");
        setSelectedDays([]);
    };


    // drop down 
    const onDropDownOpen = (index) => {
        if (dropdownOpenIndex === index) {
            setDropdownOpenIndex("")
            setSelectedDays([])
        } else {
            setDropdownOpenIndex(index)
        }
    }

    // interval change 
    const onSelectInterval = (e) => {
        setIntervals(e)
        // setDoctorSlot([
        //     {
        //         day: "MONDAY",
        //         enabled: false,
        //         time_slot: [
        //             {
        //                 start_time: "00:00",
        //                 end_time: `00:${e}`
        //             }
        //         ]
        //     },
        //     {
        //         day: "TUESDAY",
        //         enabled: false,
        //         time_slot: [
        //             {
        //                 start_time: "00:00",
        //                 end_time: `00:${e}`
        //             }
        //         ]
        //     },
        //     {
        //         day: "WEDNESDAY",
        //         enabled: false,
        //         time_slot: [
        //             {
        //                 start_time: "00:00",
        //                 end_time: `00:${e}`
        //             }
        //         ]
        //     },
        //     {
        //         day: "THURSDAY",
        //         enabled: false,
        //         time_slot: [
        //             {
        //                 start_time: "00:00",
        //                 end_time: `00:${e}`
        //             }
        //         ]
        //     },
        //     {
        //         day: "FRIDAY",
        //         enabled: false,
        //         time_slot: [
        //             {
        //                 start_time: "00:00",
        //                 end_time: `00:${e}`
        //             }
        //         ]
        //     },
        //     {
        //         day: "SATURDAY",
        //         enabled: false,
        //         time_slot: [
        //             {
        //                 start_time: "00:00",
        //                 end_time: `00:${e}`
        //             }
        //         ]
        //     },
        //     {
        //         day: "SUNDAY",
        //         enabled: false,
        //         time_slot: [
        //             {
        //                 start_time: "00:00",
        //                 end_time: `00:${e}`
        //             }
        //         ]
        //     },
        // ])
    }

    // submit slot
    const onSubmit = () => {
        if (!doctorSlot.some(obj => obj.enabled === true)) {
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: `Please enable at least one day`,
            // })
            return
        }
        setIsLoader(true)
        let data = {
            "slot_interval": intervals,
            "day_schedule": doctorSlot
        }
        openAiService
            .addSchedule(data)
            .then((result) => {
                setIsLoader(false)
                Swal.fire(
                    "Success",
                    "Slots availability saved successfully.",
                    "success"
                ).then(() => {
                    // navigate("/dashboard")
                });
            })
            .catch((err) => {
                setIsLoader(false)
                console.log(err);
            }).finally(() => {
                setIsLoader(false)
            })
    }
    const handleDocumentClick = (e) => {
        if (!e.target.closest('.dropdown')) {
            setDropdownOpenIndex()
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Add Slot
        </Tooltip>

    );
    const renderTooltip_1 = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Copy Slot
        </Tooltip>

    );

    console.log("selectedDays", selectedDays)
    return (
        <>
            <section className="availability-box">
                {isLoader && <Loading />}
                <Container>
                    <div style={{ cursor: "pointer" }} class="back-btn-box">
                        <a onClick={() => navigate("/dashboard")}>
                            {" "}
                            <i class="fa fa-angle-double-left me-2" aria-hidden="true"></i>
                            Back To List{" "}
                        </a>
                    </div>
                    <div className="row mb-2">
                        <aside className="col-md-6">
                            <h2>Manage Availability</h2>
                        </aside>
                    </div>
                    <div className="row mb-3">
                        <aside className="col-md-3 col-sm-5">
                            <label>Time Duration</label><br />
                            <select value={intervals} onChange={(e) => onSelectInterval(e.target.value)}>
                                <option value="" disabled>Select Slot Interval</option>
                                {slotIntervals.map((interval) => (
                                    <option key={interval.value} value={interval.value}>
                                        {interval.label}
                                    </option>
                                ))}
                            </select>
                        </aside>
                    </div>
                    <div>
                        {doctorSlot?.map((item, index) => (
                            <div key={index} className="availabilty-box">
                                <Row>
                                    <Col lg={3} sm={3}>
                                <div className="d-flex align-items-center justify-content-start">
                                    <label className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={item?.enabled}
                                            onChange={(e) => onCheckboxSelected(index)}
                                        />

                                    </label>
                                    <h5>{item.day}:</h5>
                                </div>
                                </Col>
                                <Col lg={6} sm={6}>
                                <div className="available-boxes-main">
                                    {item?.time_slot?.map((slot, indexSlot) => {
                                        return (

                                            <div key={indexSlot} className="d-flex align-items-center">
                                                <div className="timing-zone me-2">
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['TimePicker']}>
                                                            <TimePicker
                                                                format="HH:mm"
                                                                value={dayjs(`2023-10-18T${slot.start_time}`)}
                                                                onChange={(e) => {
                                                                    let value = moment(e.$d).format('HH:mm')
                                                                    handleStartTimeChange(value, indexSlot, index)
                                                                }}
                                                                label="Start Time"
                                                                ampm={false}
                                                                slotProps={{
                                                                    textField: {
                                                                        readOnly: true,
                                                                    },
                                                                }}
                                                                defaultValue={dayjs(`2023-10-18T${slot.start_time}`)}
                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                </div>
                                                <div className="timing-zone">
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['TimePicker']}>

                                                            <TimePicker
                                                                format="HH:mm"
                                                                value={dayjs(`2023-10-18T${slot?.end_time}`)}
                                                                onChange={(e) => {
                                                                    let value = moment(e.$d).format('HH:mm')
                                                                    handleEndTimeChange(value, indexSlot, index)
                                                                }}
                                                                label="End Time"
                                                                ampm={false}
                                                                slotProps={{
                                                                    textField: {
                                                                        readOnly: true,
                                                                    },
                                                                }}
                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                </div>
                                                {item?.time_slot.length > 1 && (
                                                    <div>
                                                        <button onClick={() => onRemoveSlot(indexSlot, index)}>
                                                            <img src={require("../../assests/images/delete-app.svg").default} alt="img" className="ms-3" />
                                                        </button>
                                                    </div>
                                                )}
                                            </div>

                                        )
                                    })}
                                </div>
                                </Col>
                                <Col lg={3} sm={3}>
                                <div className="available-btn d-flex align-items-center">
                                    <a className="blue-text mr-3" onClick={() => onAddSlot(index)}>
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip}
                                        >
                                            <img src={require("../../assests/images/add.svg").default} alt="img" className="" />
                                        </OverlayTrigger>
                                    </a>
                                    <a className="add-available-box">


                                        <Dropdown show={dropdownOpenIndex === index}
                                            className="drop-down">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip_1}
                                                >
                                                    <img onClick={() => onDropDownOpen(index)} src={require("../../assests/images/paste.svg").default} alt="img" className="" />
                                                </OverlayTrigger>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="delete-wrap">
                                                {DaysOfWeek.map((items, index) => {
                                                    if (item.day == items) {
                                                        return
                                                    }
                                                    return (
                                                        <Dropdown.Item >
                                                            <div className="form-check" id={index}>
                                                                {/* <input
                                                                    name={`name${index}`}
                                                                    value={`value${index}`}
                                                                    id={`id${index}`}
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={selectedDays && selectedDays.includes(item)}
                                                                    onChange={(e) => handleDaySelection(item)}
                                                                /> */}
                                                                <Checkbox
                                                                    className="form-check-input"
                                                                    checked={selectedDays.includes(items)}
                                                                    onClick={(e) =>
                                                                        handleDaySelection(items)
                                                                    }
                                                                />

                                                                <label className="form-check-label">
                                                                    {items}
                                                                </label>
                                                            </div>
                                                        </Dropdown.Item>
                                                    )
                                                })}
                                                <Dropdown.Item className="">
                                                    <div onClick={() => onCopySlot(index)} className="availabilty-btn">
                                                        <a type="button" className="join-button" >Apply</a>
                                                    </div>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </a>
                                </div>
                                </Col>
                                </Row>
                            </div>
                        ))}

                        <a className={`try-ti-butons text-light ${!doctorSlot.some(obj => obj.enabled === true) ? "btn-disabled" : ""} `} onClick={() => { onSubmit() }}>Submit</a>
                    </div>
                </Container>
            </section>
        </>
    )
}
export default DoctorAvailability;