import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { openAiService } from "../../services/openai.services";
import { RotatingLines } from "react-loader-spinner";
import { DEFAULT_PLAN_ID } from "../../config/config";
import { context } from "../../GlobalContext/GlobalContext";
import Swal from "sweetalert2";
import { Loading } from "../common/loader";

const Pricing = (props) => {
  const navigate = useNavigate();
  const globalData = useContext(context);
  const [allPlans, setAllPlans] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [cancelPlanModal, setCancelPlanModal] = useState(false);
  const [planToCancel, setPlanToCancel] = useState();
  const [cancellationPolicyCheck, setCancellationPolicyCheck] = useState(false);
  const [errors, setErrors] = useState({
    cancellationPolicyError: false,
  });
  const cancellationLabel = (
    <span style={{ fontSize: "14px" }}>
      Accept Cancellation Policy{" "}
      <a target="_blank" href="/cancellation-policy">
        Click here for Cancellation Policy
      </a>
    </span>
  );

  const purchasedPlan =
    localStorage.getItem("plan") &&
    JSON?.parse(localStorage.getItem("plan")).active
      ? JSON?.parse(localStorage.getItem("plan"))
      : "";
  useEffect(() => {
    listPlans();
    // updatePlan();
  }, []);

  // const updatePlan = () => {
  //   let params = {
  //     active: false
  //   }
  //   openAiService.updatePlan("plan_Fh3E2btVgD9Kik", params).then(res=>{debugger})
  // }

  const listPlans = () => {
    setShowLoader(true);
    openAiService
      .listSubscriptions()
      .then((res) => {
        let sortedArray = res.data.data.sort(function (a, b) {
          return parseFloat(a.amount) - parseFloat(b.amount);
        });
        console.log("sortedArray-->",sortedArray)
        setAllPlans(sortedArray);
        setShowLoader(false);
        if (props?.setScrollToPrice) {
          globalData.setScrollToPricing(true);
        }
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };
  function cancelSubscription(id) {
    setShowLoader(true);
    openAiService
      .cancelSubscription(id)
      .then((res) => {
        console.log("cancel sub res", res);
        let user = JSON.parse(localStorage.getItem("user"));
        user.subscription_plan = "";
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.removeItem("plan");
        setCancelPlanModal(false);
        setShowLoader(false);
        Swal.fire("Success", "Plan Cancelled Successfully.", "success").then(
          () => {
            window.location.reload();
          }
        );
      })
      .catch((err) => {
        console.log("error", err);
        setShowLoader(false);
        Swal.fire("Error", "Something went wrong", "error");
      });
  }

  return (
    <>
      {/* {showLoader && (
        <div className="main-loader">
          <div class="loader3">
            <span></span>
            <span></span>
          </div>
        </div>
      )} */}
      {showLoader && <Loading />}
      <section className="pricing-area">
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <div
                id="pricing-div-element-id"
                className="pricing-top-heading-area"
              >
                <h2 className="sub-heading">Plans & Pricing</h2>
                <p>
                Experience unlimited access to healthcare assistance and premium AI features with AI Health Assistant. Utilize these features to quickly review healthcare reports and documents.
                </p>
              </div>
            </Col>
          </Row>
     
          <Row className="justify-content-center">
            {console.log("allPlans",allPlans)}
            {allPlans.length > 0
              ? allPlans.map((plan, index) => {
                // {console.log("====json",plan,plan.metadata?.features)}
                let features
                  if(plan.metadata?.features==undefined){
                    features = ""
                   }else{
                   features = JSON?.parse(plan.metadata?.features);
                   }
                  let button;
                  // if(localStorage.getItem("user") && (!localStorage.getItem("plan") || JSON.parse(localStorage.getItem("plan"))?.id === DEFAULT_PLAN_ID)){
                  //   button = "Current Plan "
                  // } else {
                  if (purchasedPlan === "") {
                    if (plan.id === DEFAULT_PLAN_ID) {
                      button = "Default Plan ";
                    } else {
                      button = (
                        <>
                         Default Plan 
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </>
                      );
                    }
                  } else {
                    if (purchasedPlan.id === plan.id) {
                      button = "Current Plan ";
                    } else if (purchasedPlan.amount < plan.amount) {
                      button = (
                        <>
                          Upgrade Plan{" "}
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </>
                      );
                    } else if (purchasedPlan.amount > plan.amount) {
                      button = (
                        <>
                          Purchase Plan
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </>
                      );
                    } else {
                      button = (
                        <>
                          Purchase Plan
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </>
                      );
                    }
                  }
                  //  }
                  return (
                    <>
                      <Col
                        key={`${plan?.id}_${index}`}
                        lg={3}
                        sm={6}
                        className="mb-4"
                      >
                        <div
                          className={
                            plan.amount === 0 ||
                            purchasedPlan?.amount > plan.amount
                              ? "plan-disabled pricing-content-area"
                              : purchasedPlan?.id === plan.id
                              ? "cursor-default-important pricing-content-area"
                              : "pricing-content-area"
                          }
                          onClick={() => {
                            if (localStorage.getItem("user")) {
                              if (
                                plan.amount === 0 ||
                                purchasedPlan?.amount > plan.amount ||
                                purchasedPlan?.id === plan.id
                              ) {
                              } else {
                                navigate("/card_details", {
                                  state: { plan: plan },
                                });
                              }
                            } else {
                              globalData.setLoginVia("PRICING");
                              globalData.setTriggerLogin(true);
                            }
                          }}
                        >
                          {/* <div className="pricing-top">
                      <img
                        src={require("../../assests/images/pricing1.png")}
                        alt="img"
                      />
                    </div> */}
                          <h5>
                            ${plan.amount / 100} /{plan.interval}
                          </h5>
                          <ul>
                            {features &&
                              features?.map((feature, index) => {
                                return (
                                  <>
                                    <li key={feature + "_" + index}>
                                      {" "}
                                      <img
                                        src={require("../../assests/images/pricing-arrow.png")}
                                        alt="img"
                                      />{" "}
                                      {feature}
                                    </li>
                                  </>
                                );
                              })}
                          </ul>
                          <Button
                            className={
                              purchasedPlan.id === plan.id ? "current-plan" : ""
                            }
                            type="button"
                          >
                            {button}
                          </Button>
                          {purchasedPlan.id === plan.id ? (
                            <Button
                              id="cursor-pointer-important"
                              type="button"
                              onClick={() => {
                                setCancelPlanModal(true);
                                setPlanToCancel(plan);
                              }}
                            >
                              Cancel Plan
                            </Button>
                          ) : null}
                        </div>
                      </Col>
                    </>
                  );
                })
              : null}
          </Row>
          <div className="pricing-contact-btn">
          </div>
        </Container>
      </section>
      <Modal
        className="cancel-plan-box-area"
        show={cancelPlanModal}
        // backdrop="static"
        onHide={() => {
          setCancelPlanModal(false);
          setErrors({});
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Sign Up</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="google-login-btn">
            <Form>
              <div className="row">
                <div className="login-form-box cancel-area-box">
                  <h5 className="mb-4">
                    Are you sure you want to cancel your plan?
                  </h5>
                  <Button
                    type="button"
                    variant="unset"
                    className="w-75 cancel-btn"
                    onClick={() => {
                      let subId = JSON?.parse(
                        localStorage.getItem("user")
                      ).subscription_plan;
                      cancelSubscription(subId);
                    }}
                    style={{ margin: "12px 12px" }}
                  >
                    Cancel Plan
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Pricing;
