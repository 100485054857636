import { Button, Col, Container, Form, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { openAiService } from '../../services/openai.services';
import Swal from 'sweetalert2';
import moment from 'moment';
import { Loading } from "../common/loader";
import { baseUrl } from "../../config/config";
import DatePicker from "react-datepicker";
import {  DateTimeFormatter } from "../common/common"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";


const UserMedicationPage = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [modalShow, setModalShow] = useState({ recommendModal: false, medicationModal: false, dietaryModal: false })
  const [onAddValue, setOnAddValue] = useState("")
  const [selectedItemTemp, setSelectedItemTemp] = useState([])
  const [suggestedMedicines, setSuggestedMedicines] = useState([]);
  const [medicineId, setMedicineId] = useState("")
  const [activeIndex, setActiveIndex] = useState();
  const [checked, setChecked] = useState(false);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [nextAppointment, setNextAppointment] = useState(new Date());
  const [availableSlots, setAvailableSlots] = useState([]);
  const [slot, setSlot] = useState("");
  const [valid, setValid] = useState(false);
  const [enableDays, setEnableDays] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [showModalReportPrecision, setShowModalReportPrecision] = useState(false);
  const [reportPrecisionUrl, setReportPrecisionUrl] = useState("");
  const [shareEmailsShow, setShareEmailsShow] = useState(false);
  const [shareEmails, setShareEmails] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [responseOfPrescription, setResponseOfPrescription] = useState({});
  const [onAddValueEmail, setOnAddValueEmail] = useState("");
  const [selectedItemTempEmail, setSelectedItemTempEmail] = useState([]);
  const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const [isSeemar,setIsSeemar] = useState(true)
  const interval = useRef(null);
  const [testExists,setTestExists] = useState(false);
  const [dietExists,setDietExists] = useState(false)
  var allDays = [
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ];

  useEffect(() => {
    getAvailableSlots(moment(new Date(nextAppointment)).format("YYYY-MM-DD"));
  }, [nextAppointment]);

  const closeModalReportPrecision = () => {
    setShowModalReportPrecision(false);
    navigate("/my-appointment");
  };

  

  const onAddRecommendTest = () => {
    if (!onAddValue) {
      return
    }
    if(currentAppointments.RecommendedTests.includes(onAddValue)){
     setTestExists(true)
    }else{
      setTestExists(false)
      setCurrentAppointments(prevData => ({
        ...prevData,
        ["RecommendedTests"]: [...prevData["RecommendedTests"], onAddValue]
      }));
      setSelectedItemTemp((prevSelectedDays) => {
        return [...prevSelectedDays, onAddValue];
      })
    }
    setOnAddValue("")

  }
  const onAddDietaryTest = () => {
    if (!onAddValue) {
      return
    }
    if(currentAppointments.DietaryPlan.includes(onAddValue)){
      setDietExists(true)
    }else{
       setDietExists(false)  
      setCurrentAppointments(prevData => ({
        ...prevData,
        ["DietaryPlan"]: [...prevData["DietaryPlan"], onAddValue]
      }));
      setSelectedItemTemp((prevSelectedDays) => {
        return [...prevSelectedDays, onAddValue];
      })
    }
    
    setOnAddValue("")
  }
  function isAnyKeyEmpty(objects) {
    return objects.some(obj =>
      Object.values(obj).some(value => value === '' || value === null || value === undefined)
    );
  }
  const onSaveRecommendTest = () => {
    setAppointmentData({ ...appointmentData, recommended_test: selectedItemTemp })
    onCloseModal("recommendModal")
    setSelectedItemTemp([])
  }
  const onSaveMedication = () => {
    if (isAnyKeyEmpty(selectedItemTemp)) {
      Swal.fire({
        title: "Error",
        text: "Required data is missing in the fields. Please review and save the necessary information.",
        type: "error"
      })
      return
    }
    setAppointmentData({ ...appointmentData, medication: selectedItemTemp })
    onCloseModal("medicationModal")
    setSelectedItemTemp([])

  }
  const onSaveDietary = () => {
    setAppointmentData({ ...appointmentData, dietary_plan: selectedItemTemp })
    onCloseModal("dietaryModal")
    setSelectedItemTemp([])
  }


  const onCloseModal = (type) => {
    setTestExists(false)
    setDietExists(false)
    setOnAddValue()
    setSelectedItemTemp([])
    if (type === "recommendModal") {
      setModalShow({ ...modalShow, recommendModal: false })
    } else if (type === "medicationModal") {
      setSuggestedMedicines([]);
      setModalShow({ ...modalShow, medicationModal: false })
    } else if (type === "dietaryModal") {
      setModalShow({ ...modalShow, dietaryModal: false })
    }
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Add
    </Tooltip>
  );
  const renderTooltip12 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Delete
    </Tooltip>
  );
  // const renderTooltipedit = (props) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     Edit
  //   </Tooltip>
  // );


  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const userType = localStorage.getItem("userType");
  const appointmentsId = searchParams.get("id");
  const type = searchParams.get("type");
  const [isLoader, setIsLoader] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState()
  const [currentAppointments, setCurrentAppointments] = useState()
  const [ifLoader,setIfLoader] = useState(true)
  const [lateTest,setLateTest] = useState(false);
  const [appointmentData, setAppointmentData] = useState({
    medication: [],
    recommended_test: [],
    dietary_plan: [],
    // note: "",
    purpose: ""
  })
  useEffect(() => {
    getUserHistory(appointmentsId);
    getDiagnoses(appointmentsId);
    // getSuggestedMedicines();
    if (userType !== "doctor" && user == null) {
      navigate("/login");
    } else if (
      (userType !== "doctor" && user !== null) ||
      (userType === "doctor" && user !== null)
    ) {
      navigate("/247-virtual-assistant");
    }
    getAvailableDays();
  }, []);

  // if(!currentAppointments){
  //   setInterval(() => {
  //     setIsSeemar(true)
  //     getUserHistory(appointmentsId);
  //   }, 1000);
  // }else{
  //   setIsSeemar(false)
  // }

  const getDiagnoses = (id) => {
   openAiService.getDiagnoses(id)
   .then((res) => {
    console.log("resulttt", res.data)
   })
   .catch((err) => {
    console.log("errr---", err)
   })
  }


  const getUserHistory = (appointmentsId) => {
    // if(ifLoader){setIsLoader(true)}else{
      lateTest ? setIsLoader(false) :setIsLoader(true)
    // 
    setIsSeemar(true)
    // setIsLoader(true)
    openAiService.getAppointmentId(appointmentsId)
      .then((result) => {
        console.log("result.data", result.data)
        setAppointmentDetails(result.data)
        setAppointmentData({ ...appointmentData, purpose: result.data.doctor_rephrased_problem})
        setIsLoader(false);
        setIsSeemar(false)
        
        if (result?.data?.diagnose.length > 0) {
          console.log("result?.data?.diagnose123", result?.data?.diagnose)
          function findObjectById() {
            return result?.data?.diagnose?.find(obj => obj?.appointment?.id == appointmentsId);
          }

          let foundObject = findObjectById();
          console.log("foundObjectfoundObject",foundObject)
          if (foundObject) {
            let DietaryPlan = JSON.parse(foundObject?.recommended_test)?.DietaryPlan ? JSON.parse(foundObject?.recommended_test)?.DietaryPlan : []
            let RecommendedTests = JSON.parse(foundObject?.recommended_test)?.RecommendedTests ? JSON.parse(foundObject?.recommended_test)?.RecommendedTests : []
            setCurrentAppointments({ RecommendedTests: RecommendedTests ? RecommendedTests : [], DietaryPlan: DietaryPlan ? DietaryPlan : [] })
          } else {
            setCurrentAppointments({ RecommendedTests: [], DietaryPlan: [] })
          }

        } else {
          setCurrentAppointments({ RecommendedTests: [], DietaryPlan: [] })
        }
        // setIsSeemar(false)

      })
      .catch((error) => {
        setIsLoader(false);
        setIsSeemar(false)
        if (error?.response?.status === 401) {
          localStorage.clear();
          Swal.fire({
            title: "Error",
            text: " Your session has expired, Please login again.",
            type: "error"
          }).then(() => {
            navigate("/login");
          })

        }
      });
  };

  useEffect(() => {
    let intervalId;

    const fetchDataWithInterval = () => {
      if (!currentAppointments?.RecommendedTests || currentAppointments?.RecommendedTests.length === 0) {
        console.log("currentAppointments?.RecommendedTests123",currentAppointments?.RecommendedTests)
        setIsSeemar(true);
        setLateTest(true);
        setTimeout(() => {
          getUserHistory(appointmentsId)
        }, 5000);
        // intervalId = setInterval(getUserHistory(appointmentsId), 20000);
      }else{
        setLateTest(false);
        setIsSeemar(false);
      }
      
    };
    fetchDataWithInterval();

    // return () => {
    //   clearInterval(intervalId);
    // };
  },[currentAppointments]);


  const getSuggestedMedicines = (keyword) => {
    // setIsLoader(true)
    openAiService.getSuggestedMedicine(keyword).then((res) => {
      console.log("medicines------", res.data)
      if (res.data.length > 0) {
        setSuggestedMedicines(res.data)
      } else {
        setSuggestedMedicines([])
      }

      // setIsLoader(false)
    }).catch((err) => console.log("err-----------", err))
  }

  const onGenerate = () => {
    if (!appointmentData.purpose) {
      Swal.fire({
        title: "Error",
        text: "Please enter clinical description.",
        type: "error"
      })
      return
    }
    if (!appointmentData.note) {
      Swal.fire({
        title: "Error",
        text: "Please enter note",
        type: "error"
      })
      return
    }

    console.log("nextAppointment---",nextAppointment,slot)
    var date = moment(
      new Date(moment(nextAppointment).format("YYYY-MM-DD") + " " + slot)
    ).format("YYYY-MM-DDTHH:mm");
    
    setIsLoader(true);
    let data = {
      appointment: appointmentsId,
      recommended_test: JSON.stringify(appointmentData.recommended_test),
      dietary_plan: JSON.stringify(appointmentData.dietary_plan),
      note: appointmentData.note,
      medication: JSON.stringify(appointmentData.medication),
      problem: appointmentData.purpose,
      appointment_time: checked ? date : null
    }
    console.log("data---",data)
    openAiService.updatePrescription(data)
      .then((result) => {
        setIsLoader(false);

        console.log("result",result.data.data)
        console.log("prescription_report",result?.data?.data?.prescription_report)
        setResponseOfPrescription(result?.data?.data);
        setShowModalReportPrecision(true);
        setReportPrecisionUrl(baseUrl+"/"+result?.data?.data?.prescription_report);

        // Swal.fire(
        //   "Success",
        //   "The prescription has been created, and the appointment has been marked as completed.",
        //   "success"
        // ).then(() => {
        //   console.log("data",data)
        //   debugger
        //   console.log("data",data)
        //   setShowModalReportPrecision(true);
        //   setReportPrecisionUrl(data?.prescription_report);
        //   // navigate(`/userdetails?id=${appointmentsId}&type=${type}&tabType=past`)
        //   // if (result?.data?.data?.prescription_report) {
        //   //   window.open(`https://aimedhealth-api-staging.netsetdigital.com/${result?.data?.data?.prescription_report}`);
        //   // }

        // });

      })
      .catch((error) => {
        setIsLoader(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          Swal.fire({
            title: "Error",
            text: " Your session has expired, Please login again.",
            type: "error"
          }).then(() => {
            navigate("/login");
          })

        }
      });
  }

  const onHandleChange = (val) => {
    //  var value = e.target.value;
    // console.log("indexxxx---", index)
     if(val == ""){
      setSuggestedMedicines([])
     }else{
      getSuggestedMedicines(val)
     }


  }


  const onSelectItem = (item) => {
    setTestExists(false);
    setDietExists(false);
    setSelectedItemTemp((prevSelectedDays) => {
      const isSelected = prevSelectedDays.includes(item);
      if (isSelected) {
        return prevSelectedDays.filter((selectedDay) => selectedDay !== item);
      } else {
        return [...prevSelectedDays, item];
      }
    }, () => {
      console.log(item); // Log the current state to check
    });
  }

  const onChangeMedicine = (text, index, type) => {
    let array = [...selectedItemTemp]
    console.log("test--test", text)
    array[index][type] = text
    console.log("dsfs", array[index].dosage)
    if(array[index].dosage <= 0){
      array[index].dosage = "";
    }
    setSelectedItemTemp(array)
  }

  const onAddMore = () => {
    if (isAnyKeyEmpty(selectedItemTemp)) {
      Swal.fire({
        title: "Error",
        text: "Required data is missing in the fields. Please review and save the necessary information.",
        type: "error"
      })
      return
    }
    let array = [...selectedItemTemp]
    array.push({ medicine: "", dosage: "", route: "", frequency: "" })
    setSelectedItemTemp(array)
  }

  const onRemoveMedication = (index) => {
    let array = [...selectedItemTemp]
    array.splice(index, 1);
    setSelectedItemTemp(array)
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onAddDietaryTest()
    }
  };

  const handleKeyPres1 = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onAddRecommendTest()
    }
  };

  const showHideModal = (e) => {
    if (e.target.checked) {
      setShowAppointmentModal(true);
      setChecked(true);
    } else {
      setShowAppointmentModal(false);
      setChecked(false);
    }
  };

  const handleDateChange = (date) => {
    console.log("formated dates", moment(date).format("YYYY-MM-DD"));
    getAvailableSlots(moment(date).format("YYYY-MM-DD"));
    setNextAppointment(date);
    setSlot("")
    setValid(false)
    // set
  };

  const isDayEnabled = (date) => {
    console.log("enable days ", enableDays);
    return enableDays.some((enabledDay) => enabledDay === date.getDay());
  };

  function getAvailableSlots(date) {
    console.log("dateeeeeee", date);
    openAiService
      .getAvailableSlots(date)
      .then((result) => {
        // console.log("getAvailableSlots------", new Date(date + " " + result.data.data[1].time))
        var output = result?.data?.data?.filter(
          (s) => new Date(date + " " + s.time)?.getTime() > new Date().getTime()
        );
        console.log("output------>>>>>>>>>>>", output);
        setAvailableSlots(output);
      })
      .catch((err) => console.log(err));
  }

  const scheduleRevisit = () => {
    if (slot === "") {
      Swal.fire("error", "Please select a slot", "error");
    } else {
      setSelectedDate(moment(
        new Date(moment(nextAppointment).format("YYYY-MM-DD") + " " + slot)
      ).format("YYYY-MM-DDTHH:mm"))
      setShowAppointmentModal(false)
      // setChecked(false)
    }
  };

  function removeSecondFromString(time) {
    var modify = time.split(":");
    return modify[0] + ":" + modify[1];
  }

  const handleCloseNext = () => {
    setShowAppointmentModal(false);
    if(selectedDate){
      setChecked(true);
    }else{
      setChecked(false);
    }
    // setValid(false)
    // setNextAppointment(new Date())
    // setSlot("")
  };

  function getAvailableDays() {
    const dayArr = [];
    openAiService
      .getAvailableDays()
      .then((result) => {
        console.log("availbale dayass", result.data.data);
        result.data.data
          .filter((d) => d.enabled)
          .map((res) => {
            console.log("available slots", res.day);

            dayArr.push(
              allDays.indexOf(res.day) !== -1 ? allDays.indexOf(res.day) : ""
            );
            setEnableDays(dayArr);
            // if(!enableDays.includes(res.day)){
            //   enableDays.push(res.day)
            // }
          });
      })
      .catch((err) => console.log("error--", err));
  }

  const back = () => {
    navigate(-1);
  }
  const renderTooltip_change = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Change Date/Time
    </Tooltip>
  );


  const onShareWithEmail = () => {
    
    if(selectedItemTempEmail.length === 0){
       Swal.fire("error","Please choose an email.", "error")
    }else{
        console.log("responseOfPrescription---",responseOfPrescription) 
      let data = {
        emails: selectedItemTempEmail,
        id: responseOfPrescription.id,
        appointment_id: responseOfPrescription.appointment,
      };
      setIsLoader(true);
    openAiService
      .shareOnEmail(data)
      .then((result) => {
        setIsLoader(false);
        Swal.fire(
          "Success",
          "The prescription has been shared on the mentioned emails.",
          "success"
        ).then(() => {
          setShareEmailsShow(false);
          setSelectedItemTempEmail([]);
          setShareEmails([]);
        });
      })
      .catch((error) => {
        setIsLoader(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          Swal.fire({
            title: "Error",
            text: " Your session has expired, Please login again.",
            type: "error",
          }).then(() => {
            navigate("/login");
          });
        }
      });
    }
  };


  const emailHandleKeyPres1 = (e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          if (!EMAILREGEX.test(onAddValueEmail)) {
            setEmailError("Please enter valid email");
          } else {
            setEmailError("");
            onAddRecommendEmail();
          }
        }
      };
    
      const onAddRecommendEmail = () => {
        if (!onAddValueEmail) {
          return;
        }
        setShareEmails((prevItem) => {
          return [...prevItem, onAddValueEmail];
        });
        setSelectedItemTempEmail((prevSelectedDays) => {
          return [...prevSelectedDays, onAddValueEmail];
        });
        setOnAddValueEmail("");
      };
    
      const onSelectItemEmail = (item) => {
        setSelectedItemTempEmail(
          (prevSelectedDays) => {
            const isSelected = prevSelectedDays.includes(item);
            if (isSelected) {
              return prevSelectedDays.filter((selectedDay) => selectedDay !== item);
            } else {
              return [...prevSelectedDays, item];
            }
          },
          () => {
            console.log(item); // Log the current state to check
          }
        );
      };
    
    
    
 
  return (
    <>
      <section className="user-medication-area">
        {isLoader && <Loading />}
        <Container>
          <div style={{ cursor: "pointer" }} class="back-btn-box">
            <a onClick={back}>
              {" "}
              <i class="fa fa-angle-double-left me-2" aria-hidden="true"></i>
              Back To List{" "}
            </a>
          </div>
          <Row className="justify-content-center">
            <Col md={10} className="user-medication-form">

              <div className="border-bottom-div">
                <Row>
                  <Col md={6}>
                    <div className="doctor-details-box">
                      <h6><b>{appointmentDetails?.doctor ? appointmentDetails?.doctor?.first_name + " " + appointmentDetails?.doctor?.last_name : null}</b></h6>
                      <h6>Mohali City, Punjab, 160055</h6>
                      <h6><b>Ph:</b> {appointmentDetails?.doctor ? appointmentDetails?.doctor?.phone : ""}</h6>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="doctor-details-box">
                      <h6><b>Patient Name:</b> {appointmentDetails?.member ? appointmentDetails?.member?.name : appointmentDetails?.user?.name}</h6>
                      <h6><b>Appointment Date:</b> {moment(new Date(appointmentDetails?.appointment_time)).format("DD MMM YYYY | HH:MM")}</h6>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="border-bottom-div">
              <div className="border-bottom-div last-border">
                <div className="d-flex">
                  <h4 className="mb-3 me-3 notes-text w-25">Clinical Description*
                  </h4>
                  <textarea value={appointmentData?.purpose}
                    placeholder="Please enter clinical description"
                    onChange={(e) => setAppointmentData({ ...appointmentData, purpose: e.target.value.trimStart() })}
                    className="form-control mt-0 w-100">
                  </textarea>
                </div>
              </div>

                <div className="d-flex justify-content-between align-items-center mb-3" >
                  <h4>Recommend Test</h4>
                  <a className="add-btn" style={{cursor:"pointer"}} onClick={() => {
                    console.log("appointmentData.recommended_test", currentAppointments?.RecommendedTests)
                    if(!currentAppointments?.RecommendedTests || currentAppointments?.RecommendedTests.length === 0){
                      setIsSeemar(true);
                      getUserHistory(appointmentsId)
                    }else{
                      setIsSeemar(false); 
                    }
                    setSelectedItemTemp(appointmentData.recommended_test)
                    setModalShow({ ...modalShow, recommendModal: true })
                  }} >Recommend Test <img
                      className="ms-1"
                      src={
                        require("../../assests/images/add-main.svg")
                          .default
                      }
                      alt=""
                    />
                  </a>
                </div>
                 
                


                {appointmentData?.recommended_test.length !== 0 ?
                    <>
                      <div className="recommend-box">
                        {appointmentData?.recommended_test?.map((item) => {
                          return (
                            <span>
                              {item}
                            </span>
                          )
                        })}
                      </div>
                    </>
                    :
                    <div className="no-test-here">
                      <img
                        className="ms-1"
                        src={
                          require("../../assests/images/add-test.gif")
                        }
                        alt=""
                      />
                      <h4>No Recommend test found!</h4>
                    </div>
                }

              </div>
              <div className="border-bottom-div">
                <div className="d-flex justify-content-between align-items-center mb-3" >
                  <h4>Medication</h4>
                  <a  className="add-btn" style={{cursor:"pointer"}}
                    onClick={() => {
                      if (appointmentData.medication.length === 0) {
                        setSelectedItemTemp([{ medicine: "", dosage: "", route: "", frequency: "" }])
                      } else {
                        setSelectedItemTemp(appointmentData.medication)
                      }
                      setModalShow({ ...modalShow, medicationModal: true })
                    }}
                  >Medication <img
                      className="ms-1"
                      src={
                        require("../../assests/images/add-main.svg")
                          .default
                      }
                      alt=""
                    />
                  </a>
                </div>
                {appointmentData?.medication.length !== 0 ?
                  <div className="medication-table">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th>Medicine Name</th>
                          <th>Dosage</th>
                          <th>Route</th>
                          <th>Frequency</th>
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {appointmentData?.medication?.map((item) => {
                          return (
                            <tr>
                              <td>{item.medicine}</td>
                              <td>{item.dosage}</td>
                              <td>{item.route}</td>
                              <td>{item.frequency}</td>
                              {/* <td>
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip_edit}
                                >
                                  <img
                                    className="me-2 "
                                    src={
                                      require("../../assests/images/edit.svg")
                                        .default
                                    }
                                    alt=""
                                  />
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip12}
                                >
                                  <img
                                    className="me-2 "
                                    src={
                                      require("../../assests/images/delete-action.svg")
                                        .default
                                    }
                                    alt=""
                                  />

                                </OverlayTrigger>

                              </td> */}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  :
                  <div className="no-test-here">
                    <img
                      className="ms-1"
                      src={
                        require("../../assests/images/add-test.gif")
                      }
                      alt=""
                    />
                    <h4>No Medication found!</h4>
                  </div>}
              </div>
              <div className="border-bottom-div">
                <div className="d-flex justify-content-between align-items-center mb-3" >
                  <h4>Dietary Plan </h4>
                  <a className="add-btn" style={{cursor:"pointer"}} onClick={() => {
                    setSelectedItemTemp(appointmentData.dietary_plan)
                    setModalShow({ ...modalShow, dietaryModal: true })
                  }}>Diet Plan <img
                      className="ms-1 "
                      src={
                        require("../../assests/images/add-main.svg")
                          .default
                      }
                      alt=""
                    />
                  </a>
                </div>
                {appointmentData?.dietary_plan.length !== 0 ?
                  <div className="recommend-box">
                    {appointmentData?.dietary_plan?.map((item) => {
                      return (
                        <span>
                          {item}
                        </span>
                      )
                    })}
                  </div>
                  :
                  <div className="no-test-here">
                    <img
                      className="ms-1"
                      src={
                        require("../../assests/images/add-test.gif")
                      }
                      alt=""
                    />
                    <h4>No Dietary Plan found!</h4>
                  </div>}
              </div>
              <div className="border-bottom-div last-border
          ">
                <div className="d-flex">
                  <h4 className="mb-3 me-3 notes-text">Notes*
                  </h4>
                  <textarea value={appointmentData?.note}
                    placeholder="Please enter note"
                    maxLength={1000}
                    disabled={(!currentAppointments?.RecommendedTests || currentAppointments?.RecommendedTests.length === 0) ? true:false}
                    onChange={(e) => setAppointmentData({ ...appointmentData, note: e.target.value.trimStart() })}
                    className="form-control">
                  </textarea>
                </div>
              </div>
              
              <aside className="col-md-12 main-check-box">
                        <div key={`default-checkbox`} className="mb-3">
                        <Form.Group className="d-flex mt-2 align-items-center">
                          <b>
                          <Form.Check
                            type={"checkbox"}
                            value={checked}
                            disabled={(!currentAppointments?.RecommendedTests || currentAppointments?.RecommendedTests.length === 0) ? true:false}
                            style={{cursor:"pointer"}}
                            onChange={(e) => {
                              showHideModal(e);
                            }}
                            id={`default-checkbox`}
                            checked={checked}
                            label={"Next Visit Required?"}
                          />
                          </b>
                           {/* <Form.Label className="ms-2 d-block"><b>Next Visit Required? </b> </Form.Label> */}
                           </Form.Group>
                        </div>

                     
                     <>
                      {checked && selectedDate ? 
                     <div className="slot-changes">
                     <p><i class="fa fa-calendar" aria-hidden="true"></i>
 <DateTimeFormatter date={selectedDate} /> </p>
                     <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip_change}
                            >
                      <a onClick={()=>setShowAppointmentModal(true)}><i class="fa fa-clock-o" aria-hidden="true"></i>Change Date/Time</a>
                      </OverlayTrigger>
                      </div>
                       : ""}                      
                     </>
                      {/* : null} */}
                </aside>

              <div className="save-btn">
                <button onClick={() => onGenerate()} className="add-btn">
                  Generate Prescription
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Recommend Test modal start */}
      <Modal show={modalShow.recommendModal} onHide={() => onCloseModal("recommendModal")} centered>
        <Modal.Header closeButton className='p-0 pb-3'>
          <Modal.Title>Recommend Test</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-0'>
          <div className='recommend-box-upper'>
            <div className='recommend-box'>
            <span className="recommend-span-box">
            {isSeemar && (
                      <>
                    
                        {[1, 2, 3, 4].map(() => (
                          <SkeletonTheme>
                          
                            <Skeleton count={1} height={40} width={45} />
                          </SkeletonTheme>
                        ))}
                        
                      </>
                    
                    )}
              </span>
              {(!isSeemar && currentAppointments?.RecommendedTests)  && currentAppointments?.RecommendedTests?.map((item) => {
                return (
                    <span className={`${selectedItemTemp?.includes(item) ? "active" : ""} `} onClick={() => onSelectItem(item)}>{item}</span>
                   
                  
                  
                )
              })}
            </div>
            <div className='input-box'>
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <div className='d-flex align-items-center justify-content-between'>
                    <Form.Label>Add Recommend Test</Form.Label>
                    {/* <a onClick={() => onAddRecommendTest()} className='add-text'>Add</a> */}
                  </div>
                  <Form.Control onKeyPress={(e) => { handleKeyPres1(e) }} onChange={(e) => setOnAddValue(e.target.value.trimStart())} value={onAddValue} type="text" maxLength={50} placeholder="Press the 'Enter' key to add the test" className='mt-2' />
                  {testExists &&
                  <span style={{color:"red"}}>*
                  The test name already exists.</span>
                  }
                </Form.Group>

              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={selectedItemTemp.length == 0} onClick={() => onSaveRecommendTest()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Recommend Test modal end */}

      {/* Dietry Plan modal start */}
      <Modal show={modalShow.dietaryModal} onHide={() => onCloseModal("dietaryModal")} centered>
        <Modal.Header closeButton className='p-0 pb-3'>
          <Modal.Title>Dietary Plan </Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-0'>
          <div className='recommend-box-upper'>
            <div className='recommend-box'>
              {currentAppointments?.DietaryPlan?.map((item) => {
                return (
                  <span className={`${selectedItemTemp?.includes(item) ? "active" : ""} `} onClick={() => onSelectItem(item)}>{item}</span>
                )
              })}
            </div>
            <div className='input-box'>
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <div className='d-flex align-items-center justify-content-between'>
                    <Form.Label>Add Dietary Plan</Form.Label>
                    {/* <a onClick={() => onAddDietaryTest()} className='add-text'>Add</a> */}
                  </div>
                  <Form.Control onKeyPress={handleKeyPress} onChange={(e) => setOnAddValue(e.target.value.trimStart())} value={onAddValue} type="text" maxLength={50} placeholder="Press the 'Enter' key to add the test" className='mt-2' />
                  {dietExists &&
                  <span style={{color:"red"}}>*The diet name already exists.</span>
                  }
                </Form.Group>

              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={selectedItemTemp.length == 0} onClick={() => onSaveDietary()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Dietry Plan modal end */}

      {/* Medication modal start */}
      <Modal show={modalShow.medicationModal} onHide={() => onCloseModal("medicationModal")} centered className='medication-modal'>
        <Modal.Header closeButton className='p-0 pb-3'>
          <Modal.Title>Medication</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-0'>
          <div className='recommend-box-upper'>
            <div className="medication-table">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Medicine Name</th>
                    <th>Dosage</th>
                    <th>Route</th>
                    <th>Frequency</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {selectedItemTemp?.map((item, index) => {
                    return (
                      <tr>
                        <td className="w-34">
                          {console.log("suggestedMedicinessuggestedMedicines", suggestedMedicines)}
                          {/* <input className="form-control" placeholder="Paracetamol" value={item.medicine} onChange={(e) => onChangeMedicine(e.target.value, index, "medicine")} /> */}
                          <input className="form-control" placeholder="Paracetamol" value={item.medicine} onChange={(e) => {
                            var value = e.target.value;
                            if (value === "") {
                              setSuggestedMedicines([]);
                              setActiveIndex();
                              onChangeMedicine(value, index, "medicine")

                            } else {
                              setActiveIndex(index);
                              onChangeMedicine(value, index, "medicine")
                              onHandleChange(value)
                            }

                          }} />
                          <div className={activeIndex === index && suggestedMedicines.length > 0 ? "suggestion-tags siggestion-inner-box" : "suggestion-tags"}>
                            {suggestedMedicines && activeIndex === index ?
                              suggestedMedicines.map((res, i) => {
                                return (
                                  <span
                                    style={{ cursor: "pointer" }}
                                    className={
                                      i === medicineId
                                        ? "active tags"
                                        : "tags"
                                    }
                                    onClick={(res) => {
                                      console.log("result----+++", res)
                                      onChangeMedicine(res.target.innerText, index, "medicine")
                                      setActiveIndex();
                                      setMedicineId(i);
                                      setSuggestedMedicines([]);
                                    }}
                                  >
                                    {res.name}
                                  </span>


                                )


                              }) : ""
                            }
                          </div>
                        </td>

                        <td >

                          <input type="number" className="form-control" placeholder="1 tablet" value={item.dosage} onChange={(e) => {
                            if(e.target.value > 99999){
                              return false;
                            }else if(e.target.value < 0 ){
                              return false;
                            }else{
                              onChangeMedicine(e.target.value, index, "dosage")
                            }
                            }} />

                        </td>
                        <td >
                          <select onChange={(e) => onChangeMedicine(e.target.value, index, "route")} value={item.route} >
                            <option value="">Select Route</option>
                            <option value="Oral">Oral</option>
                            <option value="Injection">Injection</option>
                          </select>
                        </td>
                        <td className="w-20">
                          <select onChange={(e) => onChangeMedicine(e.target.value, index, "frequency")} value={item.frequency} >
                            <option value="">Select Frequency</option>
                            <option value="Qd-every day">Qd-every day</option>
                            <option value="od-1x a day">od-1x a day</option>
                            <option value="bid-2x a day">bid-2x a day</option>
                            <option value="tid-3x a day">tid-3x a day</option>

                            <option value="qid-4x a day">qid-4x a day</option>
                            <option value="qam-every morning">qam-every morning</option>
                            <option value="qpm-every night">qpm-every night</option>
                            <option value="q2h-every 2 hours">q2h-every 2 hours</option>
                            <option value="qod-every other day">qod-every other day</option>
                            <option value="hs- at bedtime">hs- at bedtime</option>

                            {/* <option value="In evening">In evening</option> */}

                          </select>
                        </td>
                        <td className="action-text">
                          {selectedItemTemp.length === 1 ?
                            null :
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip12}
                            >
                              <img
                                onClick={() => onRemoveMedication(index)}
                                className="me-2 "
                                src={
                                  require("../../assests/images/delete-action.svg")
                                    .default
                                }
                                alt=""
                              />

                            </OverlayTrigger>}
                          {selectedItemTemp.length - 1 === index &&
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip}
                            >
                              <img
                                onClick={() => onAddMore()}
                                className="me-2 "
                                src={
                                  require("../../assests/images/add-action.svg")
                                    .default
                                }
                                alt=""
                              />
                            </OverlayTrigger>}

                        </td>
                      </tr>
                    )
                  })}

                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary"  onClick={() => 
              onSaveMedication()
              }>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Medication modal end */}


      <Modal show={showAppointmentModal} onHide={handleCloseNext} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Slot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="key-point-box history-box required-box p-0">
            <div className="date-box">
              <div className="">
                <Row>
                  <Col md={12}>
                    <div className="position-relative date-inner">
                    <DatePicker
                      selected={nextAppointment}
                      onChange={handleDateChange}
                      filterDate={isDayEnabled}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      minDate={new Date()}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                     <i class="fa fa-calendar" aria-hidden="true"></i>
                     </div>
                    <div className="tags-box-main">
                      {availableSlots && availableSlots.length > 0
                        ? availableSlots.map((res) => {
                            return res.available ? (
                              <span
                                style={{ cursor: "pointer" }}
                                className={
                                  slot === res.time ? "tags active" : "tags"
                                }
                                onClick={(e) => {
                                  setSlot(res.time);
                                  setValid(true);
                                  
                                }}
                              >
                                <i class="fa fa-clock-o" aria-hidden="true"></i>
                                {removeSecondFromString(res.time)}
                              </span>
                            ) : (
                              <span
                                style={{ cursor: "pointer" }}
                                className={"tag-disables tags"}
                              >
                                <i class="fa fa-clock-o" aria-hidden="true"></i>
                                {removeSecondFromString(res.time)}
                              </span>
                            );
                          })
                        : ""}
                    </div>
                  </Col>
                  {/* <Col md={3}>
                    <a
                      onClick={() => {
                        scheduleRevisit();
                      }}
                      className="btn btn-primary"
                    >
                      Next Appointment
                    </a>
                  </Col> */}
                </Row>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
         
          {valid ? <Button variant="primary"  onClick={() => {scheduleRevisit()}}>
          Select
          </Button> : <Button variant="" className={valid ? "" :"btn-disabled"}>
          Select
          </Button>}

          
        </Modal.Footer>
      </Modal>

      <Modal
        className="success-box pdf-view-modal"
        backdrop="static"
        show={showModalReportPrecision} onHide={closeModalReportPrecision}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pdf-view-inner">
            <a
              onClick={() => setShareEmailsShow(true)}
              className="add-btn share-b"
            >
              <i class="fa fa-share-alt" aria-hidden="true"></i>
            </a>
            <iframe
              src={reportPrecisionUrl}
              frameborder="0"
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={shareEmailsShow}
        onHide={() => setShareEmailsShow(false)}
        centered
      >
        <Modal.Header closeButton className="p-0 pb-3">
          <Modal.Title>Share Prescription</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="recommend-box-upper">
            <div className="recommend-box">
              {shareEmails?.map((item) => {
                return (
                  <span
                    className={`${
                      selectedItemTempEmail?.includes(item) ? "active" : ""
                    } `}
                    onClick={() => onSelectItemEmail(item)}
                  >
                    {item}
                  </span>
                );
              })}
            </div>
            <div className="input-box">
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Label>Add Email</Form.Label>
                  </div>
                  <Form.Control
                    onKeyPress={emailHandleKeyPres1}
                    onChange={(e) => setOnAddValueEmail(e.target.value.trimStart())}
                    value={onAddValueEmail}
                    type="email"
                    placeholder="Press the 'Enter' key to add the email"
                    className="mt-2"
                  />
                  <p className="errors" style={{ color: "red" }}>
                    {emailError}
                  </p>
                </Form.Group>
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={selectedItemTempEmail.length==0} variant="primary" onClick={() => onShareWithEmail()}>
            Share
          </Button>
        </Modal.Footer>
      </Modal>


    </>
  );
};
export default UserMedicationPage;
