import { Badge, OverlayTrigger, Toast, Tooltip } from "react-bootstrap";
import { DateTimeFormatter } from "./common";
import Calendar from "react-calendar";
import { baseUrl } from "../../config/config";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { useState } from "react";

export const CalenderView = (props) => {
  const events = props.result;
  const [currentPage, setCurrentPage] = useState(0);

  const countEventsForDate = (date) => {
    return events.filter(
      (event) =>
        moment(event.appointment_time).format("YYYY-MM-DD") ===
        moment(date).format("YYYY-MM-DD")
    ).length;
  };

  const handlePageClick = (data) => {
    console.log("data.selected", data)
    
    const selectedPage = data.selected;
    console.log("selectedPage",selectedPage)
    setCurrentPage(selectedPage);
  };

  const startIndex = currentPage * 5;
  const endIndex = startIndex + 5;
  const currentItems = props.data.slice(startIndex, endIndex);
  console.log("currentItems",currentItems)

  // const renderTooltip = (e) => (

  //   <Tooltip id="button-tooltip" {...e}>
  //     View Detail
  //   </Tooltip>
  // );

  // Custom tile content function
  const tileContent = ({ date, view }) => {
    if (view === "month") {
      const eventCount = countEventsForDate(date);
      return eventCount > 0 ? (
        eventCount > 9 ? (
          <p className="appointment-text-main">9+</p>
        ) : (
          <p className="appointment-text-main">{eventCount}</p>
        )
      ) : null;
    }
    return null;
  };

  const tileClass = ({date, view}) => {
    if (view === "month") {
      const count = countEventsForDate(date);
      return count > 0 ? 'tile-class' :null;
      
    }
    return null;
    
  }

  return (
    <div className="calender-view-section width-wise-dashboard calender-view-box-section">
      <div className="container">
        <div className="row">
          <aside className="col-lg-4 col-md-12">
            <div className="calender-boxes ">
              <Calendar
                onChange={(e) => {
                  // handlePageClick({selected:0})
                  props.calenderData(e);
                }}
                value={props.value}
                onActiveStartDateChange={({
                  action,
                  activeStartDate,
                  value,
                  view,
                }) => {
                  let month = new Date(activeStartDate).getMonth() + 1;
                  let year = new Date(activeStartDate).getFullYear();
                  props.upcomingEvent(month, year);
                }}
                tileContent={tileContent}
                tileClassName={(activeStartDate, view) => tileClass(activeStartDate, view)}
                // tileContent={({tileContent}) => {
                //   props.title(date,view)
                // }}
              />
            </div>
          </aside>
          <aside className="col-lg-8 col-md-12">
            <div className="appointment-list-view calender-list-view-section">
              <h4 className="mb-4">
                Appointments{" "}
                <span className="purple-txt">({props.data.length})</span>
              </h4>
              {currentItems.length > 0 ? (
                currentItems.map((res, index) => {
                  {console.log("statusstatus", res.status)}
                  return (
                    <div
                      class="appointments_box request_appointments_box doctor-side toggle_up"
                      id={"toggle_" + res.id}
                    >
                      <div class="appointment_innerbox">
                        <div className="appointment-content-image">
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              props.profile(
                                res.id,
                                res.member != null ? "someone" : "me",
                                "calender",
                                res.appointment_time
                              );
                            }}
                          >
                            <img
                              src={
                                res?.user?.profile_image
                                  ? baseUrl + res?.user?.profile_image
                                  : res?.user?.picture
                              }
                              referrerpolicy="no-referrer"
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="appointment-content-text">
                          <h3
                            class="category_name"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              props.profile(
                                res.id,
                                res.member != null ? "someone" : "me",
                                "calender",
                                res.appointment_time
                              );
                            }}
                          >
                            <b>{res.name}</b>
                          </h3>
                          <p class="short_info purple-txt">
                            Reason For Visit: <br />
                            <span>{res.reason}</span>
                          </p>

                          <div class="appointment_rightarea text-right">
                            <p>
                              <DateTimeFormatter date={res.appointment_time} />
                            </p>
                            <span
                              class="appointment_togglebtn my-2"
                              onClick={() => {
                                props.toggle(res.id);
                              }}
                            >
                              <i
                                class="fa fa-chevron-down"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <div className="icons-left">
                              {res?.call?.can_call && res?.call?.have_log && (
                                <a
                                  class="call-history-box"
                                  onClick={(e) => {
                                    props.callLogs(res.id);
                                  }}
                                >
                                  <img
                                    src={
                                      require("../../assests/images/call-history.svg")
                                        .default
                                    }
                                    alt="img"
                                    className="call-history-icon"
                                  />
                                </a>
                                // </OverlayTrigger>
                              )}

                                <a
                                  class="cc-calto-action-ripple "
                                  title={
                                    !res?.call?.can_call ? "On Call" : "Call"
                                  }
                                  onClick={
                                    !res?.call?.can_call
                                      ? () => {
                                          return false;
                                        }
                                      : () => {
                                          props.callTo(res.id);
                                        }
                                  }
                                >
                                  <i
                                    class={`fa fa-phone ${
                                      !res?.call?.can_call
                                        ? "disable-call"
                                        : ""
                                    }`}
                                  ></i>
                                </a>

                              <a
                                className="download_box"
                                title="View Details"
                                onClick={() => {
                                  props.profile(
                                    res.id,
                                    res.member != null ? "someone" : "me",
                                    "calender",
                                    res.appointment_time
                                  );
                                }}
                              >
                                <i class="fa fa-eye"></i>
                              </a>
                              {res.status == 'not-attended' ?
                                      <Badge
                                       bg="warning"
                                       className="completed-text calender-badge"

                                     >
                                     Not-Attended
                                     </Badge>

                                     : res.status == 'completed'? <Badge
                                     bg="danger"
                                     className="completed-text calender-badge"
                                   >
                                    Completed
                                   </Badge> : <Badge
                                     bg="success"

                                     className="completed-text calender-badge"
                                   >
                                    Upcoming
                                   </Badge>}

                              {/* </OverlayTrigger> */}
                              {/* <Toast
                                show={res.show_call_btn == true}
                                onClose={() =>
                                  props.showHide(res, index, false)
                                }
                              >
                                <Toast.Header>
                                  <strong className="me-auto">Call</strong>
                                </Toast.Header>
                                {props.duplicate ? (
                                  <p>
                                    {" "}
                                    <img
                                      src={require("../../assests/images/check-main.png")}
                                      alt="img"
                                    />
                                    Copied
                                  </p>
                                ) : (
                                  ""
                                )}
                                <Toast.Body>
                                  <input
                                    type="text"
                                    id="copy-phone"
                                    value={res.phone}
                                    readOnly
                                  ></input>

                                  <i
                                    class="fa fa-clone"
                                    onClick={() => {
                                      props.copy();
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                </Toast.Body>
                              </Toast> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="appointment_fullcontent">
                        <p className="purple-txt">Reason For Visit: </p>
                        <span>{res.reason}</span>
                        <p className="purple-txt mb-0 mt-2">Patient: </p>
                        <span>
                          {res.member ? res.member.name : res.user.name},{" "}
                          {res.member
                            ? props.age(res.member.dob)
                            : props.age(res.user.dob)}
                          , {res.member ? res.member.gender : res.user.gender}
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="no-appoitment-schedule">
                  <img
                    src={
                      require("../../assests/images/no-appointment.svg").default
                    }
                    alt="img"
                  />
                  <p>No Appointment Schedule</p>
                </div>
              )}
              {props.data.length > 5 && (
                <ReactPaginate
                  pageCount={Math.ceil(props.data.length / 5)}
                  // Number of pages to display at the beginning and end of the pagination bar
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  activeClassName="active"
                />
              )}
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
};
