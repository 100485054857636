import { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Pricing from "./Pricing";
import { useContext } from "react";
import { context } from "../../GlobalContext/GlobalContext";
import {
  DEFAULT_PLAN_ID,
  EMAILREGEX,
  Project_Title,
} from "../../config/config";
import { useRef } from "react";
import { openAiService } from "../../services/openai.services";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReCAPTCHA from "react-google-recaptcha";
import { AssetRender } from "../common/common";
import videoFile from "../../assests/images/home-right-video.mp4"

const ct = require("countries-and-timezones");

const timezone = ct.getTimezone(
  Intl.DateTimeFormat().resolvedOptions().timeZone
);
const COUNTRY_CODE = timezone.countries[0];
const myStyles = {
  transform: 'scale(0.77)',
  width: '100%',
};

const UpdatedHome = () => {
  const navigate = useNavigate();
  const globalData = useContext(context);
  const location = useLocation();
  const pricingSectionRef = useRef();
  const [contactUsFields, setContactUsFields] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
  });
  const [isCaptchaValue, setIsCaptchaValue] = useState();
  const [contactUsFieldsErrors, setContactUsFieldsErrors] = useState({
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    message: false,
  });
  const [isCollapsed, setIsCollapsed] = useState({
    isCollapsedOne: false,
    isCollapsedTwo: false,
    isCollapsedThree: false,
    isCollapsedFour: false,
    isCollapsedFive: false,
  });
  const [isImportant, setIsImportant] = useState({
    isCollapsedOne: false,
    isCollapsedTwo: false,
    isCollapsedThree: false,
    isCollapsedFour: false,
    isCollapsedFive: false,
  });


  return (
    <>
      <section className="common-background-section">
        <div className="under-banner-section">
          <Container>
            <Row>
              <Col md={12}  data-aos="fade-up">
                <div className="hero-section-content text-center" >
                  <h1 className="plan-text">Plan Your Month </h1>
                  <h4 className="plan-text-organize">Organize your calendar with AI for</h4>
                  <h3 className="plan-text-maximum">maximum efficiency</h3>
                  <Button className="get-started-btn inner-get-started">Get Started </Button>
                </div>
                <div className="under-image-section position-relative">
                  <h4 className="plan-text-organize mb-5">Create More Time With Smart Features </h4>
                  <img
                    className=""
                    src={require("../../assests/images/laptop-avail.png")}
                    alt="img"
                  />
                   <img
                   data-aos="fade-left"
                    className=" mobile-screen"
                    src={require("../../assests/images/mobile-avail.png")}
                    alt="img"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="craft-section">
          <Container>
            <Row className="align-items-center padding-btm">
              <Col lg={6} data-aos="fade-up">
                <img
                  className="wi100"
                  src={require("../../assests/images/craft-img.svg").default}
                  alt="img"
                />
              </Col>
              <Col lg={6} data-aos="fade-up">
                <div className="craft-box" data-aos="fade-up">
                  <h5>RESOURCE SCHEDULING
                  </h5>
                  <h3>Craft high-level schedules</h3>
                  <p>The Resource Scheduling feature creates optimized schedules with drag-and-drop ease, integrating seamlessly with project management tools.</p>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center padding-btm">
              <Col lg={6} data-aos="fade-up">
                <div className="craft-box">
                  <h5>CAPACITY MANAGEMENT
                  </h5>
                  <h3>Look at the big picture</h3>
                  <p> Capacity Management monitors workloads, preventing overload and ensuring efficient resource allocation with utilization reports and forecasting tools. High-level dashboards offer quick insights into overall capacity and resource distribution.</p>
                </div>
              </Col>
              <Col lg={6} data-aos="fade-up">
              <div className="position-relative">
                <div className="web-look">
                <img
                  className="wi100"
                  src={require("../../assests/images/look-img.svg").default}
                  alt="img"
                />
                <img
                  className="bell-shrink"
                  src={require("../../assests/images/bell.svg").default}
                  alt="img"
                />
                </div>
                <img
                  className="mobile-show-look"
                  src={require("../../assests/images/look-mobile-img.svg").default}
                  alt="img"
                />
                
                </div>
              </Col>
            </Row>
            <Row className="align-items-center padding-btm">
              <Col lg={6} data-aos="fade-up">
                <img
                  className="wi100"
                  src={require("../../assests/images/stay-img.svg").default}
                  alt="img"
                />
              </Col>
              <Col lg={6} data-aos="fade-up">
                <div className="craft-box">
                  <h5>FORECASTING
                  </h5>
                  <h3>Stay one step ahead</h3>
                  <p>Ai Schedule forecasts staffing needs using historical data, trends, and AI algorithms, adjusting schedules with seasonal and real-time data. Managers simulate scenarios for informed staffing decisions.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="customer-section">
          <Container>
            <Row>
              <Col md={4}>
                <div className="customer-main-box text-center">
                  <h2>1232+</h2>
                  <h6>HAPPY CLIENTS</h6>
                </div>
              </Col>
              <Col md={4}>
                <div className="customer-main-box text-center">
                  <h2>130+</h2>
                  <h6>TECHNICIANS</h6>
                </div>
              </Col>
              <Col md={4}>
                <div className="customer-main-box text-center">
                  <h2>800+</h2>
                  <h6>CUSTOMERS</h6>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="get-started-section">
          <Container>
            <Button className="get-started-btn inner-get-started">Get Started </Button>
          </Container>
        </div>
        <div className="enterprises-started-section">
          <Container>
            <h2 className="mb-3">Enterprise security, support,
              & scalability</h2>
            <Row className="justify-content-center">
              <Col md={5}>
                <div className="enterprises-box">
                  <img
                    className="wi100"
                    src={require("../../assests/images/enterprises.svg").default}
                    alt="img"
                  />
                  <h5>Live human support</h5>
                  <p>Get live chat & email support, with a €20 minute
                    average response time.</p>
                </div>
              </Col>
              <Col md={5}>
                <div className="enterprises-box">
                  <img
                    className="wi100"
                    src={require("../../assests/images/enterprises.svg").default}
                    alt="img"
                  />
                  <h5>99.9% uptime</h5>
                  <p>Advanced high availability & disaster recovery
                    you can trust</p>
                </div>
              </Col>
              <Col md={5}>
                <div className="enterprises-box">
                  <img
                    className="wi100"
                    src={require("../../assests/images/enterprises.svg").default}
                    alt="img"
                  />
                  <h5>SOC 2 Type 11</h5>
                  <p>Proud to hold the highest standards lor security
                    & compliance.</p>
                </div>
              </Col>
              <Col md={5}>
                <div className="enterprises-box">
                  <img
                    className="wi100"
                    src={require("../../assests/images/enterprises.svg").default}
                    alt="img"
                  />
                  <h5>GDPR & DPF</h5>
                  <p>Your personal data is always properly handled.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="advantage advantage2 common-padding no-bg">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
                <div className="NFTICALLYAdvantageContent">
                  <h2 className="text-light text-center mb-4">FAQs</h2>
                  <p></p>
                </div>
                <div className="capabilities-section">
                  <div id="accordionExample" className="accordion shadow">
                    {/* <!-- Accordion item 1 --> */}
                    <div className="card">
                      <div
                        id="headingOne1"
                        className="card-header bg-white shadow-sm border-0"
                      >
                        <h2 className="mb-0">
                          <button
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne1"
                            aria-expanded="false"
                            aria-controls="collapseOne1"
                            className={isCollapsed.isCollapsedOne ? "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link" : "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link collapsed"}
                            onClick={() =>
                              setIsCollapsed({
                                ...isCollapsed,
                                isCollapsedOne: isCollapsed.isCollapsedOne ? false : true,
                                isCollapsedTwo: false,
                                isCollapsedThree: false,
                                isCollapsedFour: false,
                                isCollapsedFive: false,
                              })
                            }
                          >
                            1. What is {Project_Title}?
                          </button>
                        </h2>
                      </div>
                      {isCollapsed.isCollapsedOne ? (
                        <div
                          id="collapseOne1"
                          aria-labelledby="headingOne1"
                          data-parent="#accordionExample"
                          className="collapse"
                        // className={isCollapsed ? "collapse show":"collapse" }
                        >
                          <div className="card-body p-4">
                            <p className="font-weight-light m-0">
                              {Project_Title} is an innovative platform that
                              offers AI-powered healthcare advice and solutions at
                              affordable prices. It allows users to easily
                              comprehend complex medical documents and conduct
                              research in any language.
                            </p>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {/* <!-- End --> */}

                    {/* <!-- Accordion item 2 --> */}
                    <div className="card">
                      <div
                        id="headingTwo2"
                        className="card-header bg-white shadow-sm border-0"
                      >
                        <h2 className="mb-0">
                          <button
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseTwo2"
                            aria-expanded="false"
                            aria-controls="collapseTwo2"
                            className={isCollapsed.isCollapsedTwo ? "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link" : "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link collapsed"}
                            onClick={() =>
                              setIsCollapsed({
                                ...isCollapsed,
                                isCollapsedOne: false,
                                isCollapsedTwo: !isCollapsed.isCollapsedTwo,
                                isCollapsedThree: false,
                                isCollapsedFour: false,
                                isCollapsedFive: false,
                              })
                            }
                          >
                            2. Who can benefit from the {Project_Title} platform?
                          </button>
                        </h2>
                      </div>
                      {isCollapsed.isCollapsedTwo ? (
                        <div
                          id="collapseTwo2"
                          aria-labelledby="headingTwo2"
                          data-parent="#accordionExample"
                          className="collapse"
                        >
                          <div className="card-body p-4">
                            <p className="font-weight-light m-0">
                              The {Project_Title} platform is designed to benefit
                              various groups, including patients, healthcare
                              professionals, researchers, healthcare
                              organizations, individuals seeking health
                              information, students, and educators. It aims to
                              support a wide range of individuals and entities in
                              accessing reliable healthcare information, making
                              informed decisions, improving healthcare outcomes,
                              and enhancing the overall healthcare experience.
                            </p>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {/* <!-- End --> */}

                    {/* <!-- Accordion item 3 --> */}
                    <div className="card">
                      <div
                        id="headingThree3"
                        className="card-header bg-white shadow-sm border-0"
                      >
                        <h2 className="mb-0">
                          <button
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseThree3"
                            aria-expanded="false"
                            aria-controls="collapseThree3"
                            className={isCollapsed.isCollapsedThree ? "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link" : "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link collapsed"}
                            onClick={() =>
                              setIsCollapsed({
                                ...isCollapsed,
                                isCollapsedOne: false,
                                isCollapsedTwo: false,
                                isCollapsedThree: !isCollapsed.isCollapsedThree,
                                isCollapsedFour: false,
                                isCollapsedFive: false,
                              })
                            }
                          >
                            3. What countries/languages does {Project_Title}{" "}
                            support?
                          </button>
                        </h2>
                      </div>
                      {isCollapsed.isCollapsedThree ? (
                        <div
                          id="collapseThree3"
                          aria-labelledby="headingThree3"
                          data-parent="#accordionExample"
                          className="collapse"
                        >
                          <div className="card-body p-4">
                            <p className="font-weight-light m-0">
                              {Project_Title} has been trained on a diverse range
                              of text data, including medical and health-related
                              information. As a result, it can understand and
                              respond to queries related to various medical
                              topics. However, it is essential to remember that{" "}
                              {Project_Title} is not a licensed medical
                              professional, and its responses should not be
                              considered a substitute for professional medical
                              advice.
                            </p>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {/* <!-- End --> */}

                    <div className="card">
                      <div
                        id="headingFour4"
                        className="card-header bg-white shadow-sm border-0"
                      >
                        <h2 className="mb-0">
                          <button
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseFour4"
                            aria-expanded="false"
                            aria-controls="collapseFour4"
                            className={isCollapsed.isCollapsedFour ? "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link" : "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link collapsed"}
                            onClick={() =>
                              setIsCollapsed({
                                ...isCollapsed,
                                isCollapsedOne: false,
                                isCollapsedTwo: false,
                                isCollapsedThree: false,
                                isCollapsedFour: !isCollapsed.isCollapsedFour,
                                isCollapsedFive: false,
                              })
                            }
                          >
                            4. How can I start working with {Project_Title}?
                          </button>
                        </h2>
                      </div>
                      {isCollapsed.isCollapsedFour ? (
                        <div
                          id="collapseFour4"
                          aria-labelledby="headingFour4"
                          data-parent="#accordionExample"
                          className="collapse"
                        >
                          <div className="card-body p-4">
                            <p className="font-weight-light m-0">
                              To begin using the {Project_Title} platform, you can
                              log in using your Google account. Afterward, you
                              will need to choose a plan that suits your needs,
                              and then you can proceed further with utilizing the
                              platform's features and benefits.
                            </p>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {/* <!-- End --> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="work-with-us">
          <Container>
            <Row className="justify-content-center">
              <Col md={10}>
                <div className="work-with-us-box">
                  <div className="d-flex align-items-center justify-content-between">
                    <h2>WANT TO WORK WITH US</h2>
                    <Button className="get-started-btn inner-get-started">contact us </Button>
                  </div>
                  <p>We’re here to assist you with all your AI needs. Whether you have questions about our services, need a custom AI solution,</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default UpdatedHome;
