import React, { useRef, useState, useContext, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Row,
} from "react-bootstrap";
import { openAiService } from "../../services/openai.services";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { allBloodGroup, baseUrl } from "../../config/config";
import { context } from "../../GlobalContext/GlobalContext";
import { useNavigate } from "react-router-dom";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import Swal from "sweetalert2";
import OtpInput from "react-otp-input";
import { setItem } from "../utils/localStorage";
import { Loading } from "../common/loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const MyProfile = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [nameerror, setNameerror] = useState("");
  const [blood, setBlood] = useState("");
  const [blooderror, setBlooderror] = useState("");
  const [heightft, setHeightft] = useState("");
  const [heightfterror, setHeightfterror] = useState("");
  const [heightinch, setHeightinch] = useState(0);
  const [heightincherror, setHeightincherror] = useState("");
  const [dob, setDob] = useState(new Date("2010-01-01"));
  const [doberror, setDoberror] = useState("");
  const [weight, setWeight] = useState("");
  const [weighterror, setWeighterror] = useState("");
  const bloodGroupRegex = /^(A|B|AB|O)[+-]$/;
  const [mobile, setMobile] = useState("");
  const [temporaryNumber,setTemporaryNumber] = useState("");
  const [mobileCode, setmobileCode] = useState("91");
  const [profileImage, setProfileImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const [otp, setotp] = useState("");
  const [otpErr, setotpErr] = useState("");
  const [mobileErr, setMobileErr] = useState();
  const [verifymobile, setverifymobile] = useState(false);
  const [authToken, setauthToken] = useState(localStorage.getItem("token"));
  const [email, setEmail] = useState(
    JSON.parse(localStorage.getItem("user")).email
  );
  const [profileData, setprofileData] = useState("");
  const [showLoader, setshowLoader] = useState(false);
  const [gender, setGender] = useState("");
  const [genderError, setGendererror] = useState("");
  const globalData = useContext(context);
  const globalDataRef = useRef();
  const [counter, setCounter] = useState(0);
  globalDataRef.current = globalData;
  const [medication, setMedication] = useState([
    { name: "", dosage: "", unit: "MG", id: "" },
  ]);
  const [delmedication, setDelMedication] = useState([]);

  useEffect(() => {
    getProfiles();
  }, []);
  const handleClose = () => {
    setShowModal(false);
  };
  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);
  const handleshow = () => {
    setshowLoader(true);
    let params = {
      phone: mobile,
    };
    openAiService
      .send_otp(params)
      .then((res) => {
        setshowLoader(false);
        setShowModal(true);
        setCounter(60);
      })
      .catch((error) => {
        setMobileErr(error.response.data.error);
        setshowLoader(false);
      });
  };

  const resendOtp = () => {
    let params = {
      phone: mobile,
    };
    openAiService
      .send_otp(params)
      .then((res) => {
        setshowLoader(false);
        setCounter(60);
      })
      .catch((error) => {
        setshowLoader(false);
      });
  };

  const verifyOtp = () => {
    let params = {
      phone: mobile,
      otp: otp,
    };
    openAiService
      .verify_otp(params)
      .then((res) => {
        Swal.fire(
          "success",
          " Your phone number has been verified.",
          "success"
        );
        handleClose();
        setverifymobile(true);
        setotp("");
        setotpErr("");
        setCounter(60);
      })
      .catch((error) => {
        console.log(error.response.data.error);
        setotpErr(error.response.data.error);
        setshowLoader(false);
      });
  };

  function getProfiles() {
    console.log(">>>>>>>res>>>>>>>>>>>", localStorage.getItem("token"));
    console.log("browser is running",/^((?!chrome|android).)*safari/i.test(navigator.userAgent))
    setshowLoader(true);
    openAiService
      .getProfiles()
      .then((res) => {
        console.log(">>>>>>>res>>>>>>>>>>>", res.data);
        setEmail(res.data?.data.email);
        setName(res.data?.data.name);
        setBlood(res.data?.data.blood_group);
        setWeight(res.data?.data.weight);
        setGender(res.data?.data.gender);
        setImageUrl(res.data?.data?.profile_image ? baseUrl + res.data?.data?.profile_image : res.data?.data?.picture)
        if (res.data?.data.phone) {
          setMobile(res.data?.data.phone);
          setTemporaryNumber(res.data?.data.phone);
          setverifymobile(true);
        }

        setprofileData(res.data?.data);
        // console.log(">>>>>>>heightdata>>>0000>>>>>>>>", res.data.data.height.split("."))
        let heightdata = res.data.data.height.toString();

        setHeightft(heightdata.split(".")[0]);
        setHeightinch(heightdata.split(".")[1]);

        if (res.data?.data.medication.length > 0) {
          setMedication(res.data?.data.medication);
        }

        moment(res.data?.data?.dob).format("MM/DD/YYYY");
        setDob(moment(res.data?.data?.dob).format("YYYY-MM-DD"));
        console.log(
          ">>>>>>>heightdata>>>>>>>>>>>",
          moment(res.data?.data?.dob).format("MM/DD/YYYY")
        );
        setshowLoader(false);
      })
      .catch((error) => {
        console.log(">>>>>>>heightdata>>>=nnnnnn====>>>>>>>>", error);
        // if(error?.response?.status === 401){
        //   localStorage.clear()
        //   globalDataRef.current.setSignUpModal(true)
        //   navigate("/")
        // }
        setshowLoader(false);
      });
  }
  function onChangeMedicationName(index, value) {
    let tempList = [...medication];
    tempList[index].name = value;
    setMedication(tempList);
  }

  function onChangeMedicationDosage(index, value) {
    let tempList = [...medication];
    tempList[index].dosage = value;
    setMedication(tempList);
  }

  function onChangeMedicationUnit(index, value) {
    let tempList = [...medication];
    tempList[index].unit = value;
    setMedication(tempList);
  }

  function addMoreMedications() {
    let tempList = [...medication];
    tempList.push({ id: "", name: "", dosage: "", unit: "MG" });
    setMedication(tempList);
  }

  function DeleteAddMoreMedications(index, item) {
    let tempList = [...medication];

    tempList.splice(index, 1);
    setMedication(tempList);

    // console.log(item)
    // tempListDel.push(item.id)
    // (tempListDel)
    if (item.id != "") {
      let newArray = [...delmedication, item.id];
      console.log(newArray);
      setDelMedication(newArray);
    }
  }

  function onChangeName(e) {
    let data = e.target.value;
    console.log(e.target.value.length);
    if (e.target.value.trim() === "") {
      setNameerror("Name field is required.");
      setName("");
    } else if (e.target.value.length > 30) {
      setNameerror("Name field should have 30 characters only.");
      // setName("")
    } else {
      setNameerror("");
      setName(data);
    }
  }

  function onChangeBlood(e) {
    let data = e.target.value;
    if (!data) {
      setBlooderror("Blood Group field is required.");
      setBlood("");
    } else if (!bloodGroupRegex.test(data)) {
      setBlooderror(
        "Invalid blood group. Please enter a valid blood group (e.g., A+, B-, AB+)."
      );
      setBlood("");
    } else {
      setBlooderror("");
      setBlood(data);
    }
    // setBlood(data)
  }

  function onChangeGender(e) {
    let data = e.target.value;
    if (!data) {
      setGendererror("Blood Group field is required.");
      setGender("");
    } else {
      setGendererror("");
      setGender(data);
    }
    // setBlood(data)
  }
  function onPhoneChanged(a, b, c, d) {
    setmobileCode("+" + b.dialCode); 
    setMobile("+" + a);
    if (a.length > 10) {
      if(temporaryNumber == "+" + a){
        setverifymobile(true);
      }else{
        setverifymobile(false);
      }
    }
  }
  function onChangeHeightFt(e) {
    let data = e.target.value;
    if (!data) {
      setHeightfterror("Height field is required.");
      setHeightft("");
    } else if (isNaN(data)) {
      setHeightfterror("Height must be a number");
      setHeightft("");
    } else if (parseFloat(data) <= 0) {
      setHeightfterror("Height must be greater than 0");
      setHeightft("");
    } else {
      setHeightfterror("");
      setHeightft(data);
    }
    // setHeightft(data)
  }

  function onChangeDob(date) {
    let data = date;
    console.log("????????dob..", data);
    const selectedDate = new Date(data);
    console.log("selected", selectedDate)
    const currentDate = new Date("2010-01-01");
    if (!data) {
      setDoberror("DOB field is required");
      setDob("");
    } else if (isNaN(selectedDate.getTime())) {
      setDoberror("Invalid date of birth");
      setDob("");
    } else if (selectedDate > currentDate) {
      setDoberror("DOB can't be set as a future date.");
      setDob("");
    } else {
      setDoberror("");
      setDob(moment(data).format("YYYY-MM-DD"));
    }
    // setDob(data)
  }

  function onChangeWeight(e) {
    let data = e.target.value;
    if (!data) {
      setWeighterror("Weight field is required");
      setWeight("");
    } else if (isNaN(data)) {
      setWeighterror("Weight must be a number");
      setWeight("");
    } else if (data <= 0) {
      setWeighterror("Weight must be greater than 0");
      setWeight("");
    } else {
      setWeighterror("");
      setWeight(data);
    }
    // setWeight(data)
  }

  function onChangeHeightInch(e) {
    let data = e.target.value;
    if (isNaN(data)) {
      setHeightincherror("Inches must be a number");
      setHeightinch("");
    } else if (parseFloat(data) < 0 || parseFloat(data) >= 12) {
      setHeightincherror("Inches must be between 0 and 11");
      setHeightinch("");
    } else {
      setHeightincherror("");
      setHeightinch(data);
    }
    // setHeightinch(data)
  }

  function OnSubmit(e) {
    e.preventDefault();
    if (name) {
      setNameerror("");
      // setName(data)
    } else {
      setNameerror("Name field is required.");
      // setName("")
    }

    if (!blood) {
      setBlooderror("Blood group field is required.");
    } else if (!bloodGroupRegex.test(blood)) {
      setBlooderror(
        "Invalid blood group. Please enter a valid blood group (e.g., A+, B-, AB+)."
      );
      //  setBlood("")
    } else {
      setBlooderror("");
      // setBlood(blood)
    }

    if (!heightft) {
      setHeightfterror("Height field is required.");
    } else if (isNaN(heightft)) {
      setHeightfterror("Height must be a number.");
      // setHeightft("")
    } else if (parseFloat(heightft) <= 0) {
      setHeightfterror("Height must be greater than 0");
      // setHeightft("")
    } else {
      setHeightfterror("");
      // setHeightft(heightft)
    }

    const selectedDate = new Date(dob);
    const currentDate = new Date();

    if (!dob) {
      setDoberror("DOB field is required.");
    } else if (isNaN(selectedDate.getTime())) {
      setDoberror("Invalid date of birth");
      // setDob("")
    } else if (selectedDate > currentDate) {
      setDoberror("DOB can't be set as a future date.");
      // setDob("")
    } else {
      setDoberror("");
      // setDob(dob)
    }

    if (!weight) {
      setWeighterror("Weight field is required.");
    } else if (isNaN(weight)) {
      setWeighterror("Weight must be a number");
      // setWeight("")
    } else if (weight <= 0) {
      setWeighterror("Weight must be greater than 0");
      // setWeight("")
    } else {
      setWeighterror("");
      // setWeight(weight)
    }


    if (isNaN(heightinch)) {
      setHeightincherror("Inches must be a number");
      // setHeightinch("")
    } else if (parseFloat(heightinch) < 0 || parseFloat(heightinch) >= 12) {
      setHeightincherror("Inches must be between 0 and 11");
      // setHeightinch("")
    } else {
      // console.log(inches);
      setHeightincherror("");
      // setHeightinch(heightinch)
    }
    if (mobile != "" && !verifymobile && mobile.length > mobileCode.length) {
      setMobileErr("Please verify your mobile.");
    } else {
      setMobileErr();
    }

    if (
      authToken &&
      name &&
      !nameerror &&
      blood &&
      !blooderror &&
      heightft &&
      !heightfterror &&
      !heightincherror &&
      dob &&
      !doberror &&
      weight &&
      !weighterror &&
      (verifymobile & (mobile.length > mobileCode.length) ||
        mobile == "" ||
        mobile.length == mobileCode.length)
    ) {
      // api perform
      // console.log("api perform........")
      // user datails api
      function userDetails() {
        let height = heightft;
        if (heightinch > 0) {
          height = heightft + "." + heightinch;
        }
        // debugger
        setshowLoader(true);
        var formdata = new FormData();
        formdata.append("name", name);
        formdata.append("blood_group", blood);
        formdata.append("heightft", height);
        formdata.append("dob", dob);
        formdata.append("weight", weight);
        if (mobile != "" && mobile && verifymobile) {
          formdata.append("phone", (mobile != "" && mobile && verifymobile) ? mobile : null);
        }
        formdata.append("heightinch", heightinch);
        formdata.append("medication", JSON.stringify(medication));
        formdata.append("delmedication", JSON.stringify(delmedication));
        formdata.append("gender", gender);
        if (profileImage) {
          formdata.append("profile_image", profileImage)
        }



        // const params = {
        //   name: name,
        //   blood_group: blood,
        //   heightft: height,
        //   dob: dob,
        //   weight: weight,
        //   phone: null,

        //   heightinch: heightinch,
        //   medication: medication,
        //   delmedication: delmedication,
        //   gender: gender,
        // };

        // if (mobile != "" && mobile && verifymobile) {
        //   params.phone = mobile;
        // }
        openAiService
          .user_detail(formdata)
          .then((res) => {
            setshowLoader(false);
            setItem("user", res.data.data);
            Swal.fire(
              "success",
              "Profile data updated successfully.",
              "success"
            ).then(() => {
              navigate("/my_profile");
            });
            console.log("res----------->", res);
          })
          .catch((error) => {
            setshowLoader(true);
            console.log("Error", error);
          });
      }
      userDetails();
    }
  }

  return (
    <>
      {/* {showLoader && (
        <div className="main-loader">
          <div class="loader3">
            <span></span>
            <span></span>
          </div>
        </div>
      )} */}

    {showLoader && <Loading />}

      <section className="pricing-area profile-main">
        <div className="container"></div>
        <Container>
          <Row className="justify-content-center">
            <Col md={12}>
              <div className="profile-content-area my-profile-content">
                <h2>My Profile</h2>
                <Row >
                  <Col md={4} lg={3}>
                    <div className="upload-family-img profile-upload">
                      <img
                        className=""
                        id="member_image"
                        src={imageUrl ? imageUrl : require("../../assests/images/family-img.png")}
                        alt="img"
                      />
                      <Form.Control id="member_profile" onChange={(e) => {
                        setProfileImage(e.target.files[0])
                        setImageUrl(URL.createObjectURL(e.target.files[0]))
                      }} type="file" accept="image/*"></Form.Control>
                      <p>Upload Profile Picture here</p>
                    </div>
                  </Col>
                  <Col md={8} lg={9}>
                    <Row>
                      <Col md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Name*</Form.Label>
                          <Form.Control
                            type="text"
                            value={name}
                            placeholder="Enter Name"
                            onChange={(e) => onChangeName(e)}
                            onKeyDown={(e) => {
                              if (!e.target.value && e.code === "Space") {
                                e.preventDefault();
                              }
                            }}
                          />
                          <p style={{ color: "red" }}>{nameerror}</p>
                        </Form.Group>
                      </Col>
                      <Col md={6} lg={4}>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Blood Group*</Form.Label>
                          <Form.Select
                            value={blood}
                            aria-label="Default select example"
                            onChange={(e) => onChangeBlood(e)}
                          >
                            <option>Select Blood Group</option>
                            {allBloodGroup.map((groupBlood, index) => {
                              // {console.log(groupBlood)}
                              return (
                                <option value={groupBlood}>{groupBlood}</option>
                              );
                            })}
                          </Form.Select>
                          {/* <Form.Control
    type="text"
    placeholder="Enter Blood Group"
    onChange={(e) => onChangeBlood(e)}
    onKeyDown={(e) => {
      if (!e.target.value && e.code === "Space") {
        e.preventDefault();
      }
    }}
  /> */}
                          {/* {blooderror} */}
                          <p style={{ color: "red" }}>{blooderror}</p>
                        </Form.Group>
                      </Col>
                      <Col md={6} lg={4}>
                        <Form.Group className="mb-3 date-icon main" controlId="formBasicPassword">
                          <Form.Label>DOB*</Form.Label>
                          {/^((?!chrome|android).)*safari/i.test(navigator.userAgent) ?
                            <DatePicker
                            selected={new Date(dob)}
                            onChange={(e) => onChangeDob(e)}
                            dateFormat="yyyy-MM-dd"
                            className="form-control"
                            placeholderText="Enter DOB"
                            maxDate={new Date("2010-01-01")}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                          />
                          
                          :<Form.Control
                          value={dob}
                          type="date"
                          max="2010-01-01"
                          placeholder="Enter DOB"
                          onChange={(e) => onChangeDob(e.target.value)}
                          onKeyDown={(e) => {
                            return false;
                            // if (!e.target.value && e.code === "Space") {
                            //   e.preventDefault();
                            // }
                          }}
                        />
                      }
                          
                          
                          {/* {doberror} */}
                          <i class="fa fa-calendar" aria-hidden="true"></i>
                          <p style={{ color: "red" }}>{doberror}</p>
                        </Form.Group>
                      </Col>
                      <Col md={6} lg={4}>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Weight(KG)*</Form.Label>
                          <Form.Control
                            value={weight}
                            type="number"
                            onWheel={() => document.activeElement.blur()}
                            placeholder="Enter Weight eg(65.5)"
                            min={1}
                            onChange={(e) => onChangeWeight(e)}
                            onKeyDown={(e) => {
                              if (!e.target.value && e.code === "Space") {
                                e.preventDefault();
                              }
                            }}
                          />
                          {/* {weighterror}*/}
                          <p style={{ color: "red" }}>{weighterror}</p>
                        </Form.Group>
                      </Col>

                      <Col md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Height(ft)*</Form.Label>
                          <Form.Control
                            value={heightft}
                            type="number"
                            max={9}
                            onWheel={() => document.activeElement.blur()}
                            placeholder="Enter Height "
                            min={1}
                            onChange={(e) => onChangeHeightFt(e)}
                            onKeyDown={(e) => {
                              if (!e.target.value && e.code === "Space") {
                                e.preventDefault();
                              }
                            }}
                          />
                          {/* {heightfterror} */}
                          <p style={{ color: "red" }}>{heightfterror}</p>
                        </Form.Group>
                      </Col>
                      <Col md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Height(inches)</Form.Label>
                          <Form.Control
                            value={heightinch}
                            type="number"
                            placeholder="Enter Height "
                            onWheel={() => document.activeElement.blur()}
                            min={0}
                            max={11}
                            onChange={(e) => onChangeHeightInch(e)}
                            onKeyDown={(e) => {
                              if (!e.target.value && e.code === "Space") {
                                e.preventDefault();
                              }
                            }}
                          />
                          {/* {heightincherror} */}
                          <p style={{ color: "red" }}>{heightincherror}</p>
                        </Form.Group>
                      </Col>
                      <Col md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Email*</Form.Label>
                          <Form.Control
                            type="email"
                            value={email}
                            disabled
                            placeholder="Enter Email"
                          />
                        </Form.Group>
                      </Col>

                      <Col md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Gender*</Form.Label>
                          <Form.Select
                            value={gender}
                            aria-label="Default select example"
                            onChange={(e) => onChangeGender(e)}
                          >
                            <option value={""}>Select Gender</option>
                            <option value={"male"}>Male</option>
                            <option value={"female"}>Female</option>
                            <option value={"other"}>Other</option>
                          </Form.Select>
                          <p style={{ color: "red" }}>{genderError}</p>
                        </Form.Group>
                      </Col>
                      <Col md={6} lg={4} className="position-relative">
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Mobile</Form.Label>
                          <PhoneInput
                            country={"in"}
                            placeholder={"Enter phone number"}
                            className={
                              //   contactUsFieldsErrors.phone
                              //     ? "border-red phone-input"
                              "phone-input"
                            }
                            value={mobile}
                            countryCodeEditable={false}
                            onChange={(value, data, event, formattedValue) =>
                              onPhoneChanged(value, data, event, formattedValue)
                            }
                          />
                        </Form.Group>
                        <p style={{ color: "red" }}>{mobileErr}</p>

                        {mobile != "" && !verifymobile ? (
                          <button
                            className="verify-bnt"
                            onClick={handleshow}
                            type="button"
                          >
                            Verify
                          </button>
                        ) : null}
                        <>
                          {" "}
                          {mobile != "" && verifymobile ? (
                            <div className="mb-3 verified-icon">
                              <RiVerifiedBadgeFill color="green" size="20px" />
                            </div>
                          ) : null}
                        </>
                      </Col>
                    </Row>

                  </Col>
                </Row>
                <Row>
                  {/* <Col md={6}>
                    <div className="upload-family-img profile-upload">
                      <img
                        className=""
                        id="member_image"
                        src={imageUrl ? imageUrl : require("../../assests/images/family-img.png")}
                        alt="img"
                      />
                      <Form.Control id="member_profile" onChange={(e) => {
                        setProfileImage(e.target.files[0])
                        setImageUrl(URL.createObjectURL(e.target.files[0]))
                      }} type="file" accept="image/*"></Form.Control>
                      <p>Upload Profile Picture here</p>
                    </div>
                  </Col> */}




                  <Col md={12}>
                    <div className="profile-medication-area">
                      <h6>Medication</h6>
                      {medication &&
                        medication.map((item, index) => {
                          let dosage = parseInt(medication[index]?.dosage);
                          let unit = parseInt(medication[index]?.unit);
                          //  let dosage = parseInt(med[0])
                          //    console.log(med)
                          return (
                            <>
                              <Form className="medication-form">
                                <Row>
                                  <Col md={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Name</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter Name"
                                        onChange={(e) =>
                                          onChangeMedicationName(
                                            index,
                                            e.target.value
                                          )
                                        }
                                        value={medication[index]?.name}
                                        onKeyDown={(e) => {
                                          if (
                                            !e.target.value &&
                                            e.code === "Space"
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                    </Form.Group>
                                  </Col>
                                  {/* {console.log("medication.......", medication)} */}
                                  <Col md={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Dosage</Form.Label>
                                      <div className="dosage-area">
                                        <Form.Control
                                          type="number"
                                          placeholder="Enter Dosage"
                                          onWheel={() =>
                                            document.activeElement.blur()
                                          }
                                          value={dosage}
                                          onChange={(e) =>
                                            onChangeMedicationDosage(
                                              index,
                                              e.target.value
                                            )
                                          }
                                          onKeyDown={(e) => {
                                            if (
                                              !e.target.value &&
                                              e.code === "Space"
                                            ) {
                                              e.preventDefault();
                                            }
                                          }}
                                        />

                                        <Form.Select
                                          aria-label=".form-select-lg example"
                                          style={{ width: "20%" }}
                                          onChange={(e) =>
                                            onChangeMedicationUnit(
                                              index,
                                              e.target.value
                                            )
                                          }
                                          value={
                                            medication[index]?.unit || unit
                                          }
                                        >
                                          <option value="MG">MG</option>
                                          <option value="ML">ML</option>
                                        </Form.Select>
                                      </div>
                                    </Form.Group>
                                  </Col>
                                </Row>

                                {index == medication?.length - 1 &&
                                  medication?.length < 5 && (
                                    <p
                                      style={{ cursor: "pointer" }}
                                      onClick={() => addMoreMedications()}
                                    >
                                      + Add More
                                    </p>
                                  )}
                                {medication?.length > 1 ? (
                                  <a
                                    href="javascript:;"
                                    className="delete-text"
                                    onClick={() =>
                                      DeleteAddMoreMedications(index, item)
                                    }
                                  >
                                    <i
                                      class="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                ) : null}
                              </Form>
                            </>
                          );
                        })}
                    </div>
                  </Col>
                  <Col md={6}>
                    {/* <div className="profile-medication-area">
                      <h6>Disease</h6>
                      <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Name</Form.Label>
                          <Form.Control type="text" placeholder="Enter Name" />
                        </Form.Group>
                        <Row>
                          <Col md={5}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Diagnosedate</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Diagnosedate"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={7}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Stage</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Stage"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>
                      <p>+ Add More</p>
                    </div> */}
                  </Col>
                </Row>
                <Button
                  className="profile-submit-btn"
                  variant="unset"
                  type="button"
                  onClick={(e) => OnSubmit(e)}
                >
                  Submit
                </Button>
                <Button
                  onClick={(e) => navigate('/my_profile')}
                  variant="unset"
                  type="button"
                  className="profile-submit-btn back-btn"
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>

          <Modal
            className="add-notes"
            show={showModal}
            onHide={() => {
              handleClose();
            }}
            centered
          >
            <Modal.Header closeButton className="p-0">
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0 mt-3">
              <Form>
                <div class=" p-2 text-center">
                  <h6 className="mb-3">
                    Please enter the one time password(OTP) to verify your phone
                    number.
                  </h6>
                  <div>
                    {" "}
                    <span>A code has been sent to</span>{" "}
                    <small>*******{mobile.substring(mobile.length - 4)}</small>{" "}
                  </div>
                  <div
                    id="otp"
                    class="inputs d-flex flex-row justify-content-center mt-2 verify-input-field"
                  >
                    <OtpInput
                      class=""
                      value={otp}
                      onChange={setotp}
                      numInputs={6}
                      // renderSeparator={<span>-</span>}
                      renderInput={(props) => (
                        <input
                          {...props}
                          className="m-2 text-center form-control rounded"
                        />
                      )}
                      inputStyle={{ width: "3em" }}
                    />
                  </div>
                  <p className="error text-danger">{otpErr}</p>
                  <div class="mt-4">
                    {" "}
                    <button
                      type="button"
                      class="btn btn-primary px-4 validate"
                      onClick={verifyOtp}
                    >
                      Verify
                    </button>{" "}
                  </div>
                  {counter > 0 && (
                    <p className="mt-3">
                      Resend OTP in{" "}
                      <span className="timer">
                        <i
                          class="fa fa-clock-o position-unset"
                          aria-hidden="true"
                        ></i>
                        {"00:" + counter}
                      </span>
                    </p>
                  )}
                  {counter == 0 && (
                    <div class="mt-4">
                      {" "}
                      <button
                        type="button"
                        class={
                          counter == 0
                            ? "btn btn-warning px-4 validate"
                            : "btn btn-disabled px-4 validate"
                        }
                        onClick={resendOtp}
                      >
                        Resend OTP
                      </button>{" "}
                    </div>
                  )}
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        </Container>
      </section>
    </>
  );
};
export default MyProfile;
